import React, {forwardRef} from "react";
import FileManager from "./FileManager";

function PolygonFileManager({polygonPath, setPolygonPath, projectName}, ref) {
  function updatePolygon(polygonPath) {
    const parsedPolygonPath = JSON.parse(polygonPath);
    setPolygonPath(parsedPolygonPath);
  }

  function getFileName() {
    return `polygon-${polygonPath.length}-points-${projectName}-${new Date(Date.now())
      .toLocaleString()
      .split(",")[0]
      .replaceAll(".", "-")}.json`;
  }

  return <FileManager ref={ref} fileName={getFileName()} data={polygonPath} onFileChange={updatePolygon} accept=".json" />;
}

export default forwardRef(PolygonFileManager);
