import React, {useEffect, useState} from 'react';
import {OverlayView} from '@react-google-maps/api';
//import SectorsDialog from '../Sector/SectorsDialog'
import styled from "styled-components";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";
import useProject from "../../Hooks/useProject";
import Constants from "../../controller/Constants";
// C:\Users\aviad\Documents\GitHub\flycomm\Smart Cities\smart-cities3-react\src\components\Map\Marker.js
const MarkerDiv = styled.div`
  &&{
      z-index: 1;
      &.marker .marker-wrapper:before {
        background-color: ${({backgroundColor}) => backgroundColor} !important;
        ${({isProd, imageUrl}) => isProd && imageUrl && `
        ${imageUrl}
        `}
      }
      &.marker{
        text-align: start;
      }
      .title{
        opacity: 0;
        transition: opacity 800ms ease-in;
        &:hover{
          opacity: 1;
        }
      }
      &.marker .marker-wrapper .pin .image{
         box-shadow: none;
        &::after{
          border-top-color:${({theme}) => theme.components.marker.color};
        }
      }
      &.marker .marker-wrapper .pin{
        border-color:${({theme}) => theme.components.marker.color};
        &::before{
            background-color:${({theme}) => theme.components.marker.color};
            border-color:${({theme}) => theme.components.marker.color};
        }
      }
  }
`;

const Title = styled.h3`
  color: #fff;
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 30px;
`;

function Marker(props) {
 // console.log("--------------------inside Marker.js")
 // console.log("--------------------inside Marker.js - props:", props.site)

    return <OverlayView
        position={props.site  }
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
        <div style={{position: 'relative'}}>
            <MarkerDiv
                // backgroundColor={props.site.length > 0 ? '#595763' : '#E42324'}
                className="marker">
                <div className="title">{"Default tag name"}</div>
                <div className="marker-wrapper">
                    <div className="pin">
                        <Title className='image'>64</Title>
                    </div>
                </div>
            </MarkerDiv>
        </div>
    </OverlayView>;
}


export default (Marker);