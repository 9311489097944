
export const mapLayers = {
  RSSI_COVERAGE: "RSSI - Coverage Optimized",
  RSSI_CAPACITY: "RSSI - Capacity Optimized",
  MCS_COVERAGE: "MCS - Coverage Optimized",
  MCS_CAPACITY: "MCS - Capacity Optimized",
  BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
  BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
  RSRP_COVERAGE: "RSRP - Coverage Optimized",
  RSRP_CAPACITY: "RSRP - Capacity Optimized",
  RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
  RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
};

export const providersColors = {
    // wxtract to "theam"?
    CELLCOM_COLOR: "#E0B0FF",//#8884d8",
    PELEPHONE_COLOR: "#0096FF		", //"#82ca9d"  #0096FF	
    PARTNER_COLOR: "#48D1CC",  //"#ffc658"
  
  };

export const colorsForCoveragePreformence = ['#00b14f', '#94c954', '#f6eb14', '#fec00f', '#ed2024']//["#1f77b4", "#ff7f0e", "#2ca02c"]
export const ColorsForPowerDensity = ['#00b14f', '#94c954', '#f6eb14', '#fec00f', '#ed2024', "#ffff"]




