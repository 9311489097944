import React, {useState, useRef, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Api from "../../controller/ApiManager/index";
import {openDialog, setDialogSite} from "../../store/actionCreators/general";
import {addToast} from "../../store/actionCreators/general";
import {useFetchCallback} from "../../Hooks/useFetchCallback";
import {modals} from "../../controller/Constants";
import {addProject} from "../../store/actionCreators/map";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import PolygonInput from "../Common/Inputs/PolygonInput";
import {useFetchCities} from "../../Hooks/useFetchCities";

const polygonVisibleButtons = {saveVisible: true, resetVisible: true, deleteVisible: false, uploadVisible: true, downloadVisible: true};

const MapContainer = styled.div`
  height: 400px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const ModalContent = styled.div`
  width: 40vw;
  .checkbox .form-check-input {
    position: relative;
    bottom: -7px;
    margin-top: 10px;
  }
`;

const SubmitButton = styled(Button)`
  &.btn[type="submit"] {
    margin-top: 20px !important;
  }
`;

const DisabledMessage = styled.p`
  display: flex;
  color: red;
`;

function AddProject({onClose: closeDialog, addToast, addProject}) {
  const [projectName, setProjectName] = useState("");
  const [polygonPath, setPolygonPath] = useState([]);
  const [citySelectedId, setCitySelectedId] = useState("");
  const [cityName, setCityName] = useState(null);
  const {t} = useTranslation();
  const isChecked = useRef(null);
  const history = useHistory();
  const cities = useFetchCities()
  
  const handleSubmit = useFetchCallback(
    async (ev) => {
      ev.preventDefault();
      const cityInfo = {id:citySelectedId===""?null:citySelectedId, name: cityName};
      const newProject = await Api.Project.addProject(projectName, polygonPath, cityName, cityInfo);
      addToast(`Successfully added Project "${newProject.name}" to Database!`);
      addProject({...newProject});
      if (isChecked.current.checked) {
        history.push("/projects/" + newProject._id);
      } 
      closeDialog();
    },
    [projectName, cityName, polygonPath, closeDialog, citySelectedId, cities],
    {
      setError: (err) => {
        console.error(err);
        closeDialog();
      },
    }
  );

  return (
    <ModalContent className="modal-static">
      <Modal.Header closeButton className="modal-static">
        <div className="section-title">
          <h2>{t("Add_Project_Title")}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form className="form inputs-underline" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Form.Group>
                <Form.Label htmlFor="project_name" column={false}>
                  {t("Project_Name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="project_name"
                  placeholder="Please enter..."
                  onChange={(ev) => setProjectName(ev.target.value)}
                  value={projectName}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="city_name" column={false}>
                  {t("City_Name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="city_name"
                  placeholder="Please enter..."
                  onChange={(ev) =>{
                    setCityName(ev.target.value)
                    const findCityByName = cities.find(city=>city.name === ev.target.value)
                    if(findCityByName!==undefined && ev.target.value=== findCityByName.name){
                      setCitySelectedId(findCityByName._id)
                    } else{
                      setCitySelectedId("")
                    }
                  }}
                  value={cityName}
                />
              </Form.Group>
                 <Form.Group>
                 <Form.Label htmlFor="select_city" column={false}>
                  {t("Select_City_Name")}
                </Form.Label>
                 <Form.Control
                     as="select"
                     custom
                     value={citySelectedId}
                     onChange={e => {
                      setCityName(cities.find(city=>city._id === e.target.value).name)
                      setCitySelectedId(e.target.value)
                       }}
                  >
                    <option value={""} >Select city</option>
                   {
                      cities.map((city)=>{
                       return <option key={city._id} value={city._id}>{city.name}</option>
                     })
                    }   
        </Form.Control>
                 </Form.Group>
            </div>
          </div>
          <Form.Text>please drag the marker or right click to set the location:</Form.Text>
          <MapContainer>
            <PolygonInput
              projectName={projectName}
              visibleButtons={polygonVisibleButtons}
              onPolygonPathChanged={setPolygonPath}
              polygonPath={polygonPath}
            />
          </MapContainer>

          <Form.Group className="checkbox">
            <Form.Check ref={isChecked} type="checkbox" label="Go to the new project" />
          </Form.Group>
          <Form.Group className="center">
            <SubmitButton type={"submit"} variant={"primary"} className="width-100" disabled={polygonPath.length < 3}>
              {t("Add_Project")}
            </SubmitButton>
            {polygonPath.length < 3 && <DisabledMessage>* Please enter a polygon and click 'Done' to enable this button</DisabledMessage>}
          </Form.Group>
        </Form>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(() => ({}), {setDialogSite, addToast, addProject})(AddProject);