import React from "react";
import DropdownItem from "./Common/Dropdown/DropdownItem";
import StarsBarInfo from "./Common/StarsBarInfo";
import {techUsageCategoriesThresholds, technolegyCoverage} from "../controller/Constants";
import {between} from "../controller/Constants";

function  getTechnologyInfo(signal){
return technolegyCoverage.find(({range})=>between(signal, range.max, range.min))
}

export function calcTechnologyCoverage(signal){
    if (between(signal, 20, 0)) return   {color:'#ed2024', lable:'Poor Coverage', starRate:1}
    if (between(signal, 40, 20)) return  {color:'#fec00f', lable:'Low Coverage', starRate:2}
    if (between(signal, 60, 40)) return  {color:'#f6eb14', lable:'Moderate Coverage', starRate:3}
    if (between(signal, 80, 60)) return  {color:'#94c954', lable:'Good Coverage', starRate:4}
    if (between(signal, 100, 80)) return {color:'#00b14f', lable:'High Coverage', starRate:5}
    if (signal < 0 || signal > 100) throw Error("Error calculating percentages");
  }

function TechnologyRating({userPreferences, technologiesCoverage, showIcons}) {
    let tech = "3G";
    if (userPreferences.technologies.includes("5G")) tech = "5G";
    else if (userPreferences.technologies.includes("4G")) tech = "4G";

    return (
        Object.keys(techUsageCategoriesThresholds[tech]).map((technology) => {
            if (technology != "General") {
                const {lable, color, starRate} = getTechnologyInfo(technologiesCoverage[technology])
                const percentage = technologiesCoverage[technology];
              return (
                <DropdownItem title={technology} icon={showIcons?<i className={"fa fa-cog nav-icon"} />:''} content={<StarsBarInfo showPercentage title={technology} lable={lable} color={color} starRate={starRate} percentage={percentage}/>} />
              );
                }
          })
  );
}

export default TechnologyRating;