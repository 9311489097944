import React, {useRef, useState} from "react";
import {useUserPreferencesState} from "./useUserPreferences";
import {colorsLabelsInfo} from "../controller/Constants";
import {between} from "../controller/Constants";

export function useGetColor() {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  return function (signal) {
    const levelInfo = colorsLabelsInfo[userPreferences.currentMapLayer].find(({levels}) => between(signal, levels.max, levels.min));
    if (!levelInfo) throw Error("The signal doesn't match any range of colors");
    return levelInfo.color;
  };
}

export function colorMapper(rssi, rssiLevels, mapLayerState) {
  if (mapLayerState.EM) {
    if (rssi > 1.12) return "#ed2024";
    if (between(rssi, 1.12, 0.00112)) return "#fec00f"; //"#7a5a07"
    if (between(rssi, 0.00112, 0.0000112)) return "#f6eb14";
    if (between(rssi, 0.0000112, 0.000000112)) return "#94c954";
    if (between(rssi, 0.000000112, 0.00000000112)) return "##00b14f";
    if (rssi < 0.00000000112) return "#ffffff"; // "#939393"
  } else {
    if (rssi > -80) return "#00b14f"; // green
    if (between(rssi, -80, -90)) return "#94c954"; // light green
    if (between(rssi, -90, -105)) return "#f6eb14"; // yellow
    if (between(rssi, -105, -120)) return "#fec00f"; // orange
    if (rssi < -120) return "#ed2024"; // red
  }

  if (mapLayerState.EM) {
    if (between(rssi, 0.01, 0.0004)) return "#f70a65"; //"#7a5a07"
    if (between(rssi, 4e-6, 4e-7)) return "#ff0800";
    if (between(rssi, 4e-7, 4e-8)) return "#ffc019";
    if (between(rssi, 4e-8, 4e-9)) return "#21ea00";
    if (between(rssi, 4e-9, 4e-10)) return "#b3f266"; // "#5ecdff"
    if (between(rssi, 4e-10, 4e-11)) return "#ffffff"; // "#939393"
    if (rssi < 4e-11) return "#ffffff"; // "#939393"
    // if(between(rssi, 4e-10, 4e-11)) return "#ffffff" // "#939393"
  } else {
    if (between(rssi, -20, -60)) return "#7a5a07";
    if (between(rssi, -60, -75)) return "#ff0800";
    if (between(rssi, -75, -90)) return "#ffc019";
    if (between(rssi, -90, -105)) return "#21ea00";
    if (between(rssi, -105, -120)) return "#5ecdff";
    if (rssi < -120) return "#939393";
    // return '#de34eb'
  }

  // const is5 = rssiLevels.length === 5;          <<<<<<<<<
  // const oneIf5 = (is5 ? 1 : 0);                 <<<<<<<<<
  // if (rssi <= rssiLevels[3 + oneIf5])           <<<<<<<<<
  //     return '#939393';                         <<<<<<<<<
  // if (rssi <= rssiLevels[2 + oneIf5])           <<<<<<<<<
  //     return '#ff0800';                         <<<<<<<<<
  // if (rssi <= rssiLevels[1 + oneIf5])           <<<<<<<<<
  //     return '#ffc019';                         <<<<<<<<<
  // if (rssi <= rssiLevels[1])
  //     return '#fcff00';
  // if (rssi <= rssiLevels[0])
  //     return '#5ecdff';
  // return '#21ea00';
}
