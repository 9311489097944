//optimize

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = { "count": 0, "list": [] }
    }
    acc[key].list.push(obj)
    acc[key].count++
    return acc
  }, [])
}

function getRange(key) {
  switch (true) {
    case (key >= 0.00000000001 && key <= 0.000000001):
      return 0;
    case (key > 0.000000001 && key <= 0.0000001):
      return 1;
    case (key >= 0.0000001 && key <= 0.00001):
      return 2;
    case (key > 0.00001 && key <= 0.001):
      return 3;
    case (key > 0.001 && key <= 0.1):
      return 4;
    case (key > 0.1 && key <= 10):
      return 5;
    case (key > 10 && key <= 120):
      return 6;
    default: 
      return -1
  }
}

function calcEMBinValue(RSSIValue) {
  return (Math.pow(10, RSSIValue / 10) / 1000) * 112000;
}


export function groupByRange(objectArray) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[0]
    var range = getRange(calcEMBinValue(key))
    if (!acc[range]) {
      acc[range] = { "count": 0, "list": [] }
    }
    acc[range].list.push(obj[1].list)
    acc[range].count += obj[1].count
    return acc
  }, [])
}

export function sumAllKeysValue(groupedBySignalRange) {
  var sum = 0;
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);//what with -1
    sum += value.count;
  }
  return sum;
}

export function toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, data) {
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);
    if (key != "-1")
      data[key].dBm = ((value.count / sumOfAllKeysValue) * 100).toFixed(1);;
  }
  return data;
}