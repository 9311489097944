import {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setProject as setReduxProject} from "../store/actionCreators/general";
import {setAsyncProject} from "../store/actionCreators/general";
import Api from "../controller/ApiManager/index";
import {usePrevious} from "./common";
import {useUserPreferencesState} from "./useUserPreferences";
import EventEmitter from "../controller/EventEmitter";
import {events, mapLayerTypes, mapLayerTypesList} from "../controller/Constants";
import {DEFAULT_PROJECT_PARAMS} from "../store/defaultValues/general";

function useProject() {
  const project = useSelector((state) => state.general.project);
  return project;
}

export function useSetProject() {
  const dispatch = useDispatch();
  const setProject = useCallback((project) => dispatch(setReduxProject(project)), [dispatch]);
  return setProject;
}

export function useSetAndUpdateProject() {
  const dispatch = useDispatch();
  const setProject = useCallback(async (project) => dispatch(await setAsyncProject(project)), [dispatch]);
  return setProject;
}

function useProjectByCurrentMapLayer(setFetching, setError) {
  const [project, setProject] = useProjectState();
  const previousProject = usePrevious(project);
  const {projectId} = useParams();

  useEffect(() => {
    if (previousProject?.defaultMapLayer === project.defaultMapLayer || !previousProject?._id || previousProject?._id !== project._id) {
      return;
    }
    setFetching(true);
    console.log("getProject 4");
    Api.Project.getProject(projectId, {binsPopulated: true}, {mock: false})
      .then((projectRes) => {
        // call to the server with loader
        console.log("before setProject 3");
        // debugger;
        setProject(projectRes);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [project.defaultMapLayer]);
}

function useFetchProjectById(setError, setFetched, fetchProject) {
  const {projectId} = useParams();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setError(null);
    setFetched(false);
    fetchProject().then(() => ({}));
  }, [projectId, user.isAuthenticated]); // כניסה חדשה או פרויקט אחר.
}

export function useFetchProject() {
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const {projectId} = useParams();
  const user = useSelector((state) => state.user);
  const [project, setProject] = useProjectState();
  const previousProject = usePrevious(project);

  async function fetchProject() {
    let projectRes;
    try {
      if (!user.isAuthenticated) {
        return;
      }
      setFetching(true);
      projectRes = await Api.Project.getProject(projectId); // call to the server with loader
      if (!projectRes) return;
      setProject(projectRes);
      setFetched(true);
      setError(null);
    } catch (err) {
      console.error(err);
      setError(err);
      return;
    } finally {
      setFetching(false);
      setFetched(true);
    }
    return projectRes;
  }

  EventEmitter.subscribe(events.UPDATE_PROJECT, fetchProject); // look inside

  useFetchProjectById(setError, setFetched, fetchProject);

  useProjectByCurrentMapLayer(setFetching, setError);

  return {fetching, error, fetched};
}

export function useProjectParams() {
  const project = useProject();
  const {mapLayersParams} = project;
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;
  const desiredMapLayer = isBestServer ? mapLayerTypes.CELLULAR : userPreferences.currentMapLayer;
  const layerParams = mapLayersParams.find((layerParams) => layerParams.mapLayer === desiredMapLayer);
  if (!layerParams) return DEFAULT_PROJECT_PARAMS;

  return layerParams.projectParams;
}

export default useProject;

export function useProjectState() {
  return [useProject(), useSetProject()];
}

export function useProjectActions() {
  return {
    project: useProject(),
    setProject: useSetProject(),
    setAndUpdateProject: useSetAndUpdateProject(),
  };
}
