import React from "react";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import Home from "./components/Home";
import DialogGenerator from "./components/DialogGenerator";
import styled, {ThemeProvider} from "styled-components";
import AlertGenerator from "./components/AlertsGenerator";
import Application from "./controller";
import {theme} from "./configuations/theme";
import ChartsDashboard  from "./components/ChartsAndGraphs/chartsDashboard";


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const application = new Application();

function App() {
  return (
    <Provider store={application.store}>
      <ThemeProvider theme={theme}>
        <Router>
          <PageWrapper className="page-wrapper-div">
            <Navbar />
            <DialogGenerator />
            <AlertGenerator />
            <Switch>
              <Route path="/projects/:projectId">
                <Home />
              </Route>
              <Route path="/charts" component={ChartsDashboard} />
          {/* <Route path="/projects/:projectId/charts" component={ChartsDashboard} />/ */}
              <Route path="/" component={Home} />
              {/* /  need id project */}
            </Switch>
          </PageWrapper>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
export {application};
