import React, { useState, useEffect, useMemo } from "react";
import { Polygon, useGoogleMap } from "@react-google-maps/api";
import Bin from "./Bin";
import OverlayScreenView from "../MapControls/OverlayScreenView";
import Constants, { mapLayerValues } from "../../controller/Constants";
import { useMapState, usePositionMapAccordingToPolygon } from "../../Hooks/map";
import LocationMarker from "./LocationMarker";
import { useArieCoverageReliabilityState, usePlacementsActions, usePolygonPath } from "../../Hooks/common";
import StandardMapEffects from "./StandardMapEffects";
import RealTimeMapEffects from "./RealTimeMapEffects";
import useProject, { useProjectParams } from "../../Hooks/useProject";
import SitesClusterer from "./SitesClusterer";
import useMapDetails from "../../Hooks/useMapDetails";
import AreaSelectionPolygon from "../MapControls/AreaSelectionPolygon";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import PredictionSwitch from "../MapControls/PredictionSwitch";
import { Marker } from '@react-google-maps/api';

const polygonOptions = {
  fillColor: "rgba(0,0,0,0.1)",
  strokeColor: "rgba(0,0,0,0.25)",
  zIndex: 0,
};

function MapContent() {
  const map = useGoogleMap();
  const mapState = useMapState();
  const isAreaSelectionState = mapState === Constants.mapStates.AREA_SELECTION;
  const polygonPath = usePolygonPath();
  const [binsArray, setBinsArray] = useState([]);
  const [allBinsArray, setAllBinsArray] = useState([]);
  const [globalTooltipID, setGlobalTooltipID] = useState('');

  var mapBounds = new window.google.maps.LatLngBounds();
  const projectParams = useProjectParams();
  const project = useProject();
  const { clearPlacements } = usePlacementsActions();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const [ArieCoverageReliability, setArieCoverageReliability] = useArieCoverageReliabilityState()
  let key;

  useEffect(() => {
    console.log({ binsArray })
  }, [binsArray]);
  useEffect(() => {
    console.log({ allBinsArray })
  }, [allBinsArray]);

  useEffect(() => {
    const provider = userPreferences.providers[0];
    const technology = userPreferences.technologies[0];
    if (userPreferences.technologies.length > 0 && userPreferences.providers.length > 0) {

      if (userPreferences.technologies[0].includes("LTE M") && userPreferences.providers[0].includes("AT&T")) {
        key = "AT&T (AT&T Mobility) | LTE M | United States\r"


      } else if (userPreferences.technologies[0].includes("LTE M") && userPreferences.providers[0].includes("Verizon")) {
        key = "Verizon Wireless | LTE M | United States"


      } else if (userPreferences.technologies[0].includes("NB-IoT") && userPreferences.providers[0].includes("Verizon")) {
        key = "Verizon Wireless | NB-IoT | United States"
      


      }
    }

    // debugger
    if (key  &&  allBinsArray.length > 0) {
      const newBinsArray = allBinsArray.map((bin) => ({ clusteredBins: [], location: { lat: Number(bin.lat), lng: Number(bin.lng) }, signal: bin[key].includes("Yes") ? -60 : -130 }))
      debugger
      // const mapBounds = new window.google.maps.LatLngBounds();

      // newBinsArray.forEach((point) => mapBounds.extend(point.location));
      // map.fitBounds(mapBounds);



      // map.fitBounds(mapBounds, { bottom: 200, right: 200, left: 200, top: 100 });

      setBinsArray(newBinsArray);
      // setArieCoverageReliability(allBinsArray[key].coverageReliability)

    } else {
      setBinsArray([]);

    }
  }, [allBinsArray, userPreferences]);
  // useEffect(() => {
  //   const provider = userPreferences.providers[0];
  //   const technology = userPreferences.technologies[0];
  //   if(provider && technology  && allBinsArray.length > 0 && allBinsArray.every(bin => `${provider}-${technology}` in bin)){
  //      const newBinsArray = allBinsArray.map((bin)=>({clusteredBins:bin.clusteredBins, location:bin.location, signal: bin[`${provider}-${technology}`] === "Yes"? -60: -120 })) 

  //     setBinsArray(newBinsArray);
  //   }else{
  //     setBinsArray([]);

  //   }
  // }, [allBinsArray, userPreferences]);

  useEffect(() => {
    clearPlacements();
  }, [project._id]);


  const { currentMapLayer } = userPreferences;
  const { REAL_TIME, HEAT_MAP, CELLULAR } = mapLayerValues;
  usePositionMapAccordingToPolygon(map, project.polygon);
  const { center, bounds } = useMapDetails({ binsArray });
  return (
    <>
      <PredictionSwitch setAllBinsArray={setAllBinsArray} />

      <StandardMapEffects setBinsArray={setBinsArray} binsArray={binsArray} />
      {/* {projectParams.isRealTime ? (
        <RealTimeMapEffects setBinsArray={setBinsArray} />
      ) : (
        <StandardMapEffects setBinsArray={setBinsArray} binsArray={binsArray} />
      )} */}
      {/* <SitesClusterer sites={sites} mapBounds={mapBounds} /> */}

      {/*bins display*/}
      {/* <Marker
      onLoad={(a)=>console.log({load232222222:a})}
      position={{lat: 37.512568, lng: -122.2706}}
    /> */}
      {/* <Bin
       details={{signal: -80, location: {lat: 37.512568, lng: -122.2706}}}
       signal={ -80}
       location={{lat: 37.512568, lng: -122.2706}}
       // key={`${bin.location.lat},${bin.location.lng},${bin.signal}`}
      //  binID={`${bin.location.lat},${bin.location.lng},${bin.signal}`}
       clusteredBins={[]}
       globalTooltipID={globalTooltipID}
       setGlobalTooltipID={setGlobalTooltipID}
       ></Bin> */}
      {binsArray.map((bin) => (
        <Bin
          details={{ signal: bin.signal, location: bin.location }}
          signal={bin.signal}
          location={bin.location}
          key={`${bin.location.lat},${bin.location.lng},${bin.signal}`}
          binID={`${bin.location.lat},${bin.location.lng},${bin.signal}`}
          clusteredBins={bin.clusteredBins}
          globalTooltipID={globalTooltipID}
          setGlobalTooltipID={setGlobalTooltipID}
        />
      ))}
      {(isAreaSelectionState || polygonPath.length > 2) && <AreaSelectionPolygon />}
      <OverlayScreenView bins={binsArray} />
      {/*location stats Marker*/}
      <LocationMarker bins={binsArray} />
      <Polygon path={project.polygon} editable={false} draggable={false} options={polygonOptions} />
    </>
  );
}

// MapContent = React.memo(MapContent);

export default MapContent;
