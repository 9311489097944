import {useCallback, useEffect} from "react";
import useProject, {useFetchProject} from "./useProject";
import Api from "../controller/ApiManager/index";
import {setProjects} from "../store/actionCreators/map";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import EventEmitter from "../controller/EventEmitter";
import {useAuth} from "./useAuthentication";
import {useInitDisplayedAntennas} from "./displayedSectors";
import {DisplayedAntennasManager as DAM} from "../controller/models/classes";
import {useFetchUserPreferences} from "./useUserPreferences";
import { openDialog } from "../store/actionCreators/general";
import { modals } from "../controller/Constants";

window.EventEmitter = EventEmitter;

function useFetchProjects() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const setProjectsRedux = useCallback(
    (projects) => {
      return dispatch(setProjects(projects));
    },
    [dispatch]
  );
  useEffect(() => {
    if (user.isAuthenticated) {
      Api.Project.getProjects()
        .then((projects) => {
          // call to the server with loader
          setProjectsRedux(projects || []);
        })
        .catch(console.error);
    }
  }, [user]);
}

function useRedirectProject() {
  const isInSomeProject = useRouteMatch("/projects/:projectId");
  const history = useHistory();
  const params = useParams();
  const {projectId} = params;
  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.map.projects);
  useEffect(() => {
    const hasProjects = Boolean(projects.length);
    const currentProjectExist = projectId ? hasProjects && projects.map((project) => project._id).includes(projectId) : true; // :false ?
    if ((!isInSomeProject || !currentProjectExist) && hasProjects) {
      // history.push("/projects/" + "61c2f32bb6218800086aa557");
      history.push("/projects/" + projects[0]._id);
    }
  }, [projectId, user.isAuthenticated, projects.map((project) => project._id)]);
}

function useProjectsDialog() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.map.projects);
  const isInSomeProject = useRouteMatch("/projects/:projectId");
  const params = useParams();
  const {projectId} = params;
  useEffect(() => {
    const hasProjects = Boolean(projects.length);
    const isProjectExistInProjects = projectId ? hasProjects && projects.map((project) => project._id).includes(projectId) : true; // :false ?
    const validUrlProject = isInSomeProject && isProjectExistInProjects
    if(user.isAuthenticated && !(validUrlProject) )
      dispatch(openDialog(modals.PROJECT_LIST))
  }, [projects]);
}

function useInitApp() {
  useFetchProjects();
  // useRedirectProject();
  useProjectsDialog();
  const {fetching: projectFetching, fetched: projectFetched, error: projectError} = useFetchProject();
  useFetchUserPreferences();
  const {error: userError, fetched: userFetched, fetching: userFetching} = useAuth();
  const requireLogin = Boolean(userError && userError.message);
  return {
    fetching: projectFetching || userFetching,
    projectFetched,
    userFetched,
    error: userError || projectError,
    requireLogin,
  };
}

export default useInitApp;
