
import { Col, Row, Container } from "react-bootstrap"
import BarRechartComponent from "./powerDensity/bar.recharts";
import AreachartComponent from "./areacharts/area.rechart";
import { monthData } from "./mockData";

function PowerDensity({ densityData }) {
    return (<>
        <Container fluid style={{ textAlign: "center" }} >
            {/* <div >
                <h2 style={{ textDecoration: "underline" }}  > Power Density</h2>
            </div> */}
            <Row  >
                <Col md={12}lg={6}xl={4}> <h4  > Cellcom-4G</h4>  <BarRechartComponent barData={densityData.filter(placement => placement.provider === "cellcom" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col>
                <Col md={12}lg={6}xl={4}> <h4  > Pelephone-4G</h4> <BarRechartComponent barData={densityData.filter(placement => placement.provider === "pelephone" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col>
                <Col md={12}lg={6}xl={4}> <h4  > Partner-4G</h4> <BarRechartComponent barData={densityData.filter(placement => placement.provider === "partner" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col>
                {/* <Col md={12}lg={6}xl={3}> <h4  > Partner-4G</h4> <BarRechartComponent barData={densityData.filter(placement => placement.provider === "partner" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col> */}

                </Row>
            <Row >
                {/* <Col md={3}>  <BarRechartComponent barData={densityData.filter(placement => placement.provider === "cellcom" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>
                <Col md={3}>  <BarRechartComponent barData={densityData.filter(placement => placement.provider === "pelephone" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>
                <Col md={3}>  <BarRechartComponent barData={densityData.filter(placement => placement.provider === "partner" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col> */}
            </Row>
        </Container>
    </>
    );
}
export default PowerDensity;

