import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { setMapState } from "../../store/actionCreators/map";
import { connect, useDispatch } from "react-redux";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import { useArieCoverageReliabilityState, usePolygonPath, usePredictionState } from "../../Hooks/common";
import { openDialog } from "../../store/actionCreators/general";
import Constants, { modals, mapLayerTypes } from "../../controller/Constants";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import useProject from "../../Hooks/useProject";
import { filterPointsByPolygon, polygonToLatLngObjects } from '../../controller/GEOoperations'
import * as XLSX from 'xlsx'
import JSZip from 'jszip';
import Api from "../../controller/ApiManager";

const Div = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
`;
function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}
function PredictionSwitch({ setAllBinsArray, provider }) {
  const { t } = useTranslation();
  const [predictionState, setPredictionState] = usePredictionState();
  const [userPreferences] = useUserPreferencesState();
  const timeoutRef = useRef();
  const polygonPath = usePolygonPath();
  const dispatch = useDispatch();
  const project = useProject();
  const [lines, setLines] = useState([])
  const [ArieCoverageReliability, setArieCoverageReliability] = useArieCoverageReliabilityState()
  
  const choices = [
    { value: "prediction", label: t("predict_l"), checked: predictionState, disabled: userPreferences.currentMapLayer === mapLayerTypes.HEAT_MAP },
    { value: "samples", label: t("samples_l"), checked: !predictionState }
  ];

  const handleSetPredictionState = (val) => {
    if (val === "prediction" && !predictionState) {
      if (polygonPath.length < 3) {
        return dispatch(openDialog(modals.CHOOSE_POLYGON_ALERT));
      } else {
        const userSelectedPolygon = polygonPath.map((point) => polygonToLatLngObjects(point));
        const userPointInProjectPolygon = filterPointsByPolygon(userSelectedPolygon, project.polygon)
        if (userSelectedPolygon.length > userPointInProjectPolygon.length) {
          return dispatch(openDialog(modals.CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON));
        }
      }
    }
    setPredictionState(val === "prediction");
  };

  useEffect(() => {
    const arr = lines.map(a => ({ ...a, location: { lat: Number(a.lat), lng: Number(a.lng) }, clusteredBins: [] }))
    const [head, ...tail] = arr;
    const arr2 = tail.map(({ lat, lng, ...rest }) => ({ ...rest }))
    console.log({ arr2 })
    // const arr = lines.map(a => ({location:{lat:Number(a.lat), lng:Number(a.lng)}, VerisonLTEM:a[provider] === "YES"? 110:60,clusteredBins:[]}))
    setAllBinsArray(arr2)
    // console.log({tail})
  }, [lines])

  function fileReader(oEvent) {
    var oFile = oEvent.target.files[0];
    var sFilename = oFile.name;

    var reader = new FileReader();
    var result = {};

    reader.onload = function (e) {
      var data = e.target.result;
      data = new Uint8Array(data);
      var workbook = XLSX.read(data, { type: 'array' });
      console.log(workbook);
      var result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        if (roa.length) result[sheetName] = roa;
      });
      // see the result, caution: it works after reader event is done.
      console.log({ Feuil1:result.Feuil1});

      const [head, ...tails] =  result.Feuil1;
      const spleatedTails = tails.map(a => a[0].split(",").filter(s => s))
      const atntYess = spleatedTails.filter(line => line[5] === "Yes")
      const verisonNBYess = spleatedTails.filter(line => line[3] === "Yes")
      const verisonLTEYess = spleatedTails.filter(line => line[4] === "Yes")

       const ans ={
        "AT&T-LTE M":atntYess / spleatedTails.length,
        "Verison-LTE M":verisonLTEYess / spleatedTails.length,
        "Verison-NB-IoT":verisonNBYess / spleatedTails.length,
      }
       console.log({ans});

      // setLines(result.Feuil1.map(a => a[0].split(",").filter(s => s)).map(a => ({ lat: a[1], lng: a[2], "AT&T-LTE M": a[3], "Verison-LTE M": a[4], "Verison-NB-IoT": a[5] })))
    };
    reader.readAsArrayBuffer(oFile);
  }

  function myReducer(accmulator, line) {
    [
      "AT&T (AT&T Mobility) | LTE M | United States\r",
      "Verizon Wireless | LTE M | United States",
      "Verizon Wireless | NB-IoT | United States"
    ].forEach((provider =>{
      if(line[provider]){
        accmulator[provider] += line[provider].includes("Yes")? 1: 0
      }
      
      }))
      accmulator["total"] += 1

    return accmulator;
  }

  function fileReaderCSV(oEvent) {
    var oFile = oEvent.target.files[0];
    var sFilename = oFile.name;

    var reader = new FileReader();
    var result = {};
    var result2 = {
      "AT&T (AT&T Mobility) | LTE M | United States\r":0,
   "Verizon Wireless | LTE M | United States":0,
   "Verizon Wireless | NB-IoT | United States":0
 };

    reader.onload = function (e) {
      const text = e.target.result;
      const data = csvToArray(text);
      console.log({data});
      const binsData = data.slice(0,5000).map(line => ({
        lat: line["LATITUDE"],
        lng: line["LONGITUDE"],
        "AT&T (AT&T Mobility) | LTE M | United States\r": line["AT&T (AT&T Mobility) | LTE M | United States\r"],
        "Verizon Wireless | LTE M | United States": line["Verizon Wireless | LTE M | United States"],
        "Verizon Wireless | NB-IoT | United States": line["Verizon Wireless | NB-IoT | United States"],
         }))
      // for(let l of data){
      //   debugger
      //   result2["AT&T (AT&T Mobility) | LTE M | United States\r"] += l["AT&T (AT&T Mobility) | LTE M | United States\r"].includes("Yes")? 1: 0
      //   result2["Verizon Wireless | LTE M | United States"] += l["Verizon Wireless | LTE M | United States"].includes("Yes")? 1: 0
      //   result2["Verizon Wireless | NB-IoT | United States"] += l["Verizon Wireless | NB-IoT | United States"].includes("Yes")? 1: 0
      
      // }
      const redAns = data.filter(line=>line).reduce(myReducer,{
         "AT&T (AT&T Mobility) | LTE M | United States\r":0,
      "Verizon Wireless | LTE M | United States":0,
      "Verizon Wireless | NB-IoT | United States":0,
      total:0
    })
    
    let final = {
      "AT&T (AT&T Mobility) | LTE M | United States\r": redAns["AT&T (AT&T Mobility) | LTE M | United States\r"] / redAns["total"],
      "Verizon Wireless | LTE M | United States": redAns["Verizon Wireless | LTE M | United States"] / redAns["total"],
      "Verizon Wireless | NB-IoT | United States": redAns["Verizon Wireless | NB-IoT | United States"] / redAns["total"],
  }
    console.log({final});
    setAllBinsArray(binsData)
    setArieCoverageReliability(final)
      // const spleatedTails = tails.map(a => a[0].split(",").filter(s => s))
      // const atntYess = spleatedTails.filter(line => line[5] === "Yes")
      // const verisonNBYess = spleatedTails.filter(line => line[3] === "Yes")
      // const verisonLTEYess = spleatedTails.filter(line => line[4] === "Yes")

      //  const ans ={
      //   "AT&T-LTE M":atntYess / spleatedTails.length,
      //   "Verison-LTE M":verisonLTEYess / spleatedTails.length,
      //   "Verison-NB-IoT":verisonNBYess / spleatedTails.length,
      // }

      // var data = e.target.result;
      // console.log(data);
      // console.log(data.split("\r\n"));
     };
    reader.readAsText(oFile);
  }
  function getBase64(file) {
    var reader = new FileReader();
    var ans
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      ans = reader.result

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
    return ans
 }
 

  function handleToZip(e) {
    
    const zip = new JSZip();
    const file = e.target.files[0];
    const fileReader = new FileReader();

    // fileReader.onload = async function (e) {
    fileReader.onload = function (e) {
       
      const arrayBuffer = e.target.result;
      zip.file(file.name, arrayBuffer);
      zip.generateAsync({ type: "blob"}).then(async function (content) {
        const fileName = `${file.name}.zip`
        const zipedFile = new File([content], fileName); // var files = new File([content], "name.zip");
        const formData = new FormData();
        // debugger
        formData.append('fileZip', zipedFile);
        // await Api.uploadExternalDataFile(formData,fileName)
        // debugger
        // const fileKey = `smart-cities-rest-api-${process.env.REACT_APP_STAGE}-external-data-files/${fileName}` 
        const dataResponse = await Api.processExternalDataFile(zipedFile)
        // debugger
        
          setAllBinsArray("dataResponse")
          // setArieCoverageReliability(res[key].coverageReliability)
          // setAllBinsArray(res[key].bins)
          // setArieCoverageReliability(res[key].coverageReliability)
        
      })
    }
    fileReader.readAsArrayBuffer(file);
  }
  async function sendOriginalFileToServer(e) {
    const file = e.target.files[0];
    var reader = new FileReader();
     reader.readAsDataURL(file);
    reader.onload = async function () {
      console.log(reader.result);
      const dataResponse = await Api.processExternalDataFile(reader.result)
    console.log({dataResponse})

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 


     

  }

  return (
    <Div>
      <input type="file" onChange={fileReaderCSV} />
      {/* <input type="file" onChange={fileReader}/> */}
      {/* <ButtonsSelect
        round={false}
        values={choices}
        onSelect={(obj) => {
          const {value: val} = obj;
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            handleSetPredictionState(val);
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }, 0);
        }}
      /> */}
    </Div>
  );
}

function mapStateToProps(reduxState) {
  return {};
}

export default connect(mapStateToProps, { setMapState })(PredictionSwitch);
