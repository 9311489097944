import { useDispatch, useSelector } from "react-redux";
import { setLocationsStats, setMapZoomCreator } from "../store/actionCreators/map";
import { useCallback, useEffect } from "react";
import { colorMapper, getRssiLevels } from "../controller/mapper";
import { useProjectParams } from "./useProject";
import { useUserPreferencesState } from "./useUserPreferences";
import { usePrevious } from "./common";
import EventEmitter from "../controller/EventEmitter";
import { events } from "../controller/Constants";

export function useMapState() {
  return useSelector((state) => state.map.mapState);
}

export function useMapZoom() {
  return useSelector((state) => state.map.mapZoom);
}

export function useSetMapZoom() {
  const dispatch = useDispatch();

  return useCallback((zoom) => dispatch(setMapZoomCreator(zoom)), [dispatch]);
}

export function useLocationStatsState() {
  const dispatch = useDispatch();
  const setLocationStats = useCallback((locationStats) => dispatch(setLocationsStats(locationStats)), []);
  const locationStats = useSelector((state) => state.map.locationStats);
  return [locationStats, setLocationStats];
}

export function useLocationStats() {
  return useSelector((state) => state.map.locationStats);
}

export function useMapType() {
  return useSelector((state) => state.map.mapType);
}

export function useRssiColor(rssi) {
  /// ????
  const mapType = useMapType();
  const projectParams = useProjectParams();
  const [userPreferences,setUserPreferences] = useUserPreferencesState();
  if (!rssi) return "#fff";
  const { mapLayer, channelBW, rssiThreshold } = projectParams;
  const rssiLevels = getRssiLevels(mapType, mapLayer, channelBW, rssiThreshold);
  const binColor = colorMapper(rssi, rssiLevels, userPreferences.currentMapLayer);
  return binColor;
}

export function usePositionMapAccordingToPolygon(map, polygon) {
  const previousPolygon = usePrevious(polygon);
  
  const positionMap = useCallback(()=>{
    const mapBounds = new window.google.maps.LatLngBounds();
    polygon.forEach((point) => mapBounds.extend(point));
    map.fitBounds(mapBounds, { bottom: 200, right: 200, left: 200, top: 100 });
    const newBounds = map.getBounds();
    //return newBounds
    return mapBounds
  },[polygon, map])
  
  
  useEffect(() => {
    let timeout;
    try {
      function retryPositionMap(tries){
        const newBounds = positionMap();
        if(!newBounds && tries > 0){
          timeout = setTimeout(()=>retryPositionMap(tries -1),2000);
        }
      }
      // dont reposition map is the polygon was just changed. only do it after the polygon was inserted and the previous polygon wasnt exist.
     // if (!previousPolygon || previousPolygon.length === 0) {
        retryPositionMap(3);
     // }
    } catch (error) {
      console.error(error);
      //todo: add log to the dev team.0
    }
    return ()=>{
      clearTimeout(timeout)
    }
  }, [polygon]);

  useEffect(() => {
    const eventId = EventEmitter.subscribe(events.REPOSITION_CENTER, positionMap);
    return () => {
      EventEmitter.unsubscribe(events.REPOSITION_CENTER, eventId)
    }
  },[positionMap])
}