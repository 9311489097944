import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Api from "../controller/ApiManager";
import { getFormatedStringDate } from "../controller/common";
import {dateRangeTypes, DAY_IN_MILLISECONDS, TECHNOLOGYS_LIST, PROVIDERS_LIST, mapLayerTypes} from "../controller/Constants";
import {setUserPreferences} from "../store/actionCreators/map";
import {useSetFetchLoader} from "./common";
import {getCognitoId} from "./useAuthentication";
import useProject from "./useProject";


// export function generateDateRange(dateType) {
//   let from;
//   let to;
//   if (dateType === dateRangeTypes.DAY) {
//     from = new Date(Date.now() - 7 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//     to = new Date(Date.now() - 1 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//   } else if (dateType === dateRangeTypes.WEEK) {
//     from = new Date(Date.now() - 14 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//     to = new Date(Date.now() - 7 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//   } else if (dateType === dateRangeTypes.MONTH) {
//     from = new Date(Date.now() - 60 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//     to = new Date(Date.now() - 30 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0);
//   }
//   return {dateType: dateType, from: new Date(from), to: new Date(to)};
// }
// export function handleUpdateDateRange(userPreferences) {
//   userPreferences.dateRange = generateDateRange(userPreferences.dateRange.dateType);
//   return userPreferences;
// }

function getLastMonth() {
  const today = new Date()
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  return new Date (todayYear,todayMonth-1)
}

export function useFetchUserPreferences() {
  const project = useProject();
  const setUserPreferences = useSetUserPreferences();
  const setFetchLoader = useSetFetchLoader();

  async function handleFetchUserPreferences() {
    if (!project._id) return;
    try {
      let userPreferences;
      setFetchLoader(true);
      userPreferences = await Api.UserPreferences.getCurrentUserPreferences(project._id); // call to the server with loader
      if (!userPreferences) {
        userPreferences = await Api.UserPreferences.createUserPreferences(project._id, mapLayerTypes.CELLULAR);
      }
      const dateString = getFormatedStringDate({originalDate: getLastMonth(), toFormat: "yyyy-mm"} )
      const newUserPreferences = JSON.parse(JSON.stringify(userPreferences));
      newUserPreferences.relativeDateRange = {dateType: "date", date :dateString};
      setUserPreferences(newUserPreferences);
    } catch (err) {
      console.error(err);
      return; ///// ???
    } finally {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    handleFetchUserPreferences();
  }, [project._id]);
}

function useUserPreferences() {
  const userPreferences = useSelector((state) => state.map.userPreferences);
  return userPreferences;
}

function useSetUserPreferences() {
  const dispatch = useDispatch();
  return useCallback((userPreferences) => dispatch(setUserPreferences(userPreferences)), [dispatch]);
}

async function useUpdateUserPreferenceAndRenderUi(userPreferences) {
  try {
    await Api.UserPreferences.editUserPreferences(userPreferences);
  } catch (e) {
    console.log(e);
  }
}

function useUpdateUserPreferences() {
  const setUserPreferences = useSetUserPreferences();
  return useCallback(
    async (userPreferences) => {
      try {
        setUserPreferences(userPreferences);
        await Api.UserPreferences.editUserPreferences(userPreferences);
      } catch (e) {
        console.log("There was a problem updating userPreferences:");
        console.log(e);
      }
    },
    [setUserPreferences]
  );
}

function useUserReset() {
  const userPreferences = useUserPreferences();
  const update = useUpdateUserPreferenceAndRenderUi;
  const setUserPreferences = useSetUserPreferences();

  return useCallback((_) => {
    let LocalUserPreferences = {...userPreferences};
    LocalUserPreferences.polygon.coordinates = [];
    LocalUserPreferences.technologies = [];
    LocalUserPreferences.providers = [];
    update(LocalUserPreferences).then(() => {
      LocalUserPreferences.relativeDateRange.dateType = ""; // TODO: needToFixInServer
      setUserPreferences(LocalUserPreferences);
    });
  });
}

function useUserUpdateData() {
  const setUserPreferences = useSetUserPreferences();
  const userPreferences = useUserPreferences();
  return useCallback((_) => {
    setUserPreferences({...userPreferences});
  });
}

export function useUserPreferencesState() {
  return [useUserPreferences(), useSetUserPreferences()];
}
export function useUserPreferencesActions() {
  return {
    resetPreferences: useUserReset(),
    updateUserData: useUserUpdateData(),
    updateUserPreferences: useUpdateUserPreferences(),
  };
}
