
import { Col, Row, Container, Card } from "react-bootstrap"
import styled from "styled-components";
import CoveragePreformence from "./coveragePreformence";
import CoverageReliability from "./coverageReliability"
import PowerDensity from "./powerDensity";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from 'react';
import { yearData, monthData } from "./mockData";
import { usePlacementsActions } from "../../Hooks/common";
import useProject from "../../Hooks/useProject";
import { getInitialDateProjectCanAccessDataFrom, getMonthDiff } from "../../controller/common";
import { YAxis } from "recharts";

const ReactBootstrapForm = styled(Form)`
 color: red;
//  &.form-control:hover {
//     border-color: #80bdff;
//  }
//  .abc{
//         background: blue;
//         color: blue;
//         background-color: blue;
//  }
 `;

const ScrollDiv = styled.div`
   overflow-y:scroll;
 `;

function adaptMonthFormation(value) {// changeName
    if (!value)
        return
    const b = value.split("-")
    console.log(b[1][0])
    // if (b[1][0] !== 0)//10-12 to do
    //    return value;
    b[1] = b[1].replace("0", "")
    return b.join().replace(",", "-");
}
function getChartsDataFromServer() {
    return { monthData: monthData, yearData: yearData };
}


function desiredChartsDataNotInLocallStore() {
    return true//for now
}
function ChartsDashboard() {

    // const [date, setDate] = useState(new Date());
    const [chartsData, setCartsData] = useState();
    const [date, setDate] = useState();
    const [monthsSequence, setMonthsSequence] = useState();
    const { placements } = usePlacementsActions();
    const project = useProject();

    function getMinDate(project) {
        const showDataFrom = getInitialDateProjectCanAccessDataFrom(project);
        return new Date(showDataFrom.getFullYear(), showDataFrom.getMonth()).toISOString().slice(0, 7)
    }

    function getLastMonthDate() {
        const today = new Date()
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        return new Date(todayYear, todayMonth - 1, 31).toISOString().slice(0, 7)// one month beack
    }

    function createSequenceOfMonths() {
        const projectCreatedAt = new Date(project.createdAt)
        const numberOfMonthsFromProjectCreation = getMonthDiff(projectCreatedAt, new Date())
        console.log("careted at diff", numberOfMonthsFromProjectCreation)
        console.log("project.createdAt", project.createdAt)
        if (numberOfMonthsFromProjectCreation > 0) {
            const monthsFromProjectCreation = [...Array(numberOfMonthsFromProjectCreation).keys()].map((index) => new Date(new Date(projectCreatedAt).setMonth(new Date(projectCreatedAt).getMonth() + index + 1)))// + 1 because months starts from 0 
            const monthsAsFormatedStrings = monthsFromProjectCreation.map(month => `${month.getFullYear()}-${month.getMonth()}`)
            console.log("monthsAsFormatedStrings", monthsAsFormatedStrings)
            const monthsBackwardsCration = [...Array(12 - numberOfMonthsFromProjectCreation).keys()].map((index) => new Date(new Date(projectCreatedAt).setMonth(new Date(projectCreatedAt).getMonth() - index - 1)))// + 1 because months starts from 0 
            const monthsBackwardsCreationStrings = monthsBackwardsCration.map(month => `${month.getFullYear()}-${month.getMonth() + 1}`).reverse()
            console.log("monthsBackwardsCreationStrings", monthsBackwardsCreationStrings)
            const allYear = monthsBackwardsCreationStrings.concat(monthsAsFormatedStrings)
            console.log("allYear", allYear)
            setMonthsSequence(allYear)
        }

    }



    useEffect(() => {
        // setCartsData({ myArray: [...this.state.myArray, getChartsDataFromServer()] })
        setCartsData({ placements: placements })
        createSequenceOfMonths()
    }, []);

    useEffect(() => {
        let ans
        // if (desiredChartsDataNotInLocallStore()) {
        //     ans = getChartsDataFromServer()
        // } else {
        //     ans = getReleventChartsData()
        // }

        // setChartsData([...ChartsData, ...ans])
        // setCartsData( { monthData: 
        //     [...chartsData.monthData,...getChartsDataFromServer().monthData],
        //     yearData: [...chartsData.monthData,...getChartsDataFromServer().yearData ]
        // } )
        console.log(date)
    }, [date]);


    function handleMonthClick(key) {
        console.log(key)
        setDate(key)
    }
    return (
        <ScrollDiv style={{ background: "#D2D6DD", overflowY: "scroll" }}>
            <Container style={{ maxWidth: "1430px" }}  >
                <Row className="justify-content-center" >
                    <div className="justify-content-center" style={{ marginBottom: 15, marginTop: 15, background: "#6A6E79", padding: "5px 20px 5px 20px" }} >
                        <h4 style={{ marginBottom: 0, textAlign: "center", color: "white" }} >{project.name}</h4>
                    </div>
                </Row>
                <Row className="justify-content-center">
                    <ReactBootstrapForm.Group className=".abc" style={{ height: "30px", width: "120px", background: "#EEEFF2" }} controlId="duedate">
                        <Form.Control
                            defaultValue={getLastMonthDate(project)}
                            type="month"
                            name="duedate"
                            placeholder="Due date"
                            value={date}
                            onChange={(e) => setDate((e.target.value))}
                            min={getMinDate(project)}
                            max={getLastMonthDate(project)}
                        />
                    </ReactBootstrapForm.Group>
                </Row>
                <Row>
                    {chartsData && <Container fluid   >
                        <Row >
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }} >
                                <Card.Header style={{ textAlign: "center" }}> <h2> Coverage Performence</h2></Card.Header>
                                <Card.Body>
                                    <CoveragePreformence preformenceData={chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp" && placement.dateRange.date === adaptMonthFormation(date))} />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2  > Coverage Reliability</h2></Card.Header>
                                <Card.Body>
                                    <CoverageReliability monthsSequence={monthsSequence} reliabilityData={chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp" && placement.dateRange.dateType === "date")} />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: "20px", marginBottom: "20px", }}>
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2 > Power Density</h2></Card.Header>
                                <Card.Body>
                                    <PowerDensity densityData={chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rssi" && placement.dateRange.date === adaptMonthFormation(date))} />
                                </Card.Body>
                            </Card>
                        </Row>

                    </Container>
                    }

                </Row>


            </Container>
        </ScrollDiv >

    );
}
export default ChartsDashboard;
