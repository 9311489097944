import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList,
  Cell,
  ResponsiveContainer
} from "recharts";
import { colorsForCoveragePreformence, ColorsForPowerDensity } from '../Constants';
import { barData1 } from "./data";
import { groupBy, groupByRange, sumAllKeysValue, toDataStructureAsPrecent } from "./helper";

//handle -minus 
//data ganery for power density and preformence bar chart

export default function BarRechartComponent({ barData }) {
  const dataStruct = [
    {
      name: "1e-11 - 1e-9",  // "0.0000001 - 0.00001"
      dBm: 0,
    },
    {
      name: "1e-9 - 1e-7",  // "0.0000001 - 0.00001"
      dBm: 0,
    },
    
    {
      name: "1e-7 - 1e-5",  // "0.0000001 - 0.00001"
      dBm: 0,
    },
    {
      name: "1e-5 - 1e-3",     //  0.00001 - 0.001
      dBm: 0,
    },
    {
      name: "1e-3 - 1e-1",      //0.001 - 0.1
      dBm: 0,
    },
    {
      name: "1e-1 -10",    // 0.1 - 10
      dBm: 0,
    },
    // {
    //   name: "10 - 120",
    //   dBm: 0,
    // },
    // {
    //   name: "120 -140",
    //   dBm: 0,
    // },
    
  ];
  
  function extractData(barData) {
    if (barData !== undefined) {
      var groupedBySignal = groupBy(barData, 'signal')
      var groupedBySignalEntries = Object.entries(groupedBySignal)
      var groupedBySignalRange = groupByRange(groupedBySignalEntries)
      const sumOfAllKeysValue = sumAllKeysValue(groupedBySignalRange);
      return toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, dataStruct);
    }
  }
  
  return (
    <ResponsiveContainer width="100%" height={280}>

    <BarChart
      width={570}
      height={300}
      data={extractData(barData) || []}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      {/* uw/sqm */}
      <XAxis dataKey="name"   style={{
            fontSize: '0.5rem'
          }}>  
      <Label value="uw/sqcm" offset={-10} position="right" fontSize={10} />
       {/* <Label value="<-75" offset={-10} position="insideBottomRight" />  */}
      </XAxis>
      <YAxis type="number" domain={[0, 100]}  label={{ value: 'percent %', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
      <Tooltip />
      <Legend />
      <Bar dataKey="dBm" fill="#82ca9d" legendType ="none" > 
      <LabelList dataKey="dBm" position="top" />
          {
            dataStruct.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={ColorsForPowerDensity[index % 7]} />
            ))
          }
      </Bar>
    </BarChart>
    </ResponsiveContainer>

  );
}



