import {ifFuncExec} from './common'

function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2-lat1);  // deg2rad below
  const dLon = deg2rad(lon2-lon1); 
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; // Distance in km
  return d;
}

export function getDistanceFromLocationToLocations(location, binsArry, radius){
    const filteredBins = binsArry.filter( (bin) => {
     if(getDistanceFromLatLonInKm(location.lat, location.lng, bin.location.lat,bin.location.lng) <= radius)
       return bin
      })
    return filteredBins;
  }

export function isPointInPolygon(point, polygonPath) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  const polygon = polygonPath.map((point) => [point.lat, point.lng].map(ifFuncExec));
  const x = point[0], y = point[1];
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0], yi = polygon[i][1];
      const xj = polygon[j][0], yj = polygon[j][1];
      // eslint-disable-next-line
      const intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect)
          inside = !inside;
  }
  return inside;
}
export function filterPointsByPolygon(points, polygon) {
  if (polygon.length > 2) {
    return points.filter((point) => isPointInPolygon([point.lat, point.lng], polygon));
  }
  return points;
}

export function polygonToLatLngObjects(pointLaLngFunc){
  //GOOGLE api return {lat: function, lng:function} with type Function, by runing the function Function object convert to String
  return {lat: ifFuncExec(pointLaLngFunc.lat), lng: ifFuncExec(pointLaLngFunc.lng)}
}