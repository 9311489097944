import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modals} from "../controller/Constants";
import Modal from "react-bootstrap/Modal";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import {
  Register,
  AddProject,
  ProjectList,
  EditProject,
  Parameters,
  DisplayCoverage,
  Cluster,
  ClusterAlert,
  Login,
  TryAgainGetCoverage,
  Terms,
  ChoosePolygonAlert,
  ReducePolygonSizeAlert,
  ChoocePolygonInsideProjectPolygon,
  CreateUser
} from "./Dialogs";
import styled from "styled-components";
import {useFetchLoader} from "../Hooks/common";
import {DialogAlertProvider} from "../contexts/DialogAlert";

const StyledModal = styled(Modal)`
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

function DialogGenerator({}) {
  const dispatch = useDispatch();
  const {currentDialog} = useSelector(MapStateToProps);
  const [closing, setClosing] = useState(false);
  const [previousDialogs, setPreviousDialogs] = useState([]);
  const [fetching, setFetching] = useFetchLoader();
  function closeDialogs() {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: null,
    });
    setClosing(false);
    setPreviousDialogs([]);
  }

  useEffect(() => {
    setPreviousDialogs([...previousDialogs, currentDialog]);
    if (currentDialog === null && fetching) setFetching(false);
  }, [currentDialog]);

  function backDialog() {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: previousDialogs.slice(-2)[0],
    });
    setPreviousDialogs([...previousDialogs].splice(-1, 1));
  }

  function onFetchStart() {
    setFetching(true);
  }

  function onFetchEnd() {
    setFetching(false);
  }

  function setFetchingProp(flag) {
    setFetching(flag);
  }
  const currentDialogComponent = (() => {
    switch (currentDialog) {
      case modals.REGISTER:
        return <Register key={modals.REGISTER} />;
      case modals.ADD_PROJECT:
        return <AddProject key={modals.ADD_PROJECT} onClose={closeDialogs} onBack={backDialog} />;
      case modals.PROJECT_LIST:
        return <ProjectList key={modals.PROJECT_LIST} />;
      case modals.EDIT_PROJECT:
        return <EditProject key={modals.EDIT_PROJECT} onClose={closeDialogs} />;
      case modals.PARAMETERS:
        return <Parameters key={modals.PARAMETERS} />;
      case modals.CLUSTER:
        return <Cluster key={modals.CLUSTER} onClose={closeDialogs} />;
      case modals.CLUSTER_ALERT:
        return <ClusterAlert key={modals.CLUSTER} />;
      case modals.LOGIN:
        return <Login key={modals.LOGIN} />;
      case modals.DISPLAY_COVERAGE:
        return <DisplayCoverage key={modals.DISPLAY_COVERAGE} onClose={closeDialogs} />;
      case modals.TRY_AGAIN_GET_COVERAGE:
        return <TryAgainGetCoverage key={modals.TRY_AGAIN_GET_COVERAGE} onClose={closeDialogs} />;
      case modals.TERMS:
        return <Terms key={modals.TERMS} onClose={closeDialogs} />;
      case modals.CHOOSE_POLYGON_ALERT:
        return <ChoosePolygonAlert key={modals.CHOOSE_POLYGON_ALERT} onClose={closeDialogs} />;
      case modals.REDUCE_POLYGON_SIZE_ALERT:
        return <ReducePolygonSizeAlert key={modals.REDUCE_POLYGON_SIZE_ALERT} onClose={closeDialogs} />;
      case modals.CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON:
          return <ChoocePolygonInsideProjectPolygon key={modals.CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON} onClose={closeDialogs} />;
      case modals.CREATE_USER:
          return <CreateUser key={modals.CREATE_USER} onClose={closeDialogs} />;
      default:
        return null;  
    }
  })();
  const DialogObject =
    currentDialogComponent && React.cloneElement(currentDialogComponent, {onFetchStart, onFetchEnd, setFetching: setFetchingProp});
  return (
    <DialogAlertProvider>
      <StyledModal
        backdrop={DialogObject?.key === modals.TERMS ? false : true}
        className={"dialog-generator"}
        show={!closing && Boolean(currentDialog)}
        onHide={() => {
          setClosing(true);
        }}
        onExited={closeDialogs}
        scrollable
        enforceFocus={false}
      >
        {DialogObject}
      </StyledModal>
    </DialogAlertProvider>
  );
}

function MapStateToProps(reduxState) {
  return {currentDialog: reduxState.general.currentDialog};
}

export default DialogGenerator;
