import React, {useCallback, useRef, useState, useEffect} from "react";
import {useLocationStats, useRssiColor, useLocationStatsState} from "../../Hooks/map";
import {Marker, useGoogleMap, InfoWindow} from "@react-google-maps/api";
import EventEmitter from "../../controller/EventEmitter";
import {events} from "../../controller/Constants";
import {locationToAddress, extractAddressFromGeocoder, reduceBinSitesToHighestRssi} from "../../controller/mapper";
import {OverlayView, Polyline} from "@react-google-maps/api";
import {ListGroup, Popover} from "react-bootstrap";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import TechnologyRating from '../TechnologyRating'
import {useUserPreferencesState, useUserPreferencesActions} from "../../Hooks/useUserPreferences";
import {usePlacementsActions} from '../../Hooks/common'
import {techUsageCategoriesThresholds as categoriesThresholds, defaultTechnologiesCoverage} from "../../controller/Constants";
import {DEFAULT_LOCATION_STATS} from "../../store/defaultValues/map";
import './LocationMarker.css' // hide tooltip marker close button (x)
import {useGetTechnologiesCoverage} from '../../Hooks/useGetTechnologiesCoverage.js'
import {TECHNOLOGIES_CALCULATION_RADIUS, mapLayerTypes} from '../../controller/Constants'
import {getDistanceFromLocationToLocations} from '../../controller/GEOoperations'

const StyledPopover = styled(Popover)`
  &#location-marker-popover {
    z-index: 4;
    border-color: ${({theme}) => theme.palette.secondary.main};
    top: -65px;
    left: 15px;
    width: auto;
    .arrow {
      margin: 55px 0;
      &::before {
        border-right-color: ${({theme}) => theme.palette.secondary.main};
      }
    }
    .list-group-item {
      white-space: nowrap;
    }
  }
`;

const {SET_LOCATION_STATS} = events;

function LocationMarker({bins}) {
  const {t} = useTranslation();
  const [locationStats, setLocationStats] = useLocationStatsState();
  //const {closestBin, rssi, bitRate, distance, location} = locationStats;
  const map = useGoogleMap();
  const markerRef = useRef(null);
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const [isDispleyMarkerWindwosInfo , setIsDispleyMarkerWindwosInfo]  = useState(true);
  const {placements} = usePlacementsActions();
  const [technologiesCoverage , setTechnologiesCoverage] = useState({...defaultTechnologiesCoverage})
  const getTechnologiesCoverage = useGetTechnologiesCoverage()
  
  useEffect(()=>{
    onLoadMarker()
  },[bins])

  const onHoverOutMarker = ()=>{
    setIsDispleyMarkerWindwosInfo(false)
  }
  const onHoverInMarker = ()=>{
    setIsDispleyMarkerWindwosInfo(true)
  }

  const setTechnologiesCoverageByRadius = (location)=>{
    const filterdBinsByRadius = getDistanceFromLocationToLocations(location, bins, TECHNOLOGIES_CALCULATION_RADIUS)
    const technologiesCoverage = getTechnologiesCoverage(filterdBinsByRadius) 
    setTechnologiesCoverage(technologiesCoverage)
  }

  const onLoadMarker = ()=>{
    setTechnologiesCoverageByRadius(locationStats.location)
  }

const handleOnDragEnd = (e)=>{
  const newLocation ={lat:e.latLng.lat(), lng:e.latLng.lng()}
  setTechnologiesCoverageByRadius(newLocation)
  setLocationStats({...DEFAULT_LOCATION_STATS, display:true, location:newLocation});
}
  return (
    locationStats.display && (
      <>
      //The marker component can also accept a child InfoMarker component for situations where there is only 1 marker and 1 infowindow.
        <Marker
          onLoad={(marker) => {
           markerRef.current = marker;
          onLoadMarker(bins)
          }}
          draggable={true}
          onDragEnd={handleOnDragEnd}
          map={map}
          position={locationStats.location}
          onMouseOut={onHoverOutMarker}
          onMouseOver={onHoverInMarker}
        >
                 {(bins.length > 0) && isDispleyMarkerWindwosInfo &&  mapLayerTypes.HEAT_MAP!== userPreferences.currentMapLayer &&<InfoWindow
                 position={locationStats.location}
                 >
          <div style={{listStyleType: "none"}}>
           <TechnologyRating showIcons={false} userPreferences={userPreferences} technologiesCoverage={technologiesCoverage}/> 
          </div>
     </InfoWindow>}
        </Marker>
      </>
    )
  );
}

  export default LocationMarker;