import { useUserPreferencesState } from "./useUserPreferences";
import { useCallback } from "react";
import {
  defaultTechnologiesCoverage,
  techUsageCategoriesThresholds as categoriesThresholds,
} from "../controller/Constants";

export function useGetTechnologiesCoverage() {
  const [useUserPreferences] = useUserPreferencesState();

  return useCallback((binsArray) => {
    function calcCoverageForCategorys(accm, binObject) {
      let tech = "3G";
      if (useUserPreferences.technologies.includes("4G")) tech = "4G";
      for (const [category, threshold] of Object.entries(
        categoriesThresholds[tech]
      )) {
        if (binObject.signal >= threshold) accm[category] += 1;
      }
      return accm;
    }

    if (binsArray.length > 0) {
      const techThresholds = binsArray.reduce(calcCoverageForCategorys, {
        ...defaultTechnologiesCoverage,
      });
      const technologiesCoverage = { ...techThresholds };
      for (const [category, value] of Object.entries(technologiesCoverage)) {
        technologiesCoverage[category] = Number(
          ((value / binsArray.length) * 100).toFixed()
        );
      }
      return technologiesCoverage;
    } else {
      return { ...defaultTechnologiesCoverage };
    }
  }, [useUserPreferences.technologies]);
}
