import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { colorsForCoveragePreformence } from '../Constants';
import { barData1 } from "./data";
import { groupBy, groupByRange, sumAllKeysValue, toDataStructureAsPrecent } from "./helper";

//handle -minus 
//data ganery for power density and preformence bar chart

export default function BarRechartComponent({ barData }) {
  const dataStruct = [
    // {
    //   name: "-20 -40",
    //   dBm: 0,
    // },
    {
      name: "-60 -80",
      dBm: 0,
    },
    {
      name: "-80 -90",
      dBm: 0,
    },
    {
      name: "-90 -105",
      dBm: 0,
    },
    {
      name: "-105 -120",
      dBm: 0,
    },
    {
      name: "-120 -140",
      dBm: 0,
    },
  ];

  function extractData(barData) {
    if (barData !== undefined) {
      var groupedBySignal = groupBy(barData, 'signal')
      var groupedBySignalEntries = Object.entries(groupedBySignal)
      var groupedBySignalRange = groupByRange(groupedBySignalEntries)
      const sumOfAllKeysValue = sumAllKeysValue(groupedBySignalRange);
      return toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, dataStruct);
    }
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={280}>

        <BarChart
          // width={490}
          // height={280}
          // width="100%"
          // height="100%"
          data={extractData(barData) || []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" style={{
            fontSize: '0.6rem'
          }}>
            <Label value="dbm" offset={3} position="right" fontSize={14} />
            {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
          </XAxis>
          <YAxis type="number" domain={[0, 100]} label={{ value: 'percent %', angle: -90, position: 'insideLeft', textAnchor: 'middle' }}></YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey="dBm" fill="#82ca9d" legendType="none" >
            <LabelList dataKey="dBm" position="top" />
            {
              dataStruct.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorsForCoveragePreformence[index % 6]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>

    </div>

  );
}

