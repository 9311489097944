import Constants, { errors } from "../Constants";
import Validator from "../Validator";
import { fetchAuth, handleError, navigateResponse } from "./helper";
import { Project } from "./Project";
import { ProjectParams } from "./ProjectParams";
import { UserPreferences } from "./UserPreferences";
import { City } from "./City";
import { allSamples } from "./testSampels2";
import { mockProject } from "./mockProject_old";
import { Cellcom3GMock, Cellcom4GMock, Pelephone4GMock, Pelephone3GMock, PHI3GMock, PHI4GMock } from "./mocks/placements";

class Api {
  static Project = Project;
  static ProjectParams = ProjectParams;
  static UserPreferences = UserPreferences;
  static City = City;

  static async getRealTimeCoverage(projectId, userPreferences, algorithm) {
    const navigateRealTimeCoverageResponse = async (res) => {
      let err;
      let responseBody = await res.json();
      if (res.ok) {
        return { responseBody, err };
      }
      console.log("Error response: ", res);
      const currentError = "there were problem with the server response, status: " + res.status + "\nErrorMessage: " + responseBody;
      console.log(currentError);
      if (Number(res.status) === 504 || responseBody.includes("MemoryError")) {
        return { responseBody: null, err: errors.PREDICTION_TOO_LARGE };
      }
      throw Error(currentError);
    };

    try {
      const url = new URL(`${Constants.REST_URL}projects/${projectId}/realtime-cluster`);
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({ requestPreferences: userPreferences, algorithm, polygonSource: "request-preferences" }),
      });

      const { responseBody, err } = await navigateRealTimeCoverageResponse(res);
      if (responseBody) {
        return { ...responseBody };
      } else if (err === errors.PREDICTION_TOO_LARGE) {
        return err;
      }
      // await navigateResponse(res);
    } catch (e) {
      handleError(e);
    }
  }

  static async uploadExternalDataFile(formDataFile, fileName) { 
    try {
      const url = new URL(`${Constants.REST_URL}process-external-data-file/${fileName}`);
      const res = await fetchAuth(url, {
        method: "POST",
        body: formDataFile,
      });
      if (!res.ok) {
        console.log(res)
        throw Error(`there were problem uploading file.`); 

      }
    } catch (e) {
      handleError(e);
    }
    // try {
    //   const res = {
    //     "AT&T-LTE M":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"Yes"},{lat: 31.779754, lng:35.179432, status:"Yes"}], 
    //       coverageReliability: 1
    //     },
    //     "Verison-LTE M":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"No"},{lat: 31.779754, lng:35.179432, status:"Yes"}], 
    //       coverageReliability: 2
    //     },
    //     "Verison-NB-IoT":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"No"},{lat: 31.779754, lng:35.179432, status:"No"}], 
    //       coverageReliability: 3
    //     },
    //   }
     
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve(res);
    //     }, 3000);
    //   });
    // } catch (e) {
    //   handleError(e);
    // }
  }
  static async processExternalDataFile(file64) {
    try {
      const url = new URL(`${Constants.REST_URL}projects/1234567/process-external-data-file`);
      const res = await fetchAuth(url, {
        method: "POST",
        body:file64
      });
      debugger
      const projectResult = await navigateResponse(res);
      return projectResult;
    } catch (e) {
      handleError(e);
    }
  }

  static async getCoverage(projectId, userPreferences, algorithm) {
    try {
      const url = new URL(`${Constants.REST_URL}projects/${projectId}/get-coverage`);
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({ requestPreferences: userPreferences, algorithm }),
      });
      const projectResult = await navigateResponse(res);
      return projectResult;
    } catch (e) {
      handleError(e);
    }
  }
  static async getCoverage_mock(projectId, userPreferences, algorithm) {
    const list = [];
    if (userPreferences.providers.includes("cellcom") && userPreferences.technologies.includes("3G")) list.push(Cellcom3GMock);
    if (userPreferences.providers.includes("cellcom") && userPreferences.technologies.includes("4G")) list.push(Cellcom4GMock);
    if (userPreferences.providers.includes("pelephone") && userPreferences.technologies.includes("3G")) list.push(Pelephone3GMock);
    if (userPreferences.providers.includes("pelephone") && userPreferences.technologies.includes("4G")) list.push(Pelephone4GMock);
    if (userPreferences.providers.includes("PHI") && userPreferences.technologies.includes("3G")) list.push(PHI3GMock);
    if (userPreferences.providers.includes("PHI") && userPreferences.technologies.includes("4G")) list.push(PHI4GMock);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(list);
      }, 2);
    });
    // try catch
  }

  static async uploadImage(image) {
    if (image.size > 1000000) throw Error("It is not possible to upload an image above 10 MB");
    return await fetchAuth(`${Constants.REST_URL}uploadimage/${image.name}`, {
      method: "POST",
      body: image,
    })
      .then((data) => {
        return data;
      })
      .catch(handleError);
  }
}

class UserApi {
  static login({ email, password }) {
    const emailValid = Validator.isEmail(email);
    const passwordValid = password.length >= 6;
    if (emailValid && passwordValid)
      return fetch(`${Constants.AUTH_URL}login`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
      })
        .then(navigateResponse)
        .catch(handleError);
    else if (!emailValid && !passwordValid) {
      throw Error(`invalid email and password`);
    } else if (!emailValid && passwordValid) {
      throw Error(`invalid email`);
    } else if (emailValid && !passwordValid) {
      throw Error(`invalid password`);
    }
  }

  static register({ email, password, cityId }) {
    debugger;
    const emailValid = Validator.isEmail(email);
    const passwordValid = password.length >= 6;
    if (emailValid && passwordValid)
      return fetch(`${Constants.AUTH_URL}register`, {
        method: "POST",
        body: JSON.stringify({ email, password, cityId, secret: 'flytech57', group: 'manager' }),
      })
        .then(navigateResponse)
        .catch(handleError);
    else if (!emailValid && !passwordValid) {
      throw Error(`invalid email and password`);
    } else if (!emailValid && passwordValid) {
      throw Error(`invalid email`);
    } else if (emailValid && !passwordValid) {
      throw Error(`invalid password`);
    }
  }

  static refreshToken({ email, refreshToken }) {
    return fetch(Constants.AUTH_URL + "refreshToken", {
      method: "POST",
      body: JSON.stringify({ email, refreshToken }),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static logout(email) {
    return fetch(Constants.AUTH_URL + "logout", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static changeAttributes(attributes) {
    return fetchAuth(Constants.AUTH_URL + "updateAttributes", {
      method: "POST",
      body: JSON.stringify(attributes),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
}

export default Api;
export { UserApi };
