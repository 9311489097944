import {useEffect, useRef} from "react";
import {usePlacementsActions, usePolygonPath, usePolygonPathState, usePredictionState, useSetTechnologiesCoverage} from "../../Hooks/common";
import {useUserPreferencesActions, useUserPreferencesState} from "../../Hooks/useUserPreferences";
import Constants, {
  algorithmsTypes,
  techUsageCategoriesThresholds as categoriesThresholds,
  availabiltyStatuses,
  dateRangeValues,
  dateRangeTypes,
  PROVIDERS_LIST,
  modals,
  errors,
  mapLayerTypes,
  defaultTechnologiesCoverage,
  mapLayerValues,
} from "../../controller/Constants";
import {openDialog} from "../../store/actionCreators/general";
import useProject, { useProjectParams } from "../../Hooks/useProject";
import {useDispatch} from "react-redux";
import {SET_AVERAGE_SIGNAL} from "../../store/actionTypes";
import {useUserEnvironment} from "../../Hooks/useUserEnvironment";
import {useTimeOfDay} from "../../Hooks/useTimeOfDay";
import useClusterAndApllyFactor from "../../Hooks/useClusterAndApllyFactor";
import Api from "../../controller/ApiManager";
import { isPointInPolygon } from "../../controller/GEOoperations";
import { ifFuncExec, getMonthDiff, generateDateRange, getInitialDateProjectCanAccessDataFrom } from "../../controller/common";
import {useGetTechnologiesCoverage} from '../../Hooks/useGetTechnologiesCoverage'
import {useFetchLoader} from "../../Hooks/common";

const { KMEANS, STATIC_CLUSTERING } = algorithmsTypes;
const {CELLULAR, HEAT_MAP} = mapLayerValues
 
function StandardMapEffects({setBinsArray, binsArray}) {
  const userEnvironment = useUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const projectParams = useProjectParams();
  const setTechnologiesCoverage = useSetTechnologiesCoverage();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const {updateUserPreferences} = useUserPreferencesActions();
  const {placements, pushToPlacements} = usePlacementsActions();
  const dispatch = useDispatch();
  const project = useProject();
  const [polygonPath, setPolygonPath] = usePolygonPathState();
  const [predictionState] = usePredictionState();
  const algorithm = predictionState ? KMEANS : STATIC_CLUSTERING;
  const clusterAndApllyFactor = useClusterAndApllyFactor();
  const getTechnologiesCoverage = useGetTechnologiesCoverage()
  const [fetching, setFetching] = useFetchLoader();
  const isFeachInitDataWasCalled = useRef(false);
  const {MONTH,DAY,WEEK,DATE} = dateRangeTypes

  function bestServerToCellularMapLayer(userPreferences) {
    if (userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER) {
      userPreferences.currentMapLayer = mapLayerTypes.CELLULAR;
    }
    return userPreferences;
  }
 
  function addMapLayersToListOfUserPreferences(listOfUserPreferences, mapLayers){
    const allUserPreferences = [];
    for(const mapLayer of mapLayers){   
      const newListOfUserPreferences = JSON.parse(JSON.stringify(listOfUserPreferences));
      allUserPreferences.push(...newListOfUserPreferences.map(up => ({...up, currentMapLayer: mapLayer})))
    }
    return allUserPreferences;
  }
  
  function fetchSlidingWindowsPlacements(){
         let listOfUserPreferences = createAllUserCombinations({...userPreferences});
         listOfUserPreferences = addMapLayersToListOfUserPreferences(listOfUserPreferences,[CELLULAR, HEAT_MAP])
           const ListOfUserPreferencesNoBestServer = listOfUserPreferences.map(bestServerToCellularMapLayer);
        if (ListOfUserPreferencesNoBestServer.length > 0) {
          ListOfUserPreferencesNoBestServer.map(handleGetCoverage);
        }
  }

  function fetchFullMonthsPlacements(){
    const fetchDataFrom = getInitialDateProjectCanAccessDataFrom(project);
    const numberOfMonthsToFetch = getMonthDiff(fetchDataFrom, new Date())
    if(numberOfMonthsToFetch > 0){
      const monthsToFetch = [...Array(numberOfMonthsToFetch).keys()].map((index) => new Date(new Date(fetchDataFrom).setMonth(new Date(fetchDataFrom).getMonth() + index  +1)))// + 1 because months starts from 0 
      const monthsAsFormatedStrings = monthsToFetch.map(month=>`${month.getFullYear()}-${month.getMonth()}`)
       let userPreferencesOfFullMonths = []
       userPreferencesOfFullMonths = createAllUserFullMonthsCombinations({...userPreferences}, monthsAsFormatedStrings)
       userPreferencesOfFullMonths = addMapLayersToListOfUserPreferences(userPreferencesOfFullMonths,[CELLULAR, HEAT_MAP])
      userPreferencesOfFullMonths.map(handleGetCoverage)
    }
  }
  
  useEffect(()=>{
    //by changing this ref  value the useEfect that fetch bp can fetch
    isFeachInitDataWasCalled.current = false;
  },[project._id])
 
  // useEffect(() => { // feach init data
  //   if (!isFeachInitDataWasCalled.current && isUserPreferencesValidToSend(userPreferences)) {
  //     isFeachInitDataWasCalled.current = true
  //     fetchFullMonthsPlacements();
  //     fetchSlidingWindowsPlacements();
  //   }
  // }, [userPreferences]); ////////////////////////////////////////// maby up is not good for dipen here?

  // useEffect(() => {
  //   setBinsArray(getReleventBins());
  // }, []);

  // useEffect(() => {
  //   // set relevent bins to display when placements change
  //   // אם אשים פה תנאי מה הוא יחסוך?
  //   // אם זה חישוב כבד הוא יחסוך אותו
  //   setBinsArray(getReleventBins());
  // }, [placements, polygonPath, userEnvironment, timeOfDay, projectParams.devParams.systemGain ]);

  // useEffect(() => {
  //    if (isUserPreferencesValidToSend(userPreferences)) {
  //     if (predictionState) {
  //       handleGetRealTimeCoverage(userPreferences);
  //     } else {
  //       const userPreferencesNoBestServer = bestServerToCellularMapLayer({...userPreferences})
  //       handleGetCoverage(userPreferencesNoBestServer)
  //     }
  //   } else {
  //     isUserPreferencesValidToRender(userPreferences);
  //   }
  // }, [userPreferences, predictionState]);


  useEffect(() => {
    function checkIfHeatMap(mapLayerDisplayType) {
      return userPreferences.currentMapLayer == mapLayerValues.HEAT_MAP

    }
    // calc averageSignal when sectors changed
    function calcAverageSignalObject(accm, binObject) {
      accm.binsCount += binObject.clusteredBins.length;
      accm.signalsSum += binObject.clusteredBins.map(({signal}) => signal).sum();
      return accm;
    }
    function calcAverageSignalObjectEM(accm, binObject) {
      accm.binsCount += 1;
      accm.signalsSum += binObject.signal;
      return accm;
    }
    let averageSignal = 0;
    if (binsArray.length > 0) {
      const reducer = checkIfHeatMap(mapLayerTypes) ? calcAverageSignalObjectEM : calcAverageSignalObject;
      const avgObject = binsArray.reduce(reducer, { binsCount: 0, signalsSum: 0 });
      averageSignal = avgObject.signalsSum / avgObject.binsCount;
      averageSignal = checkIfHeatMap(userPreferences.currentMapLayer) ? Number(averageSignal) : Number(averageSignal.toFixed(0));
    }

    dispatch({type: SET_AVERAGE_SIGNAL, averageSignal});
  }, [project, userEnvironment, binsArray, polygonPath, userPreferences.currentMapLayer]);

  useEffect(() => {
   const technologiesCoverage = getTechnologiesCoverage(binsArray)
   setTechnologiesCoverage(technologiesCoverage);
  }, [project, mapLayerTypes, binsArray, polygonPath]);


  function isUserPreferencesValidToSend(userPreferences) {
    return userPreferences.providers.length > 0 && userPreferences.technologies.length > 0 &&
    ( (userPreferences.relativeDateRange.dateType) || (userPreferences.relativeDateRange.dateType==="date" && userPreferences.relativeDateRange.date) );
  }

  function placementToCombination({technology, provider, algorithm, dateRange}) {
    return {
      technology,
      provider,
      algorithm,
      relativeDateRange: dateRange ,

    };
  }

  function filterMissingPlacements({binsPlacemets}) {
    const noDuplicatePlacements = removeDuplicatePlacements(binsPlacemets)
    const missingPlacements = [];
    for (const placement of noDuplicatePlacements) {
      if (!hasPlacementInStorForCombination(placementToCombination(placement))){
        missingPlacements.push(placement);
      }
    }
    return missingPlacements;
  }

  function extractAllPlacementsCombinations(userPreferences) {
    return userPreferences.providers.flatMap((prov) =>
      userPreferences.technologies.map((tech) => ({
        technology: tech,
        provider: prov,
        algorithm,
        relativeDateRange: userPreferences.relativeDateRange,
      }))
    );
  }

  function isDatesEquals(date1, date2, dateType) {
    if(dateType === DATE){
      return date1.toUTCString() === date2.toUTCString()
    }
    const isYearEquals = date1.getFullYear() === date2.getFullYear()
    const isMonthEquals = date1.getMonth() === date2.getMonth()
    const isDayEquals = date1.getDate() === date2.getDate()
    return isYearEquals && isMonthEquals && isDayEquals
}

  function isRelativeDateRangeEqualToDateRange(relativeDateRange, dateRange) {
    if(relativeDateRange.dateType !== dateRange.dateType) return false;
     const generatedDateRange = generateDateRange(relativeDateRange);
    const isFromDateEquals =  isDatesEquals(generatedDateRange.from, new Date(dateRange.from), generatedDateRange.dateType)
    const isToDateEquals =  isDatesEquals(generatedDateRange.to, new Date(dateRange.to), generatedDateRange.dateType)
    return isFromDateEquals && isToDateEquals;
  }

  function isDateRangeEqual(dateRange1, dateRange2) {
    return dateRange1.from === dateRange2.from && dateRange1.to === dateRange2.to && dateRange1.dateType === dateRange2.dateType;
  }

  function hasPlacementInStorForCombination({provider, technology, relativeDateRange, algorithm}) {
    const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;
    const desiredMapLayer = isBestServer ? mapLayerTypes.CELLULAR : userPreferences.currentMapLayer;

    const placement = placements.find(
      (placement) =>
        placement.project === project._id &&
        (isBestServer || placement.provider === provider) &&
        placement.technology === technology &&
        placement.algorithm === algorithm &&
        isRelativeDateRangeEqualToDateRange(relativeDateRange, placement.dateRange) &&
        desiredMapLayer === placement.mapLayer
      // אם המשתמש לחץ על טיפוס זמן אחר מהבקשה שנשלחה לשרת - אז פה התשובה לא תכנס לרידקס - האם זה תקין?
      // מקרה בוחן הוא שאני מבקש בינים של מהיום שבוע אחרונה ומקבל מאתמול שבוע אחורה, האם להתיחס למקרה זה?
    );
    return placement;
  }

  function getReleventBins() {
    const placements = filterRelevantPlacementsFromStore();
    const bins = clusterAndApllyFactor(placements);
    return filterByPolygon(bins);
  }

  function filterByPolygon(bins) {
    if (polygonPath.length > 2) {
      return bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygonPath));
    }
    return bins;
  }

  function isThereBinsInPlacements(placements) {
    return placements.every((placement) => placement.bins);
  }

  function filterRelevantPlacementsFromStore() {
    const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;
    const desiredMapLayer = isBestServer ? mapLayerTypes.CELLULAR : userPreferences.currentMapLayer;

    const ans = placements.filter(
      ({dateRange, algorithm: placementAlgorithm, technology, provider, mapLayer}) =>
        (isBestServer || userPreferences.providers.includes(provider)) &&
        userPreferences.technologies.includes(technology) &&
        algorithm === placementAlgorithm &&
        isRelativeDateRangeEqualToDateRange(userPreferences.relativeDateRange, dateRange) &&
        desiredMapLayer === mapLayer
    );
    return removeDuplicatePlacements(ans);
  }

  function removeDuplicatePlacements(binsPlacements){
    if(!binsPlacements) return []; 
    const binsPlacementsIds = binsPlacements.map(bp => bp._id)
    const noDuplicatePlacements = binsPlacements.filter(({_id},index)=> !binsPlacementsIds.includes(_id, index + 1))
    return noDuplicatePlacements;
  }
  
  function returnMissingCombinations(userPreference) {
    const missingPlacementsCombinations = [];
    const desiredPlacementsCombinations = extractAllPlacementsCombinations(userPreference);
    for (const combination of desiredPlacementsCombinations) {
      if (!hasPlacementInStorForCombination(combination)) missingPlacementsCombinations.push(combination);
    }
    return missingPlacementsCombinations;
  }

  function createAllUserCombinations(userPreferences) {
    if (!isUserPreferencesValidToSend(userPreferences)) return []
    const ListOfUserPreferences = []
    userPreferences.technologies = ["3G", "4G"];
    userPreferences.providers = PROVIDERS_LIST;
    for (const date of [MONTH,DAY,WEEK]) {
      const newUserPreferences = JSON.parse(JSON.stringify(userPreferences))
      newUserPreferences.relativeDateRange.dateType = date
      ListOfUserPreferences.push(newUserPreferences);
    }
    return ListOfUserPreferences;
  }

  function createAllUserFullMonthsCombinations(userPreferences, months) {
    if (!isUserPreferencesValidToSend(userPreferences)) return []
    const ListOfUserPreferences = []
    userPreferences.technologies = ["3G", "4G"];
    userPreferences.providers = PROVIDERS_LIST;
    for (const month of months) {
      const newUserPreferences = JSON.parse(JSON.stringify(userPreferences))
      newUserPreferences.relativeDateRange.dateType = DATE
      newUserPreferences.relativeDateRange.date = month
      ListOfUserPreferences.push(newUserPreferences);
    }
    return ListOfUserPreferences;
  }

  function isUserPreferencesValidToRender(userPreferences) {
    setBinsArray([]);
  }

  const isUserPreferencesDateRangeEquel = (userPreferencesA, userPreferencesB) => {
    if(userPreferencesA.relativeDateRange.dateType === DATE){
      return userPreferencesB.relativeDateRange.dateType === DATE && userPreferencesA.relativeDateRange.date === userPreferencesB.relativeDateRange.date;
    }
    return userPreferencesA.relativeDateRange.dateType === userPreferencesB.relativeDateRange.dateType;
  }

  const requestUserPreferencesContainCurrentUserPreferences = (userPreferencesArg) => {
    if(!userPreferencesArg) return 

    return userPreferencesArg.technologies.includes(userPreferences.technologies[0]) &&
    userPreferencesArg.providers.includes(userPreferences.providers[0]) &&
    userPreferencesArg.algorithm === userPreferences.algorithm && 
    userPreferencesArg.currentMapLayer == userPreferences.currentMapLayer &&
    isUserPreferencesDateRangeEquel(userPreferencesArg, userPreferences);
  }

  function getToCellularIfBestServer(userPreferences) {
    if(userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER){
      userPreferences.currentMapLayer = mapLayerTypes.CELLULAR
    }  
    return userPreferences
  }

  async function handleGetCoverage(userPreferences) {
    const missingCombinations = returnMissingCombinations(userPreferences);
    if (missingCombinations.length === 0) {
      setBinsArray(getReleventBins());
    } else {
      let newPlacements
      try{
        setFetching(true)
        newPlacements = await Api.getCoverage(project._id, userPreferences, algorithm);
        // if(!newPlacements) return;
      }catch (err) {
        console.log("There was a problem calling getCoverage:");
        console.log(err);
      }finally {
        setFetching(false)
      }
      //TODO: dispatch HERE IS BAD I THINK
      if (newPlacements.status === availabiltyStatuses.PENDING && requestUserPreferencesContainCurrentUserPreferences(userPreferences)) {
        return dispatch(openDialog(Constants.modals.TRY_AGAIN_GET_COVERAGE));
      }
      const onlyMissingPlacements = filterMissingPlacements(newPlacements);
      if (!isThereBinsInPlacements(onlyMissingPlacements)) throw Error("Not all placements hase bins!");
      //TODO: dispatch HERE IS BAD I THINK (also here)
      pushToPlacements([...onlyMissingPlacements]);
    }
  }

  async function handleGetRealTimeCoverage(userPreferences) {
    if (polygonPath.length === 0) throw Error("There must be a polygon in the 'getRealTimeCoverage' request");
    const formatedPolygonPath = polygonPath.map(({lat, lng}) => ({lat: ifFuncExec(lat), lng: ifFuncExec(lng)}));
    const newPolygon = {type: "Polygon", coordinates: formatedPolygonPath};
    const newUserPreferences = {...userPreferences, polygon: newPolygon};
    const userPreferencesNoBestServer = getToCellularIfBestServer(newUserPreferences);
    let newPlacements
    try {
      setFetching(true)
      newPlacements = await Api.getRealTimeCoverage(project._id, userPreferencesNoBestServer, algorithm);
    } catch (err) {
      console.log("There was a problem calling getRealTimeCoverage:");
      console.log(err);
    } finally {
      setFetching(false)
    }
    if (newPlacements === errors.PREDICTION_TOO_LARGE) {
      console.log("show dialog of minimize polygon size and try again");
      return dispatch(openDialog(modals.REDUCE_POLYGON_SIZE_ALERT));

      // return console.log("return void");
    }
    if (!isThereBinsInPlacements(newPlacements.binsPlacements)) throw Error("Not all placements hase bins!");
    pushToPlacements([...newPlacements.binsPlacements]);
  }


  return null;
}

export default StandardMapEffects;