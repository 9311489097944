// src/components/line.rechart.js

import React from "react";
// import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import React, { PureComponent } from 'react';
import { AreaChart, Area, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { barData1 } from './data'
import { groupBy, groupByRange, toDataStructureAsPrecent, sumAllKeysValue, calcMean } from "./helper";
import { providersColors} from '../Constants'


export default function AreachartComponent({ areaData }) {
  const dataStruct = [
    // {
    //   name: -20,
    //   cellcom:0,
    //   pelephone:0,
    //   partner:0,
    //}
  ];
  // function extractData(areaData) {
  //   if (areaData !== undefined) {
  //     var groupedBySignal = groupBy(areaData, 'signal')
  //     var groupedBySignalEntries = Object.entries(groupedBySignal)
  //     var groupedBySignalRange = groupByRange(groupedBySignalEntries)
  //     const sumOfAllKeysValue = sumAllKeysValue(groupedBySignalRange);
  //     return toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, dataStruct);
  //   }
  // }




  function extractData(areaData) {
    if (areaData !== undefined) {
      areaData.forEach(function (item, index) {
        // console.log(item.placements, index);
        // const month =item.date.split("-")[1]
        let listOfAllSignals = item.placements.map(placements => placements.signal)
        let { mean, std } = calcMean(listOfAllSignals)
        return toDataStructureAsPrecent(listOfAllSignals, dataStruct, item.provider, mean, std);
      });
      return dataStruct;
    }
  }

  return (
    // <ResponsiveContainer width="100%" height={280}>

    <AreaChart
      width={650}
      height={300}
      // width={"100%"}
      // height={"100%"}
      data={extractData(areaData) || []}
      margin={{
        top: 10,
        right: 30,
        left: 10,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorCellcom" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={providersColors.CELLCOM_COLOR} stopOpacity={0.8} />
          <stop offset="95%" stopColor={providersColors.CELLCOM_COLOR} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorPelephone" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={providersColors.PELEPHONE_COLOR} stopOpacity={0.8} />
          <stop offset="95%" stopColor={providersColors.PELEPHONE_COLOR} stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorPartner" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={providersColors.PARTNER_COLOR} stopOpacity={0.8} />
          <stop offset="95%" stopColor={providersColors.PARTNER_COLOR} stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name">
      <Label value="dbm" offset={16} position="right" fontSize={7}/>
      </XAxis>
      <YAxis type="number" />
      <Tooltip />
      {/* <Legend  align= "center" verticalAlign="top"  height={36}/> */}
      <Legend />
      <Area  type="monotone" dataKey="cellcom" stroke={providersColors.CELLCOM_COLOR} fillOpacity={1} fill="url(#colorCellcom)" />
      <Area type="monotone" dataKey="partner" stroke={providersColors.PARTNER_COLOR} fillOpacity={1} fill="url(#colorPartner)" />
      <Area type="monotone" dataKey="pelephone" stroke={providersColors.PELEPHONE_COLOR} fillOpacity={1} fill="url(#colorPelephone)" />
    </AreaChart>
    // </ResponsiveContainer>

  );
}


