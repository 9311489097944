import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../controller/ApiManager/index";
//import { closeDialog } from "../../store/actionCreators/general";
import { useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import { setUser } from "../../store/actionCreators/user";
import { Button, Form } from "react-bootstrap";
import {useUser} from "../../Hooks/common";
import { useFetchCallback } from "../../Hooks/useFetchCallback";
import Constants from "../../controller/Constants";
import { useSetUserRedux } from "../../Hooks/common";
import { getTokenIdParameter } from "../../controller/common";
import {useSetUserToRedux} from '../../Hooks/common'
import {useFetchCities} from "../../Hooks/useFetchCities";
import { async } from "validate.js";
import { useFetchLoader } from "../../Hooks/common";
import Alert from "react-bootstrap/Alert";

const SaveChangesButton = styled(Button)`
  margin-top: 20px !important;
  padding: 13px !important;
`;

const ModalContent = styled.div`
   width: 40vw;
  .wrapper {
    position: relative;
    height: 300px;
    width: 40vw;
    text-align: right;
    /* unicode-bidi: embed; */
    p {
  opacity: 1;
  }
}

.modal-footer{
   justify-content: center;
    flex-direction: column
}
.modal-header{
  justify-content: center;
}
`;
const DEFAULT_ALERT = {
    message: "",
    error: false,
  };

  const DEFAULT_FORM = {
    email: '',
    password: '',
    cityId:''
}

function CreateUser({ onClose: closeDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cities = useFetchCities();
  const [, setLoading] = useFetchLoader();
  const [alert, setAlert] = useState(DEFAULT_ALERT);
  const [formData, setFormData] = useState(DEFAULT_FORM)


  const handleSubmit = useCallback(async (event) => {
    event.preventDefault(); 
    try {
      setLoading(true);
      const newUser = await UserApi.register({...formData});
      setAlert({ message: "User added successfully" });
      setFormData(DEFAULT_FORM);
    } catch (err) {
      console.error(err);
      setAlert({ message: "Error added user", error: true });
    } finally {
      setLoading(false);
    }
  }, [formData])

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    console.log('key:', key, '  ', "value:", value);
    setFormData({...formData, [key]: value})
}
  return (
    <ModalContent>
        <Modal.Header closeButton>
            <div className="section-title">
             <h2>{t("Create_New_User")}</h2>
            </div>
      </Modal.Header>
        <Modal.Body>
        {alert.message && (
          <Alert
            dismissible
            onClose={() => {
              setAlert(DEFAULT_ALERT);
            }}
            variant={alert.error ? "danger" : "success"}
          >
            {alert.message}
          </Alert>
        )}
    <Form  className="form inputs-underline" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required name="email" value={formData.email} onChange={handleChange} type="email" placeholder="Enter email" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control required name="password" value={formData.password} onChange={handleChange} type="text" placeholder="Password" />
      </Form.Group>

      <Form.Group>
                 <Form.Label htmlFor="select_city" column={false}>
                  {t("Select_City_Name")}
                </Form.Label>
                 <Form.Control
                     name="cityId"
                     as="select"
                     custom
                     required
                     value={formData.cityId}
                     onChange={handleChange}
                  >
                    <option value={""} >Select city</option>
                   {
                      cities.map((city)=>{
                       return <option key={city._id} value={city._id}>{city.name}</option>
                     })
                    }   
        </Form.Control>
                 </Form.Group>
                 <Form.Group className="center">
            <SaveChangesButton type="submit"  variant={"primary"} className="width-100">
              {t("Create")}
            </SaveChangesButton>
          </Form.Group>          
    </Form>
    </Modal.Body>
    </ModalContent>
  );
}

export default CreateUser;
