import React from "react";
import {OverlayView} from "@react-google-maps/api";
import TableBS from "react-bootstrap/Table";
import SmallModal from "../StyledComponents/SmallModal";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Constants, {mapLayerTypes} from "../../controller/Constants";
import {mapLayers} from "../../controller/Constants";
import {getBitRateFromBin, getLayerType, reduceBinSitesToHighestRssi} from "../../controller/mapper";
import {useProjectParams} from "../../Hooks/useProject";
import BinDetailsTableBestServer from "./BinDetailsTableBestServer";
import {useUserPreferencesState} from "../../Hooks/useUserPreferences";

const StyledTable = styled.div`
  .exitIcon {
    display: flex;
    flex-direction: row;
    justify-content: end;
    font-size: 16px;
    width: 100%;
    padding: 2%;
  }
  table {
    margin-bottom: 1px;
    td {
      padding: 8px;
    }
  }
`;

function BinDetails({position, mapType, details, signal, location, show, clusteredBins}) {
  const {t} = useTranslation();
  const projectParams = useProjectParams();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;

  function getBinsDetails() {
    const {mapLayer} = projectParams;
    const bestServerObj = clusteredBins.reduce(reduceBinSitesToHighestRssi, {rssi: -Infinity});
    const bestServer = bestServerObj.site;
    let detailsRes = [{key: "best_server", value: bestServer}];

    let key = "rssi";
    const signalType = getLayerType(mapLayer);
    if (!["MCS", "Bit_Rate"].includes(signalType)) {
      key = signalType.toLowerCase();
    }
    if (mapType === Constants.mapTypes.BEST_SERVER) return [...detailsRes, {key: key, value: Number(signal)}];
    if (mapType === Constants.mapTypes.C2I) return [...detailsRes, {key: key + "_gap", value: Number(signal.toFixed(3))}];
  }

  const binDataInTooltip = (clusteredBins, signal)=>{
   if(userPreferences.currentMapLayer === mapLayerTypes.HEAT_MAP)
    {
      if(clusteredBins.length===1){
        return {signal:signal, provider:clusteredBins[0]?.provider}
      }else throw "clusteredBins length isn't 1"
    }
    return clusteredBins
  }

  const binDetails = getBinsDetails();
  return (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <SmallModal>
        <StyledTable responsive size="sm">
          <div className={"exitIcon"}>
            <i className="fa fa-times size-icon" aria-hidden="true" onClick={show}></i>
          </div>
          <TableBS>{details && <BinDetailsTableBestServer binsList={binDataInTooltip(clusteredBins, signal)} />}</TableBS>
        </StyledTable>
      </SmallModal>
    </OverlayView>
  );
}

export default BinDetails;
