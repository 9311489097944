import {useCallback} from "react";
import {useProjectParams} from "./useProject";
import Constants from "../controller/Constants";
import {environmentFactor, timeOfDayFactor, mapLayerValues} from "../controller/Constants";
import {useUserEnvironment} from "./useUserEnvironment";
import {useUserPreferencesState} from "./useUserPreferences";
import {useTimeOfDay} from "./useTimeOfDay";

export function useCorrectionFactorCalculator() {
  const projectParams = useProjectParams();
  const userEnvironment = useUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const isHeatMapLayer = userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP;
  const envFactor = isHeatMapLayer ? 0 : environmentFactor[userEnvironment.toUpperCase()];
  const timeFactor = isHeatMapLayer ? 0 : timeOfDayFactor[timeOfDay.toUpperCase()];
  const {antennaPolarization, devParams} = projectParams;
  const {systemGain} = devParams;
  const polarizationLoss = antennaPolarization === Constants.polarizationTypes[3] ? 3 : 0;
  const callback = useCallback(() => {
    const correctionFactor = polarizationLoss - systemGain + envFactor + timeFactor;
    return correctionFactor;
  }, [systemGain, polarizationLoss, userEnvironment, timeOfDay, isHeatMapLayer]);
  return callback;
}
