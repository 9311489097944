import React, {useCallback, useEffect, useState} from "react";
import ColorMapping from "./ColorMapping";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import SelectAreaButton from "./SelectAreaButton";
import styled from "styled-components";
import useProject from "../../Hooks/useProject";
import Constants, {mapControlsDisplayType, mapLayerTypes} from "../../controller/Constants";
import AreaSelectionControls from "./AreaSelectionControls";
import HighlightOpacityButtons from "./HighlightOpacityButtons";
import MapLayerControls from "./MapLayerControls";
import useAsyncLoading from "../../Hooks/useAsyncLoading";
import AsyncLoader from "./AsyncLoader";
import {useUser, useUserGroups} from "../../Hooks/common";
import {useUserPreferencesState} from "../../Hooks/useUserPreferences";
import PredictionSwitch from "./PredictionSwitch";
import SearchLocation from "./SearchLocation";
import {mapLayerValues} from "../../controller/Constants";
import {filterControlsConfig} from "../../configuations/mapControlsDisplay";

import FilterControls from "./FilterControls";
import {models} from "mongoose";

const Div = styled.div`
  background-color: ${({theme}) => theme.palette.primary.main};
  color: ${({theme}) => theme.palette.common.white};
  position: absolute;
  ${(props) => (props.top ? "top: " + props.top + ";" : "")}
  ${(props) => (props.bottom ? "bottom: " + props.bottom + ";" : "")}
    ${(props) => (props.left ? "left: " + props.left + ";" : "")}
    ${(props) => (props.right ? "right: " + props.right + ";" : "")}
    transform:translateX(-50%);
  width: auto;
  z-index: 99;
  border-radius: 0;
  //background: rgba(250, 250, 250,1);
  padding: 0 20px;
  border: none;
  box-shadow: ${({theme}) => theme.shadows.map.control};
  &.zoom {
    padding: 0;
    text-align: center;
    width: 40px;
    span {
      font-size: 15px;
    }
  }
  h4 {
    margin-bottom: 4px;
  }
`;

function MapControls({requireLogin, projectFetched}) {
  // const [locationStats,setLocationStats] = useLocationStatsState();
  const {zoom, mapState, mapLoaded} = useSelector(mapStateToProps);
  const user = useUser();
  const isAreaSelection = mapState === Constants.mapStates.AREA_SELECTION;
  const isColorMapper = projectFetched && !requireLogin;
  const isSelectAreaButton = projectFetched && !requireLogin;
  const {loading, progress} = useAsyncLoading();
  const [isFilterControlsDisplayed, setIsFilterControlsDisplayed] = useState();
  const project = useProject();
  const userGroups = useUserGroups();
  const isAdmin = userGroups.includes(Constants.userGroups.ADMIN);
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const filterControlsDisplayConfig = getFilterControlsDisplayConfig();

  useEffect(() => {
    const isDisplayed = isAdmin || Object.values(filterControlsDisplayConfig || {}).some((item) => item);
    setIsFilterControlsDisplayed(isDisplayed);
  }, [userPreferences.currentMapLayer, project]);

  function getFilterControlsDisplayConfig() {
    return userPreferences.currentMapLayer === mapLayerTypes.CELLULAR
      ? filterControlsConfig[project.mapControlsDisplayType || mapControlsDisplayType.STANDARD]
      : filterControlsConfig[userPreferences.currentMapLayer];
  }

  return (
    Boolean(user.isAuthenticated && mapLoaded) && (
      <>
        {Boolean(zoom) && (
          <Div className={"zoom"} left={"calc(100% - 214px)"} top={"calc(100% - 120px)"}>
            <span title={"Map Zoom"}>{zoom}</span>
          </Div>
        )}
        {user.isAuthenticated && project.name && (
          <Div top={"10px"} left={"50%"}>
            <h4 style={{marginTop: 5}}>{project.name}</h4>
          </Div>
        )}

        {isFilterControlsDisplayed && <FilterControls isAdmin={isAdmin} displayConfig={filterControlsDisplayConfig} />}

        {/* {isFilterControlsDisplayed = Object.values(configFile[mapDisplayType]["FilterControls"]).every(item => item === true)}
         {personalConfig VS deufoultConfig}
         { filterControlsDisplayState = ["standard","dateRangeOf24Hours","technologyOnly"]        }

          {mapLayerState === "cellular"?}

         {isFilterControlsDisplayed && <FilterControls displayConfig={filterControlsDisplayConfig["standard"]}/>}
         
         {isFilterControlsDisplayed && <FilterControls displayConfig={filterControlsDisplayConfig["standard"]}/>}
         {isFilterControlsDisplayed && <FilterControls displayConfig={filterControlsDisplayConfig["dateRangeOf24Hours"]}/>}
         {isFilterControlsDisplayed && <FilterControls displayConfig={filterControlsDisplayConfig[project.mapControlsDisplayType]}/>} */}

        <MapLayerControls />
        {Boolean(isColorMapper) && <ColorMapping />}
        {Boolean(isSelectAreaButton) && <SelectAreaButton />}
        {Boolean(isAreaSelection) && <AreaSelectionControls />}
        {Boolean(isSelectAreaButton) && <HighlightOpacityButtons />}
        {/*{locationStats.displayed && <LocationsStats/>}*/}
        {/* <PredictionSwitch /> */}
        <AsyncLoader loading={loading} progress={progress} />
        <SearchLocation />

        {/* {userPreferences.currentMapLayer === mapLayerValues.CELLULAR && <FilterControls />} */}
      </>
    )
  );
}

MapControls.propTypes = {
  requireLogin: PropTypes.bool.isRequired,
  projectFetched: PropTypes.bool.isRequired,
  // isCostsTable: PropTypes.bool.isRequired,
};

function mapStateToProps(reduxState) {
  return {
    zoom: reduxState.map.mapZoom,
    mapState: reduxState.map.mapState,
    mapLoaded: reduxState.map.loaded,
  };
}

export default MapControls;
