import {locationEquals} from "../common";
import Constants from "../Constants";
import {errors} from "../Constants";
import {fetchAuth, handleError, navigateResponse} from "./helper";
import {mockProject} from "./mockProject_old";
import {projecrRenew} from "./mockProject_renew";

export const Project = class {
  static async addMapLayersToProject(projectId, mapLayers) {
    return fetchAuth(`${Constants.REST_URL}projects/${projectId}/addMapLayers`, {
      method: "PUT",
      body: JSON.stringify({mapLayersToAdd: mapLayers}),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static async getProject(projectId) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      // ////////

      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(projecrRenew);
      //   }, 2);
      // });

      // ///////
      const res = await fetchAuth(`${Constants.REST_URL}projects/${projectId}`, {method: "GET"});
      return await navigateResponse(res);
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) return;
      handleError(e);
    }
  }

  static async addProject(projectName, polygon, cityName, cityInfo) {
    try {
        const res = await fetchAuth(Constants.REST_URL + "projects", {
        method: "POST",
        body: JSON.stringify({project: {name: projectName, polygon: polygon, cityName: cityName, cityInfo: cityInfo}}),
      });
      const projectResult = await navigateResponse(res);
      if (!projectResult.polygon) {
        console.log({projectResult});
      }
      return {...projectResult};  
    } catch (e) {
      handleError(e);
    }
  }

  static editProject(newProject, isPolygonChanged) {
    const polygon = [...newProject.polygon];
    if (locationEquals(polygon[0], polygon.slice(-1)[0])) {
      polygon.splice(-1, 1);
    }
    const project = {...newProject, polygon};
    console.log(newProject);
    return fetchAuth(Constants.REST_URL + "projects/" + newProject._id, {
      method: "PUT",
      body: JSON.stringify({project, isPolygonChanged}),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static async getProjects() {
    try {
      const res = await fetchAuth(Constants.REST_URL + "projects", {method: "GET"});
      return await navigateResponse(res);
    } catch (e) {
      handleError(e);
    }
  }

  static deleteProject(projectId) {
    return fetchAuth(Constants.REST_URL + "projects/" + projectId, {method: "DELETE"})
      .then(navigateResponse)
      .catch(handleError);
  }

  static clusterProject(projectId, mapLayer = null) {
    return fetchAuth(`${Constants.REST_URL}projects/${projectId}/cluster`, {
      method: "POST",
      body: JSON.stringify({mapLayer: mapLayer}),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
};
