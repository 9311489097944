import React from "react";
import PropTypes from "prop-types";
import SubDropdown from "./SubDropdown";
import styled from "styled-components";

const Li = styled.li`
  cursor: pointer;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
function DropdownItem({children, onClick, title, icon, content}) {
  // eslint-disable-next-line
  return (
    <Li className={children && "has-child"}>
      <a onClick={onClick}>
        <FlexDiv>
          <div>
            {icon}
            {title}
          </div>
          {content}
        </FlexDiv>
      </a>
      {children && <SubDropdown>{children}</SubDropdown>}
    </Li>
  );
}

DropdownItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default DropdownItem;
