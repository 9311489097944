//optimize to 1 iteration
export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = { "count": 0, "list": [] }
    }
    acc[key].list.push(obj)
    acc[key].count++
    return acc
  }, [])
}

function getRange(key) {
  switch (true) {
    // case (key <= 0 && key >= -20):
    //   return 0;
    // case (key < -20 && key >= -40):
    //   return 0;
    case ( key >= -80):
      return 0;
    case (key < -80 && key >= -90):
      return 1;
    case (key < -90 && key >= -105):
      return 2;
    case (key < -105 && key >= -120):
      return 3;
    case (key < -120 ):
      return 4;
    default:
      return -1
  }

}

export function groupByRange(objectArray) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[0]
    var range = getRange(key)
    if (!acc[range]) {
      acc[range] = { "count": 0, "list": [] }
    }
    acc[range].list.push(obj[1].list)
    acc[range].count += obj[1].count
    return acc
  }, [])
}

export function sumAllKeysValue(groupedBySignalRange) {//to do: change name
  var sum = 0;
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);//what with -1
    sum += value.count;
  }
  return sum;
}

export function toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, data) {
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);
    if (key != "-1")
      data[key].dBm = ((value.count / sumOfAllKeysValue) * 100).toFixed(1);
  }
  return data;
}