import {mapLayerTypes} from './../../controller/Constants'
import {useUserPreferencesState} from "../../Hooks/useUserPreferences";

function LocalTableRow({provider, signal}) {
  return (
    <tr key={provider}>
      <td>{provider}</td>
      <td>{signal}</td>
    </tr>
  );
}

function BinDetailsTableBestServer({binsList}) {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  function getBinIndex(bin, bins) {
    if (binsList.length === 0) return 1;
    const signals = bins.map((bin) => bin.signal);
    if (Math.min(...signals) === bin.signal) return 3;
    if (Math.max(...signals) === bin.signal) return 1;
    return 2;
  }

  function getDecoratedBinsList(bins) {
    const signalsList = bins.map((bin) => ({
      provider: bin.provider,
      signal: bin.signal.toFixed(0),
      index: getBinIndex(bin, bins),
    }));
    return signalsList;
  }
  const heatBinsDetails  = (binData) =>{
    const newbin = {
      provider: binData.provider,
      index:1,
      signal: binData.signal.toExponential(2) + 'w'+'/'+'m'+'\u00B2'  // w\m^2
    }
    return [newbin]
  }

  const decoratedBinsList = userPreferences.currentMapLayer === mapLayerTypes.HEAT_MAP?  heatBinsDetails(binsList): getDecoratedBinsList(binsList)

  return (
    <tbody>
      {decoratedBinsList
        .filter((bin) => bin.index === 1)
        .map(({provider, signal}) => (
          <LocalTableRow key={provider} provider={provider} signal={signal} />
        ))}
      {decoratedBinsList
        .filter((bin) => bin.index === 2)
        .map(({provider, signal}) => (
          <LocalTableRow key={provider} provider={provider} signal={signal} />
        ))}
      {decoratedBinsList
        .filter((bin) => bin.index === 3)
        .map(({provider, signal}) => (
          <LocalTableRow key={provider} provider={provider} signal={signal} />
        ))}
    </tbody>
  );
}

export default BinDetailsTableBestServer;
