import React from "react";
import styled from "styled-components";
import GoogleMap from "./MapContainer";
import {useDispatch, useSelector} from "react-redux";
import {events} from "../../controller/Constants";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import {mapLoadedCreator} from "../../store/actionCreators/map";
import ErrorBoundary from "../Common/ErrorBoundary";
import EventEmitter from "../../controller/EventEmitter";
import MapContent from "./MapContent";

const StyledMap = styled(GoogleMap)`
  height: 100%;
  width: 100%;
`;

function Map() {
  const {mapLoaded} = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <StyledMap
      onLoad={(map) => {
        window.mainMap = map;
        window.mainMap.setCenter({lat: 31.968119116241805, lng: 34.896709818865});
        window.mainMap.setZoom(10);
        dispatch(mapLoadedCreator());

      }}
      onBoundsChanged={() => EventEmitter.dispatch(events.CENTER_CHANGED)}
    >
      <ErrorBoundary>{mapLoaded ? <MapContent /> : <LoaderSpinner />}</ErrorBoundary>
    </StyledMap>
  );
}

function mapStateToProps(reduxState) {
  return {
    mapLoaded: reduxState.map.loaded,
  };
}

export default Map;
