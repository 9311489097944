import React, {useCallback, useRef, useState} from "react";
import {calcRadiusMultByZoom, getRssiLevels} from "../../controller/mapper";
import {Circle, Rectangle} from "@react-google-maps/api";
import BinDetails from "./BinDetails";
import {useProjectParams} from "../../Hooks/useProject";
import {useDebounce, usePredictionState} from "../../Hooks/common";
import {useGetColor} from "../../Hooks/useGetColor"; //  useGetColor";
import Constants from "../../controller/Constants";
import {useSelector} from "react-redux";

const {METERS_TO_LAT_LNG} = Constants;

function generateBounds(center, radius) {
  const calc = (field, mult = 1) => center[field] + (mult * METERS_TO_LAT_LNG * radius) / (field === "lat" ? 1 : 1);
  return {
    east: calc("lng"),
    north: calc("lat"),
    south: calc("lat", -1),
    west: calc("lng", -1),
  };
}

function Bin({clusteredBins, details, signal, location, binID, globalTooltipID, setGlobalTooltipID}) { 
  const getColor = useGetColor();
  const {mapType, zoom, highlightOpacity} = useSelector(mapStateToProps);
  const zoomMultiplier = calcRadiusMultByZoom(zoom);
  const [tooltip, setTooltip] = useState(false);
  const projectParams = useProjectParams();
  const [predictionState] = usePredictionState();
  const debouncePredictionState = useDebounce(predictionState, 500);
  const {
    devParams: {binResolution: binDiameter},
    rssiThreshold,
  } = projectParams;
  const locationObj = {lat: Number(location.lat), lng: Number(location.lng)};
  const signalLevels = getRssiLevels(mapType, projectParams.mapLayer, projectParams.channelBW, rssiThreshold); ////// ><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const radius = /*binDiameter > 50 ? 50 / 2 :*/ binDiameter / 2;
  const binColor = getColor(signal);
  const circleRef = useRef(null);
  const rectangleRef = useRef(null);
// console.log({signal,location})
  const handleClick =  useCallback(()=>{
    setTooltip(prev=>!prev);
    setGlobalTooltipID(binID)
  },[setTooltip, setGlobalTooltipID])


  // debounde equal to the state let the component to unmount and then change the component ( there was an error there...).
  return (
    debouncePredictionState === predictionState && (
      <>
        {predictionState ? (
          <Rectangle
            onLoad={(rectangle) => {
              if (circleRef.current) {
                circleRef.current.setMap(null);
                circleRef.current = null;
              }
              rectangleRef.current = rectangle;
            }}
            bounds={generateBounds(locationObj, binDiameter * 1.642)}
            options={{
              // strokeColor: '#FF0000',
              strokeOpacity: 0,
              strokeWeight: 2,
              fillColor: binColor,
              fillOpacity: highlightOpacity || 0.6,
              clickable: true,
              draggable: false,
              editable: false,
              visible: true,
              zIndex: 1000 + signal,
            }}
            onClick={handleClick}
          />
        ) : (
          <Circle
            onLoad={(circle) => {
              circleRef.current = circle;
            }}
            center={locationObj}
            // radius={50}
            radius={radius * zoomMultiplier}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0,
              strokeWeight: 2,
              fillColor: binColor,
              fillOpacity: 1,
              clickable: true,
              draggable: false,
              editable: false,
              visible: true,
              zIndex: 1000 + signal,
            }}
            onClick={handleClick}
          />
        )}
        {globalTooltipID === binID && tooltip && (
          <BinDetails
            details={details}
            signal={signal}
            location={location}
            clusteredBins={clusteredBins}
            mapType={mapType}
            key={String(locationObj.lat) + "," + String(locationObj.lng)}
            show={handleClick}
            position={{
              lat: locationObj.lat,
              lng: locationObj.lng,
            }}
          />
        )}
      </>
    )
  );
}

function mapStateToProps(reduxState) {
  return {
    lines: reduxState.map.lines,
    mapType: reduxState.map.mapType,
    zoom: reduxState.map.mapZoom,
    highlightOpacity: reduxState.map.highlightOpacity,
  };
}

Bin = React.memo(Bin);
export default Bin;
