import React from "react";
import styled from "styled-components";
import { StyledBadge } from "../NavbarStyles";
import StarsBar from "./StarsBar";

const Wrapper = styled.div`
  display: flex;
`;

function  StarsBarInfo({showPercentage, title, showBorder, lable, color, starRate, percentage}) {
  return (
    <Wrapper>
      {showPercentage && 
      <StyledBadge showBorder={showBorder} padding variant="none" color={color || "#ffff"}>
        {lable ? `${lable}` : ""}
      </StyledBadge>}
      <StarsBar starRate={starRate} />
      {showPercentage && title && `${percentage} %`}
    </Wrapper>
  );
}

export default StarsBarInfo;
