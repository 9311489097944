import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BSButton from "react-bootstrap/Button";
import styled from "styled-components";
import {dateRangeTypes, mapLayerTypes, PROVIDERS_LIST, TECHNOLOGYS_LIST, userEnvironments} from "../../controller/Constants";
import {useSetUserEnvironment, useUserEnvironment} from "../../Hooks/useUserEnvironment";
import CardCollapse from "./CardCollapse";
import {useTimeOfDay, useSetTimeOfDay} from "../../Hooks/useTimeOfDay";
import {timeOfDay as timeOfDayConstants, DAY_IN_MILLISECONDS} from "../../controller/Constants";
import {useUserPreferencesState, useUserPreferencesActions} from "../../Hooks/useUserPreferences";
import validate from "validate.js";
import {useDebounce, usePolygonPath} from "../../Hooks/common";
import Api from "../../controller/ApiManager";
import { Container } from "../StyledComponents/cardCollapseContainer";
import { capitalizeFirstLetter, getFormatedStringDate, getInitialDateProjectCanAccessDataFrom, isUserPreferencesContentEquals } from "../../controller/common";
import CardCollapseWithDatePicker from "./CardCollapseWithDatePicker";
import useProject from "../../Hooks/useProject";

const Position = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 200;
`;
const StyledContent = styled.div`
  margin: 0 10px;
  z-index: 100;
`;

const ClearMapButton = styled(BSButton)`
  &&&,
  &&&:focus {
    font-family: "Lato", sans-serif;
    border: 2px #8db6f7 solid;
    background-color: #ffff;
    color: #413c4a;
    margin-top: 5px;
    &:hover {
      background-color: #8db6f7;
      color: #ffff;
    }
  }
`;

function FilterControls({isAdmin, displayConfig}) {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const userEnvironment = useUserEnvironment();
  const setUserEnvironment = useSetUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const {resetPreferences} = useUserPreferencesActions();
  const setTimeOfDay = useSetTimeOfDay();
  const [localUserPreferences, setLocalUserPreferences] = useState(userPreferences);
  const debouncedUserPreferences = useDebounce(localUserPreferences, 2000);
  const {t} = useTranslation();
  const {MEN, CAR, HOME} = userEnvironments;
  const {MORNING, AFTERNOON, EVENING} = timeOfDayConstants;
  const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;
  const project = useProject();

  function getMinDate(project) {
    const showDataFrom = getInitialDateProjectCanAccessDataFrom(project);
    return new Date(showDataFrom.getFullYear(),showDataFrom.getMonth())
  }

  function getMaxDate() {
    const today = new Date()
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    return new Date (todayYear,todayMonth-1, 31)// one month beack
  }

  const providerValues = {
    controlName: "providers",
    title: "Provider",  
    isIcon: false,
    defaultOpen: true,
    values: [...PROVIDERS_LIST,"AT&T","Verizon"].map((provider) => ({
      value: provider,
      label: capitalizeFirstLetter(provider),
      active: isBestServer || localUserPreferences?.providers.includes(provider),
      onClick: (val) => handlChange("providers", [val]),
      disabled: false,
    })),
  };

  const technologyValues = {
    controlName: "technologies",
    title: "Technology",
    isIcon: false,
    defaultOpen: true,
    values: [...TECHNOLOGYS_LIST,"NB-IoT","LTE M"].map((tech) => ({
      value: tech,
      label: tech,
      active: localUserPreferences?.technologies.includes(tech),
      onClick: (val) => handlChange("technologies", [val]),
      disabled: tech === "5G" ? true : false,
    })),
  };

  const dateRangeValues = {
    controlName: "dateRange",
    title: "Date range",
    isIcon: false,
    defaultOpen: true,
    values: ["day", "week", "month"].map((date) => ({
      value: date,
      label: `last ${date}`,
      active: localUserPreferences?.relativeDateRange?.dateType === date,
      onClick: (val) => handlChange("relativeDateRange", {dateType: val, date :null }),
      disabled: false,
    })),
  };

  const environmentValues = {
    controlName: "environmentFactor",
    title: "Environment",
    isIcon: true,
    values: [MEN, CAR, HOME].map((env) => ({
      value: env,
      label: env,
      iconLabel: env === MEN ? "male" : env === CAR ? "car" : "home",
      active: userEnvironment === env,
      onClick: (val) => setUserEnvironment(val),
      disabled: false,
    })),
  };

  const timeOfDayValues = {
    controlName: "dayTimeFactor",
    title: "Day time",
    isIcon: false,
    values: [MORNING, AFTERNOON, EVENING].map((time) => ({
      value: time,
      label: time,
      active: timeOfDay === time,
      onClick: (val) => setTimeOfDay(val),
      disabled: false,
    })),
  };

  const values = [
    providerValues,
    technologyValues,
    // datesValues,
    environmentValues,
    timeOfDayValues,
  ];

  function resetBins() {
    resetPreferences();
  }

  async function handlChange(category, value) {
    const newUserPreferences = JSON.parse(JSON.stringify(localUserPreferences));
    newUserPreferences[category] = value;
    setLocalUserPreferences(newUserPreferences);
    
  }

  async function handlChangeDate(date) {
     const dateString = getFormatedStringDate({originalDate: date, toFormat: "yyyy-mm"} )
    handlChange("relativeDateRange", {dateType: "date", date : dateString})
  }

  useEffect(() => {
    async function handleUpdateUserPreferences() {
      setUserPreferences(debouncedUserPreferences);
      try {
        await Api.UserPreferences.editUserPreferences(debouncedUserPreferences);
      } catch (err) {
        console.log("There was a problem updating userPreferences:");
        console.log(err);
      }
    }

    if (!isUserPreferencesContentEquals(debouncedUserPreferences, userPreferences)) {
      handleUpdateUserPreferences();
    }
  }, [debouncedUserPreferences]);

  useEffect(() => {
    setLocalUserPreferences(userPreferences);
  }, [userPreferences]);
 
  return (
    <Position>
      <StyledContent>
        {displayConfig &&
             [ providerValues,
              technologyValues]
            .filter(({controlName}) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
          }
            {displayConfig &&
              <CardCollapseWithDatePicker title={dateRangeValues.title} values={dateRangeValues.values} isIcon={dateRangeValues.isIcon} defaultOpen={Boolean(dateRangeValues.defaultOpen)}  onChange={handlChangeDate}
              minDate={getMinDate(project)} maxDate={getMaxDate(project)} active={localUserPreferences?.relativeDateRange?.dateType === dateRangeTypes.DATE} monthString={localUserPreferences?.relativeDateRange?.date }/>
            }  
        {displayConfig &&
             [ environmentValues,
              timeOfDayValues]
            .filter(({controlName}) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
          }
        <Container>{displayConfig && <ClearMapButton onClick={resetBins}>{t("clearMap")}</ClearMapButton>}</Container>
      </StyledContent>
    </Position>
  );
}

export default FilterControls;
