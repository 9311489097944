import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import {isLight} from "../../controller/common";
import {useUserPreferencesState} from "../../Hooks/useUserPreferences";
import {colorsLabelsInfo} from "../../controller/Constants";

const ColoredListItem = styled(ListGroup.Item)`
  color: ${(props) => (isLight(props.backcolor) ? "black" : "white")};
  font-weight: bold;
  //text-align: center;
  //text-shadow: 0 0 2px rgba(0,0,0,1), 1px 1px 0 rgba(0,0,0,0.7), -1px -1px 0 rgba(0,0,0,0.2) ;
  background-color: ${(props) => (props.backcolor ? props.backcolor + " !important" : "red")};
  &.list-group-item {
    && {
      border-radius: 0;
    }
    padding: 2px 4px;
    font-size: 12px;
  }
`;

const StyledModal = styled(Modal.Dialog)`
  &.modal-dialog {
    @media (min-width: 576px) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 0;
    border: none; //${({theme}) => theme.palette.primary.main};
    box-shadow: ${({theme}) => theme.shadows.map.control};
    .list-group-colors {
      border-radius: 0;
      min-width: 100px;
    }
  }
  .modal-header {
    padding: 7px 16px;
    background-color: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
    border-radius: 0;
    .h4.modal-title {
      font-size: 15px;
    }
  }
`;
const ControlContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
`;

function ColorMapping() {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();

  try {
    // const {mapLayer, channelBW, rssiThreshold: minRssi} = projectParams;
    return (
      <ControlContainer>
        <StyledModal>
          <Modal.Header>
            <Modal.Title>{"Flycomm Scale"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup className={"list-group-colors"}>
              {colorsLabelsInfo[userPreferences.currentMapLayer].map(({color, label}) => (
                <ColoredListItem key={`${label}`} backcolor={color}>
                  {label}
                </ColoredListItem>
              ))}
            </ListGroup>
          </Modal.Body>
        </StyledModal>
      </ControlContainer>
    );
  } catch (e) {
    console.error(e);
    return (
      <ControlContainer>
        <div>there is an error here</div>
      </ControlContainer>
    );
  }
}

export default ColorMapping;
