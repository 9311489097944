import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Api from "../../controller/ApiManager/index";
import { setProjects } from "../../store/actionCreators/map";
import { addToast } from "../../store/actionCreators/general";
import PolygonInput from "../Common/Inputs/PolygonInput";
import { useDebounce, useEffectExceptFirst, useFetchLoader, usePlacementsActions } from "../../Hooks/common";
import { useProjectState } from "../../Hooks/useProject";
import Alert from "react-bootstrap/Alert";
import DeleteButton from "../Common/Buttons/DeleteButton";
import { locationEquals, compare2Paths } from "../../controller/common";

const MapContainer = styled.div`
  height: 400px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const ModalContent = styled.div`
  width: 40vw;
  .checkbox .form-check-input {
    position: relative;
    bottom: -7px;
    margin-top: 10px;
  }
`;

const StyledDeleteButton = styled(DeleteButton)`
  margin-top: auto;
  margin-bottom: auto;
`;

const SaveChangesButton = styled(Button)`
  margin-top: 20px !important;
  padding: 13px !important;
`;

const DEFAULT_ALERT = {
  message: "",
  error: false,
};


function EditProject({ onClose: closeDialog, addToast, setProjects, projects }) {
  const [project, setProject] = useProjectState();
  const [name, setName] = useState(project.name);
  const [alert, setAlert] = useState(DEFAULT_ALERT);
  const [, setLoading] = useFetchLoader();
  const {clearPlacements} = usePlacementsActions();
  const [polygonPath, setPolygonPath] = useState(project.polygon);
  const { t } = useTranslation();
  const polygonVisibleButtons = { saveVisible: true, resetVisible: true, deleteVisible: false, uploadVisible: true, downloadVisible: true };

  const saveProjectChanges = useCallback(async () => {
    try {
      if (polygonPath.length < 3) throw Error("Project must have polygon");
      setLoading(true);
      const polygon = polygonPath.map(latLng => typeof latLng.lat === 'function' ? latLng.toJSON() : latLng);
      const isPolygonChanged = !compare2Paths(polygonPath, project.polygon)
      if(isPolygonChanged) clearPlacements();
      const projectUpdated = await Api.Project.editProject({ ...project, name, polygon }, isPolygonChanged);
      setProject({ ...projectUpdated, mapLayersParams: project.mapLayersParams });
      setAlert({ message: "project successfully changed" });
    } catch (err) {
      console.error(err);
      setAlert({ message: "Error Changing project", error: true });
    } finally {
      setLoading(false);
    }
  }, [project.polygon, polygonPath, name])

  async function deleteProject() {
    try {
      setLoading(true);
      const toDelete = window.confirm("Are_You_Sure");
      if (!toDelete) return;
      const response = await Api.Project.deleteProject(project._id);
      // const projectUpdated = await Api.Project.getProject(project._id, {binsPopulated: true});
      addToast(`Successfully deleted Project "${project.name}" from Database!`);
      setProjects(projects.filter((projectI) => projectI._id !== project._id)); // if projects list changes so the url change and couse featch of difrent project?
      closeDialog();
    } catch (e) {
      setAlert({ message: "Error Deleting Project, please see Logs...", error: true });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{t("Edit_Project_Title")}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        {alert.message && (
          <Alert
            dismissible
            onClose={() => {
              setAlert(DEFAULT_ALERT);
            }}
            variant={alert.error ? "danger" : "success"}
          >
            {alert.message}
          </Alert>
        )}
        <Form className="form inputs-underline">
          <div className="row">
            <div className="col-md-9 col-sm-9">
              <Form.Group>
                <Form.Label htmlFor="project_name" column={false}>
                  {t("Project_Name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="project_name"
                  placeholder="Please enter..."
                  onChange={(ev) => setName(ev.target.value)}
                  value={name}
                />
              </Form.Group>
            </div>
            <div className="col-md-3 col-sm-3">
              <StyledDeleteButton label={t("Delete_Project")} onClick={deleteProject} />
            </div>
          </div>
          <Form.Text>please drag the marker or right click to set the location:</Form.Text>
          <MapContainer>
            {polygonPath.length > 2 && <PolygonInput
              projectName={name}
              visibleButtons={polygonVisibleButtons}
              onPolygonPathChanged={setPolygonPath}
              polygonPath={polygonPath}
            />}
          </MapContainer>
          <Form.Group className="center">
            <SaveChangesButton onClick={saveProjectChanges} variant={"primary"} className="width-100">
              {t("Save_Project_Changes")}
            </SaveChangesButton>
          </Form.Group>
        </Form>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(
  (state) => {
    return { projects: state.map.projects };
  },
  { addToast, setProjects }
)(EditProject);
