//optimize
const math = require('mathjs');

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = { "count": 0, "list": [] }
    }
    acc[key].list.push(obj)
    acc[key].count++
    return acc
  }, [])
}

function getRange(key) {
  switch (true) {
    case (key <= 0 && key >= -20):
      return 0;
    case (key < -20 && key >= -40):
      return 1;
    case (key < -40 && key >= -60):
      return 2;
    case (key < -60 && key >= -80):
      return 3;
    case (key < -80 && key >= -100):
      return 4;
    case (key < -100 && key >= -120):
      return 5;
    default:
      return -1
  }
}

export function groupByRange(objectArray) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[0]
    var range = getRange(key)
    if (!acc[range]) {
      acc[range] = { "count": 0, "list": [] }
    }
    acc[range].list.push(obj[1].list)
    acc[range].count += obj[1].count
    return acc
  }, [])
}

export function sumAllKeysValue(groupedBySignalRange) {
  var sum = 0;
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);//what with -1
    sum += value.count;
  }
  return sum;
}

function pdf(value, mean = 0, standardDeviation = 1) {
  const dividend =
    Math.E ** -((value - mean) ** 2 / (2 * standardDeviation ** 2));
  const divisor = standardDeviation * Math.sqrt(2 * Math.PI);
  return dividend / divisor;
}

export function toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, data, provider, mean, std) {
  const providerdbm = `${provider}dBm`
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);
    if (key != "-1") {
      //  data[key].dBm = pdf((value.count/sumOfAllKeysValue)*100,70,20);
      // data[key].dBm = (value.count/sumOfAllKeysValue);

      // switch (true) {
      //   case (provider === 'cellcom'):
      //      data[key].cellcom = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;
      //   case (provider === 'pelephone'):
      //      data[key].pelephone = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;
      //   case (provider === 'partner'):
      //      data[key].partner = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;
      //   default:
      //     return;
      // }
      
      // if (provider === 'cellcom')
      //   data[key].cellcom = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;
      // if (provider === 'pelephone')
      //   data[key].pelephone = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;if(provider ='cellcom')
      // if (provider === 'partner')
      //   data[key].partner = pdf((value.count / sumOfAllKeysValue) * 100, 84.4 - 20, 10.8);   // todo calc mean;
      //  data[key].providerdbm = pdf((value.count/sumOfAllKeysValue)*100,84.4-20, 10.8);   // todo calc mean



      if (provider === 'cellcom')
        data[key].cellcom = pdf((value.count / sumOfAllKeysValue) * 100, mean, std);   // todo calc mean;
      if (provider === 'pelephone')
        data[key].pelephone = pdf((value.count / sumOfAllKeysValue) * 100, mean, std);   // todo calc mean;if(provider ='cellcom')
      if (provider === 'partner')
        data[key].partner = pdf((value.count / sumOfAllKeysValue) * 100, mean, std);   // todo calc mean;
      //  data[key].providerdbm = pdf((value.count/sumOfAllKeysValue)*100,84.4-20, 10.8);   // todo calc mean
    }
  }
  return data;
}
export function calcMean(listOfAllSignals) {
  let mean = math.mean(listOfAllSignals)
  let std = math.std(listOfAllSignals);
  return { mean, std }
}

// export function calcStdandMean(groupedBySignalRange, sumOfAllKeysValue, data) {
//   debugger
//   const list =[]
//   for (const [key, value] of Object.entries(groupedBySignalRange)) {
//     console.log(key, value);
//     list.push()
//     if (key != "-1"){
//       // data[key].dBm = pdf((value.count/sumOfAllKeysValue)*100,70,20);
//        data[key].dBm = (value.count/sumOfAllKeysValue);
//     }

//   }
// }
// const x = (std) => {
//   var m =math.mean([1.1334655709832813,43.241711533012186, 51.77103995466138, 3.655426466421082,0,0])// need expected value
//   var s =math.std([1.1334655709832813,43.241711533012186, 51.77103995466138, 3.655426466421082,0,0])
//     return {m,s}
//   }