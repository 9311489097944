import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../controller/ApiManager/index";
//import { closeDialog } from "../../store/actionCreators/general";
import { useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import { setUser } from "../../store/actionCreators/user";
import { Button } from "react-bootstrap";
import {useUser} from "../../Hooks/common";
import { useFetchCallback } from "../../Hooks/useFetchCallback";
import Constants from "../../controller/Constants";
import { useSetUserRedux } from "../../Hooks/common";
import { getTokenIdParameter } from "../../controller/common";
import {useSetUserToRedux} from '../../Hooks/common'

const ModalContent = styled.div`
   width: 40vw;
  .wrapper {
    overflow-y: scroll;
    position: relative;
    height: 300px;
    width: 40vw;
    text-align: right;
    /* unicode-bidi: embed; */
    p {
  opacity: 1;
  }
}

.modal-footer{
   justify-content: center;
    flex-direction: column
}
.modal-header{
  justify-content: center;
}
`;

function Terms({ onClose: closeDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userM = useUser();
  //const setUserRedux = useSetUserRedux();
  const setUserToRedux = useSetUserToRedux();


  const isAttributeTermsApproved = (result) => {
    return result.attributes.filter((item) => item === "isTermsChecked").length >= 1;
  };

  const OnClickApproveTerms = async () => {
    try {
      const userAttributeTerms = { attributes: { isTermsChecked: "true" } };
      const result = await UserApi.changeAttributes(userAttributeTerms);

      if (isAttributeTermsApproved(result)) {
        const refreshToken = localStorage.getItem(
          Constants.localStorage.REFRESH_TOKEN
        );
        const userEmail = userM.user.email;
        const tokens = await UserApi.refreshToken({
          email: userEmail,
          refreshToken,
        });
        const { refreshToken: refreshRes } = tokens;
        const idToken = tokens.idToken;
        localStorage.setItem(Constants.localStorage.ID_TOKEN,"Bearer " + idToken);
        localStorage.setItem(Constants.localStorage.REFRESH_TOKEN, refreshRes);
        setUserToRedux(idToken, refreshRes)
        closeDialog();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalContent className={"container"}>
      <Modal.Header closeButton={userM.user.isTermsChecked}>
        <Modal.Title>
          <div className="section-title center">
            <h2>{t("Terms and Conditions")}</h2>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={"wrapper"} dir="rtl">
        <p>
          <strong>תנאי שימוש בתוכנת FLYCOMM </strong>
        </p>
        <p>
          <strong>1. כללי</strong>
        </p>
        <ul>
          <li>
            <ol>
              <li>
                תנאי השימוש שלהלן חלים על השימוש בתוכנת Municipality
                Communication - MuniC של חברת Flycomm וכן לכל גרסה קיימת או
                עתידית של התוכנה (להלן: "התוכנה").{" "}
              </li>
              <li>
                למען הסר ספק, המונח "התוכנה" מתייחס לתוכנה על כל חלקיה ולתוכן
                המוצג בה מכל סוג ומין שהוא, לרבות כל המידע והתוכן הכלול בתוכנה,
                בין אם הוא גלוי למשתמשים ובין אם לאו. כמו כן, המונח "התוכנה"
                יתייחס גם לחלקים של התוכנה אשר הגישה אליהם נעשית באמצעות כתובות
                אחרות.{" "}
              </li>
              <li>
                השימוש בתוכנה מעיד על הסכמתכם לתנאי השימוש, כלשונם ובמלואם,
                ומהווה הסכמה בהתנהגות לתנאי השימוש. המשתמש מתבקש לקרוא את תנאי
                השימוש בקפידה.{" "}
              </li>
              <li>
                התוכנה מנוהלת ומופעלת על-ידי חברת פלייקום בע"מ (להלן:
                "פלייקום").
              </li>
              <li>
                הגישה לתוכנה והשימוש בה ובכלל זה בתכנים הכלולים בה ובשירותים
                השונים הפועלים בה כפופים לתנאים המפורטים להלן (להלן: "תנאי
                השימוש") המסדירים את היחסים בין פלייקום לבין כל משתמש, צופה,
                בתוכנה או במידע המצוי בה ו/או מקבל/ת מידע ו/או שירות הניתן
                בתוכנה, במישרין או בעקיפין (להלן: "המשתמש/ת" או "המשתמשים").
              </li>
              <li>
                תנאי השימוש חלים על השימוש בתוכנה ובתכנים הכלולים בה באמצעות כל
                מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, סמארטפון, טאבלט
                וכיו"ב). כמו כן תנאי השימוש חלים על השימוש בתוכנה בין באמצעות
                רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחרים.
              </li>
              <li>
                בתנאי השימוש המונחים "מידע" או "תוכן" או "תכנים" כוללים מידע מכל
                מין וסוג, לרבות כל תוכן מילוי, חזותי, או כל שילוב ביניהם וכן
                עיצוב, עיבוד, עריכה, הפצה ודרך הצגתם של התכנים לרבות (אך לא רק):
                מפה, נתונים, תמונה, צילום, איור, הנפשה, תרשים, הדמיה, סרטון,
                קובץ קולי וכן כל אובייקט, תוכנה, קובץ, קוד מחשב, יישום, תסדיר
                (format), פרוטוקול, טופס אלקטרוני, מאגר נתונים וממשק וכל תו,
                סימן סמל וצלמית (icon).
              </li>
              <li>
                כל התייחסות לחלק מן התוכנה מתייחס לכל חלק מסוים מתוכן התוכנה,
                לרבות כל עמוד או חלק מעמוד, או לכל אובייקט, תכונה, אפשרות,
                אפליקציה או חלק אחר של התוכנה, כפי שיהיה מעת לעת.
              </li>
              <li>
                בתנאי שימוש זה "מחשב" – לרבות כל מתקן או אמצעי המאפשר גישה
                לתכנים באמצעות רשת האינטרנט, כגון: טלפון חכם ("סמארטפון"), מחשב
                לוח (tablet) וכד'.
              </li>
              <li>
                תנאי השימוש בתוכנה מנוסחים בלשון זכר לצרכי נוחות בלבד, והם
                מתייחסים לנשים וגברים כאחד.
              </li>
            </ol>
          </li>
        </ul>
        <p> </p>
        <p>
          <strong>2. התכנים בתוכנה והמידע המוצג בה</strong>
        </p>
        <ul>
          <li>
            <ol>
              <li>
                {" "}
                התוכנה בכללותה לרבות כל המידע המופיע בה, מוצעים למשתמשים כמו שהם
                (AS IS).
              </li>
              <li>
                {" "}
                חברת פלייקום עושה מאמצים כדי שהמידע הכלול בתוכנה והתוצרים
                המוצגים יהיו מדויקים ומעודכנים ואולם יתכן ותכנים או תוצרים
                מסוימים אינם שלמים ו/או ייתכן ונפלו טעויות טכניות ואחרות בתוכן
                מסוים, לרבות ברכיבי תוכנה. פלייקום לא תישא באחריות לנזק, הוצאה
                ו/או אובדן שיגרמו למשתמשים עקב טעויות ו/או אי דיוקים במידע כאמור
              </li>
              <li>
                {" "}
                ידוע למשתמש ומוסכם עליו, כי הגישה לחלקים מן התוכנה עשויה להיות
                מותנה ו/או מושפעת מהתקנת רכיבי חומרה תוכנה במחשבו, לרבות דפדפנים
                מסוימים ותוספים לאותם דפדפנים. המשתמש אחראי לברר ולהתקין כל רכיב
                תוכנה שיידרש לצורך השימוש בתוכנה, על חשבונו ואחריותו. פלייקום
                אינה אחראית לרכיבי תוכנה אלה ולכל פגם בהם, ולכל טעות או אי התאמה
                בהצגת התכנים הנובעת מרכיבי התוכנה, מהתקנתם או מאי התקנתם. מבלי
                לגרוע מכלליות האמור לעיל, הגישה לתוכנה ולתכנים והצגתם עשויה
                להשתנות בהתאם לתכונות החומרה, מערכת ההפעלה והתוכנה שבמחשב המשתמש
                ולהגדרותיהם. פלייקום אינה מתחייבת כי התכנים יוצגו בצורה מלאה
                ו/או זהה ו/או תקינה במחשבים שונים.
              </li>
              <li>
                {" "}
                פלייקום מבהירה כי המידע והתוצרים המוצגים בתוכנה, מבוססים על
                מדידות של מכשירי קצה המשקפים את חוויית המשתמש בלבד וכי אין
                פלייקום ערבה למידת הדיוק של תכנים אלה.{" "}
              </li>
              <li>
                . כמו כן התכנים והתוצרים המוצגים בתוכנה, אין בהם כדי לבטל, לפסול
                או להציג חלופה אל מול תוצרי מערכת וביצועי מערכת שייתכן ויוצגו
                ע"י חברות סלולר או כל גורם שלישי המייצג אותן.
              </li>
              <li>
                . התכנים והתוצרים בתוכנת פלייקום מנתחים ומציגים את תמונת התקשורת
                ברבדים שונים בראיית המשתמש ברשות המוניציפאלית{" "}
              </li>
              <li>
                {" "}
                אין בהעברת מידע לתוכנה או ממנה כדי ליצור בין המשתמש לבין פלייקום
                יחסים משפטיים כלשהם, למעט חובותיו המשפטיות של המשתמש כמפורט
                בתקנון זה. זכויות הצדדים הן אלו המפורטות בתנאי שימוש אלו, ואין
                בשימוש בתוכנה או במידע המופק ממנה כאמור כדי להקנות כל זכויות
                נוספות.{" "}
              </li>
              <li>
                {" "}
                ככל שמוצגים בתוכנה ערכים של ביצועי מערכת ברבדים השונים, הערכים
                המוצגים בתוכנה הם תוצרי ניתוח על בסיס האלגוריתמים שפותחו
                בפלייקום. פלייקום שומרת על זכותה לעדכן או לתקן את תוצרי הניתוח
                המוצג בה.
              </li>
              <li>
                {" "}
                פלייקום רשאית להגביל את הגישה לתוכנה או לחלקים ממנה לסוגי
                משתמשים שונים ו/או ללקוחותיה ו/או לחלקם ו/או להתנות את הגישה
                אליהם למשתמשים שיאשרו את הסכמתם לתנאי השימוש.
              </li>
              <li>
                המשתמש מוותר על כל טענה או תביעה כלפי פלייקום ולכל זכות כספית או
                אחרת כלפיה, בגין כל שימוש או אי שימוש שיעשה בתוכנה ובתוצריה.
              </li>
            </ol>
          </li>
        </ul>
        <p> </p>
        <p>
          <strong>3. זכויות יוצרים</strong>
        </p>
        <p>
          3.1 התוכנה בכללותה והמידע המופיע בה, לרבות תוצריה (ומבלי לגרוע מכלליות
          האמור לעיל – גם עיצובו, יישומי התוכנה שבו, השמות וסימני המסחר וכל פרט
          אחר המופיע בו או גלום בו), הינם קניינה הבלעדי של פלייקום או של צדדים
          שלישיים שהתירו לפלייקום להשתמש בהם והם מוגנים על ידי הדין, לרבות חוקי
          מדינת ישראל, אמנות בינלאומיות ודיני מדינות זרות. מבלי לגרוע מכלליות
          האמור לעיל, הזכויות בכל סימן מסחר של פלייקום יוותרו בבעלותה הבלעדית
          והמשתמשים אינם רשאים לעשות בו כל שימוש.{" "}
        </p>
        <p>
          3.2 המשתמש מתחייב לנהוג על פי הדינים והאמנות הנ"ל ונאסר עליו לגרום לכל
          שינוי או סילוף במידע, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל,
          להנפיק רישיון, ליצור עבודות נגזרות או למכור או להשכיר כל חלק מן המידע
          הכלול בתוכנה ו/או לעשות שימוש בשמות ובסימנים המסחריים המוצגים בתוכנה,
          ללא הסכמתה של פלייקום בכתב ומראש.
        </p>
        <p> </p>
        <p>
          <strong>4. קישורים לאתרים אחרים (Links)</strong>
        </p>
        <p>
          בתוכנה עשויים להופיע קישורים לאתרי אינטרנט שאינם מופעלים על-ידי
          פלייקום (להלן: "קישורים"). הקישורים מוצגים לנוחות המשתמש בלבד ואין
          לפלייקום שליטה על אתרים אלה ואין היא נושאת באחריות לתכנים המופיעים
          בהם. אין בהכללת הקישורים בתוכנה כדי להעיד על תמיכה בתוכן המוצג באתרים
          אלו או על כל קשר אחר לאתרים אלו או למפעיליהם. פלייקום אינה מתחייבת
          לתקינות הקישורים או לכך שקישורים אלו מכוונים לאתרים אשר אליהם מתיימר
          כל מצביע אלקטרוני לקשר. פלייקום רשאית לפי שיקול דעתה הבלעדי להסיר כל
          קישור מהתוכנה ו/או להוסיף קישורים נוספים.
        </p>
        <p> </p>
        <p>
          <strong>5. כפיפות למדיניות הפרטיות</strong>
        </p>
        <p>פלייקום מייחסת חשיבות רבה לשמירה על פרטיות המשתמשים בתוכנה. </p>
        <p>
          מדיניות הפרטיות של פלייקום מפורטת להלן והיא מהווה חלק בלתי נפרד מתנאי
          שימוש אלה. הואיל ומדיניות הפרטיות יכולה להשתנות מעת לעת, מומלץ למשתמש
          לחזור ולקרוא את מסמך המדיניות מפעם לפעם. השימוש בתוכנה מהווה אף הסכמה
          למדיניות הפרטיות.
        </p>
        <p>
          מסירת מידע לצד שלישי - פלייקום אינה משתפת מידע אישי ומידע שנאסף על
          פעולותיך (ככל שפרטים ומידע זה מזהים אותך אישית) ולא תעביר מידע זה
          לצדדים שלישיים למעט במקרים הבאים:
        </p>
        <p>
          <strong>5.1 </strong> קיבלנו את הסכמתך – אנו נשתף פרטים אישיים עם
          צדדים שלישיים לאחר שקיבלנו את הסכמתך לכך.
        </p>
        <p>
          <strong>5.2 </strong> אם תיקח חלק בפעילות תוכן של צד שלישי או
          בפעילויות משותפות לפלייקום ולצד שלישי. במקרים אלה יועבר לצד השלישי
          הרלוונטי המידע הדרוש לו לשם ניהול פעילות התוכן הרלבנטית וליצירת או
          שמירת קשר איתך.
        </p>
        <p>
          <strong>5.3</strong> לצרכים סטטיסטיים – אנו מספקים מידע לגופים או
          חברות שאנו נותנים בהם אמון כדי שיעבדו את המידע עבורנו לפי ההוראות שלנו
          ובאופן העולה בקנה אחד עם מדיניות הפרטיות שלנו. ככלל, וככל שלא ניתנה
          הסכמתך למסירת מידע אישי, מידע המועבר לצרכים סטטיסטיים אינו כולל פרטים
          מזהים, אשר צפויים לאפשר לצד השלישי לזהות אותך.
        </p>
        <p>מטעמים משפטיים: </p>
        <p>
          • אם יתקבל בידי פלייקום צו שיפוטי המורה לה למסור את פרטיך או המידע
          אודותיך לצד שלישי.{" "}
        </p>
        <p>
          • אם הדבר יידרש, לדעת פלייקום, לצורך ו/או במסגרת כל מחלוקת, טענה,
          תביעה, דרישה או הליכים משפטיים אם יהיו, בינך או בין צד ג' לבין
          פלייקום.
        </p>
        <p>
          • במקרה שתפר או תנסה להפר את תנאי השימוש בתוכנה או איזה מהשירותים
          המוצעים על ידי פלייקום או תבצע, באופן ישיר או באמצעות גורם אחר, פעולות
          הנחזות כמנוגדות לדין, תהיה פלייקום רשאית למסור את המידע לכל רשות
          מוסמכת.{" "}
        </p>
        <p>
          • בכל מקרה שפלייקום תסבור כי מסירת המידע נחוצה באופן סביר כדי למנוע
          נזק לך או לרכושך או לצד שלישי או לרכושו.
        </p>
        <p>• בכל מקרה בו קיים היתר/חובה עפ"י דין למסור את הפרטים.</p>
        <p>
          <strong>5.6</strong> אם פלייקום תארגן את פעילות התוכנה במסגרת תאגיד
          אחר - וכן במקרה שתתמזג עם גוף אחר או תמזג את הפעילות בתוכנה עם פעילות
          של צד שלישי - היא תהיה זכאית להעביר לתאגיד החדש את המידע שנאגר אודותיך
          או כל מידע סטטיסטי שנאסף על ידה ובלבד שהתאגיד אליו יועבר המידע יקבל על
          עצמו את הוראות מדיניות פרטיות זו וכן תתקבל הסכמתך המפורשת להבערת המידע
          לאמור.{" "}
        </p>
        <p>
          <strong>6. חובות המשתמש</strong>
        </p>
        <p>
          6.1 המשתמש מתחייב שלא לבצע פעולות בתוכנה העלולות להגביל או למנוע
          מאחרים שימוש בתוכנה, ושלא להשתמש בתוכנה באופן שאינו תואם לכל דין,
          לרבות כל שינוי או מחיקה של מידע או תוכן.
        </p>
        <p>
          6.2 המשתמש מתחייב שלא לבצע על פעולה המהווה זיוף, התחזות,הטעיה או
          הונאה.
        </p>
        <p>
          6.3 המשתמש מתחייב שלא להעלות, לשלוף, לשדר, להפיץ או לפרסם מידע או תוכן
          אחר הכולל פרסומת מכל סוג שהוא, בלי הרשאה מפורשת מראש מפלייקום.
        </p>
        <p>
          6.4 המשתמש מתחייב שלא לעשות כל שימוש מסחרי במידע ובתוכן שבתוכנה, או
          בכל חלק מהם.
        </p>
        <p>
          6.5 המשתמש מתחייב לשפות ולפצות את פלייקום מיד עם דרישתה בגין כל נזק,
          ישיר או עקיף ו/או כל הוצאה שתגרם לפלייקום בקשר לתביעה ו/או לדרישה
          הנובעת משימוש בתוכנה, לרבות כל פעולה המנוגדת לתנאי שימוש אלה או
          להוראות כל דין.{" "}
        </p>
        <p>
          6.6 המשתמש מסכים כי, מבלי לפגוע בכל זכות אחרת של פלייקום, במקרים בהם
          פלייקום תחשוש כי השימוש של המשתמש אינו מתיישב עם הוראות תנאי השימוש
          ו/או כל דין, תהא פלייקום רשאית לבצע כל פעולה אשר תמצא לנכון לנקוט כדי
          להגן על קניינה ו/או על זכויותיה ועל זכויות משתמשים אחרים ובין היתר,
          למנוע מהמשתמש גישה לתוכנה ולתעד ו/או להתחקות אחר השימוש של המשתמש
          בתוכנה. כן מובהר, כי פלייקום עשויה להעביר פרטים לגבי זהות המשתמש ולגבי
          פעולות שביצע בתוכנה לצדדים שלישיים, לרבות במקרה שבו הצדדים השלישיים
          יוכיחו, להנחת דעתה של פלייקום, כי הינם נפגעים מפעילותו המפרה של
          המשתמש.
        </p>
        <p>
          6.7 ככל שהמשתמש קיבל סיסמא או קוד גישה או הרשאה לצורך השימוש בתוכנה או
          בכל חלק שלה, הוא מתחייב שלא לגלות את הסיסמא או קוד הגישה לכל אדם אחר
          ולא לאפשר לכל אדם אחר לעשות בהם או בהרשאה שימוש. המשתמש יהיה אחראי לכל
          שימוש שיעשה תוך שימוש בסיסמא, בקוד הגישה או בהרשאה שלו.
        </p>
        <p>
          6.8 המשתמש מתחייב לפעול בהתאם להוראות ההפעלה שנתנו הן עבור המכשירים
          והן של תוכנת MuniC. במידה והמשתמש פעל שלא ע"פ הוראות ההפעלה לא תהיה לו
          שום טענה או קובלנה על ביצועי המערכת או פעולה לרבות חדלון פעולת המערכת.
        </p>
        <p>
          6.9 חובת המשתמש לשמור על תקינות המכשירים, הכבלים והמטענים במשך כל זמן
          ההתקשרות.
        </p>
        <p>
          {" "}
          פגיעה כתוצאה משימוש רשלני או אובדן או גניבה של הפריטים יחייב את המשתמש
          / לקוח בתשלום מלא. עבור אובדן / גניבה של מכשיר יחוייב הלקוח בתשלום של
          1000 ₪. אובדן מטען או כבל יחייב תשלום של 50 ₪ לכל פריט. חיוב התשלום
          יבוצע עם הודעה על אובדן פריט והחברה תתחייב להשלים את הפריט לאחר ביצוע
          תשלום מלא לחברת Flycomm ע"י המשתמש ולא יאוחר מ-21 ימי עבודה ובתנאי
          שלמשתמש הרשאה בתוקף לשימוש בתוכנת MuniC.{" "}
        </p>
        <p> </p>
        <p> </p>
        <p>
          <strong>7. הגבלת אחריות</strong>
        </p>
        <p>
          7.1 השימוש בתוכנה נעשה על אחריות המשתמש בלבד. פלייקום אינה נושאת בכל
          אחריות או חבות בגין כל שיבוש, טעות או השמטה בתכולת התוכנה. פלייקום לא
          תהא אחראית לכל נזק ישיר, עקיף, תוצאתי או אגבי עקב גישה לתוכנה והשימוש
          בה או עקב מניעה כלשהי מהגישה או השימוש בתוכנה, בין בעילה חוזית ובין
          בעילה נזיקית. פלייקום אינה אחראית לכל נזק לרבות בשל נגיף-מחשב
          ("וירוס"), תכונות עוינות הידועות כסוס טרויאני, תולעים (Worms) ,
          ואנדלים (Vandals), יישומים מזיקים (Malicious Applications), רוגלות או
          יישומי תוכנה למיניהם לציוד המחשב של המשתמש או לכל רכוש אחר של המשתמש,
          שיגרמו בשל גלישה
        </p>
        <p>או שימוש בתוכנה ובכלל זאת, בשל הורדת מידע מהתוכנה.</p>
        <p> </p>
        <p>
          <strong>8. הוראות שונות</strong>
        </p>
        <p>
          8.1 פלייקום רשאית בכל עת להכניס שינויים בתוכנה, להסיר כל חלק מהתוכנה
          ולהוסיף לו, מעת לעת, על-ידי עדכון התוכנה, וכן להפסיק ולהתחיל את פעילות
          התוכנה, לסרב להעניק גישה לתוכנה או לחלקים ממנו לכל משתמש והכל לפי
          שיקול דעתה הבלעדי של פלייקום וללא התראה מוקדמת ולמשתמש לא תהיה כל טענה
          כלפי פלייקום בקשר לכך. מבלי לגרוע מכלליות האמור לעיל, פלייקום אינה
          מחויבת לשמור את המידע והתוכן של התוכנה, בין אם מקורו בפלייקום ובין אם
          מקורו במשתמשים או צדדים אחרים, והיא רשאית למחקו.
        </p>
        <p>
          8.2 במידה ויקבע שתנאי כלשהו מתנאי השימוש אינו תקף או שאינו בר אכיפה,
          אזי התנאים שתוקפם נשלל או שאינם ניתנים לאכיפה ייחשבו כאילו הוחלפו
          בתנאים תקפים הניתנים לאכיפה שתוכנם תואם במידת הקירוב הגדולה ביותר את
          משמעות וכוונת התנאים המקוריים ואילו יתר תנאי השימוש ישארו בתוקפם.
        </p>
        <p>
          8.3 פלייקום רשאית להמחות את זכויותיה והתחייבויותיה לפי תנאי שימוש אלה,
          או כל חלק מהן, לכל צד שלישי כפי שתמצא לנכון וללא כל חובה מצד פלייקום
          לפרסם את דבר ההמחאה.
        </p>
        <p>
          8.4 המשתמש אינו רשאי להמחות, להעביר או להסב את זכויותיו והתחייבויותיו
          לפי תנאי שימוש אלה, או כל חלק מהן, או כל חשבון או הרשאה שיינתנו לו
          לתוכנה או לכל חלק ממנה.
        </p>
        <p>
          8.5 כל הפעילות בתוכנה וכל הקשור והכרוך בה, לרבות תנאי השימוש, יוכפפו
          לדין הישראלי בלבד וסמכות השיפוט הבלעדית בכל סכסוך הנוגע לתוכנה ולשימוש
          בה ולתנאי השימוש תהיה נתונה לבתי המשפט המוסמכים במחוז תל אביב, ישראל.{" "}
        </p>
        <p>
          8.6 פלייקום שומרת על זכותה להכניס שינויים בתנאי השימוש בכל עת, לפי
          שיקול דעתה, וללא התראה מוקדמת וכל שינוי בתנאי השימוש יכנס לתוקפו מיד
          עם פרסומו, במסגרת תנאי השימוש, אלא אם נקבע אחרת. באחריות המשתמש/ת
          לבדוק ולהתעדכן, מעת לעת, בתנאי השימוש.
        </p>
        <p>
          8.7 כל שאלה או בעיה הקשורה לתוכנה או לתנאי השימוש בה וכן בכל הצעה
          לשיפור או המלצה יש להפנות ל:{" "}
          <a href="mailto:moshe@flycomm.co" target="_blank">
            moshe@flycomm.co
          </a>
        </p>
        <p>
          8.8 שימושו של המשתמש בתוכנה מהווה הצהרה ואישור מטעמו, כי קרא את תקנון
          זה, הבין את תוכנו, והסכים להוראותיו. המשתמש מוותר בזה מראש ולא יהיה
          רשאי לבוא בכל טענה ו/או דרישה כנגד פלייקום או מי מטעמה ביחס לנושאים
          המוסדרים בתקנון זה.
        </p>
      </Modal.Body>
      <Modal.Footer>
        {userM.user.isTermsChecked===true ? (
          "") : 
        (<>
             <p>By clicking on “{t('Accept')}” button you are accepting the Terms & Conditions</p>
              <Button onClick={OnClickApproveTerms}>Accept</Button>
        </>
        )
        }
      </Modal.Footer>
    </ModalContent>
  );
}

export default Terms;
