import Constants, {DAY_IN_MILLISECONDS} from "../Constants";
import {errors} from "../Constants";
import Validator from "../Validator";
import {fetchAuth, handleError, navigateResponse} from "./helper";

export const UserPreferences = class {
  static async listUserPreferences(projectId) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      const res = await fetchAuth(`${Constants.REST_URL}userPreferences`, {
        method: "GET",
      });
      const allUserPreferences = await navigateResponse(res);
      return allUserPreferences;
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) {
      } else {
        handleError(e);
      }
    }
  }
  static async getUserPreferences(projectId, userPreferencesId) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      const res = await fetchAuth(`${Constants.REST_URL}userPreferences/${userPreferencesId}`, {
        method: "GET",
      });
      const project = await navigateResponse(res);
      return project;
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) {
      } else {
        handleError(e);
      }
    }
  }
  static async getCurrentUserPreferences(projectId) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      const res = await fetchAuth(`${Constants.REST_URL}projects/${projectId}/userPreferences/me`, {
        method: "GET",
      });

      const project = await navigateResponse(res);
      return project;
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) {
      } else {
        handleError(e);
      }
    }
  }
  static async createUserPreferences(projectId, currentMapLayer) {
    try {
      const res = await fetchAuth(Constants.REST_URL + "userPreferences", {
        method: "POST",
        body: JSON.stringify({
          userPreferences: {
            project: projectId,
            currentMapLayer,
            providers: [],
            technologies: [],
            polygon: [],
            dateRange: {
              from: new Date(Date.now() - 7 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0),
              to: new Date(Date.now() - 1 * DAY_IN_MILLISECONDS).setUTCHours(0, 0, 0, 0),
              dateType: "day",
            },
          },
        }),
      });
      const userPreferencesResult = await navigateResponse(res);
      return {...userPreferencesResult};
    } catch (e) {
      handleError(e);
    }
  }

  static async editUserPreferences(userPreferences) {
    if (userPreferences == null) return;
    try {
      const res = await fetchAuth(`${Constants.REST_URL}userPreferences/${userPreferences._id}/`, {
        method: "PUT",
        body: JSON.stringify({userPreferences}),
      });
      const userPreferencesResult = await navigateResponse(res);
      return {...userPreferencesResult};
    } catch (e) {
      handleError(e);
    }
  }
};
