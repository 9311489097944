
const barData1 = [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.84674892,
      "lng": 34.99202021999999
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.846921200000004,
      "lng": 34.992122800000004
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8470793,
      "lng": 34.99218475
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.847261600000003,
      "lng": 34.992289525000004
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.847509600000002,
      "lng": 34.9923997
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.847734820000007,
      "lng": 34.99251914
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.847903399999996,
      "lng": 34.99257446666667
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.84830957142857,
      "lng": 34.99277525714286
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.848505742857146,
      "lng": 34.99286260000001
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.84867718,
      "lng": 34.992937940000004
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8488561,
      "lng": 34.993024025000004
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.84898654,
      "lng": 34.993085859999994
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.849149033333333,
      "lng": 34.9931254
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.849375377777775,
      "lng": 34.993244911111105
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.849611799999998,
      "lng": 34.993362600000005
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8497374,
      "lng": 34.993383425
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.849893475,
      "lng": 34.993446975
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85008625964915,
      "lng": 35.03817456842102
    },
    "signal": -90.3
  },
  {
    "location": {
      "lat": 31.8500933,
      "lng": 34.99355865
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.850300574999995,
      "lng": 34.993606237499996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.850457040000002,
      "lng": 34.99366705999999
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.850530575,
      "lng": 34.993724675
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.850697950000004,
      "lng": 34.993769125
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.850835571428572,
      "lng": 34.9938156
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.851015699999998,
      "lng": 34.993909475
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85119573333333,
      "lng": 34.99395548333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.851358925,
      "lng": 34.994040225
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85158363333333,
      "lng": 34.99414576666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.851720599999997,
      "lng": 34.994219125
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.851910225,
      "lng": 34.994355475
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.852108925,
      "lng": 34.99452925
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.852259420000003,
      "lng": 34.99470613999999
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8523163,
      "lng": 34.9948276
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85245885,
      "lng": 34.995024075
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85257266666667,
      "lng": 34.99510183333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.852622042857142,
      "lng": 34.995254457142856
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.852701725000003,
      "lng": 34.99541442499999
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.85279063333333,
      "lng": 34.99560338333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.852841425,
      "lng": 34.995763149999995
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.852944133333335,
      "lng": 34.99595746666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.852971614285714,
      "lng": 34.996171357142856
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.853012675,
      "lng": 34.996351649999994
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.85303194,
      "lng": 34.99650036
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.853068,
      "lng": 34.9968024
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8531189,
      "lng": 34.997222316666665
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.853119980000002,
      "lng": 34.9970185
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.85315952,
      "lng": 34.99739358
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.853182999999994,
      "lng": 34.99758182000001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.853195340000003,
      "lng": 34.99777898000001
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853212050000003,
      "lng": 34.9979623
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853234550000003,
      "lng": 34.99812765
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853297533333333,
      "lng": 34.99835336666667
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853313416666666,
      "lng": 34.9986016
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853343783333333,
      "lng": 34.99882603333334
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.85337065,
      "lng": 34.999013825
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853417466666667,
      "lng": 34.99936496666667
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.853459275,
      "lng": 34.999565450000006
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.85347046,
      "lng": 34.99973296
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.85350374285714,
      "lng": 34.99993525714286
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853537225,
      "lng": 35.0001067
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853558100000004,
      "lng": 35.00026844
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853597320000006,
      "lng": 35.00044810000001
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853633283333334,
      "lng": 35.000687049999996
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853668225,
      "lng": 35.0009589
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8537421,
      "lng": 35.00133655
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853770575,
      "lng": 35.001498975
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853784525000002,
      "lng": 35.001670450000006
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.853826216666665,
      "lng": 35.00187966666667
    },
    "signal": -84.7
  },
  {
    "location": {
      "lat": 31.853875522222225,
      "lng": 35.00210162222223
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.853895875000003,
      "lng": 35.00228935
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.853911724999996,
      "lng": 35.002403574999995
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.853917739999996,
      "lng": 35.00259264
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.853953079999997,
      "lng": 35.00279652
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.85402608,
      "lng": 35.00298196
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.85403765714286,
      "lng": 35.00314251428571
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.854082514285718,
      "lng": 35.00333788571429
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.854095599999997,
      "lng": 35.00349705000001
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.85413956,
      "lng": 35.003665340000005
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.854193957142858,
      "lng": 35.003732771428574
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.854259640000002,
      "lng": 35.004053940000006
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.8542602875,
      "lng": 35.0038878375
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.854264949999997,
      "lng": 35.00440426666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.854267600000004,
      "lng": 35.004225250000005
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.85446276666667,
      "lng": 35.0044799
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.854586539999996,
      "lng": 35.0043942
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.854616899999996,
      "lng": 35.00425523333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.854782077777777,
      "lng": 35.00418945555556
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.854961314285713,
      "lng": 35.00405657142857
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.855136416666667,
      "lng": 35.00400668333334
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8552907,
      "lng": 35.0241088
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.855313575,
      "lng": 35.003856875000004
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.85532535,
      "lng": 35.019900050000004
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.8553466,
      "lng": 35.0192163
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.8553642,
      "lng": 35.0195423
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.8553961,
      "lng": 35.023881599999996
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.855417499999998,
      "lng": 35.0235597
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.855424600000003,
      "lng": 35.0201885
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.855448499999998,
      "lng": 35.023225599999996
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.85546055,
      "lng": 35.017920950000004
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.855479250000002,
      "lng": 35.003835275
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8554815,
      "lng": 35.0205304
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.855527000000002,
      "lng": 35.0208792
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.8555294,
      "lng": 35.0176716
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.855556800000002,
      "lng": 35.0212235
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.8555578,
      "lng": 35.0218979
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.8555712,
      "lng": 35.021564500000004
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.8556477,
      "lng": 35.003725325000005
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.85571736861123,
      "lng": 35.01604035166652
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.85578515,
      "lng": 35.00362510000001
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.85586275,
      "lng": 35.003519625
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.856193649999998,
      "lng": 35.003290449999994
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8562909,
      "lng": 35.003165949999996
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.85650185,
      "lng": 35.0030108
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.85672125,
      "lng": 35.002803625
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.856944199999997,
      "lng": 35.002605599999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.857136275,
      "lng": 35.002436725
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.857334424999998,
      "lng": 35.002269749999996
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.857503775,
      "lng": 35.002094199999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8576571,
      "lng": 35.00204144999999
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.857975780000004,
      "lng": 35.00184668
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.85821045,
      "lng": 35.001742400000005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.858443233333333,
      "lng": 35.00164196666666
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.858683799999998,
      "lng": 35.001560833333336
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.858915275,
      "lng": 35.0014906
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.859256799999997,
      "lng": 35.001378100000004
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.859448333333333,
      "lng": 35.00136353333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.85966448,
      "lng": 35.001368740000004
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.860010199999998,
      "lng": 35.00139142
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.860196733333336,
      "lng": 35.0014151
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.86035334,
      "lng": 35.001346819999995
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8606595,
      "lng": 35.001498350000006
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.860883460000004,
      "lng": 35.00154032
    },
    "signal": -85.65
  },
  {
    "location": {
      "lat": 31.86109565714285,
      "lng": 35.00154738571429
    },
    "signal": -85.3
  },
  {
    "location": {
      "lat": 31.861262,
      "lng": 35.0017016
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.861438383333336,
      "lng": 35.00170648333333
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8614799,
      "lng": 35.0018005
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.861634433333332,
      "lng": 35.001861633333334
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.861849,
      "lng": 35.001886
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.861982100000002,
      "lng": 35.00201534999999
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.862122999999997,
      "lng": 35.0021077
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.86231464,
      "lng": 35.00207858
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8623305,
      "lng": 35.0022062
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8625232,
      "lng": 35.002263766666665
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.862747866666666,
      "lng": 35.00236853333333
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.8629193,
      "lng": 35.00243845
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8630736,
      "lng": 35.0024538
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.863122566666664,
      "lng": 35.0025619
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8632558,
      "lng": 35.002579233333336
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.86356365,
      "lng": 35.0027119
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.863590014285716,
      "lng": 35.00260347142857
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.863807340000005,
      "lng": 35.002749820000005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.86398535,
      "lng": 35.0027942
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.864120275,
      "lng": 35.0028303
    },
    "signal": -79.7
  },
  {
    "location": {
      "lat": 31.86431602,
      "lng": 35.00281506
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.864341999999997,
      "lng": 35.002878
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.864534499999998,
      "lng": 35.0028989
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.86465474,
      "lng": 35.002810059999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8646669,
      "lng": 35.00289356666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.86487965,
      "lng": 35.0028077
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.86490773333333,
      "lng": 35.002902666666664
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.86511995,
      "lng": 35.0029052
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.865200575000003,
      "lng": 35.0028025
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8653262,
      "lng": 35.0028896
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.865426559999996,
      "lng": 35.002802560000006
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.86558775,
      "lng": 35.0028445
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.865694825,
      "lng": 35.002799374999995
    },
    "signal": -76.65
  },
  {
    "location": {
      "lat": 31.8659148,
      "lng": 35.0027933
    },
    "signal": -76.65
  },
  {
    "location": {
      "lat": 31.8661525,
      "lng": 35.002741949999994
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.866327050000002,
      "lng": 35.0026235
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8664228,
      "lng": 35.0026305
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.866629879999998,
      "lng": 35.00260606
    },
    "signal": -76.65
  },
  {
    "location": {
      "lat": 31.866834166666667,
      "lng": 35.002535
    },
    "signal": -76.65
  },
  {
    "location": {
      "lat": 31.867065166666666,
      "lng": 35.002440033333336
    },
    "signal": -73.8
  },
  {
    "location": {
      "lat": 31.86720006666667,
      "lng": 35.002267933333336
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8672869,
      "lng": 35.0023434
    },
    "signal": -78.55
  },
  {
    "location": {
      "lat": 31.86751178,
      "lng": 35.002211179999996
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.86772664,
      "lng": 35.00208334
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.867915600000003,
      "lng": 35.00199370000001
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.86792733333333,
      "lng": 35.001890466666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.86811298,
      "lng": 35.00173701999999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.868119300000004,
      "lng": 35.0018492
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.86830048,
      "lng": 35.00168119999999
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.868477133333332,
      "lng": 35.00152793333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.868570333333334,
      "lng": 35.001453500000004
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.868695933333328,
      "lng": 35.001321600000004
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.86877525,
      "lng": 35.001268249999995
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.868884549999997,
      "lng": 35.00114485
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.869026766666664,
      "lng": 35.00099518333334
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.869218200000002,
      "lng": 35.000780385714286
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.869376449999997,
      "lng": 35.00060020000001
    },
    "signal": -80.95
  },
  {
    "location": {
      "lat": 31.8695218,
      "lng": 35.000409000000005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.869559600000002,
      "lng": 35.00030549999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.869751771428575,
      "lng": 35.00010291428571
    },
    "signal": -80.95
  },
  {
    "location": {
      "lat": 31.869903366666666,
      "lng": 34.99991653333333
    },
    "signal": -80.95
  },
  {
    "location": {
      "lat": 31.870060300000002,
      "lng": 34.999740275
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.87021146666667,
      "lng": 34.99952483333333
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.870295028571434,
      "lng": 34.9993587
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8703842,
      "lng": 34.9993434
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.87044775714286,
      "lng": 34.99919787142857
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.870536299999998,
      "lng": 34.999144099999995
    },
    "signal": -80.95
  },
  {
    "location": {
      "lat": 31.870636190909092,
      "lng": 34.99899596363636
    },
    "signal": -80.85
  },
  {
    "location": {
      "lat": 31.870798712499997,
      "lng": 34.9988041625
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.87094668333333,
      "lng": 34.99861466666667
    },
    "signal": -79.4
  },
  {
    "location": {
      "lat": 31.871113225000002,
      "lng": 34.998415300000005
    },
    "signal": -68.95
  },
  {
    "location": {
      "lat": 31.87126405,
      "lng": 34.99822965
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.871361516666667,
      "lng": 34.99809523333334
    },
    "signal": -68.95
  },
  {
    "location": {
      "lat": 31.871526999999997,
      "lng": 34.997897359999996
    },
    "signal": -68.95
  },
  {
    "location": {
      "lat": 31.871663849999997,
      "lng": 34.99773549999999
    },
    "signal": -76.2
  },
  {
    "location": {
      "lat": 31.8718142,
      "lng": 34.99755033333333
    },
    "signal": -76.6
  },
  {
    "location": {
      "lat": 31.871888475000002,
      "lng": 34.997438975
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.87197075,
      "lng": 34.9973538
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.872066149999995,
      "lng": 34.99722998333334
    },
    "signal": -76.6
  },
  {
    "location": {
      "lat": 31.872280725000003,
      "lng": 34.997012475
    },
    "signal": -76.6
  },
  {
    "location": {
      "lat": 31.87245828,
      "lng": 34.99686888
    },
    "signal": -76.2
  },
  {
    "location": {
      "lat": 31.872619299999997,
      "lng": 34.996718959999995
    },
    "signal": -76.6
  },
  {
    "location": {
      "lat": 31.872767279999998,
      "lng": 34.996666999999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.872899033333336,
      "lng": 34.996610933333336
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.872991733333333,
      "lng": 34.9965521
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.873135299999998,
      "lng": 34.9965102125
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.87335691428572,
      "lng": 34.99644340000001
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.873667899999997,
      "lng": 34.9962986
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8737039,
      "lng": 34.996403
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.873824971428572,
      "lng": 34.996360942857144
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.87403175,
      "lng": 34.996375075
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.874235125,
      "lng": 34.996354
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.874386166666667,
      "lng": 34.99635955
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.874582516666663,
      "lng": 34.99634013333334
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.874736449999997,
      "lng": 34.9963392
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.874902633333335,
      "lng": 34.996318333333335
    },
    "signal": -102.3
  },
  {
    "location": {
      "lat": 31.87509371666667,
      "lng": 34.99630536666667
    },
    "signal": -102.3
  },
  {
    "location": {
      "lat": 31.87526934285714,
      "lng": 34.99630535714285
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.875468125,
      "lng": 34.99629163749999
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.875647499999996,
      "lng": 34.99629983333333
    },
    "signal": -102.3
  },
  {
    "location": {
      "lat": 31.8758218875,
      "lng": 34.9963319
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87601571666666,
      "lng": 34.996378916666664
    },
    "signal": -102.2
  },
  {
    "location": {
      "lat": 31.876092100000005,
      "lng": 34.9964184
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87620635,
      "lng": 34.99645159999999
    },
    "signal": -87.95
  },
  {
    "location": {
      "lat": 31.876361800000005,
      "lng": 34.99653092
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.876565520000003,
      "lng": 34.99665048
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8767279,
      "lng": 34.996746
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.876730366666663,
      "lng": 34.996773033333334
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.87679538125,
      "lng": 35.00779109375001
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87683213333333,
      "lng": 35.0076415
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87687585555556,
      "lng": 34.996901677777785
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.876887342105263,
      "lng": 35.0072921
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.876940544999997,
      "lng": 35.007090719999994
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.8770179,
      "lng": 35.00687129999999
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87705890454546,
      "lng": 35.00673812727273
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.877060939999996,
      "lng": 34.99703174
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.877213649999998,
      "lng": 35.00638205909091
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.8772570875,
      "lng": 34.9971731875
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.877305504347834,
      "lng": 35.00619856086957
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87741306,
      "lng": 34.997275020000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.877426521739128,
      "lng": 35.00600093478261
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.877588119432165,
      "lng": 35.00903754541465
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.877605588095246,
      "lng": 35.008342390476194
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.877656931666657,
      "lng": 35.00851885000001
    },
    "signal": -105.65
  },
  {
    "location": {
      "lat": 31.877729216666665,
      "lng": 35.00857431666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.87774933684218,
      "lng": 35.008927705263204
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.87778063913044,
      "lng": 35.00872171739131
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.879384122222216,
      "lng": 34.99829626296295
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.879389821428568,
      "lng": 34.99809715
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.879395946153846,
      "lng": 34.99847093846153
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.87941490714286,
      "lng": 34.99863162857143
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.87943792857143,
      "lng": 34.99791366428572
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.879487554545452,
      "lng": 34.99895238181818
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.87957107333333,
      "lng": 34.999182266666665
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.879593104347826,
      "lng": 34.997787869565215
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8796324,
      "lng": 34.999363056250004
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.879717916666667,
      "lng": 34.999608349999995
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.879724758333335,
      "lng": 34.99780604166667
    },
    "signal": -94.8
  },
  {
    "location": {
      "lat": 31.879765155555553,
      "lng": 34.99973836666667
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.879828600000003,
      "lng": 34.999928018750005
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.879892808333334,
      "lng": 35.00011921666666
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.8799021375,
      "lng": 35.00211055
    },
    "signal": -116
  },
  {
    "location": {
      "lat": 31.87993887777778,
      "lng": 35.00029186666667
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.87996527647059,
      "lng": 34.9979268882353
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.879979564285716,
      "lng": 35.00047072857143
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.87999402727273,
      "lng": 35.003642030303034
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.880120486666662,
      "lng": 35.0034777
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880139317647064,
      "lng": 34.997970082352936
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.880190149999994,
      "lng": 34.999191957142855
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.88019145,
      "lng": 34.99903608
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880202426666663,
      "lng": 34.99884771999999
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880206276470584,
      "lng": 34.999357847058825
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880207957142854,
      "lng": 34.99868665714286
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.88024902500001,
      "lng": 34.999565025
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.88025373333333,
      "lng": 35.019502477777785
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880258423529405,
      "lng": 34.99862155882353
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880272111111115,
      "lng": 35.019671833333334
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88027823529412,
      "lng": 34.999738294117655
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880286200000004,
      "lng": 34.99847440625
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880300634999998,
      "lng": 34.99806023
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88031200714286,
      "lng": 35.019860200000004
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880319106666665,
      "lng": 34.99991470666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880319855555555,
      "lng": 34.9983247
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.880349627272725,
      "lng": 35.02005024545455
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88036294117647,
      "lng": 35.000096164705894
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88038666666667,
      "lng": 35.020183833333334
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88039158,
      "lng": 35.00024656
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880427759999996,
      "lng": 35.020300399999996
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880445412500006,
      "lng": 35.0004375875
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880492725000007,
      "lng": 35.00061664166667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880500050000002,
      "lng": 34.99829133750001
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.880514072727273,
      "lng": 35.020753327272736
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88052084285714,
      "lng": 34.99807915000001
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88053467272727,
      "lng": 35.00079004545454
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880557227272732,
      "lng": 35.02093646363637
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88057202727272,
      "lng": 35.00095271818182
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880606,
      "lng": 35.00101244
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880619549999995,
      "lng": 35.02109431666667
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880633556250004,
      "lng": 35.00117684375
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88065990833333,
      "lng": 35.02131326666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880677235714284,
      "lng": 34.99828934285715
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.88067772,
      "lng": 34.998096819999994
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.880721122222226,
      "lng": 35.02150662222222
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.880722953846153,
      "lng": 35.00151814615384
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.880740766666662,
      "lng": 35.001659266666664
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.880741733333327,
      "lng": 35.02166811666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88075868235294,
      "lng": 35.002077817647056
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.880780633333334,
      "lng": 35.00205603333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8808119125,
      "lng": 35.0218865625
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88083317222222,
      "lng": 35.001701444444436
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88083962592593,
      "lng": 35.00188813333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.880840809999995,
      "lng": 34.9982874
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.880863986666665,
      "lng": 34.99810155333333
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.880876655555554,
      "lng": 35.02219622222222
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.881021550000003,
      "lng": 35.02274519
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.881025972727276,
      "lng": 34.99826380909091
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.881075445454545,
      "lng": 35.02295132727272
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.881139266666665,
      "lng": 35.0231505
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.881172600000003,
      "lng": 35.023298800000006
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.881179109999998,
      "lng": 34.99822939999999
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.88121051,
      "lng": 35.02346617
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.881228581818178,
      "lng": 34.99810345454546
    },
    "signal": -98.65
  },
  {
    "location": {
      "lat": 31.88133403333333,
      "lng": 35.02372286666667
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.88139064,
      "lng": 35.023859279999996
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.88141124444445,
      "lng": 34.99798673333333
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.881412862499996,
      "lng": 34.99813954375
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.8815191,
      "lng": 35.024061975
    },
    "signal": -110
  },
  {
    "location": {
      "lat": 31.881569444444445,
      "lng": 34.997909466666655
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.881590864285716,
      "lng": 34.99807702142857
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8817608,
      "lng": 34.99797479375
    },
    "signal": -88.9
  },
  {
    "location": {
      "lat": 31.881770791666668,
      "lng": 34.997788158333336
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.881874100000005,
      "lng": 35.024443700000006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.881919886666665,
      "lng": 34.997887373333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.881974845454536,
      "lng": 34.99777204545454
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.881980079999998,
      "lng": 34.99763523
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.882033733333333,
      "lng": 35.024612499999996
    },
    "signal": -108.9
  },
  {
    "location": {
      "lat": 31.88210743333333,
      "lng": 34.997744641666664
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.882122639999995,
      "lng": 34.997549809999995
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.88215131333333,
      "lng": 35.024717933333335
    },
    "signal": -95.6
  },
  {
    "location": {
      "lat": 31.882283266666665,
      "lng": 35.0248017
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88230053636364,
      "lng": 34.997379827272724
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.882312159090908,
      "lng": 34.997581104545446
    },
    "signal": -86.65
  },
  {
    "location": {
      "lat": 31.88233626,
      "lng": 35.02483960666666
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.882487349999998,
      "lng": 34.9971997625
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.882497591666667,
      "lng": 34.99740363333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88250686,
      "lng": 35.02491819333333
    },
    "signal": -95.6
  },
  {
    "location": {
      "lat": 31.882610350000004,
      "lng": 35.00296585
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.882613779999996,
      "lng": 35.00315392
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.882672078947365,
      "lng": 34.99724326842106
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.882675060000004,
      "lng": 34.99703561
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.8826754,
      "lng": 35.02496928888889
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.882677333333334,
      "lng": 35.0032523
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8827598,
      "lng": 35.024961700000006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.882796114285714,
      "lng": 34.99714772857143
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.882822400000002,
      "lng": 35.02503545384615
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.882847466666664,
      "lng": 35.00329135
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.88286116666666,
      "lng": 34.996879983333336
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.88287168,
      "lng": 34.997065410000005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88297817777778,
      "lng": 34.997003166666666
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.882996135294114,
      "lng": 35.025055635294116
    },
    "signal": -95.6
  },
  {
    "location": {
      "lat": 31.883002816666664,
      "lng": 35.003357300000005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.883021218181817,
      "lng": 34.99686663636363
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.883042866666667,
      "lng": 34.99674206666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8830744,
      "lng": 35.00344975
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8831856,
      "lng": 35.025064529411765
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.883193441176477,
      "lng": 34.99684623529412
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.883201087500005,
      "lng": 34.9966555875
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8832175,
      "lng": 35.003647259999994
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.88337898571429,
      "lng": 35.00384307142857
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.883381031249996,
      "lng": 35.025070025000005
    },
    "signal": -95.6
  },
  {
    "location": {
      "lat": 31.8833977,
      "lng": 34.996692
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.883552450000003,
      "lng": 35.004037537500004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.88355419375,
      "lng": 35.02504116875
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.883555512500006,
      "lng": 34.99668245625
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.883633500000002,
      "lng": 35.004173200000004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.883715628571426,
      "lng": 34.99647158571429
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.883716872727277,
      "lng": 34.99663465454546
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.883719850000002,
      "lng": 35.025016550000004
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.88372852,
      "lng": 35.00422878
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.883807066666666,
      "lng": 35.004334766666666
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.883847728571432,
      "lng": 35.03226767142856
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88385196666667,
      "lng": 35.03245153333334
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88385518333334,
      "lng": 35.03263168333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88385815,
      "lng": 35.032803475
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.883874083333335,
      "lng": 35.03296575000001
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88387454,
      "lng": 35.03206794
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.883896200000002,
      "lng": 34.996604838461536
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88389735714286,
      "lng": 35.004389999999994
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.883924074999996,
      "lng": 35.024965075000004
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.883926375,
      "lng": 34.996457312500006
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.88393906666667,
      "lng": 35.033179749999995
    },
    "signal": -96.65
  },
  {
    "location": {
      "lat": 31.883948399999998,
      "lng": 35.03191565
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8839589,
      "lng": 35.00450596666667
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.8840173,
      "lng": 35.031847799999994
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.884049287499998,
      "lng": 35.0333631
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.884068480000003,
      "lng": 34.99659518
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.884077342857147,
      "lng": 35.00459887142857
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.88409146,
      "lng": 35.024934570000006
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.884120555555555,
      "lng": 34.9964906
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.884123900000002,
      "lng": 35.03177563333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.884161624999997,
      "lng": 35.004735325
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8841791,
      "lng": 35.03348716666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88425547777778,
      "lng": 35.024900788888885
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.884259525,
      "lng": 35.03173945
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88426139166667,
      "lng": 34.996596425
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88427842857143,
      "lng": 34.99645575714286
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.884295625000004,
      "lng": 35.033549050000005
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88429776,
      "lng": 35.00489334
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.884323837500002,
      "lng": 35.0860041125
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.884442449999998,
      "lng": 35.03359306666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.884444006666666,
      "lng": 35.02485986666666
    },
    "signal": -95.4
  },
  {
    "location": {
      "lat": 31.88445739166667,
      "lng": 34.99662208333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.884464666666663,
      "lng": 35.0317173
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.884490171428574,
      "lng": 35.005105557142855
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.884549800000002,
      "lng": 35.085701799999995
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.884598799999996,
      "lng": 34.99648608571429
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.884627572727265,
      "lng": 34.996659236363634
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88463737142857,
      "lng": 35.03358935714285
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.884651383333335,
      "lng": 35.0371538
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.8846538375,
      "lng": 35.031700575
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88465725454546,
      "lng": 35.02478762727273
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.884657266666665,
      "lng": 35.03731646666666
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.884665244444445,
      "lng": 35.00529529999999
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.884691075,
      "lng": 35.036921525
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.884700675,
      "lng": 35.037493175
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.884761366666666,
      "lng": 35.085425533333336
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.88477348,
      "lng": 35.03673858
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88477585,
      "lng": 35.037657425
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88477656,
      "lng": 35.0053356
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8847841,
      "lng": 34.9965369
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.88481239090909,
      "lng": 34.996718563636364
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.884826206666666,
      "lng": 35.02477056
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.884831466666665,
      "lng": 35.03351453333334
    },
    "signal": -93.8
  },
  {
    "location": {
      "lat": 31.88483313333333,
      "lng": 35.03167643333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.884839166666666,
      "lng": 35.005456683333335
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.884851233333336,
      "lng": 35.036633800000004
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.884865266666665,
      "lng": 35.03778066666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.884934375,
      "lng": 35.036557075
    },
    "signal": -91.9
  },
  {
    "location": {
      "lat": 31.884939666666664,
      "lng": 35.03165296666666
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88496672222222,
      "lng": 34.99665686666666
    },
    "signal": -82.85
  },
  {
    "location": {
      "lat": 31.884987720325235,
      "lng": 35.005783560569114
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.884991425000003,
      "lng": 35.08512795
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.885003424999997,
      "lng": 35.037893662500004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885005200000005,
      "lng": 35.03337581428572
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885006029166664,
      "lng": 35.02473742916667
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8850190625,
      "lng": 35.0055329375
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.88502267692308,
      "lng": 34.99682216153846
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.885030266666664,
      "lng": 35.03649003333334
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.885041666666666,
      "lng": 35.031620733333334
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885141009090912,
      "lng": 35.00559836363636
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.885145899999998,
      "lng": 35.02297373333334
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88515195,
      "lng": 35.023173875
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88516796,
      "lng": 34.99689212
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.885168185714285,
      "lng": 34.996719514285715
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885168683333333,
      "lng": 35.02273071666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885177961538464,
      "lng": 35.02470723846154
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.8851786,
      "lng": 35.03155798571429
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885181150000005,
      "lng": 35.0332024
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885181266666667,
      "lng": 35.036396016666664
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88518773333333,
      "lng": 35.03797706666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88519298333333,
      "lng": 35.02345311666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885200299999997,
      "lng": 35.0225606
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885205225,
      "lng": 35.084843075
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.885231233333332,
      "lng": 35.02365121666667
    },
    "signal": -92.3
  },
  {
    "location": {
      "lat": 31.8852395,
      "lng": 35.022418259999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885258549999996,
      "lng": 35.023824399999995
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885273971428575,
      "lng": 35.022926042857144
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885284499999997,
      "lng": 35.02313843333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885297199999997,
      "lng": 35.02274863333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88531225,
      "lng": 35.024126949999996
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885318100000003,
      "lng": 35.031471100000005
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885329499999997,
      "lng": 35.008228100000004
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.885337083333336,
      "lng": 35.02258698333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885339187499998,
      "lng": 35.0239407
    },
    "signal": -84.4
  },
  {
    "location": {
      "lat": 31.885345100000002,
      "lng": 35.022373
    },
    "signal": -90.9
  },
  {
    "location": {
      "lat": 31.8853455,
      "lng": 35.024326625
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.88534557777778,
      "lng": 35.0056274
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.885350716666665,
      "lng": 35.023452066666664
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885359,
      "lng": 35.031427640000004
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885362160000003,
      "lng": 35.02451842
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885362766666663,
      "lng": 35.03302238333334
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8853628625,
      "lng": 34.996846049999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88536776,
      "lng": 35.02223554
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8853681,
      "lng": 35.0380126
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885376049999998,
      "lng": 35.0238406
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88538081333333,
      "lng": 34.99705676666667
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.885382566666667,
      "lng": 35.02367916666666
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885384225,
      "lng": 35.023269925
    },
    "signal": -93.7
  },
  {
    "location": {
      "lat": 31.88538866666667,
      "lng": 35.03627186666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88540456,
      "lng": 35.02205022
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885457325,
      "lng": 35.024081724999995
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.885464600000002,
      "lng": 35.0313417
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88548605,
      "lng": 35.02420185
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.885486633333333,
      "lng": 35.0221896
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88551115,
      "lng": 35.032821075
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885511940000004,
      "lng": 35.036199620000005
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.885516433333333,
      "lng": 35.02431143333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88552568333333,
      "lng": 35.03802278333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885534433333337,
      "lng": 35.023227391666666
    },
    "signal": -91.45
  },
  {
    "location": {
      "lat": 31.88554212222222,
      "lng": 35.00565607777778
    },
    "signal": -77.4
  },
  {
    "location": {
      "lat": 31.88554565833334,
      "lng": 34.997209025
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885546899999998,
      "lng": 35.0081883
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.88555027142857,
      "lng": 35.021850685714284
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885561142857142,
      "lng": 34.997015342857146
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885564300000002,
      "lng": 35.03121503333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885574442857145,
      "lng": 35.02201437142857
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8855763125,
      "lng": 35.024513437500005
    },
    "signal": -84.55
  },
  {
    "location": {
      "lat": 31.885588475,
      "lng": 35.03264975
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885608500000004,
      "lng": 35.0063756
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885612199999997,
      "lng": 35.008034949999995
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.8856164,
      "lng": 35.006234
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885619000000002,
      "lng": 35.0061039
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885621999999998,
      "lng": 35.006561700000006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885624749999998,
      "lng": 35.006700699999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885640900000002,
      "lng": 35.03255575
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8856672,
      "lng": 35.007130616666664
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885668000000003,
      "lng": 35.0074824
    },
    "signal": -103.8
  },
  {
    "location": {
      "lat": 31.88567304,
      "lng": 35.03242884
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885673411111114,
      "lng": 35.0060164
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.88567354,
      "lng": 35.00695128
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88567593333333,
      "lng": 35.007311316666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885675942857144,
      "lng": 35.006775271428566
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.885677020000003,
      "lng": 35.00781662
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.88568294,
      "lng": 35.005837080000006
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.885683150000002,
      "lng": 35.08427265
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8856834,
      "lng": 35.031489900000004
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885684933333334,
      "lng": 35.009612000000004
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885687066666666,
      "lng": 35.0088752
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885687133333334,
      "lng": 35.00948016666667
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885687342857146,
      "lng": 35.009272585714285
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885687599999997,
      "lng": 35.00903086666666
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8856879,
      "lng": 35.0076491
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.8856879,
      "lng": 35.0323068
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88568863333333,
      "lng": 35.00872913333333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8856915,
      "lng": 35.009831675
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885692562499997,
      "lng": 35.03174025
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88569458,
      "lng": 35.00857968
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.885696566666663,
      "lng": 35.0232101
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885699225,
      "lng": 35.031313874999995
    },
    "signal": -90.85
  },
  {
    "location": {
      "lat": 31.885705400000003,
      "lng": 35.03191306666667
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885706175000003,
      "lng": 34.9972158
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885707675,
      "lng": 35.031137675
    },
    "signal": -90.85
  },
  {
    "location": {
      "lat": 31.885708466666667,
      "lng": 35.03210446666666
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.885711974999996,
      "lng": 35.02456070000001
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.885713899999995,
      "lng": 35.02166866
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885716479999996,
      "lng": 35.00794018
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.885717416666665,
      "lng": 35.036077283333334
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.88572474,
      "lng": 35.031017930000004
    },
    "signal": -90.7
  },
  {
    "location": {
      "lat": 31.885731657142856,
      "lng": 35.03801941428571
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.885741985714283,
      "lng": 35.021833771428575
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88574332,
      "lng": 35.023182500000004
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.88575284,
      "lng": 35.00834338
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.885760333333334,
      "lng": 35.030908000000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88576565,
      "lng": 35.009975024999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8857726,
      "lng": 35.00816865
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.885854528571432,
      "lng": 35.030798628571425
    },
    "signal": -87.9
  },
  {
    "location": {
      "lat": 31.8858793375,
      "lng": 34.997382487500005
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8858839,
      "lng": 35.03597653333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.885886825,
      "lng": 35.021477649999994
    },
    "signal": -95.65
  },
  {
    "location": {
      "lat": 31.88589593333333,
      "lng": 35.02166880000001
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.885901639999997,
      "lng": 35.02456428000001
    },
    "signal": -82.3
  },
  {
    "location": {
      "lat": 31.885905466666667,
      "lng": 35.084021633333336
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.88590568,
      "lng": 35.02315051000001
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.885912124999997,
      "lng": 35.03798607499999
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.885919179999995,
      "lng": 35.01003274
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88592174545455,
      "lng": 34.99770336363636
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88593738333334,
      "lng": 35.03066616666666
    },
    "signal": -87.95
  },
  {
    "location": {
      "lat": 31.8860039,
      "lng": 35.03796305
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.886010700000003,
      "lng": 35.030597220000004
    },
    "signal": -87.95
  },
  {
    "location": {
      "lat": 31.886017220000003,
      "lng": 34.99758866
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88602408333333,
      "lng": 35.01751698333334
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.88602476666667,
      "lng": 35.01734866666666
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.886025133333334,
      "lng": 35.017695133333326
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.886026357142857,
      "lng": 35.01787957142857
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8860282,
      "lng": 35.018087933333334
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886030442857145,
      "lng": 35.02131964285714
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.886032233333335,
      "lng": 35.01822066666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8860362,
      "lng": 35.0171473
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.886037284615387,
      "lng": 34.997882484615396
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88604752857143,
      "lng": 35.03587987142858
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88605474285714,
      "lng": 35.02149507142858
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886057,
      "lng": 35.01843247142857
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886060075,
      "lng": 35.023078437500004
    },
    "signal": -92.3
  },
  {
    "location": {
      "lat": 31.886074237500004,
      "lng": 35.010039199999994
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.886079942857148,
      "lng": 35.02452543571428
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886087660000005,
      "lng": 35.017001040000004
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.886100533333337,
      "lng": 35.0186203
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886101777777778,
      "lng": 34.99778283333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.886107525,
      "lng": 35.037921100000005
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.886109855555553,
      "lng": 34.998055377777774
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886113839999997,
      "lng": 35.03049891
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8861204,
      "lng": 35.083738499999995
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8861268,
      "lng": 35.02300323333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.886150125000004,
      "lng": 35.02119007499999
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.886150933333337,
      "lng": 35.01873986666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8861669,
      "lng": 35.0230349
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.886203283333334,
      "lng": 35.03041580000001
    },
    "signal": -86.95
  },
  {
    "location": {
      "lat": 31.886212240000003,
      "lng": 34.998243130000006
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886215699999997,
      "lng": 35.0188485
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886220425,
      "lng": 35.021303225
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886252111111112,
      "lng": 35.02292342222222
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.886252419999998,
      "lng": 35.02114252
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.886253637500005,
      "lng": 35.02451640625
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886257600000004,
      "lng": 35.010040759999995
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.886258942857147,
      "lng": 35.016983728571425
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886265837499998,
      "lng": 34.99810505000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8862752,
      "lng": 35.035740100000005
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886277640000003,
      "lng": 35.03783508
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.886288033333333,
      "lng": 35.018943033333336
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886293566666666,
      "lng": 35.0210052
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.886298414285715,
      "lng": 35.030354585714285
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.886304525000003,
      "lng": 34.998427783333334
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88631385,
      "lng": 35.02282755
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.886355433333332,
      "lng": 35.083475066666665
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.886359849999998,
      "lng": 35.01903005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.886384099999997,
      "lng": 34.998298979999994
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.886391066666665,
      "lng": 35.02108916666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886402711111106,
      "lng": 35.02452945555555
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886418166666672,
      "lng": 35.02093245
    },
    "signal": -95.85
  },
  {
    "location": {
      "lat": 31.886419125,
      "lng": 34.998639116666666
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88642676666667,
      "lng": 35.035635
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88643243333333,
      "lng": 35.019135966666674
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.88643368,
      "lng": 35.02271356999999
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88643752222222,
      "lng": 34.998497588888895
    },
    "signal": -92.8
  },
  {
    "location": {
      "lat": 31.886441133333335,
      "lng": 35.020743933333335
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.88644162857143,
      "lng": 35.010035914285716
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.886452172727275,
      "lng": 35.03029189090909
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.886454228571427,
      "lng": 35.02444118571428
    },
    "signal": -82.45
  },
  {
    "location": {
      "lat": 31.88645686,
      "lng": 35.037726019999994
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.886457199999995,
      "lng": 35.01700644
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886491749999998,
      "lng": 35.019292575
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.886495,
      "lng": 35.02058395
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8865203,
      "lng": 35.035562850000005
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886575620000002,
      "lng": 35.02076444
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.88658628,
      "lng": 34.99868432
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8865869,
      "lng": 35.0831825
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.886593627272724,
      "lng": 34.99899741818182
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.886593883333337,
      "lng": 35.022532275
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.886603759999996,
      "lng": 35.03763916
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8866045,
      "lng": 35.0354883
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886605622222216,
      "lng": 34.99884105555556
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88660815,
      "lng": 35.02040158333333
    },
    "signal": -92.85
  },
  {
    "location": {
      "lat": 31.886612330000002,
      "lng": 35.02441569999999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886617987499996,
      "lng": 35.030261975
    },
    "signal": -86.85
  },
  {
    "location": {
      "lat": 31.88662217777778,
      "lng": 35.020234066666674
    },
    "signal": -95.4
  },
  {
    "location": {
      "lat": 31.886626166666662,
      "lng": 35.01970645555556
    },
    "signal": -95.4
  },
  {
    "location": {
      "lat": 31.886629822222226,
      "lng": 35.0195009
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.88663982,
      "lng": 35.02005164
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.886644024999995,
      "lng": 35.0205624
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886644025,
      "lng": 35.016982600000006
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886654475000004,
      "lng": 35.019877512499995
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.8866812,
      "lng": 35.0354156
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886684433333333,
      "lng": 35.0224023
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.886731583333333,
      "lng": 34.998970850000006
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.886761633333336,
      "lng": 35.022353788888886
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.88677265,
      "lng": 35.03753460000001
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.886780163636363,
      "lng": 34.999363154545456
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88678562857143,
      "lng": 35.03530127142857
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886786209090907,
      "lng": 35.024395772727274
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886789649999997,
      "lng": 35.019363675
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.88679948,
      "lng": 35.01003032
    },
    "signal": -88.95
  },
  {
    "location": {
      "lat": 31.886803130000004,
      "lng": 34.99919671
    },
    "signal": -93.55
  },
  {
    "location": {
      "lat": 31.886810821428575,
      "lng": 35.030248221428565
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.886811366666667,
      "lng": 35.08294586666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88683003333333,
      "lng": 35.016867366666666
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.88685225,
      "lng": 35.022242066666664
    },
    "signal": -102.05
  },
  {
    "location": {
      "lat": 31.8869261,
      "lng": 35.0374396
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8869284,
      "lng": 35.02216065714286
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.886934416666666,
      "lng": 35.03512466666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.886937375,
      "lng": 35.019261225
    },
    "signal": -95.95
  },
  {
    "location": {
      "lat": 31.88694095,
      "lng": 35.01001475
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.886963262500004,
      "lng": 34.999581375000005
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.886978516666662,
      "lng": 35.02437545
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.886980333333337,
      "lng": 35.0302712
    },
    "signal": -87.9
  },
  {
    "location": {
      "lat": 31.88698085454545,
      "lng": 34.99973194545454
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.887003116666666,
      "lng": 35.016670166666664
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8870206,
      "lng": 35.02204875
    },
    "signal": -102.05
  },
  {
    "location": {
      "lat": 31.887027666666665,
      "lng": 35.0349899
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887031133333334,
      "lng": 35.03737446666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887035899999994,
      "lng": 35.082644779999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88706745,
      "lng": 34.999580449999996
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88708816666667,
      "lng": 35.034895633333335
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88711638,
      "lng": 35.00999212000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.887119355555555,
      "lng": 34.9999128
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8871227,
      "lng": 35.01653372500001
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.887138916666668,
      "lng": 35.019161316666676
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.88714372307693,
      "lng": 35.02198256923077
    },
    "signal": -99.6
  },
  {
    "location": {
      "lat": 31.887145566666664,
      "lng": 35.03479716666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887152569230768,
      "lng": 35.03033051538461
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887154783333333,
      "lng": 35.037298666666665
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887156433333335,
      "lng": 34.99974331666667
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.887182114285714,
      "lng": 35.02436348571429
    },
    "signal": -81.5
  },
  {
    "location": {
      "lat": 31.887189274999997,
      "lng": 35.021874575
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.887199425,
      "lng": 35.0000628625
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.887206583333334,
      "lng": 35.03468015
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88725075,
      "lng": 35.03724245
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.88725535,
      "lng": 35.034594350000006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8872654,
      "lng": 35.01639556666667
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8872705,
      "lng": 35.02208845
    },
    "signal": -85.75
  },
  {
    "location": {
      "lat": 31.887272766666666,
      "lng": 35.0824129
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.887279016666668,
      "lng": 34.99992521666667
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8872831,
      "lng": 35.02178750000001
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88729695,
      "lng": 35.0190741
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.887311950000004,
      "lng": 35.0303832
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887313333333335,
      "lng": 35.03443931666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887321128571426,
      "lng": 35.00993625714286
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.887343966666663,
      "lng": 35.000111366666665
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88734846666667,
      "lng": 35.02170776666666
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.88734984285714,
      "lng": 35.02436920000001
    },
    "signal": -81.5
  },
  {
    "location": {
      "lat": 31.887354450000004,
      "lng": 35.000250460000004
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.887361750000004,
      "lng": 35.0371746
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887366219999997,
      "lng": 35.030446620000006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887380866666664,
      "lng": 35.0221813
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887383679999992,
      "lng": 35.03423514
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887385225,
      "lng": 35.016319075
    },
    "signal": -95.3
  },
  {
    "location": {
      "lat": 31.887386749999997,
      "lng": 35.018996975
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.88742023333333,
      "lng": 35.034128333333335
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88744525,
      "lng": 35.034056899999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887475724999998,
      "lng": 35.08214085
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88747831666667,
      "lng": 35.0338836
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887491400000002,
      "lng": 35.022261500000006
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.88750554,
      "lng": 35.03708494
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887510100000004,
      "lng": 35.033711499999995
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887510883333334,
      "lng": 35.000433216666664
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.887511700000005,
      "lng": 35.01888473333333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.88751608571428,
      "lng": 35.02437021428572
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.88751845,
      "lng": 35.030552300000004
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8875198,
      "lng": 35.00027858
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.887520000000006,
      "lng": 35.0162803
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.887534871428574,
      "lng": 35.02149961428571
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.887536450000002,
      "lng": 35.033508475000005
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88754154,
      "lng": 35.00985832
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88754405,
      "lng": 35.03063383333333
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.887545866666667,
      "lng": 35.03331693333333
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887548833333337,
      "lng": 35.03319846666667
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887551579999997,
      "lng": 35.03263508
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88755263333334,
      "lng": 35.03280373333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88755312857143,
      "lng": 35.03301137142857
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887562980000002,
      "lng": 35.0324137
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.887607250000002,
      "lng": 35.03225795
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88761995,
      "lng": 35.0187648
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.887635475,
      "lng": 35.030679375
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.887655855555554,
      "lng": 35.000461211111116
    },
    "signal": -88.7
  },
  {
    "location": {
      "lat": 31.88767518333333,
      "lng": 35.02236381666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887676842857143,
      "lng": 35.01626162857143
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.887682075,
      "lng": 35.00979325
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.887685457142858,
      "lng": 35.024371114285714
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8876967,
      "lng": 35.08187245
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.887699242857142,
      "lng": 35.0214495
    },
    "signal": -79.6
  },
  {
    "location": {
      "lat": 31.887708299999996,
      "lng": 35.03080299
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88771015,
      "lng": 35.03695046666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887710766666668,
      "lng": 35.018654733333335
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.887711239999998,
      "lng": 35.03211752
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.887718453846155,
      "lng": 35.00063816153846
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.887752050000003,
      "lng": 35.03094025
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.887807666666664,
      "lng": 35.0185416
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8878146,
      "lng": 35.0097384
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8878172875,
      "lng": 35.0310016125
    },
    "signal": -91.7
  },
  {
    "location": {
      "lat": 31.887851911111113,
      "lng": 35.02436971111111
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.88786475,
      "lng": 35.02158204999999
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.887872750000003,
      "lng": 35.0272488125
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887873033333335,
      "lng": 35.027039566666666
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.8878734,
      "lng": 35.02744153333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887879366666667,
      "lng": 35.02691813333333
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887879942857143,
      "lng": 35.02243732857143
    },
    "signal": -84.6
  },
  {
    "location": {
      "lat": 31.8878809,
      "lng": 35.0275915
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887889083333334,
      "lng": 35.03119245
    },
    "signal": -91.1
  },
  {
    "location": {
      "lat": 31.88789036,
      "lng": 35.032031440000004
    },
    "signal": -93.6
  },
  {
    "location": {
      "lat": 31.887890533333334,
      "lng": 35.01625966666666
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.887891919999994,
      "lng": 35.036793499999995
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.887894999999997,
      "lng": 35.027746233333325
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887901699999997,
      "lng": 35.018432733333334
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.88790355,
      "lng": 35.026717125000005
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887905383333333,
      "lng": 35.00061373333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.88791493333333,
      "lng": 35.0279016
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.887916399999998,
      "lng": 35.08160415
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.887921000000002,
      "lng": 35.0265683
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.887922979999995,
      "lng": 35.00965487999999
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.887936514285716,
      "lng": 35.031355542857135
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88794455,
      "lng": 35.031546525
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88794746,
      "lng": 35.02809454
    },
    "signal": -91.75
  },
  {
    "location": {
      "lat": 31.887950625000002,
      "lng": 35.0263861
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8879519,
      "lng": 35.0317858
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88797322,
      "lng": 35.03158054
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.887973249999998,
      "lng": 35.0367129
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.88798798,
      "lng": 35.03174882
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.88799204285714,
      "lng": 35.03190522857143
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8880062,
      "lng": 35.02836526
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888006433333334,
      "lng": 35.02617803333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888016720000003,
      "lng": 35.021643039999994
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888017766666668,
      "lng": 35.02734546666667
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88801976666667,
      "lng": 35.0271769
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88802046,
      "lng": 35.0320823
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.888024866666665,
      "lng": 35.02753953333333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888026950000004,
      "lng": 35.02703517500001
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.888039600000003,
      "lng": 35.026900680000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.888040533333335,
      "lng": 35.0277345
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8880465,
      "lng": 35.00092016666667
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888046725,
      "lng": 35.032328575
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.888047171428575,
      "lng": 35.018294714285716
    },
    "signal": -94.6
  },
  {
    "location": {
      "lat": 31.888054750000006,
      "lng": 35.026323016666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888056233333334,
      "lng": 35.03660706666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.888057080000003,
      "lng": 35.00957165999999
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88805883846154,
      "lng": 35.02436761538461
    },
    "signal": -83.8
  },
  {
    "location": {
      "lat": 31.888061966666665,
      "lng": 35.02670476666666
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.888064000000004,
      "lng": 35.02856996666666
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888064229999998,
      "lng": 35.00082526999999
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888065775,
      "lng": 35.0279307
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888067000000003,
      "lng": 35.025979
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8880672,
      "lng": 35.01626113333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.8880745,
      "lng": 35.03249446666666
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.888076166666668,
      "lng": 35.02246416666666
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.888083299999998,
      "lng": 35.02813848
    },
    "signal": -94.85
  },
  {
    "location": {
      "lat": 31.88808848333333,
      "lng": 35.02651301666666
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88811653333333,
      "lng": 35.02874726666666
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888137566666668,
      "lng": 35.036494733333335
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8881506,
      "lng": 35.032671
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8881526,
      "lng": 35.02831453333334
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888160466666665,
      "lng": 35.08133213333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88818226666667,
      "lng": 35.02612333333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.88819734166667,
      "lng": 35.00101720833334
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888202399999997,
      "lng": 35.03639642499999
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.888202825,
      "lng": 35.0256481
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888205366666668,
      "lng": 35.0285025
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888218800000004,
      "lng": 35.0216657
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88822263333334,
      "lng": 35.029099433333336
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88822503333333,
      "lng": 35.0181904
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888233449999998,
      "lng": 35.000852333333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88823533333333,
      "lng": 35.02595243333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.888237333333333,
      "lng": 35.00943696666666
    },
    "signal": -92.6
  },
  {
    "location": {
      "lat": 31.888239166666665,
      "lng": 35.022465333333336
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8882426375,
      "lng": 35.028900325
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888245325,
      "lng": 35.024361449999994
    },
    "signal": -83.8
  },
  {
    "location": {
      "lat": 31.888249275,
      "lng": 35.03282985
    },
    "signal": -52
  },
  {
    "location": {
      "lat": 31.888256685714282,
      "lng": 35.016263942857144
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.88826136666667,
      "lng": 35.02869613333334
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888262450000003,
      "lng": 35.025780025
    },
    "signal": -88.05
  },
  {
    "location": {
      "lat": 31.888273333333334,
      "lng": 35.0292748
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888275100000005,
      "lng": 35.02546743333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888299333333332,
      "lng": 35.03624373333333
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.888365375,
      "lng": 35.02560805
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.888367166666665,
      "lng": 35.029058666666664
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888367425000002,
      "lng": 35.081040825
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888374933333335,
      "lng": 35.02962363333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888377300000002,
      "lng": 35.021658583333334
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888389133333334,
      "lng": 35.02525843333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888391733333332,
      "lng": 35.018163833333325
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888397930000004,
      "lng": 35.00113718
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888404236363638,
      "lng": 35.02434456363636
    },
    "signal": -83.9
  },
  {
    "location": {
      "lat": 31.88841494,
      "lng": 35.029425339999996
    },
    "signal": -96.75
  },
  {
    "location": {
      "lat": 31.88841548571429,
      "lng": 35.02245954285714
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.888415625,
      "lng": 35.025423075
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.888415666666663,
      "lng": 35.01626673333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.88841773333333,
      "lng": 35.03605936666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.888418233333336,
      "lng": 35.02923306666667
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.888425483333336,
      "lng": 35.00927243333334
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.888428066666666,
      "lng": 35.03296816666667
    },
    "signal": -52
  },
  {
    "location": {
      "lat": 31.888428925,
      "lng": 35.029797925
    },
    "signal": -98.65
  },
  {
    "location": {
      "lat": 31.888442800000004,
      "lng": 35.0181504
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888461675000002,
      "lng": 35.025113274999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888478433333336,
      "lng": 35.02997416666667
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.888516133333336,
      "lng": 35.025272566666665
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.888520133333333,
      "lng": 35.02957566666666
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8885326,
      "lng": 35.001035825
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88854182,
      "lng": 35.0358648
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.888544337499997,
      "lng": 35.024564075
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888553100000003,
      "lng": 35.02160216
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888558872727277,
      "lng": 35.02431974545454
    },
    "signal": -84.85
  },
  {
    "location": {
      "lat": 31.888560559999995,
      "lng": 35.08077614
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.8885706,
      "lng": 35.0297508
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.888579540000002,
      "lng": 35.02504857
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888581366666667,
      "lng": 35.030322866666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88859146666667,
      "lng": 35.00118285833333
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888597880000003,
      "lng": 35.02494552
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888599640000002,
      "lng": 35.0246968
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.888600933333333,
      "lng": 35.016269766666674
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888604125,
      "lng": 35.02244175
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88861066,
      "lng": 35.03010243999999
    },
    "signal": -98.9
  },
  {
    "location": {
      "lat": 31.888615375,
      "lng": 35.033020025
    },
    "signal": -52
  },
  {
    "location": {
      "lat": 31.88861748571429,
      "lng": 35.00909744285714
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.888618733333335,
      "lng": 35.0299137
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.88862563333333,
      "lng": 35.018155433333334
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888634033333336,
      "lng": 35.0305018
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88864282,
      "lng": 35.035714899999995
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8886495625,
      "lng": 35.024244825
    },
    "signal": -85.45
  },
  {
    "location": {
      "lat": 31.888727250000002,
      "lng": 35.030270975
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.888735866666664,
      "lng": 35.03068001666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.888737866666663,
      "lng": 35.02422704444444
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.888742125,
      "lng": 35.021480375
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888751549999995,
      "lng": 35.02469787500001
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88875623333333,
      "lng": 35.0181696
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888761785714287,
      "lng": 35.0223701
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8887623,
      "lng": 35.030427599999996
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.888764433333332,
      "lng": 35.031052666666675
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.888775533333334,
      "lng": 35.032956733333336
    },
    "signal": -52
  },
  {
    "location": {
      "lat": 31.888780075000003,
      "lng": 35.001144225
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888780280000002,
      "lng": 35.03084086
    },
    "signal": -98.9
  },
  {
    "location": {
      "lat": 31.88878063333333,
      "lng": 35.01625348333334
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888781650000002,
      "lng": 35.008944425
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.888781875,
      "lng": 35.0355415875
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.888782437499998,
      "lng": 35.0013195
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888790633333333,
      "lng": 35.08050791666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.88879593333333,
      "lng": 35.03123993333333
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.88880355,
      "lng": 35.02432259285715
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88882243333333,
      "lng": 35.0314239
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.888842500000003,
      "lng": 35.03160282499999
    },
    "signal": -97.65
  },
  {
    "location": {
      "lat": 31.888843325,
      "lng": 35.03283605
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.888848475000003,
      "lng": 35.02137007500001
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888853299999997,
      "lng": 35.032650399999994
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.888853800000003,
      "lng": 35.03177983333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.88888165,
      "lng": 35.01620975
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888897274999998,
      "lng": 35.008853975
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.888902266666665,
      "lng": 35.016102999999994
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888902466666664,
      "lng": 35.015803266666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888902533333336,
      "lng": 35.015941700000006
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88890425,
      "lng": 35.03102201666667
    },
    "signal": -96.75
  },
  {
    "location": {
      "lat": 31.888908488888887,
      "lng": 35.024555444444445
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88891852857143,
      "lng": 35.03246264285715
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888929824999998,
      "lng": 35.0319802
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.888931542857144,
      "lng": 35.015579314285716
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888933525,
      "lng": 35.018169975
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888934819999996,
      "lng": 35.03262126
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888940933333334,
      "lng": 35.03541406666667
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.888944079999998,
      "lng": 35.03231444
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.888944225,
      "lng": 35.03127105
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888958242857143,
      "lng": 35.02226204285714
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.888959077777777,
      "lng": 35.00139307777778
    },
    "signal": 12
  },
  {
    "location": {
      "lat": 31.888960985714284,
      "lng": 35.03213732857142
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88896260909091,
      "lng": 35.02439509090909
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.88896528,
      "lng": 35.02114256
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.888965674999994,
      "lng": 35.031447850000006
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88897036666667,
      "lng": 35.015387800000006
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.888979833333334,
      "lng": 35.031632933333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888980642857145,
      "lng": 35.001222885714284
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88898933333333,
      "lng": 35.03182073333333
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.888993775000003,
      "lng": 35.08024825
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.88900005,
      "lng": 35.032774100000005
    },
    "signal": -91.45
  },
  {
    "location": {
      "lat": 31.88900786666667,
      "lng": 35.0087586
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.889015275,
      "lng": 35.020983375
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.889021633333332,
      "lng": 35.01521876666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.88903836,
      "lng": 35.02021084
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8890393,
      "lng": 35.0203902
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.88903995,
      "lng": 35.019994800000006
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.88904255,
      "lng": 35.020840050000004
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889044000000002,
      "lng": 35.0205035
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889048840000005,
      "lng": 35.0207178
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889053275000002,
      "lng": 35.019859875
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.8890575,
      "lng": 35.015164399999996
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.889075766666668,
      "lng": 35.02217126666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889096720000005,
      "lng": 35.01968864
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.889119700000002,
      "lng": 35.008651400000005
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.889121775,
      "lng": 35.01500205
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.889127050000003,
      "lng": 35.02440864166667
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.88912756,
      "lng": 35.01816816
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.889135099999997,
      "lng": 35.03299155999999
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.889141749999997,
      "lng": 35.03531976666667
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.889152880000005,
      "lng": 35.00133454
    },
    "signal": -86.8
  },
  {
    "location": {
      "lat": 31.889171133333335,
      "lng": 35.019576566666665
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.889203600000002,
      "lng": 35.02204953333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.889206133333335,
      "lng": 35.014856333333334
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.889219166666667,
      "lng": 35.00854406666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.889257025,
      "lng": 35.07993295000001
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8892665,
      "lng": 35.01476876666667
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.889298716666662,
      "lng": 35.019472783333335
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.889303325,
      "lng": 35.021948975
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88930836666667,
      "lng": 35.00848313333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.889316100000002,
      "lng": 35.018172266666674
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8893197,
      "lng": 35.024378882352934
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.88932657142857,
      "lng": 35.00131907142857
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.88933636666667,
      "lng": 35.0146801
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.889345159999998,
      "lng": 35.001499769999995
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.889356100000004,
      "lng": 35.0330718
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8894007,
      "lng": 35.0083876
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.889407399999996,
      "lng": 35.0218312
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.889464633333336,
      "lng": 35.007042866666666
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8894714,
      "lng": 35.00721926666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88947296666667,
      "lng": 35.00686436666666
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889477166666666,
      "lng": 35.07968033333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.889483666666667,
      "lng": 35.021693666666664
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8894857,
      "lng": 35.03523784
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.889491800000002,
      "lng": 35.00833256666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.88949605,
      "lng": 35.0066894
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889499266666665,
      "lng": 35.02436434444444
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.889499650000005,
      "lng": 35.001347916666674
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.889501733333333,
      "lng": 35.00745036666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889508816666666,
      "lng": 35.01449898333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.889510333333334,
      "lng": 35.019404233333326
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.88951968,
      "lng": 35.01817144
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.889526479999994,
      "lng": 35.0330718
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8895282,
      "lng": 35.001533800000004
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.889529433333337,
      "lng": 35.00651416666667
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889540159999996,
      "lng": 35.00762856
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889564725,
      "lng": 35.021535924999995
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.88956995,
      "lng": 35.00634855
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8895995,
      "lng": 35.0144376
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.889603200000003,
      "lng": 35.007866275
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889606466666663,
      "lng": 35.021426266666666
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8896337,
      "lng": 35.007971966666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889638133333335,
      "lng": 35.006175500000005
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889651175000004,
      "lng": 35.0212952
    },
    "signal": -85.35
  },
  {
    "location": {
      "lat": 31.88965865,
      "lng": 35.00816513333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.889661666666665,
      "lng": 35.001363633333334
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.889662799999996,
      "lng": 35.02434335714286
    },
    "signal": -73.4
  },
  {
    "location": {
      "lat": 31.889663099999996,
      "lng": 35.033072499999996
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.889686840000003,
      "lng": 35.021111839999996
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.889690333333334,
      "lng": 35.0194055
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889695,
      "lng": 35.01817233333333
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.889696850000004,
      "lng": 35.035233016666666
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.889699483333334,
      "lng": 35.07939473333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.889699933333333,
      "lng": 35.01435883333334
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8897125,
      "lng": 35.0060162
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889721366666667,
      "lng": 35.020875466666666
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.88972663333333,
      "lng": 35.0203664
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.889726833333338,
      "lng": 35.02076593333333
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.88973154285714,
      "lng": 35.020600357142854
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.8897971,
      "lng": 35.00587355
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889824299999997,
      "lng": 35.0201812875
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889831533333332,
      "lng": 35.01940485
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.889831566666665,
      "lng": 35.0013831
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.889834283333332,
      "lng": 35.0142899
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8898438,
      "lng": 35.018172525
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.889845899999997,
      "lng": 35.035231775
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.889864391666663,
      "lng": 35.0243523
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.889865414285712,
      "lng": 35.033068085714284
    },
    "signal": -82.8
  },
  {
    "location": {
      "lat": 31.889888966666664,
      "lng": 35.00574016666667
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.889949600000005,
      "lng": 35.0181658
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8899799,
      "lng": 35.0056285
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8900036,
      "lng": 35.06806455
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.890005333333335,
      "lng": 35.06567390000001
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.89001185,
      "lng": 35.035236175
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89001917142857,
      "lng": 35.00138337142858
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.890024218181814,
      "lng": 35.001563600000004
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89002862,
      "lng": 35.019405060000004
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.890034574999998,
      "lng": 35.018140800000005
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.89003546666667,
      "lng": 35.06839383333334
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.890039833333333,
      "lng": 35.02018893333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.890045621428573,
      "lng": 35.02434711428572
    },
    "signal": -73.4
  },
  {
    "location": {
      "lat": 31.89004575,
      "lng": 35.03306355
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.890078674999998,
      "lng": 35.0653468
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.8900842,
      "lng": 35.0055194
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.890089166666666,
      "lng": 35.068732700000005
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.8901388,
      "lng": 35.07884586666666
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.890154966666667,
      "lng": 35.01940783333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.890172525,
      "lng": 35.069080825
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.89018185,
      "lng": 35.065006475000004
    },
    "signal": -109.85
  },
  {
    "location": {
      "lat": 31.890209385714286,
      "lng": 35.0243558
    },
    "signal": -73.7
  },
  {
    "location": {
      "lat": 31.89021165,
      "lng": 35.033063866666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89021945,
      "lng": 35.03525845
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.890220181818187,
      "lng": 35.001554127272726
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89023073333333,
      "lng": 35.01805783333334
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.890233333333338,
      "lng": 35.06945773333333
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.890247199999997,
      "lng": 35.02019046
    },
    "signal": -70.2
  },
  {
    "location": {
      "lat": 31.890256966666666,
      "lng": 35.06470936666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.890273866666664,
      "lng": 35.00536356666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.890292266666666,
      "lng": 35.06983209999999
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.890303499999998,
      "lng": 35.064765799999996
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.890318724999997,
      "lng": 35.078530275000006
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8903502,
      "lng": 35.02435046666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89035146666666,
      "lng": 35.07020433333334
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890366733333337,
      "lng": 35.01940651666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89037017142857,
      "lng": 35.00135765714286
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.890371,
      "lng": 35.064470166666666
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.89037707142857,
      "lng": 35.03529015714285
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.890410600000003,
      "lng": 35.070595475000005
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890411179999994,
      "lng": 35.020191139999994
    },
    "signal": -54
  },
  {
    "location": {
      "lat": 31.890412933333334,
      "lng": 35.00525973333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.890414127272724,
      "lng": 35.00153313636363
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.890421171428567,
      "lng": 35.033063628571426
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.890427385714286,
      "lng": 35.01787258571428
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.890467200000003,
      "lng": 35.07095445
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8905058,
      "lng": 35.06412554
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.890507,
      "lng": 35.078227899999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8905238,
      "lng": 35.0713127
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890527266666666,
      "lng": 35.017701833333334
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8905423,
      "lng": 35.00132895714286
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89054594,
      "lng": 35.01940752
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.89055049166667,
      "lng": 35.024350891666664
    },
    "signal": -73.7
  },
  {
    "location": {
      "lat": 31.890555425000002,
      "lng": 35.020191874999995
    },
    "signal": -54
  },
  {
    "location": {
      "lat": 31.890558133333332,
      "lng": 35.03533529999999
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8905656875,
      "lng": 35.0050824
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.890576499999998,
      "lng": 35.017563700000004
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890589874999996,
      "lng": 35.06378735
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.890590075000002,
      "lng": 35.033065300000004
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.890592490909093,
      "lng": 35.00150056363636
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8906092,
      "lng": 35.0169914
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890611975,
      "lng": 35.017107100000004
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890613728571424,
      "lng": 35.01736262857143
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.89062046666666,
      "lng": 35.063852733333334
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.89062205,
      "lng": 35.0168076
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890625175,
      "lng": 35.077911675
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.890635524999997,
      "lng": 35.07201935
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890671966666662,
      "lng": 35.016618533333336
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890684540000002,
      "lng": 35.07234126
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890714900000003,
      "lng": 35.01940763333334
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.8907239,
      "lng": 35.005078499999996
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8907244,
      "lng": 35.0635287
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.890730566666672,
      "lng": 35.03306343333333
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.890734466666668,
      "lng": 35.03538303333334
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.890736699999998,
      "lng": 35.0727183
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890738974999998,
      "lng": 35.06341165
    },
    "signal": -109.85
  },
  {
    "location": {
      "lat": 31.890749366666665,
      "lng": 35.024345800000006
    },
    "signal": -79.4
  },
  {
    "location": {
      "lat": 31.890750050000005,
      "lng": 35.02019596666667
    },
    "signal": -54
  },
  {
    "location": {
      "lat": 31.890755866666666,
      "lng": 35.01645513333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890762816666662,
      "lng": 35.00131846666667
    },
    "signal": -93.8
  },
  {
    "location": {
      "lat": 31.890767266666668,
      "lng": 35.07757536666667
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8907973,
      "lng": 35.0731036
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.89080345,
      "lng": 35.063102650000005
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.890859000000003,
      "lng": 35.0735156
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.890878479999998,
      "lng": 35.077204439999996
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.890901049999997,
      "lng": 35.001390175
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8909023,
      "lng": 35.07387475
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.890904149999997,
      "lng": 35.06301455
    },
    "signal": -111
  },
  {
    "location": {
      "lat": 31.890909066666666,
      "lng": 35.02019816666667
    },
    "signal": -54
  },
  {
    "location": {
      "lat": 31.890914183333333,
      "lng": 35.019405750000004
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.890914685714286,
      "lng": 35.00496811428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89091586,
      "lng": 35.03306343999999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.890921183333333,
      "lng": 35.0162773
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.890935657142858,
      "lng": 35.03544240000001
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89094051666667,
      "lng": 35.06283125
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8909578,
      "lng": 35.02432924444444
    },
    "signal": -79.4
  },
  {
    "location": {
      "lat": 31.89096336,
      "lng": 35.076865000000005
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.890966480000003,
      "lng": 35.00122628
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8909728,
      "lng": 35.07428925
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8909819,
      "lng": 35.008047525
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891016399999998,
      "lng": 35.0624573
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8910287,
      "lng": 35.0048978
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8910299,
      "lng": 35.0746597
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89103986666667,
      "lng": 35.07649943333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.891043149999998,
      "lng": 35.0079451
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891069,
      "lng": 35.075038
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89107025,
      "lng": 35.0161953
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891073600000006,
      "lng": 35.076147524999996
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.891083255555557,
      "lng": 35.001197733333335
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8910913,
      "lng": 35.035494275
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891093599999998,
      "lng": 35.075424299999995
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89109818333333,
      "lng": 35.02019666666666
    },
    "signal": -59.7
  },
  {
    "location": {
      "lat": 31.891101425,
      "lng": 35.033065425000004
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.891102533333328,
      "lng": 35.07575163333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89110271666667,
      "lng": 35.0078179
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89110455454546,
      "lng": 35.0013775
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89111285,
      "lng": 35.00482345
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891138957142857,
      "lng": 35.02433468571428
    },
    "signal": -79.4
  },
  {
    "location": {
      "lat": 31.89114724,
      "lng": 35.01940512
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.891160025000005,
      "lng": 35.016158825
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891160066666668,
      "lng": 35.062119800000005
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8911765,
      "lng": 35.007677320000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8912223,
      "lng": 35.004744
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891267983333332,
      "lng": 35.03555021666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89127344,
      "lng": 35.03306582
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89127674285714,
      "lng": 35.00748247142857
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891286627272724,
      "lng": 35.00114580909091
    },
    "signal": -89.2
  },
  {
    "location": {
      "lat": 31.89129816666667,
      "lng": 35.0194061
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891298607142858,
      "lng": 35.00131946428571
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.891300159999997,
      "lng": 35.06181526
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891302757142856,
      "lng": 35.02019302857143
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.89130726363636,
      "lng": 35.024354100000004
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89130796666667,
      "lng": 35.016139433333336
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891338949999998,
      "lng": 35.061568050000005
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.891351250000003,
      "lng": 35.004657825
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89139626,
      "lng": 35.00724708
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8914276,
      "lng": 35.0355939
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89144805,
      "lng": 35.01940437499999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8914601125,
      "lng": 35.00114028750001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891469287500005,
      "lng": 35.03306711250001
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.891470116666664,
      "lng": 35.007100533333336
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891475999999997,
      "lng": 35.0012803
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.891480366666666,
      "lng": 35.00455996666667
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8914884875,
      "lng": 35.012461099999996
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891492999999997,
      "lng": 35.0612193
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89150092857143,
      "lng": 35.01613855714286
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891501088888884,
      "lng": 35.02436826666667
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.89151252,
      "lng": 35.035618240000005
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891531049999998,
      "lng": 35.06101460000001
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.891588700000003,
      "lng": 35.00687788333334
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891630133333333,
      "lng": 35.019404900000005
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891639115384613,
      "lng": 35.00120085384616
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.891647699999996,
      "lng": 35.0355955
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.891649475,
      "lng": 35.012446225000005
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891652220000005,
      "lng": 35.00675364
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.891658200000002,
      "lng": 35.0010455
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891667216666665,
      "lng": 35.00441891666666
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.891671366666667,
      "lng": 35.06071106666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.891671666666664,
      "lng": 35.03306695
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8916717,
      "lng": 35.01614105
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891679566666667,
      "lng": 35.03563535555556
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.891682399999997,
      "lng": 35.02019172
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.891685187500002,
      "lng": 35.0243933625
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.89170828,
      "lng": 35.006645
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.891737,
      "lng": 35.0603744
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.891768559999996,
      "lng": 35.006519100000006
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89179328333334,
      "lng": 35.0356559
    },
    "signal": -71.55
  },
  {
    "location": {
      "lat": 31.89181969166667,
      "lng": 35.00118160833333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8918314,
      "lng": 35.00099797777778
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89183915,
      "lng": 35.019406100000005
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89184265,
      "lng": 35.01611115
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.891845,
      "lng": 35.0600739
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.891845316666664,
      "lng": 35.00636565
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.891845599999996,
      "lng": 35.00426295
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89184692,
      "lng": 35.0124483
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8918497,
      "lng": 35.024406899999995
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.891852233333335,
      "lng": 35.02019313333333
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.8918529,
      "lng": 35.033061599999996
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.891916475000002,
      "lng": 35.00621275
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.8919225,
      "lng": 35.00421335
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.891967066666666,
      "lng": 35.059791866666664
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.8919971,
      "lng": 35.035708166666666
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.891999679999998,
      "lng": 35.02443124
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89200356,
      "lng": 35.01940674000001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.892003874999997,
      "lng": 35.006048449999994
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.892007257142854,
      "lng": 35.02019492857142
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.892008099999998,
      "lng": 35.01605193333334
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.892014429411763,
      "lng": 35.00113235294117
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.892018222222223,
      "lng": 35.00096166666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89202827142857,
      "lng": 35.033050100000004
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8920348,
      "lng": 35.01244549999999
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.892041499999998,
      "lng": 35.00407958
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.892060200000003,
      "lng": 35.0594943
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89207366,
      "lng": 35.00590452
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.892104800000002,
      "lng": 35.0160064
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.892161985714285,
      "lng": 35.035686471428576
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.8921632,
      "lng": 35.0244338
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.892168350000002,
      "lng": 35.05925885
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892172259999995,
      "lng": 35.0057047
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.892190629999998,
      "lng": 35.024516219999995
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.892202759999996,
      "lng": 35.0158995
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.892202925,
      "lng": 35.033022499999994
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.892212025,
      "lng": 35.012442675
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.892212999999995,
      "lng": 35.00389068571429
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8922141,
      "lng": 35.020196399999996
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.892214825,
      "lng": 35.00111330000001
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.892217019999997,
      "lng": 35.00095591
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.892234125,
      "lng": 35.019400274999995
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8922382,
      "lng": 35.00556675
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.892311816666663,
      "lng": 35.00542771666667
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89233116666667,
      "lng": 35.015729566666664
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.892360485714285,
      "lng": 35.03563418571429
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.89236163636364,
      "lng": 35.02452829090909
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.8923623,
      "lng": 35.05887033333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892367716666666,
      "lng": 35.00370038333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89236963333333,
      "lng": 35.0124429
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.892381450000002,
      "lng": 35.03297575
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89238883333333,
      "lng": 35.000992839999995
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.892390950000003,
      "lng": 35.00527721666667
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.892393319999996,
      "lng": 35.01940036
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.892413199999996,
      "lng": 35.01554648333334
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89242345,
      "lng": 35.020196325
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.8924332,
      "lng": 35.05863735
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.892467366666665,
      "lng": 35.014093599999995
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.892468533333332,
      "lng": 35.015328133333334
    },
    "signal": -92.9
  },
  {
    "location": {
      "lat": 31.892473499999998,
      "lng": 35.0131665
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.89247466666667,
      "lng": 35.015171233333334
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892477975,
      "lng": 35.01446715
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892479750000003,
      "lng": 35.014277633333336
    },
    "signal": -97.5
  },
  {
    "location": {
      "lat": 31.892480225,
      "lng": 35.013372675
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.892480250000002,
      "lng": 35.0149947
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89248078,
      "lng": 35.01482132
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892481266666667,
      "lng": 35.0146329
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.892481614285717,
      "lng": 35.0128272
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.892482166666667,
      "lng": 35.01357463333334
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.892482975,
      "lng": 35.0137817
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.892490520000003,
      "lng": 35.00507911999999
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.892509099999998,
      "lng": 35.00357353333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8925313,
      "lng": 35.03292805
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.892550420000003,
      "lng": 35.02450728666666
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.892553275,
      "lng": 35.020196675
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.8925626,
      "lng": 35.0193995
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.892568500000003,
      "lng": 35.035544057142864
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.892570250000002,
      "lng": 35.00089126666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8925748,
      "lng": 35.00103019999999
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.892575983333334,
      "lng": 35.00491615
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.892605139999993,
      "lng": 35.0036233
    },
    "signal": -88.5
  },
  {
    "location": {
      "lat": 31.8926587,
      "lng": 35.00474989999999
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.892693133333335,
      "lng": 35.03546613333334
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.892698733333333,
      "lng": 35.0328663
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89271235,
      "lng": 35.020205475
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.892713880000002,
      "lng": 35.05811336000001
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.8927154875,
      "lng": 35.0194029
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8927188,
      "lng": 35.0036914
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8927253,
      "lng": 35.00461275714286
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.892726420000002,
      "lng": 35.001041050000005
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.892740116666662,
      "lng": 35.00088446666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89275512857143,
      "lng": 35.02453385714285
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.892771366666665,
      "lng": 35.035402033333334
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8928029,
      "lng": 35.0578076
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.89285878,
      "lng": 35.00435082
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89286273333333,
      "lng": 35.0200729
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.892866916666666,
      "lng": 35.0194859
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89287195,
      "lng": 35.0198801
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8928722,
      "lng": 35.019689050000004
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.892875880000002,
      "lng": 35.035296699999996
    },
    "signal": -70.6
  },
  {
    "location": {
      "lat": 31.89288535,
      "lng": 35.032794325
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89290181,
      "lng": 35.02455545
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89290275714286,
      "lng": 35.00101322857143
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8929196,
      "lng": 35.05763949999999
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.892924285714283,
      "lng": 35.00088654285714
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8929294125,
      "lng": 35.004216799999995
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.892960666666667,
      "lng": 35.00383873333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8929933,
      "lng": 35.0351546
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.893021425,
      "lng": 35.0040219
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.893037333333336,
      "lng": 35.0327408
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.893048375,
      "lng": 35.0245819125
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.893061133333326,
      "lng": 35.000995788888886
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.893094966666666,
      "lng": 35.00089003333333
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.893098866666666,
      "lng": 35.03500110000001
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.893120275,
      "lng": 35.057289775
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.89314415,
      "lng": 35.031219212500005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.893147028571427,
      "lng": 35.03103217142857
    },
    "signal": -80.7
  },
  {
    "location": {
      "lat": 31.893147266666663,
      "lng": 35.0326884
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8931504375,
      "lng": 35.031410262499996
    },
    "signal": -80.55
  },
  {
    "location": {
      "lat": 31.8931621,
      "lng": 35.03157526
    },
    "signal": -77.7
  },
  {
    "location": {
      "lat": 31.893190319999995,
      "lng": 35.030816959999996
    },
    "signal": -80.85
  },
  {
    "location": {
      "lat": 31.893195671428575,
      "lng": 35.03174132857143
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.893214533333335,
      "lng": 35.03476941666667
    },
    "signal": -65.65
  },
  {
    "location": {
      "lat": 31.893219,
      "lng": 35.03192857142857
    },
    "signal": -76.5
  },
  {
    "location": {
      "lat": 31.89323180512821,
      "lng": 35.00117725384615
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.893238128571422,
      "lng": 35.03208621428571
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.89323941,
      "lng": 35.057078069999996
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.893249379999997,
      "lng": 35.032595459999996
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.893252633333336,
      "lng": 35.00092585555556
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.89325519090909,
      "lng": 35.024596009090914
    },
    "signal": -79.55
  },
  {
    "location": {
      "lat": 31.89325656666667,
      "lng": 35.03065645833333
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.893257136363637,
      "lng": 35.03226174545454
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.893275400000004,
      "lng": 35.034585979999996
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893277366666666,
      "lng": 35.03248655
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.89329048333333,
      "lng": 35.02473131666667
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8933086,
      "lng": 35.034435925
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893316433333336,
      "lng": 35.03051633333334
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.893340999999996,
      "lng": 35.03429826666667
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.8933515,
      "lng": 35.0567867
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.8933657,
      "lng": 35.03420869999999
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.8933684,
      "lng": 35.024507899999996
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.893387,
      "lng": 35.034078
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893394833333332,
      "lng": 35.032797566666666
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8934015125,
      "lng": 35.03043945
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.893405425,
      "lng": 35.03301185000001
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8934091,
      "lng": 35.024346324999996
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893409279999997,
      "lng": 35.03391302
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893416133333332,
      "lng": 35.03318303333334
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.893423225,
      "lng": 35.000954300000004
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.893427366666668,
      "lng": 35.033699666666664
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893428866666667,
      "lng": 35.033359733333334
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.893431566666667,
      "lng": 35.03350443333334
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.893434054545455,
      "lng": 35.00120272727273
    },
    "signal": -76.65
  },
  {
    "location": {
      "lat": 31.893434883333338,
      "lng": 35.024175633333336
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893457933333334,
      "lng": 35.03262748888889
    },
    "signal": -88.5
  },
  {
    "location": {
      "lat": 31.893461333333335,
      "lng": 35.024008
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8934795,
      "lng": 35.02470305
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8934926,
      "lng": 35.001275275
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.893493675000002,
      "lng": 35.02383625
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89349616666666,
      "lng": 35.05654341666666
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.8935001,
      "lng": 35.023677799999994
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893502359999996,
      "lng": 35.03032482
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.893552749999998,
      "lng": 35.02358785
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893576325,
      "lng": 35.0234459
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893612949999998,
      "lng": 35.03025589
    },
    "signal": -86.1
  },
  {
    "location": {
      "lat": 31.893614233333334,
      "lng": 35.02331303333333
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.893621366666665,
      "lng": 35.00131124999999
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89362201666667,
      "lng": 35.00100530833333
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.893626263636364,
      "lng": 35.00123462727273
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.89362853,
      "lng": 35.03262324000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.893642139999997,
      "lng": 35.024714599999996
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.893651440000003,
      "lng": 35.05631404
    },
    "signal": -104
  },
  {
    "location": {
      "lat": 31.893673160000002,
      "lng": 35.02315857999999
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.893736099999998,
      "lng": 35.023059399999994
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.893757300000004,
      "lng": 35.0302114
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.893778320000003,
      "lng": 35.00106962
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.89378843333333,
      "lng": 35.02293573333333
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.893801299999996,
      "lng": 35.03017295
    },
    "signal": -95.5
  },
  {
    "location": {
      "lat": 31.893810000000002,
      "lng": 35.03264098571429
    },
    "signal": -88.75
  },
  {
    "location": {
      "lat": 31.893813585714287,
      "lng": 35.05588737142857
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.893821331578945,
      "lng": 35.00135995263158
    },
    "signal": -76.4
  },
  {
    "location": {
      "lat": 31.893861814285714,
      "lng": 35.0011219
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.893867900000004,
      "lng": 35.02282056666667
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.893880600000003,
      "lng": 35.001441
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.893923533333332,
      "lng": 35.0227226
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.89394335,
      "lng": 35.0103404375
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.893951844444448,
      "lng": 35.010530522222226
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.893974500000002,
      "lng": 35.055467775
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.893980750000004,
      "lng": 35.024790749999994
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.89398265555555,
      "lng": 35.01019136666666
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.89398703333333,
      "lng": 35.03013845
    },
    "signal": -96.5
  },
  {
    "location": {
      "lat": 31.893989137500004,
      "lng": 35.001315162500006
    },
    "signal": -68.95
  },
  {
    "location": {
      "lat": 31.89399221666667,
      "lng": 35.0326726
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.893997014285713,
      "lng": 35.001475414285714
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89400420909091,
      "lng": 35.001197299999994
    },
    "signal": -68.95
  },
  {
    "location": {
      "lat": 31.894013775,
      "lng": 35.022590575
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.89402771666667,
      "lng": 35.055308133333334
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894042775,
      "lng": 35.010885525000006
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.894117566666665,
      "lng": 35.000986866666665
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.894132924999997,
      "lng": 35.0109342
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.894134949999998,
      "lng": 35.0550619
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894140399999998,
      "lng": 35.011595166666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8941433,
      "lng": 35.01142540000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8941482,
      "lng": 35.03271143333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89414895,
      "lng": 35.01130935
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894150999999997,
      "lng": 35.00085526666667
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.894154269999994,
      "lng": 35.01108515999999
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89415766666667,
      "lng": 35.02239483333333
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.89416523333333,
      "lng": 35.010096266666665
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894172433333335,
      "lng": 35.0118387
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89417410909091,
      "lng": 35.030109581818174
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.894175135294116,
      "lng": 35.001549000000004
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89418465,
      "lng": 35.032735175000006
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.894186,
      "lng": 35.00132765
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.894193966666666,
      "lng": 35.0119608
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8941964,
      "lng": 35.0248684
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.894212433333333,
      "lng": 35.00062956666666
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894222320000004,
      "lng": 35.012126880000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89422495,
      "lng": 35.054730625000005
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.8942419625,
      "lng": 35.034938925
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.89424386666667,
      "lng": 35.035110816666666
    },
    "signal": -76.75
  },
  {
    "location": {
      "lat": 31.89424635,
      "lng": 35.012255100000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894247100000005,
      "lng": 34.9888708
    },
    "signal": -93.55
  },
  {
    "location": {
      "lat": 31.8942642,
      "lng": 35.0348967
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8942745,
      "lng": 35.012394625
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894276266666665,
      "lng": 35.03521336666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.894279775,
      "lng": 35.0347841875
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.894283500000004,
      "lng": 35.054503499999996
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894297833333336,
      "lng": 35.0125416
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894301289999998,
      "lng": 34.988922980000005
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89430685,
      "lng": 35.02218645
    },
    "signal": -87.95
  },
  {
    "location": {
      "lat": 31.894307225,
      "lng": 35.00141405
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.894318849999998,
      "lng": 35.01268735000001
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.894323044444448,
      "lng": 35.03461836666666
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.894328,
      "lng": 35.00021136666667
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894328030769234,
      "lng": 35.032777107692304
    },
    "signal": -88.25
  },
  {
    "location": {
      "lat": 31.894345599999998,
      "lng": 35.024891839999995
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.894348800000003,
      "lng": 35.012847525
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.894349845454542,
      "lng": 35.035313236363635
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8943543,
      "lng": 35.022084925
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.894363674999994,
      "lng": 35.054237975
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894369433333335,
      "lng": 35.009941266666665
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894370933333335,
      "lng": 35.001662111111116
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.894372799999996,
      "lng": 35.0300744875
    },
    "signal": -95.5
  },
  {
    "location": {
      "lat": 31.89437484285714,
      "lng": 35.034431371428575
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89437776666667,
      "lng": 35.00003246666666
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.8943805,
      "lng": 35.0130117
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89438892,
      "lng": 35.00152
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.8944054,
      "lng": 35.013169
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.894417325,
      "lng": 35.0342456125
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89441865,
      "lng": 34.999848899999996
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.89442555,
      "lng": 35.0539964
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894435899999998,
      "lng": 35.013331449999995
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89444675,
      "lng": 35.0219994
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.894447999999997,
      "lng": 35.03419829999999
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894463233333337,
      "lng": 35.034075666666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.894468033333336,
      "lng": 34.989143000000006
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.894474733333336,
      "lng": 34.99969446666667
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894477159999997,
      "lng": 35.03540352
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.894482457142857,
      "lng": 35.013579671428566
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8944862875,
      "lng": 35.0017463625
    },
    "signal": -71.85
  },
  {
    "location": {
      "lat": 31.8945133125,
      "lng": 35.033885575
    },
    "signal": -82.2
  },
  {
    "location": {
      "lat": 31.8945168,
      "lng": 35.01376526666667
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.894520633333332,
      "lng": 34.99953010000001
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.89452554,
      "lng": 35.00157972
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.894536128571428,
      "lng": 35.032842214285715
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8945408625,
      "lng": 35.030048975
    },
    "signal": -96.5
  },
  {
    "location": {
      "lat": 31.89454712,
      "lng": 35.024913850000004
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.894548742857143,
      "lng": 35.01395245714286
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894550225000003,
      "lng": 35.02467600000001
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8945542,
      "lng": 34.989247899999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894556057142854,
      "lng": 35.02184614285714
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.894560024999997,
      "lng": 35.033706837500006
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.894566516666668,
      "lng": 35.03543868333333
    },
    "signal": -75.8
  },
  {
    "location": {
      "lat": 31.894566733333335,
      "lng": 34.99936803333333
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894571,
      "lng": 35.05344545
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.894594566666665,
      "lng": 35.00184324999999
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.894595000000002,
      "lng": 35.02435966
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8945973,
      "lng": 35.01421805
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894598900000002,
      "lng": 35.0532093
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.894605233333337,
      "lng": 35.03353156666666
    },
    "signal": -82.2
  },
  {
    "location": {
      "lat": 31.894614949999998,
      "lng": 35.024179399999994
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.894628125000004,
      "lng": 35.014362125000005
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894630749999997,
      "lng": 34.9992156
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.894631766666667,
      "lng": 35.02403406666667
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.894653375,
      "lng": 35.033349575
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8946534,
      "lng": 35.01454413333334
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894654985714286,
      "lng": 35.02383299999999
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.894668725,
      "lng": 35.0146383
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894674,
      "lng": 34.999048125
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.89468494,
      "lng": 35.00170836
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.894685349999996,
      "lng": 35.023648824999995
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.894687628571425,
      "lng": 35.052978700000004
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.894692600000003,
      "lng": 35.01484055
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894694725000004,
      "lng": 35.03004005
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.894700887499997,
      "lng": 35.033158612499996
    },
    "signal": -89.3
  },
  {
    "location": {
      "lat": 31.894712114285714,
      "lng": 35.01516047142857
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894712283333334,
      "lng": 35.0527468
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89471306363636,
      "lng": 35.02497558181818
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89471378333333,
      "lng": 35.03549117500001
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.894719133333336,
      "lng": 35.015349500000006
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894721833333328,
      "lng": 35.03296775555556
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.894722599999998,
      "lng": 34.99887893333334
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.894723199999998,
      "lng": 35.0158179
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894724085714284,
      "lng": 35.01554782857143
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.89472666666667,
      "lng": 35.021636066666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.894733200000005,
      "lng": 35.00189092727273
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.894734449999998,
      "lng": 35.015912099999994
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.894735425,
      "lng": 35.023488575
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.894735833333332,
      "lng": 35.016119233333335
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.89473622,
      "lng": 35.03000954
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.89473656666667,
      "lng": 34.98944899999999
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894739375,
      "lng": 35.01632675
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.89474645,
      "lng": 35.016452425
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.89474893333333,
      "lng": 35.05242846666666
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89474955714286,
      "lng": 35.016642514285714
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.89476666666667,
      "lng": 35.00199223333333
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.894773999999998,
      "lng": 35.021563799999996
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.894774033333334,
      "lng": 35.0522138
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.894781566666666,
      "lng": 34.99870886666667
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89478565,
      "lng": 35.02336065
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8947963,
      "lng": 35.0518974
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.8948336,
      "lng": 35.0214783
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.894834966666668,
      "lng": 35.051645433333334
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89483686666667,
      "lng": 35.0168267
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89485364,
      "lng": 35.0232438
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.894862433333333,
      "lng": 35.00205856666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89486863,
      "lng": 35.025029079999996
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.894882844444446,
      "lng": 35.03555124444444
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89488432857143,
      "lng": 35.029996357142856
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89489426,
      "lng": 35.00190044
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.894895950000002,
      "lng": 35.03298608750001
    },
    "signal": -94.5
  },
  {
    "location": {
      "lat": 31.894898733333335,
      "lng": 34.99834713333333
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.894900857142854,
      "lng": 35.00334205714286
    },
    "signal": -87.25
  },
  {
    "location": {
      "lat": 31.894912500000004,
      "lng": 34.98961096666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8949306,
      "lng": 35.0213621
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.894952550000003,
      "lng": 35.05082841666667
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89495736666667,
      "lng": 34.9981565
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89496606,
      "lng": 35.00218916
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.894973633333336,
      "lng": 35.023042366666665
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89497405,
      "lng": 35.05056075
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.894988075,
      "lng": 35.03558815
    },
    "signal": -84.55
  },
  {
    "location": {
      "lat": 31.8950056,
      "lng": 35.021278800000005
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.895009849999997,
      "lng": 35.016915025
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895013075,
      "lng": 34.997962475
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89502825,
      "lng": 35.003452875
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895030366666663,
      "lng": 34.989708799999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895033233333336,
      "lng": 34.984047366666665
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89503524,
      "lng": 34.984269000000005
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89503552,
      "lng": 35.050079999999994
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89503705,
      "lng": 34.983909925
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895046157142854,
      "lng": 35.02996837142857
    },
    "signal": -96.5
  },
  {
    "location": {
      "lat": 31.895049279999995,
      "lng": 35.00209358
    },
    "signal": -73.1
  },
  {
    "location": {
      "lat": 31.89505356666667,
      "lng": 34.983770166666666
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895055925,
      "lng": 34.98445975
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895071150000003,
      "lng": 35.0250906
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89507226666667,
      "lng": 34.997774633333336
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89507273333333,
      "lng": 35.03304962222222
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895072883333338,
      "lng": 35.04975160000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895074857142852,
      "lng": 35.05028168571429
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895077849999996,
      "lng": 35.022906266666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895078533333333,
      "lng": 34.984598266666666
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895081328571422,
      "lng": 35.00331275714285
    },
    "signal": -86.5
  },
  {
    "location": {
      "lat": 31.895081899999997,
      "lng": 35.00229195714285
    },
    "signal": -73.8
  },
  {
    "location": {
      "lat": 31.895094049999997,
      "lng": 35.0356273625
    },
    "signal": -84.55
  },
  {
    "location": {
      "lat": 31.895094366666665,
      "lng": 34.983558800000004
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895098266666665,
      "lng": 35.021140716666665
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.895113019999997,
      "lng": 35.04942944
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895122633333333,
      "lng": 34.984781116666674
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89512883333333,
      "lng": 34.997583
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8951349,
      "lng": 35.04914625
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8951386,
      "lng": 35.0168714
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89515225,
      "lng": 34.98980495
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8951583,
      "lng": 34.983345899999996
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895162899999995,
      "lng": 34.9848636
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895167050000005,
      "lng": 35.000982150000006
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.895169533333334,
      "lng": 35.000772633333334
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89518286666667,
      "lng": 35.001196533333335
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.895183666666668,
      "lng": 34.99738966666667
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8951898,
      "lng": 35.000637633333334
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89519465,
      "lng": 34.9849954
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895201099999998,
      "lng": 34.983208999999995
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895214666666664,
      "lng": 35.001334
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.8952155,
      "lng": 35.01282875
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895217725000002,
      "lng": 34.9918465
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895221420000002,
      "lng": 35.00226283999999
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.89522341666667,
      "lng": 35.02271058333333
    },
    "signal": -92.4
  },
  {
    "location": {
      "lat": 31.895225,
      "lng": 35.020960433333336
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.895225514285716,
      "lng": 35.04880575714286
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895228124999996,
      "lng": 35.01304485
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.8952299,
      "lng": 34.9920429
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8952318,
      "lng": 35.01264033333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.89523244,
      "lng": 35.01679915999999
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895234828571425,
      "lng": 35.00044809999999
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89523782,
      "lng": 35.002437906666664
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895240266666672,
      "lng": 34.99719206666666
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.895241225,
      "lng": 34.991436825
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895242539999998,
      "lng": 35.02993326
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895244100000003,
      "lng": 35.01322044
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895246000000004,
      "lng": 35.035687225
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895249566666664,
      "lng": 34.9922051
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895251933333338,
      "lng": 34.98516383333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89526075,
      "lng": 35.012456
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8952612,
      "lng": 35.033118460000004
    },
    "signal": -93.5
  },
  {
    "location": {
      "lat": 31.89526394,
      "lng": 34.9898893
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895270225,
      "lng": 35.048289000000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89527277,
      "lng": 35.00316088
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895273275,
      "lng": 35.0134048
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895276199999998,
      "lng": 34.99236556666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895280850000002,
      "lng": 35.001512983333335
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895282033333334,
      "lng": 35.02516213333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.895289000000002,
      "lng": 34.9853302
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895291125000004,
      "lng": 35.000248
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.895293075,
      "lng": 34.9912866
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8952937,
      "lng": 35.002608249999994
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.895293866666666,
      "lng": 35.02084643333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.89529428333334,
      "lng": 34.983005383333335
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.8952949,
      "lng": 35.01228843333334
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895297733333333,
      "lng": 34.99699116666667
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89529965,
      "lng": 35.047988000000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895306333333338,
      "lng": 35.013572599999996
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895308299999996,
      "lng": 35.04775615
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8953108,
      "lng": 34.99252193333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89531518,
      "lng": 35.01661438
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895324225000003,
      "lng": 35.012183199999996
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89532726666667,
      "lng": 35.00012933333334
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.8953297,
      "lng": 35.0164964
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8953311,
      "lng": 35.0225523
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.895344650000002,
      "lng": 34.9912095
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.895355133333336,
      "lng": 34.99678883333333
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.895357966666666,
      "lng": 35.000016966666664
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.895361466666667,
      "lng": 34.98552386666667
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.895362375,
      "lng": 34.99270525
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895364824999998,
      "lng": 35.01205915
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895365766666668,
      "lng": 35.0137452
    },
    "signal": -90.1
  },
  {
    "location": {
      "lat": 31.8953697,
      "lng": 35.01393665
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89537316,
      "lng": 35.047384120000004
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.895377066666665,
      "lng": 35.04722073333333
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.89538353333333,
      "lng": 35.0162727
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8953889,
      "lng": 34.999916400000004
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895391199999995,
      "lng": 35.001709283333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895395380000004,
      "lng": 35.00308366
    },
    "signal": -86.8
  },
  {
    "location": {
      "lat": 31.895398266666664,
      "lng": 34.982814100000006
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.895408200000002,
      "lng": 35.014126825
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89541,
      "lng": 35.011938199999996
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89541102,
      "lng": 34.98568512
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.895412487499996,
      "lng": 35.0357361625
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89541315,
      "lng": 34.9965889
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89541633636363,
      "lng": 35.00260347272728
    },
    "signal": -73.7
  },
  {
    "location": {
      "lat": 31.895416433333335,
      "lng": 35.01609716666666
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.895418300000003,
      "lng": 35.020603225
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.895419255555552,
      "lng": 35.00276717777778
    },
    "signal": -73.6
  },
  {
    "location": {
      "lat": 31.895419850000003,
      "lng": 35.033168620000005
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.89542703333333,
      "lng": 35.029895333333336
    },
    "signal": -90.9
  },
  {
    "location": {
      "lat": 31.895430249999997,
      "lng": 35.04682235
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.89543106,
      "lng": 34.9929079
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895434533333333,
      "lng": 34.990000733333325
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895439933333336,
      "lng": 34.99974546666667
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895442099999997,
      "lng": 35.01431083333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89544478,
      "lng": 35.025222119999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89545122,
      "lng": 35.02240202
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89545196666667,
      "lng": 35.015951050000005
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.895452066666667,
      "lng": 34.996382866666664
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.895463675,
      "lng": 35.046636050000004
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.89546378571429,
      "lng": 34.991085357142865
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.895474225,
      "lng": 35.014452575
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8954762,
      "lng": 35.01179405
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89548118,
      "lng": 35.020433540000006
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.89548769,
      "lng": 35.00297687000001
    },
    "signal": -86.2
  },
  {
    "location": {
      "lat": 31.89548876666667,
      "lng": 34.996190633333335
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.895490200000005,
      "lng": 35.0463005
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.895491733333333,
      "lng": 34.99957153333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8955006,
      "lng": 35.0460301
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.89550293333333,
      "lng": 34.99311543333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.89552605,
      "lng": 34.996019200000006
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.895526899999997,
      "lng": 34.99947589999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895527899999998,
      "lng": 35.0203494
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895529849999996,
      "lng": 35.014652149999996
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89553365,
      "lng": 35.01171264999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895538424999998,
      "lng": 35.01484575
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89554263333333,
      "lng": 34.986269166666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89554475,
      "lng": 34.995777733333334
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89555036666667,
      "lng": 34.988938266666665
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895552514285715,
      "lng": 34.98876191428572
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895554100000002,
      "lng": 35.020216999999995
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895554533333335,
      "lng": 35.02987393333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8955555,
      "lng": 34.99326823333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895559433333332,
      "lng": 34.99935729999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8955607,
      "lng": 34.98905356666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89556288,
      "lng": 34.98642286
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8955653,
      "lng": 34.9865875
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895566766666665,
      "lng": 35.01500633333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895569899999998,
      "lng": 35.001915133333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89557123333333,
      "lng": 34.995583233333335
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.89557512,
      "lng": 34.986053420000005
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.895575440000005,
      "lng": 35.00279622
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.895576833333337,
      "lng": 34.98857693333333
    },
    "signal": -92.75
  },
  {
    "location": {
      "lat": 31.89558363333333,
      "lng": 35.022218333333335
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.895584500000002,
      "lng": 35.033228099999995
    },
    "signal": -94.5
  },
  {
    "location": {
      "lat": 31.895586933333334,
      "lng": 34.989260699999996
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895588200000002,
      "lng": 34.990085
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89558995,
      "lng": 35.020079949999996
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895596957142857,
      "lng": 34.9884164
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895599966666666,
      "lng": 34.98253823333334
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.895600214285714,
      "lng": 35.01517282857142
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895601449999997,
      "lng": 34.9867718
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89560246666667,
      "lng": 34.98822656666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.895603888888886,
      "lng": 35.00294258333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895605500000002,
      "lng": 34.999197775
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89560745,
      "lng": 34.9880709
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89560846666667,
      "lng": 34.99342323333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895608499999998,
      "lng": 35.045746433333335
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.895613433333335,
      "lng": 34.9878833
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8956142625,
      "lng": 34.9858819625
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.895614775,
      "lng": 34.986942174999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895617311111113,
      "lng": 35.03568728888889
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89561771,
      "lng": 35.00313398000001
    },
    "signal": -73.6
  },
  {
    "location": {
      "lat": 31.895618449999997,
      "lng": 34.98947355
    },
    "signal": -87.65
  },
  {
    "location": {
      "lat": 31.895619433333334,
      "lng": 34.98766545
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89562118,
      "lng": 35.01570184
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89562332,
      "lng": 34.987152880000004
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895625671428572,
      "lng": 35.01155462857143
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.89562873333333,
      "lng": 34.98751683333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895629460000002,
      "lng": 35.019890180000004
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.89562955,
      "lng": 35.045398950000006
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.89563012857143,
      "lng": 34.99542685714285
    },
    "signal": -73.5
  },
  {
    "location": {
      "lat": 31.895633950000004,
      "lng": 35.015400575
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895634100000002,
      "lng": 35.025262857142856
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89564474,
      "lng": 34.989657699999995
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.8956448125,
      "lng": 35.029823725
    },
    "signal": -90.9
  },
  {
    "location": {
      "lat": 31.89565535,
      "lng": 35.01968445
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895658799999996,
      "lng": 34.99357555
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.8956613,
      "lng": 35.0220971
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.895662533333336,
      "lng": 34.99901653333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.895665,
      "lng": 35.01557721428571
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895667925,
      "lng": 34.990978825
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.895675733333334,
      "lng": 34.98986486666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895676249999998,
      "lng": 35.019490649999995
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895678600000004,
      "lng": 35.0451949
    },
    "signal": -106
  },
  {
    "location": {
      "lat": 31.895686499999996,
      "lng": 35.00200864999999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895686650000002,
      "lng": 35.0332731
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8956972,
      "lng": 34.998869899999995
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89569853333333,
      "lng": 34.9900132
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89570036666667,
      "lng": 35.0192045
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.895704499999997,
      "lng": 34.9986946
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.895706866666668,
      "lng": 35.03562613333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.895708399999997,
      "lng": 35.0156689
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895708966666664,
      "lng": 35.018988433333334
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.8957168,
      "lng": 35.018799575
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895718924999997,
      "lng": 34.984039200000005
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895719424999996,
      "lng": 35.01858274999999
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.89572346666667,
      "lng": 35.01584343333334
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895723933333333,
      "lng": 34.98390436666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895726775,
      "lng": 34.984239474999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89572853333333,
      "lng": 35.01824526666666
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895728566666666,
      "lng": 35.01141006666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895731725000005,
      "lng": 35.021965824999995
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.895732744444445,
      "lng": 34.990206444444446
    },
    "signal": -88.9
  },
  {
    "location": {
      "lat": 31.895733049999997,
      "lng": 35.0179304
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895733633333332,
      "lng": 35.01807896666667
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895736499999998,
      "lng": 35.003112200000004
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.895736733333333,
      "lng": 35.01773771666667
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895738971428568,
      "lng": 34.99381184285714
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895739100000004,
      "lng": 35.01608905
    },
    "signal": -88.25
  },
  {
    "location": {
      "lat": 31.895744439999998,
      "lng": 34.99847022
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.895745740000002,
      "lng": 35.016270659999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895750725,
      "lng": 35.017515949999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89575178,
      "lng": 34.983755020000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895756375,
      "lng": 35.017324075000005
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895757133333333,
      "lng": 35.017175066666674
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895758766666663,
      "lng": 35.016457833333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895761333333336,
      "lng": 35.017025133333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895761816666667,
      "lng": 35.016814583333336
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89576847692308,
      "lng": 35.035494584615385
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89576942,
      "lng": 35.01662037999999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895772026666666,
      "lng": 35.00333106666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8957737,
      "lng": 34.990515525
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895775900000004,
      "lng": 35.003499662500005
    },
    "signal": -73.6
  },
  {
    "location": {
      "lat": 31.895776566666665,
      "lng": 34.98441186666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895777600000002,
      "lng": 34.99530355
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.895786533333332,
      "lng": 35.033294588888886
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.895793519999994,
      "lng": 35.02974404
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.895800966666666,
      "lng": 34.985899966666665
    },
    "signal": -96.85
  },
  {
    "location": {
      "lat": 31.895801662500006,
      "lng": 34.9824140125
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.8958034,
      "lng": 35.02182705
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89580376666667,
      "lng": 35.00208196666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89580448,
      "lng": 34.99072826
    },
    "signal": -94.65
  },
  {
    "location": {
      "lat": 31.895804787499998,
      "lng": 35.025315562500005
    },
    "signal": -80.8
  },
  {
    "location": {
      "lat": 31.895807063636365,
      "lng": 35.01554576363637
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.895807222222224,
      "lng": 35.00281785555555
    },
    "signal": -73.5
  },
  {
    "location": {
      "lat": 31.89580856666667,
      "lng": 34.994006266666666
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895815800000005,
      "lng": 34.9909385142857
    },
    "signal": -94.45
  },
  {
    "location": {
      "lat": 31.895816866666667,
      "lng": 35.03532903333333
    },
    "signal": -85.95
  },
  {
    "location": {
      "lat": 31.895822099999997,
      "lng": 35.0113155
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895829524999996,
      "lng": 34.998315925
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.895830833333335,
      "lng": 34.9845875
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8958361,
      "lng": 34.983563974999996
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895843562499998,
      "lng": 35.00366241249999
    },
    "signal": -74.75
  },
  {
    "location": {
      "lat": 31.8958523875,
      "lng": 35.03517255
    },
    "signal": -85.95
  },
  {
    "location": {
      "lat": 31.895856,
      "lng": 35.0216815
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89585675,
      "lng": 34.994120699999996
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895858600000004,
      "lng": 34.991053199999996
    },
    "signal": -94.45
  },
  {
    "location": {
      "lat": 31.89586244444444,
      "lng": 34.997620177777776
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.895872366666666,
      "lng": 34.98211413333333
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.8958767,
      "lng": 34.9835076
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89587796666667,
      "lng": 34.9981458
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.895881599999996,
      "lng": 35.035077359999995
    },
    "signal": -85.9
  },
  {
    "location": {
      "lat": 31.895885599999996,
      "lng": 34.991226260000005
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895895433333333,
      "lng": 34.982260733333334
    },
    "signal": -102
  },
  {
    "location": {
      "lat": 31.8958995,
      "lng": 34.99026955
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89590236666667,
      "lng": 34.9979511
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.895907800000003,
      "lng": 34.995355399999994
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89590825,
      "lng": 34.984827925000005
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895912942857144,
      "lng": 35.03494842857143
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8959144,
      "lng": 35.003539525
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.895914933333334,
      "lng": 34.99145975833333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895915525,
      "lng": 34.98193405
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.89591575,
      "lng": 35.01122415
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.895921725,
      "lng": 34.994198749999995
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.895937727777778,
      "lng": 34.99774420555556
    },
    "signal": -75.8
  },
  {
    "location": {
      "lat": 31.895938400000002,
      "lng": 35.015474025
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.895943433333333,
      "lng": 34.991651
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895943466666665,
      "lng": 34.984967
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895950957142862,
      "lng": 35.03479947142857
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.895956571428574,
      "lng": 35.00275091428572
    },
    "signal": -73.5
  },
  {
    "location": {
      "lat": 31.895956723076928,
      "lng": 35.003858569230765
    },
    "signal": -74.6
  },
  {
    "location": {
      "lat": 31.895957026666668,
      "lng": 34.997536626666665
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89596377692308,
      "lng": 35.03335992307693
    },
    "signal": -92.75
  },
  {
    "location": {
      "lat": 31.8959691875,
      "lng": 35.0295830875
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89596944285714,
      "lng": 34.99180532857143
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.895978059999997,
      "lng": 35.0037047
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.895978475,
      "lng": 34.98336765
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.895986862500003,
      "lng": 35.0040294625
    },
    "signal": -74.75
  },
  {
    "location": {
      "lat": 31.89598689,
      "lng": 35.02542505
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.895993366666662,
      "lng": 35.03462118888889
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.895995749999997,
      "lng": 35.00218945
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.895995874999997,
      "lng": 34.981755174999996
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.895996020000002,
      "lng": 34.98589064
    },
    "signal": -93.7
  },
  {
    "location": {
      "lat": 31.895997549999993,
      "lng": 34.98514995
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.895998785714287,
      "lng": 35.021305257142856
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.89600360833333,
      "lng": 34.99199199166666
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.896008009999996,
      "lng": 34.997411830000004
    },
    "signal": -74.7
  },
  {
    "location": {
      "lat": 31.896011549999997,
      "lng": 35.0111371
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.896025874999996,
      "lng": 34.994290899999996
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.896026900000003,
      "lng": 35.02943055
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89603755,
      "lng": 35.03444701666667
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.896038349999998,
      "lng": 34.997238800000005
    },
    "signal": -74.3
  },
  {
    "location": {
      "lat": 31.896043300000002,
      "lng": 35.021079
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.8960465,
      "lng": 35.0041941
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.896048375,
      "lng": 34.992154074999995
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.896050766666672,
      "lng": 34.997089100000004
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.896060199999997,
      "lng": 35.03436896666667
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.896074233333334,
      "lng": 34.98159743333333
    },
    "signal": -103
  },
  {
    "location": {
      "lat": 31.89607585,
      "lng": 34.992216475
    },
    "signal": -94.7
  },
  {
    "location": {
      "lat": 31.89607865,
      "lng": 35.02092395
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.896085833333334,
      "lng": 34.99704302222222
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.896086,
      "lng": 35.02949803333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896088650000003,
      "lng": 34.98537525
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.896094200000007,
      "lng": 35.03424331111111
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8960987,
      "lng": 35.002282400000006
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89609885,
      "lng": 35.020760349999996
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.896101033333334,
      "lng": 34.98556036666667
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.896103925,
      "lng": 34.995440650000006
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.896108249999997,
      "lng": 35.004037350000004
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.896110956250002,
      "lng": 34.996852
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89611216,
      "lng": 34.99761300000001
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.8961186,
      "lng": 35.0205911
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.896125249999997,
      "lng": 35.0204191
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.896128028571432,
      "lng": 35.01541052857143
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.896128957142857,
      "lng": 35.03348545714285
    },
    "signal": -85.95
  },
  {
    "location": {
      "lat": 31.89613,
      "lng": 35.0110309
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.896131966666662,
      "lng": 35.02025413333333
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.89613424,
      "lng": 35.00422276
    },
    "signal": -70.95
  },
  {
    "location": {
      "lat": 31.89613483333333,
      "lng": 34.997690733333336
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89613538888889,
      "lng": 35.0026233
    },
    "signal": -74.85
  },
  {
    "location": {
      "lat": 31.89613566,
      "lng": 35.02004848
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.89613607142857,
      "lng": 34.985875314285714
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896136375000005,
      "lng": 34.9943605
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.896139724999998,
      "lng": 34.992337025
    },
    "signal": -89.95
  },
  {
    "location": {
      "lat": 31.896140925,
      "lng": 35.00439621249999
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.896142285714287,
      "lng": 35.02941468571429
    },
    "signal": -87.5
  },
  {
    "location": {
      "lat": 31.8961426,
      "lng": 35.0340593875
    },
    "signal": -85.95
  },
  {
    "location": {
      "lat": 31.896145455555555,
      "lng": 34.99666657777777
    },
    "signal": -75.9
  },
  {
    "location": {
      "lat": 31.896146879999996,
      "lng": 35.019846259999994
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.896155099999998,
      "lng": 35.01969523999999
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89615948888889,
      "lng": 34.9859847
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896159724999997,
      "lng": 34.985754875000005
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.896160060000007,
      "lng": 35.025419930000005
    },
    "signal": -80.8
  },
  {
    "location": {
      "lat": 31.896160536363638,
      "lng": 35.00245304545454
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.8961609,
      "lng": 34.98068276
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896162933333333,
      "lng": 35.01951835
    },
    "signal": -96.85
  },
  {
    "location": {
      "lat": 31.896170933333334,
      "lng": 35.01929043333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896176985714284,
      "lng": 34.99649501428572
    },
    "signal": -75.85
  },
  {
    "location": {
      "lat": 31.89617985833333,
      "lng": 34.979597758333334
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896187590909086,
      "lng": 35.03390399090909
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.896189579999998,
      "lng": 34.990436439999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.896189833333327,
      "lng": 35.01909823333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896193433333337,
      "lng": 35.00456581111111
    },
    "signal": -74.75
  },
  {
    "location": {
      "lat": 31.896195425000002,
      "lng": 34.980830075
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896197400000002,
      "lng": 34.983201900000005
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.896203566666667,
      "lng": 35.01899346666667
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896203999999997,
      "lng": 35.0109616
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89620458,
      "lng": 35.03367606
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8962051,
      "lng": 34.99552736666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89620763,
      "lng": 34.996310730000005
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896213466666666,
      "lng": 34.9924878
    },
    "signal": -89.95
  },
  {
    "location": {
      "lat": 31.896220399999997,
      "lng": 34.99615585
    },
    "signal": -74.55
  },
  {
    "location": {
      "lat": 31.896221979999996,
      "lng": 34.97946764000001
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896226000000002,
      "lng": 34.9944398
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.8962294,
      "lng": 35.0188579
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896231750000002,
      "lng": 34.9957002
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.8962355,
      "lng": 34.996032299999996
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.896246566666672,
      "lng": 35.004392333333335
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8962474,
      "lng": 34.9857795
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.89625092,
      "lng": 34.99613968
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89625128,
      "lng": 35.018782540000004
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896264641666672,
      "lng": 34.99597511666667
    },
    "signal": -75.9
  },
  {
    "location": {
      "lat": 31.8962659,
      "lng": 34.99259106666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89627155,
      "lng": 34.99433445
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89628655,
      "lng": 35.002454975000006
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.896291,
      "lng": 35.01861673333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.896296554545458,
      "lng": 34.99580459090909
    },
    "signal": -74.5
  },
  {
    "location": {
      "lat": 31.896304450000002,
      "lng": 35.00454805
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.89630828461538,
      "lng": 35.00474540769231
    },
    "signal": -74.6
  },
  {
    "location": {
      "lat": 31.896309287500003,
      "lng": 34.9797587375
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.89631533846153,
      "lng": 34.99451502307692
    },
    "signal": -82.4
  },
  {
    "location": {
      "lat": 31.896317114285715,
      "lng": 34.99561961428572
    },
    "signal": -73.6
  },
  {
    "location": {
      "lat": 31.8963171923077,
      "lng": 35.00262276923077
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89631797058823,
      "lng": 34.98586186470589
    },
    "signal": -97.85
  },
  {
    "location": {
      "lat": 31.896326233333337,
      "lng": 34.99762678333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.896326409090907,
      "lng": 34.97944150909091
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.89632656,
      "lng": 35.0108493
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8963305,
      "lng": 34.9954110875
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896330983333332,
      "lng": 34.98316388333333
    },
    "signal": -96.6
  },
  {
    "location": {
      "lat": 31.8963352875,
      "lng": 34.97996014374999
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896336522222228,
      "lng": 34.980860177777785
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896337938461542,
      "lng": 34.98030515384615
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896339911111113,
      "lng": 35.00495402222222
    },
    "signal": -74.75
  },
  {
    "location": {
      "lat": 31.8963438,
      "lng": 34.99766832500001
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89634544166667,
      "lng": 34.98048538333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896345999999998,
      "lng": 34.99271670833333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8963493,
      "lng": 35.01841438571429
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89635551666667,
      "lng": 34.994015100000006
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89636041428571,
      "lng": 35.0153774
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8963604375,
      "lng": 35.0254529
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.896364390909092,
      "lng": 34.9941819
    },
    "signal": -82.2
  },
  {
    "location": {
      "lat": 31.896372816666666,
      "lng": 34.99470665
    },
    "signal": -73.95
  },
  {
    "location": {
      "lat": 31.896376588888888,
      "lng": 34.99506827777778
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.89637682,
      "lng": 34.994893260000005
    },
    "signal": -73.95
  },
  {
    "location": {
      "lat": 31.896377216666668,
      "lng": 34.99525801666666
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.896389566666667,
      "lng": 34.990526333333335
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.896393333333336,
      "lng": 34.99384614444445
    },
    "signal": -84.7
  },
  {
    "location": {
      "lat": 31.896403000000003,
      "lng": 35.0107874
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89640418571429,
      "lng": 35.00512781428572
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.896411666666665,
      "lng": 35.01822276666667
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896438383333333,
      "lng": 34.994461
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.896440722222223,
      "lng": 34.99375836666667
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.89644438571429,
      "lng": 34.99433462857143
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.89645631428571,
      "lng": 35.00493804285714
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.896459433333334,
      "lng": 35.01808726666666
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89648625555556,
      "lng": 35.005308033333336
    },
    "signal": -74.5
  },
  {
    "location": {
      "lat": 31.896493599999996,
      "lng": 34.99767038
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.89649885,
      "lng": 34.98315135
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.896500276470586,
      "lng": 34.985844617647054
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896503921428575,
      "lng": 34.9928995
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.89650814,
      "lng": 34.98082104000001
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896510560000003,
      "lng": 35.00244606
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.896514375000002,
      "lng": 35.010682375
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.896514820000004,
      "lng": 35.0051396
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.896518492307695,
      "lng": 34.99361612307692
    },
    "signal": -90.1
  },
  {
    "location": {
      "lat": 31.8965186,
      "lng": 34.97947739999999
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896523133333332,
      "lng": 35.01538323333333
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.89653594,
      "lng": 35.017877799999994
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896536616666666,
      "lng": 34.99763778333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8965381125,
      "lng": 35.025484075
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.896540549999997,
      "lng": 35.0054936875
    },
    "signal": -74.75
  },
  {
    "location": {
      "lat": 31.89656066666667,
      "lng": 34.990602599999995
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.896570275,
      "lng": 34.993513449999995
    },
    "signal": -90.7
  },
  {
    "location": {
      "lat": 31.89657575,
      "lng": 35.00562000000001
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.896583399999997,
      "lng": 34.992995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.896590500000002,
      "lng": 35.0177064
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89660445,
      "lng": 35.010612949999995
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.896606199999997,
      "lng": 35.005324625
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.896625099999994,
      "lng": 35.015396833333334
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.8966262,
      "lng": 35.0023413
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89665725833333,
      "lng": 34.99342840833333
    },
    "signal": -90.4
  },
  {
    "location": {
      "lat": 31.89665997,
      "lng": 35.00566585
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.896661375,
      "lng": 35.00547039999999
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.89666168,
      "lng": 35.01752344
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.8966878,
      "lng": 34.9976493
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.896689584615388,
      "lng": 34.98584322307692
    },
    "signal": -92.6
  },
  {
    "location": {
      "lat": 31.896693325,
      "lng": 34.983195537499995
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.8966942,
      "lng": 34.9794658
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.89669708571429,
      "lng": 35.00583587857143
    },
    "signal": -70.4
  },
  {
    "location": {
      "lat": 31.896700024999998,
      "lng": 34.98082456666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.896705571428566,
      "lng": 34.99767635714285
    },
    "signal": -84.9
  },
  {
    "location": {
      "lat": 31.896705625,
      "lng": 35.010511575
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.896707600000003,
      "lng": 35.002258399999995
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8967099125,
      "lng": 34.99310695
    },
    "signal": -90.9
  },
  {
    "location": {
      "lat": 31.896711083333333,
      "lng": 35.025507633333326
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.896724159999998,
      "lng": 35.017359940000006
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896734399999996,
      "lng": 34.99067876666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.896745459999998,
      "lng": 34.99326337
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896750111111107,
      "lng": 35.006020111111106
    },
    "signal": -67.85
  },
  {
    "location": {
      "lat": 31.896775199999997,
      "lng": 35.0171919
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89678095,
      "lng": 35.015449450000006
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.896785933333334,
      "lng": 35.0021692
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89678895,
      "lng": 35.010448350000004
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.89681270909091,
      "lng": 34.99320277272727
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89682165,
      "lng": 34.98325115
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.89683116666667,
      "lng": 35.00590276666667
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.896837112500002,
      "lng": 34.9794563875
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.896852300000003,
      "lng": 35.002083266666666
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.896852614285713,
      "lng": 35.01699897142858
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.896854839999996,
      "lng": 35.015543459999996
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.896857966666666,
      "lng": 35.00621056000001
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.896870525,
      "lng": 35.025611625
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.896873340000003,
      "lng": 34.98089746
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.8968742375,
      "lng": 34.9858271375
    },
    "signal": -92.8
  },
  {
    "location": {
      "lat": 31.896878214285717,
      "lng": 35.00604441428572
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.896878700000002,
      "lng": 34.99767658333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89688725,
      "lng": 35.025528825
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.8968916,
      "lng": 35.010336599999995
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.8969105,
      "lng": 34.990756466666674
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.896917133333332,
      "lng": 34.983321700000005
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.896918066666668,
      "lng": 35.016785366666674
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896922199999995,
      "lng": 35.00632772
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.896924625,
      "lng": 35.015724175
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896926200000003,
      "lng": 34.9795892
    },
    "signal": -98
  },
  {
    "location": {
      "lat": 31.89693425,
      "lng": 35.001954749999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8969402,
      "lng": 35.015865649999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89694075,
      "lng": 35.01665165
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896968175,
      "lng": 35.016494824999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896972633333334,
      "lng": 35.016073166666665
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89697617142857,
      "lng": 35.01628585714286
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.896976800000004,
      "lng": 35.01027696666666
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.897001799999998,
      "lng": 35.001848200000005
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.897016200000003,
      "lng": 35.00622146
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.89702265,
      "lng": 34.99327738333333
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89703338666667,
      "lng": 35.006397693333334
    },
    "signal": -67.55
  },
  {
    "location": {
      "lat": 31.897034920000003,
      "lng": 34.98591298
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.897044360000002,
      "lng": 34.997576560000006
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897047583333336,
      "lng": 35.025593783333335
    },
    "signal": -80.8
  },
  {
    "location": {
      "lat": 31.89705288,
      "lng": 34.99772188000001
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897056949999996,
      "lng": 34.98100106666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.897059266666663,
      "lng": 35.00173663333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89707015,
      "lng": 34.98572635
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.89707195,
      "lng": 35.010183825
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.897093199999997,
      "lng": 34.9908189
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897120166666667,
      "lng": 35.0016123
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8971215,
      "lng": 34.997183050000004
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897127750000003,
      "lng": 34.99706055
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89714705,
      "lng": 34.985454274999995
    },
    "signal": -101
  },
  {
    "location": {
      "lat": 31.897154399999998,
      "lng": 35.00151475
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.897156300000002,
      "lng": 34.98362595
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.897156733333333,
      "lng": 34.98533316666666
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897160600000003,
      "lng": 34.99686753333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8971677,
      "lng": 34.98519886666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897167733333333,
      "lng": 34.99738506666667
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897173549999998,
      "lng": 34.9810861
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.897176366666667,
      "lng": 35.01008886666667
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.897180374999998,
      "lng": 34.984991475
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897193633333334,
      "lng": 34.9966789
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897194799999998,
      "lng": 34.98478456666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897199766666663,
      "lng": 34.9846498
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897200033333338,
      "lng": 35.00135213333333
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.897200859999998,
      "lng": 34.98577482
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89720506666666,
      "lng": 34.98378213333333
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.897208016666667,
      "lng": 35.00640788333333
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.897209933333333,
      "lng": 34.98445471666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897221333333334,
      "lng": 34.9978884
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897221357142858,
      "lng": 35.0256
    },
    "signal": -80.9
  },
  {
    "location": {
      "lat": 31.8972227,
      "lng": 34.9842635
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897225125806447,
      "lng": 35.00659066774194
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.8972277,
      "lng": 34.98590778888889
    },
    "signal": -100.6
  },
  {
    "location": {
      "lat": 31.897230399999998,
      "lng": 34.98408298333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897238774999998,
      "lng": 35.001195425
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.89724006,
      "lng": 34.996499920000005
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89724494,
      "lng": 34.998143479999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897247754545457,
      "lng": 34.99754147272727
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897250600000003,
      "lng": 34.997730190909095
    },
    "signal": -85.55
  },
  {
    "location": {
      "lat": 31.8972601,
      "lng": 34.99091193333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897263871428574,
      "lng": 34.99338957142857
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897268699999998,
      "lng": 34.981159225
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.897272599999997,
      "lng": 34.9982989
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897288725,
      "lng": 35.00103195
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.8972987,
      "lng": 35.00999155
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.897304000000002,
      "lng": 34.99630105
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.897304933333334,
      "lng": 34.998496100000004
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897333899999996,
      "lng": 35.0008614
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.89733745,
      "lng": 34.99868045
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897361625000002,
      "lng": 34.9988272
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89737662307692,
      "lng": 34.98581774615385
    },
    "signal": -100.5
  },
  {
    "location": {
      "lat": 31.897378446153844,
      "lng": 35.0065653923077
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.8973794,
      "lng": 34.98124718000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.897379840000003,
      "lng": 34.99612484
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.89738269565218,
      "lng": 35.00667637391304
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.897384199999998,
      "lng": 34.998996999999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89739062,
      "lng": 35.00060594
    },
    "signal": -80.6
  },
  {
    "location": {
      "lat": 31.897393866666665,
      "lng": 35.042857966666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897403899999997,
      "lng": 34.993477375
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897404291666664,
      "lng": 35.025616275
    },
    "signal": -80.8
  },
  {
    "location": {
      "lat": 31.897405850000002,
      "lng": 35.0098977
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.897406699999998,
      "lng": 34.9857782
    },
    "signal": -101.85
  },
  {
    "location": {
      "lat": 31.8974155,
      "lng": 34.999207739999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897416625,
      "lng": 35.00047945
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.897433066666668,
      "lng": 34.990989500000005
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897436,
      "lng": 35.00024126666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8974371,
      "lng": 34.99936305
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.897438066666666,
      "lng": 35.000027333333335
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.89743826666667,
      "lng": 34.999901066666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.897438299999997,
      "lng": 34.999738175
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8974417,
      "lng": 34.99956125999999
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.8974503,
      "lng": 34.99353489999999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8974513,
      "lng": 34.99595975
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.89745266666667,
      "lng": 34.981344199999995
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8975199,
      "lng": 34.995746200000006
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8975244,
      "lng": 35.0426389
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897531760000003,
      "lng": 35.04252894
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897538275000002,
      "lng": 34.993630025
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897555875000002,
      "lng": 34.981446675
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.897564199999998,
      "lng": 34.99559352
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.897569633333333,
      "lng": 35.00659025
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.89757187368421,
      "lng": 34.98574047368422
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.897572433333334,
      "lng": 35.0097473
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.89757562000001,
      "lng": 35.00673999999999
    },
    "signal": -63.95
  },
  {
    "location": {
      "lat": 31.897593633333333,
      "lng": 35.04234876666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8975978,
      "lng": 35.042174
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.8976064,
      "lng": 34.9910677
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897608025,
      "lng": 34.995399275000004
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.897616845454547,
      "lng": 35.02563782727273
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.897636700000003,
      "lng": 34.99377896666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.897640374999998,
      "lng": 34.995232550000004
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.897655699999998,
      "lng": 34.9815656
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89766933333333,
      "lng": 34.99506246666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.897683366666666,
      "lng": 35.042021166666665
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.89769606666667,
      "lng": 34.994825133333336
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.897709733333333,
      "lng": 34.994709199999996
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.89771346666667,
      "lng": 34.99393156666667
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.897725133333335,
      "lng": 35.04182446666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897725933333334,
      "lng": 34.99451858333334
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.897731369230765,
      "lng": 34.9856728
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.897735566666668,
      "lng": 34.994328599999996
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.89773735,
      "lng": 34.99415106666667
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.897752733333334,
      "lng": 35.00958083333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.897754550000002,
      "lng": 34.98174421666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89776,
      "lng": 35.0415913
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897771471428573,
      "lng": 35.006628400000004
    },
    "signal": -66.75
  },
  {
    "location": {
      "lat": 31.89777936666667,
      "lng": 34.99114646666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.89778543333333,
      "lng": 35.04132436666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897786193333328,
      "lng": 35.006788586666666
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.897794557142863,
      "lng": 34.98554215714285
    },
    "signal": -93.8
  },
  {
    "location": {
      "lat": 31.8977971125,
      "lng": 35.025640675
    },
    "signal": -91.6
  },
  {
    "location": {
      "lat": 31.89785945,
      "lng": 34.98196970000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.897891166666664,
      "lng": 35.0254576
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.897898633333337,
      "lng": 35.040192499999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897931640000003,
      "lng": 34.98211362000001
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.8979362,
      "lng": 35.039925399999994
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897937957142858,
      "lng": 34.985408957142866
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.897939150000003,
      "lng": 35.0094069
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.8979407,
      "lng": 35.006832425000006
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.8979434,
      "lng": 35.02530491666667
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.897947486666673,
      "lng": 35.00661589333333
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.897948649999996,
      "lng": 35.025789
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89794921111111,
      "lng": 34.98563656666667
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.89795014,
      "lng": 34.985468299999994
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.897953525,
      "lng": 35.039652350000004
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.897956066666666,
      "lng": 34.99122796666666
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.897986600000003,
      "lng": 35.006776859999995
    },
    "signal": -63.95
  },
  {
    "location": {
      "lat": 31.898005666666666,
      "lng": 35.02507203333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.898013866666663,
      "lng": 34.98229076666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.898046825,
      "lng": 35.024912925
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.898055699999997,
      "lng": 35.0393999
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898058100000004,
      "lng": 34.98551844
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.898072233333334,
      "lng": 35.0389661
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898092000000002,
      "lng": 35.024737933333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.898094,
      "lng": 35.0391619
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898104066666665,
      "lng": 35.03875260000001
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89810472,
      "lng": 34.98247038
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.89811248888889,
      "lng": 35.006768855555556
    },
    "signal": -66.8
  },
  {
    "location": {
      "lat": 31.89811425,
      "lng": 35.0065861125
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.898115649999998,
      "lng": 35.00924200000001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.898119155555555,
      "lng": 34.98536221111111
    },
    "signal": -93.6
  },
  {
    "location": {
      "lat": 31.89813005,
      "lng": 35.03832115
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898130433333336,
      "lng": 35.03853423333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898134866666663,
      "lng": 34.991310733333336
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898137525000003,
      "lng": 35.024559325000006
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.898144177777777,
      "lng": 35.02579407777778
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.898180266666667,
      "lng": 34.98264403333334
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898183166666666,
      "lng": 35.0243798
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8982272,
      "lng": 35.038070399999995
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898227666666667,
      "lng": 35.02420253333333
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.8982468,
      "lng": 35.03762013333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898267125,
      "lng": 35.0373599
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89827025,
      "lng": 34.98282603333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898273133333333,
      "lng": 35.024027233333335
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.89828071428571,
      "lng": 34.98516292857143
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898283081818178,
      "lng": 35.0067385090909
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.89828898333333,
      "lng": 35.00906906666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89831075,
      "lng": 35.006565980000005
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8983156,
      "lng": 34.99139436666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898320549999994,
      "lng": 35.025800270000005
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.898329940000004,
      "lng": 34.9829711
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89834912,
      "lng": 35.03695776
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898355,
      "lng": 35.0371683
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.898355366666664,
      "lng": 34.985043866666665
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898363975000002,
      "lng": 35.023680575
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.898364200000003,
      "lng": 35.00645525
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.898375700000003,
      "lng": 34.983120325
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898410400000003,
      "lng": 35.023509133333334
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.898437,
      "lng": 35.0367196
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898445766666665,
      "lng": 35.03651673333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8984548,
      "lng": 35.023342
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.898455133333332,
      "lng": 34.9847999
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898460640000003,
      "lng": 35.008871240000005
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.898462975,
      "lng": 35.0360641
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898470019999998,
      "lng": 35.00640196
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.898491000000003,
      "lng": 35.03630183333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898499166666667,
      "lng": 34.99147903333334
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.89850186666667,
      "lng": 35.025788122222224
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.898505566666667,
      "lng": 34.984619933333335
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898512057142856,
      "lng": 35.00658844285714
    },
    "signal": -71.75
  },
  {
    "location": {
      "lat": 31.898515716666665,
      "lng": 35.02310013333334
    },
    "signal": -96
  },
  {
    "location": {
      "lat": 31.898524366666663,
      "lng": 35.01896489999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.898530100000002,
      "lng": 35.01915255
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8985325,
      "lng": 35.01877473333334
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89853427142857,
      "lng": 34.98372162857143
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898536299999996,
      "lng": 34.98445134285714
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898539666666668,
      "lng": 35.0193176
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.898547399999998,
      "lng": 35.00876435
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.89855178571429,
      "lng": 34.98386334285714
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898554183333335,
      "lng": 35.0195198
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.898554400000005,
      "lng": 35.018584233333335
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.898559624999997,
      "lng": 34.984072125
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89856543333333,
      "lng": 35.022877533333336
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.898568066666666,
      "lng": 34.98424610000001
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898574100000005,
      "lng": 35.0197473
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898585733333334,
      "lng": 35.0199465
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898587699999997,
      "lng": 35.01839846666667
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89859626666667,
      "lng": 35.0200723
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898597950000003,
      "lng": 35.022747025
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.898608966666668,
      "lng": 35.020209333333334
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8986135,
      "lng": 35.035878000000004
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898617625,
      "lng": 35.008657324999994
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.898626800000002,
      "lng": 35.02039835
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8986336,
      "lng": 35.01821986666667
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.898637766666667,
      "lng": 35.022535366666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.898643900000003,
      "lng": 35.0205935
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898646425,
      "lng": 35.0065151375
    },
    "signal": -71.5
  },
  {
    "location": {
      "lat": 31.898657725,
      "lng": 35.0062505
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.898661500000003,
      "lng": 35.020785180000004
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898661966666662,
      "lng": 35.006399683333335
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.898662425,
      "lng": 35.0354374
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.8986644,
      "lng": 35.02236465
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.898679166666664,
      "lng": 35.02093423333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.898685699999998,
      "lng": 34.991569075
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898691640000003,
      "lng": 35.018046899999995
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89869213333333,
      "lng": 35.02217136666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.898693433333335,
      "lng": 35.02114936666666
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.898698933333336,
      "lng": 35.00851336666667
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.898703425,
      "lng": 35.021323724999995
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.89870966666666,
      "lng": 35.0215058
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8987112,
      "lng": 35.021991575
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.89871451666667,
      "lng": 35.02168326666666
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.898716425,
      "lng": 35.021877475
    },
    "signal": -82.25
  },
  {
    "location": {
      "lat": 31.89876013333333,
      "lng": 35.017881933333335
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8987691,
      "lng": 35.035224799999995
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.89878832,
      "lng": 35.008342119999995
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.898800366666666,
      "lng": 35.03500076666666
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898800524999995,
      "lng": 35.034792225
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.898807639999994,
      "lng": 35.00618164
    },
    "signal": -72.6
  },
  {
    "location": {
      "lat": 31.89881551818182,
      "lng": 35.0063811
    },
    "signal": -70.75
  },
  {
    "location": {
      "lat": 31.898839600000002,
      "lng": 35.01772150000001
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.898874366666664,
      "lng": 34.991651
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.898874633333335,
      "lng": 35.00816261666666
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8989343,
      "lng": 35.0175724
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89894024,
      "lng": 35.02548186
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89894993333333,
      "lng": 35.02567653333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.898951250000003,
      "lng": 35.00568465
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.8989533,
      "lng": 35.0345463
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.898956999999996,
      "lng": 35.008009
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.898962416666663,
      "lng": 35.005471566666664
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.898970470000002,
      "lng": 35.006009209999995
    },
    "signal": -64.5
  },
  {
    "location": {
      "lat": 31.89898124,
      "lng": 35.03427486
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.898982333333336,
      "lng": 35.0252792
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899008533333333,
      "lng": 35.02513723333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899015614285712,
      "lng": 35.006338435714284
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899016322727274,
      "lng": 35.00615086363637
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.89901776,
      "lng": 35.00786318
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.899019933333335,
      "lng": 35.0174164
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899028183333332,
      "lng": 35.03405635
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8990374,
      "lng": 35.005269366666674
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.899041044444445,
      "lng": 35.02582311111111
    },
    "signal": -89.7
  },
  {
    "location": {
      "lat": 31.899042425,
      "lng": 35.0187748
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899045200000003,
      "lng": 35.005890066666666
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.89904808,
      "lng": 35.02491288
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899051333333333,
      "lng": 35.018602433333335
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89905596666667,
      "lng": 35.01894506666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8990702,
      "lng": 34.991721500000004
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.899076166666664,
      "lng": 35.018432
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899082300000003,
      "lng": 35.019113233333336
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899090625,
      "lng": 35.007674775
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.8990969,
      "lng": 35.02462053333334
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.8991064,
      "lng": 35.0182785
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8991118,
      "lng": 35.0192901
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.8991149,
      "lng": 35.01727266666666
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899126433333333,
      "lng": 35.024433466666665
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.89913885,
      "lng": 35.007596050000004
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.899139833333333,
      "lng": 35.006000666666665
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.899145533333336,
      "lng": 35.00641976666667
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.89914636666667,
      "lng": 35.03386199999999
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899154814285712,
      "lng": 35.00509977142857
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.8991558,
      "lng": 35.0242533
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899159433333335,
      "lng": 35.01944613333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899171833333337,
      "lng": 35.01811433333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89918198,
      "lng": 35.02403558
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899187420000004,
      "lng": 35.00744452
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.89919026,
      "lng": 35.03366908
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899194360000003,
      "lng": 35.02595706
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.899203033333333,
      "lng": 35.01711493333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899205566666666,
      "lng": 35.02383946666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899209499999998,
      "lng": 35.025858766666666
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.899215633333338,
      "lng": 35.00582506666667
    },
    "signal": -77.35
  },
  {
    "location": {
      "lat": 31.89921766666667,
      "lng": 35.0196654
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.899228336363635,
      "lng": 35.00653643636364
    },
    "signal": -71.4
  },
  {
    "location": {
      "lat": 31.89922835,
      "lng": 35.023641275
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899234725,
      "lng": 35.017969699999995
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.89924613333333,
      "lng": 35.02344816666667
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899255149999995,
      "lng": 35.007285725
    },
    "signal": -67.65
  },
  {
    "location": {
      "lat": 31.899256700000006,
      "lng": 34.99181493333333
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.8992705,
      "lng": 35.0232572
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899270700000002,
      "lng": 35.004980966666665
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.8992737,
      "lng": 35.01989149999999
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89928565,
      "lng": 35.01696725
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.8992861,
      "lng": 35.023140760000004
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.89930993333333,
      "lng": 35.02295533333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899312766666668,
      "lng": 35.03340789999999
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899313799999998,
      "lng": 35.017831199999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.899327175000003,
      "lng": 35.0071217
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.899334366666665,
      "lng": 35.0227831
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.899335333333337,
      "lng": 35.020103
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89934624,
      "lng": 35.006614909999996
    },
    "signal": -71.4
  },
  {
    "location": {
      "lat": 31.899359824999998,
      "lng": 35.022606350000004
    },
    "signal": -92.6
  },
  {
    "location": {
      "lat": 31.89936306666667,
      "lng": 35.0168257
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89936985,
      "lng": 35.0056436
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.899375288888887,
      "lng": 35.025964933333334
    },
    "signal": -89.7
  },
  {
    "location": {
      "lat": 31.899377100000002,
      "lng": 35.005808875
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.899379033333332,
      "lng": 35.02026916666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.899386500000002,
      "lng": 35.02588453333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8993894,
      "lng": 35.006925433333336
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.899389924999998,
      "lng": 35.022383624999996
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.899395080000005,
      "lng": 35.00489276
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.899403133333333,
      "lng": 35.01770596666666
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8994052875,
      "lng": 35.00671405
    },
    "signal": -66.95
  },
  {
    "location": {
      "lat": 31.8994125,
      "lng": 35.02220251999999
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.899417875,
      "lng": 35.02043495
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.899432499999996,
      "lng": 35.022008625
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.899444000000003,
      "lng": 35.00554445
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.899445357142856,
      "lng": 35.016618799999996
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899448666666668,
      "lng": 34.9918903
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.899449200000003,
      "lng": 35.02185433333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.89945416666667,
      "lng": 35.02059886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.8994647,
      "lng": 35.0217013
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8994805,
      "lng": 35.02151906
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.899480699999998,
      "lng": 35.02076286666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.899482300000003,
      "lng": 35.01645755
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89949506666667,
      "lng": 35.032944533333335
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.89949686666667,
      "lng": 35.02092326666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.899498,
      "lng": 35.02130074
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.8995025,
      "lng": 35.02108333333334
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.89953845,
      "lng": 35.00567915
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.899547666666667,
      "lng": 35.01754688333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.899549330769226,
      "lng": 35.02595236153846
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.899549887499994,
      "lng": 35.006694849999995
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.89955628888889,
      "lng": 35.032656233333334
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899563699999998,
      "lng": 35.0054729
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.89956978,
      "lng": 35.0048211
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.8996645,
      "lng": 35.00557866666667
    },
    "signal": -69.75
  },
  {
    "location": {
      "lat": 31.899695800000003,
      "lng": 35.0324413
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899720540000004,
      "lng": 35.004802
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.899721916666664,
      "lng": 35.0173631
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.89972622,
      "lng": 35.01298708
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.8997352,
      "lng": 35.02594434545455
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.8997374,
      "lng": 35.005458225
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.89974355714286,
      "lng": 35.006725971428565
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.899797720000002,
      "lng": 35.00532002
    },
    "signal": -72.6
  },
  {
    "location": {
      "lat": 31.899838800000005,
      "lng": 35.032205100000006
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.899879149999997,
      "lng": 35.03191985
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.899891116666666,
      "lng": 35.017176066666664
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.899910300000002,
      "lng": 35.00482295
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.89991808181818,
      "lng": 35.00671129090909
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.89993315,
      "lng": 35.005267325
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.899939355555553,
      "lng": 35.025978288888886
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.899940900000008,
      "lng": 35.012872800000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.899952533333334,
      "lng": 34.992044299999996
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.89995495,
      "lng": 35.00542
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.89998233333333,
      "lng": 35.017067000000004
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.8999973,
      "lng": 35.01295506666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900042600000003,
      "lng": 35.0317085
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.900055200000004,
      "lng": 35.01310145
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900070349999996,
      "lng": 35.004886174999996
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.900070799999998,
      "lng": 35.01695156666667
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.900074625000002,
      "lng": 35.006658775
    },
    "signal": -75.6
  },
  {
    "location": {
      "lat": 31.90008983333333,
      "lng": 35.01320896666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.9000981,
      "lng": 35.0051871
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.900102685714284,
      "lng": 35.02599827142857
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.900102850000003,
      "lng": 34.99206290000001
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9001043,
      "lng": 35.0052964
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.90013965,
      "lng": 35.031414075
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90014838571429,
      "lng": 35.013389714285715
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900152225,
      "lng": 35.006608775000004
    },
    "signal": -75.6
  },
  {
    "location": {
      "lat": 31.9001541,
      "lng": 35.0168314
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.900206599999997,
      "lng": 35.0049772
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.900207066666667,
      "lng": 35.0135602
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90024924,
      "lng": 35.01674906
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90024937777778,
      "lng": 35.00653094444444
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9002513,
      "lng": 35.02602592000001
    },
    "signal": -88.2
  },
  {
    "location": {
      "lat": 31.900265349999994,
      "lng": 35.0137385
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90027348,
      "lng": 35.031191060000005
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90028765,
      "lng": 35.005284625
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.900293885714284,
      "lng": 35.005099914285715
    },
    "signal": -72.35
  },
  {
    "location": {
      "lat": 31.9003268,
      "lng": 34.997946559999995
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.90033295,
      "lng": 35.01671135
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.900334299999997,
      "lng": 35.0139554
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900335800000004,
      "lng": 34.997723925
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900338766666664,
      "lng": 34.99816633333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9003513,
      "lng": 34.9921211
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.900352549999997,
      "lng": 35.006424349999996
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.900359366666663,
      "lng": 34.9983165
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900364933333332,
      "lng": 34.99753393333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900371500000002,
      "lng": 35.014108
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900389533333335,
      "lng": 34.99845643333333
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900402466666666,
      "lng": 34.9973809
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900402999999997,
      "lng": 35.016628374999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.900413433333338,
      "lng": 35.03096613333333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.900415199999998,
      "lng": 35.006346
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.900438700000002,
      "lng": 35.026046012500004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90044736666667,
      "lng": 35.01429543333334
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900451166666667,
      "lng": 34.997232133333334
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.900456260000002,
      "lng": 35.005306488
    },
    "signal": -67.6
  },
  {
    "location": {
      "lat": 31.9004565,
      "lng": 34.9986542
    },
    "signal": -88.95
  },
  {
    "location": {
      "lat": 31.900459166666668,
      "lng": 35.016494933333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.900461707142853,
      "lng": 35.00621961428571
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.900466766666668,
      "lng": 35.005167666666665
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.900488166666666,
      "lng": 35.01442183333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900509166666666,
      "lng": 34.99214369999999
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9005187,
      "lng": 35.00547582
    },
    "signal": -56.900000000000006
  },
  {
    "location": {
      "lat": 31.900536499999998,
      "lng": 35.016319100000004
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900544766666666,
      "lng": 34.9988466
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900548399999998,
      "lng": 35.014616100000005
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.9005627,
      "lng": 35.0307247
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.900564266666667,
      "lng": 35.005628908333335
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.900570261193987,
      "lng": 35.00598436119406
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.900574866666663,
      "lng": 34.99696273333333
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.90057546,
      "lng": 35.01622014
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900578325,
      "lng": 35.014644849999996
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900599236363636,
      "lng": 35.00587918181818
    },
    "signal": -61
  },
  {
    "location": {
      "lat": 31.900602600000003,
      "lng": 35.00547589999999
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.9006189,
      "lng": 35.0160854
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90063,
      "lng": 35.0148154
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900644049999997,
      "lng": 35.030470916666665
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90064742857143,
      "lng": 35.02614752857143
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.900648966666665,
      "lng": 34.99684383333334
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.90064996,
      "lng": 34.9990174
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900663066666667,
      "lng": 34.99216223333333
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.90066354,
      "lng": 35.01592788000001
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.9006716,
      "lng": 35.00533036666666
    },
    "signal": -75.6
  },
  {
    "location": {
      "lat": 31.900672980000003,
      "lng": 35.014995899999995
    },
    "signal": -91.7
  },
  {
    "location": {
      "lat": 31.900684975,
      "lng": 35.005148625
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.900695866666666,
      "lng": 35.015757900000004
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900708450000003,
      "lng": 35.015209600000006
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900716420000002,
      "lng": 35.015566979999996
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900718533333333,
      "lng": 35.01534850000001
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.900788475,
      "lng": 34.999197325000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90079756666666,
      "lng": 35.026114422222214
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.900799199999998,
      "lng": 34.996659625
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.90080536666667,
      "lng": 35.03021833333333
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90082103333334,
      "lng": 34.992175499999995
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.900852133333334,
      "lng": 35.005311400000004
    },
    "signal": -75.6
  },
  {
    "location": {
      "lat": 31.900872425000003,
      "lng": 35.0051308
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.90088573333333,
      "lng": 34.9993035
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900939800000003,
      "lng": 35.0299353
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90096527142858,
      "lng": 34.99649952857143
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.900983299999996,
      "lng": 34.99218316666667
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.900987833333335,
      "lng": 34.999395033333336
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.900998742857144,
      "lng": 35.00279557142857
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.900999444444444,
      "lng": 35.02612356666666
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90101495714286,
      "lng": 35.02974954285715
    },
    "signal": -95
  },
  {
    "location": {
      "lat": 31.90101515,
      "lng": 35.00262055
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.901040919999996,
      "lng": 35.00529904
    },
    "signal": -75.2
  },
  {
    "location": {
      "lat": 31.901063433333334,
      "lng": 35.00248606666667
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.901064599999998,
      "lng": 35.005112525
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.901116100000007,
      "lng": 35.002341566666665
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901145666666665,
      "lng": 34.99218569999999
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.90114635,
      "lng": 35.002904325
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.9011553,
      "lng": 34.999512100000004
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90117103333333,
      "lng": 35.002185733333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901178480000006,
      "lng": 34.99633396
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.901181009999995,
      "lng": 35.02615298
    },
    "signal": -88.2
  },
  {
    "location": {
      "lat": 31.901192150000004,
      "lng": 35.029420699999996
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901209266666665,
      "lng": 35.00207296666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901235,
      "lng": 35.005275266666665
    },
    "signal": -75.6
  },
  {
    "location": {
      "lat": 31.9012625,
      "lng": 35.0050263
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.901300399999997,
      "lng": 35.00179945
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901308333333333,
      "lng": 35.029192466666665
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901309299999998,
      "lng": 34.9921843
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9013181,
      "lng": 35.0052826
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.901340966666666,
      "lng": 34.99959723333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90134975,
      "lng": 35.001680424999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901352237500003,
      "lng": 35.0261777125
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.901357799999992,
      "lng": 35.002992340000006
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.901372860000002,
      "lng": 34.99618503999999
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.90138078,
      "lng": 35.02905664000001
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901434499999997,
      "lng": 35.00149076666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901447700000002,
      "lng": 35.005010799999994
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.901477866666667,
      "lng": 34.99217693333333
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9015061625,
      "lng": 35.005262237500006
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.901509325000003,
      "lng": 34.996082449999996
    },
    "signal": -89.8
  },
  {
    "location": {
      "lat": 31.90151115,
      "lng": 35.00134679999999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.901519816666667,
      "lng": 35.028827549999995
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901528466666665,
      "lng": 35.00307406666667
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.90152983333333,
      "lng": 34.99966826666667
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.901556130769226,
      "lng": 35.02619784615384
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.90160255,
      "lng": 35.001205575
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9016331,
      "lng": 35.0049937
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9016534,
      "lng": 34.99216296666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.901655675,
      "lng": 35.028637875
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90167095,
      "lng": 34.99595325
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9016897,
      "lng": 35.0010815
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9016923,
      "lng": 35.00524745
    },
    "signal": -76.45
  },
  {
    "location": {
      "lat": 31.901699500000007,
      "lng": 35.00315936666667
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.901718016666667,
      "lng": 34.999713750000005
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.901725425,
      "lng": 35.028481725
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901755633333334,
      "lng": 35.02622846666667
    },
    "signal": -87.75
  },
  {
    "location": {
      "lat": 31.90175974,
      "lng": 35.001000160000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.901784600000003,
      "lng": 34.99583605
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9018325,
      "lng": 34.9921411
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.901852933333334,
      "lng": 35.028284766666665
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.901856949999996,
      "lng": 35.00499875
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.901865559999997,
      "lng": 34.99559916
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.901882583333332,
      "lng": 35.00514913333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.901883350000002,
      "lng": 35.028122366666665
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.9018931,
      "lng": 35.00526053333333
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.901903474999997,
      "lng": 35.003262325
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.9019043,
      "lng": 35.02629812
    },
    "signal": -87.75
  },
  {
    "location": {
      "lat": 31.901908699999996,
      "lng": 35.0008353
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.901914766666668,
      "lng": 34.99975053333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.9019278,
      "lng": 34.995437933333335
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.901971899999996,
      "lng": 34.9953052
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.901995333333332,
      "lng": 34.995197166666664
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.902015233333334,
      "lng": 34.9921115
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90201726666667,
      "lng": 34.9950646
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.902021425,
      "lng": 35.00072795
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.90203406666667,
      "lng": 35.02795446666667
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.902049749999996,
      "lng": 35.00498235
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.902056899999998,
      "lng": 35.003339159999996
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.902063674999997,
      "lng": 35.0262752875
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.902067249999998,
      "lng": 34.9947502
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.902067699999996,
      "lng": 35.0052437
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.90207352,
      "lng": 35.027798260000004
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.902079199999996,
      "lng": 35.00512012
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.90209725,
      "lng": 34.994546
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90210483333333,
      "lng": 34.99976328333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.90213405,
      "lng": 34.994313049999995
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90216235,
      "lng": 34.99411235
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.902188775,
      "lng": 34.993955975
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.902189266666664,
      "lng": 35.00341086666666
    },
    "signal": -63
  },
  {
    "location": {
      "lat": 31.9021963,
      "lng": 35.0276955
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90220025,
      "lng": 34.9920683
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.902213000000003,
      "lng": 34.99381053333334
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902221580000003,
      "lng": 35.02761244
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.902249400000002,
      "lng": 35.000545100000004
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.90225365,
      "lng": 35.0052254
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.902254099999997,
      "lng": 35.004995625
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.902255500000003,
      "lng": 34.993620074999995
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902259150000003,
      "lng": 35.0263188
    },
    "signal": -87.75
  },
  {
    "location": {
      "lat": 31.902280700000002,
      "lng": 34.99974426666666
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.902287166666667,
      "lng": 35.00514830000001
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902302766666665,
      "lng": 35.02743173333334
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9023066,
      "lng": 35.0034979
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902367699999996,
      "lng": 35.027373299999994
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9023735,
      "lng": 35.00359055
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.9023793,
      "lng": 34.993371350000004
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902389233333334,
      "lng": 34.99203
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9024117,
      "lng": 35.00496733333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.902413485714284,
      "lng": 35.027228557142855
    },
    "signal": -87.4
  },
  {
    "location": {
      "lat": 31.902416074999998,
      "lng": 35.003657374999996
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.90242197142857,
      "lng": 35.02707968571428
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.902425899999997,
      "lng": 35.000432583333335
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.902426100000003,
      "lng": 34.9997026
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.902445900000004,
      "lng": 35.02635481666666
    },
    "signal": -87.5
  },
  {
    "location": {
      "lat": 31.90247901666667,
      "lng": 35.00515438333333
    },
    "signal": -83.4
  },
  {
    "location": {
      "lat": 31.902484750000003,
      "lng": 34.993255475000005
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902538633333336,
      "lng": 35.0270285
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.9025672,
      "lng": 35.003663325
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.9025793,
      "lng": 34.991975399999994
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90258835,
      "lng": 34.99318515
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902597099999998,
      "lng": 35.00339193333333
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902608066666662,
      "lng": 35.026900399999995
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90261124,
      "lng": 35.02649892000001
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.90261203333333,
      "lng": 35.00355223333333
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902616637500003,
      "lng": 35.026368600000005
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.902621749999998,
      "lng": 35.00032001666667
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.902638380000003,
      "lng": 35.00496688
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.902638433333333,
      "lng": 35.00315431666666
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.90265281111111,
      "lng": 35.02673092222222
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90266183333333,
      "lng": 35.00513156666667
    },
    "signal": -79.3
  },
  {
    "location": {
      "lat": 31.90266216,
      "lng": 34.9996214
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.90266526666667,
      "lng": 34.99313166666666
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9026962,
      "lng": 35.002969500000006
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902723100000003,
      "lng": 35.026653
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.90272925,
      "lng": 35.0051445
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.90274863333333,
      "lng": 35.000245166666666
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.902768200000004,
      "lng": 35.0027813
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902776799999998,
      "lng": 34.9919067
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.902801777777775,
      "lng": 35.02616875555556
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9028041,
      "lng": 35.004944075
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9028053125,
      "lng": 34.9997085875
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.9028139,
      "lng": 34.993076
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.90282895,
      "lng": 35.002594025
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.9028756,
      "lng": 35.0001266
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.902876899999995,
      "lng": 35.0024408
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.902881999999995,
      "lng": 35.02600356666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.902888899999997,
      "lng": 34.9999089
    },
    "signal": -87
  },
  {
    "location": {
      "lat": 31.902906424999998,
      "lng": 35.00510365
    },
    "signal": -79.3
  },
  {
    "location": {
      "lat": 31.902949525,
      "lng": 35.00225987500001
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.9029553,
      "lng": 35.00493456666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9029575,
      "lng": 35.000072933333335
    },
    "signal": -83.85
  },
  {
    "location": {
      "lat": 31.902959866666666,
      "lng": 34.99185293333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.902975700000002,
      "lng": 34.993054674999996
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.902982387499996,
      "lng": 35.02582646249999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903001871428568,
      "lng": 35.002093542857146
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90300548,
      "lng": 35.0002211
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.903050166666663,
      "lng": 35.001933433333335
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90308763333333,
      "lng": 34.99997726666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.9030943,
      "lng": 35.001844125
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.903106725,
      "lng": 35.025586225
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903120474999998,
      "lng": 35.004916
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9031393,
      "lng": 35.000190475000004
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.903143566666667,
      "lng": 34.993072766666664
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903147533333335,
      "lng": 35.00169573333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.903152966666667,
      "lng": 35.000068
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.9031553,
      "lng": 35.005107620000004
    },
    "signal": -77.7
  },
  {
    "location": {
      "lat": 31.9031888,
      "lng": 35.02543746
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.90321672,
      "lng": 35.00151538
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90327256666667,
      "lng": 35.0252779
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.90328725,
      "lng": 35.001371049999996
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90330315,
      "lng": 35.004881274999995
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9033391,
      "lng": 34.99169375
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90334245,
      "lng": 34.993074775
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903355119999997,
      "lng": 35.02511774
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903357133333333,
      "lng": 35.00507146666667
    },
    "signal": -77.9
  },
  {
    "location": {
      "lat": 31.903386140000002,
      "lng": 35.00114922
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.9034457,
      "lng": 35.0048464
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.903463849999998,
      "lng": 35.0249164
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903499350000004,
      "lng": 34.993053383333326
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903504833333333,
      "lng": 35.0009828
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.903527233333335,
      "lng": 34.99160186666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.903552333333334,
      "lng": 35.0050556
    },
    "signal": -83.4
  },
  {
    "location": {
      "lat": 31.903562275,
      "lng": 35.024725425
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.903619566666666,
      "lng": 35.00093703333334
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.903622850000005,
      "lng": 35.00484165
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.903697920000003,
      "lng": 35.02448872000001
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.9037073,
      "lng": 34.9932088
    },
    "signal": -84.9
  },
  {
    "location": {
      "lat": 31.903707766666667,
      "lng": 34.9915042
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.9037125,
      "lng": 35.000716600000004
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.903713039999996,
      "lng": 35.00079824
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.903715616666663,
      "lng": 35.0049481
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.903746899999998,
      "lng": 35.0050778
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.903762299999997,
      "lng": 35.024368925000005
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90379475,
      "lng": 35.00054490000001
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.90384953333334,
      "lng": 35.0048208
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.903860639999998,
      "lng": 35.0241938
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90387613333333,
      "lng": 34.99326381666666
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.903885966666667,
      "lng": 34.99139983333333
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.903916,
      "lng": 35.0004193
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.90392,
      "lng": 35.005061
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.903981033333334,
      "lng": 35.00498496666666
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9039821,
      "lng": 35.023970750000004
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90404626666667,
      "lng": 34.99330493333333
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.904052725000003,
      "lng": 35.0238487
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904058899999995,
      "lng": 34.99129200000001
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904074766666664,
      "lng": 35.000284
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904078525,
      "lng": 35.00482925
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.904085,
      "lng": 35.005045200000005
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.904125066666666,
      "lng": 35.02370036666667
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90418736,
      "lng": 35.023590260000006
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904189024999997,
      "lng": 34.99332985
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.904204999999997,
      "lng": 35.004980925
    },
    "signal": -83.6
  },
  {
    "location": {
      "lat": 31.90422995,
      "lng": 34.9911715
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904241225,
      "lng": 35.00481715
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90426565,
      "lng": 34.9933529
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.904271174999998,
      "lng": 35.023433325
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904353425,
      "lng": 35.0232759
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904358199999997,
      "lng": 34.993357
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.904394000000003,
      "lng": 35.00497268
    },
    "signal": -83.2
  },
  {
    "location": {
      "lat": 31.9043975,
      "lng": 34.99105516666666
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904403283333338,
      "lng": 35.00006345
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904438499999998,
      "lng": 35.023114750000005
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904440675,
      "lng": 35.004801775000004
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90446375,
      "lng": 34.99332835
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.9044899,
      "lng": 35.02300515
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90455916666666,
      "lng": 34.99092513333333
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904564766666667,
      "lng": 35.02286373333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904593816666672,
      "lng": 35.00495085
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.904599349999994,
      "lng": 34.9933348
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.904616633333337,
      "lng": 34.999938633333336
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904634375,
      "lng": 35.022744875
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904719049999997,
      "lng": 35.022581975
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.9047623,
      "lng": 34.999855333333336
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.9047682,
      "lng": 35.00477696
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90478045,
      "lng": 35.00492410000001
    },
    "signal": -83.6
  },
  {
    "location": {
      "lat": 31.904785466666667,
      "lng": 34.99072845
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904786983333338,
      "lng": 34.99345113333334
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.904806725,
      "lng": 35.0224124
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904893819999995,
      "lng": 35.02224274
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904909933333332,
      "lng": 34.99977156666666
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.904942825,
      "lng": 35.00490875
    },
    "signal": -87.8
  },
  {
    "location": {
      "lat": 31.9049615,
      "lng": 35.00475306666666
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.904965800000003,
      "lng": 35.02209373333333
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.904987385714286,
      "lng": 34.99344821428571
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.905056933333338,
      "lng": 34.99968966666667
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.905079575000002,
      "lng": 35.021883599999995
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.905125533333333,
      "lng": 34.990403
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.905135675,
      "lng": 35.0049004125
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.905147775,
      "lng": 35.00473637500001
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.905147799999998,
      "lng": 34.99338296666667
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.905199,
      "lng": 34.999611666666674
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90524645,
      "lng": 35.021584675
    },
    "signal": -94
  },
  {
    "location": {
      "lat": 31.90529951666667,
      "lng": 34.990211566666666
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9053039,
      "lng": 35.004880074999996
    },
    "signal": -87.6
  },
  {
    "location": {
      "lat": 31.905305483333336,
      "lng": 34.99327253333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.905313233333334,
      "lng": 35.00473601666667
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9053277,
      "lng": 34.999541566666664
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.905328100000002,
      "lng": 35.021454799999994
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90543855,
      "lng": 35.02126945
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.905441366666665,
      "lng": 34.99948036666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.905468919999997,
      "lng": 34.99001724
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.905496254545458,
      "lng": 35.00477107272727
    },
    "signal": -89.5
  },
  {
    "location": {
      "lat": 31.905509562499994,
      "lng": 35.004907075
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.90551562,
      "lng": 34.99309484
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.905542150000002,
      "lng": 34.98992595
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90554273333333,
      "lng": 34.999425599999995
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.905549299999997,
      "lng": 35.021098225
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.905576399999998,
      "lng": 35.004664700000006
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.905642200000003,
      "lng": 34.98980888333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90565125,
      "lng": 34.9930088
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.9056604,
      "lng": 35.0046474
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.905664225000002,
      "lng": 35.020928350000005
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.905670799999996,
      "lng": 35.0049052
    },
    "signal": -84.3
  },
  {
    "location": {
      "lat": 31.905675244444442,
      "lng": 35.00481249999999
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9057045,
      "lng": 34.999338225
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.905731133333333,
      "lng": 34.9929749
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.905739025,
      "lng": 34.989693575
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.905758900000002,
      "lng": 35.02079145
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.905831557142854,
      "lng": 35.004824528571426
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.905840842857142,
      "lng": 35.004625028571425
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90586175,
      "lng": 34.9929443
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.905863899999996,
      "lng": 35.004906766666664
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.905869175000003,
      "lng": 34.9895426
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9058733,
      "lng": 35.02064176666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.905889899999995,
      "lng": 34.9992362
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90595636666667,
      "lng": 34.98944773333333
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90600843333333,
      "lng": 34.992957999999994
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.906024725,
      "lng": 35.020433025
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906026166666663,
      "lng": 34.99915993333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.906061542857145,
      "lng": 35.00460624285714
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9060633,
      "lng": 34.989378200000004
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.906076674999998,
      "lng": 35.00486515
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.906169700000003,
      "lng": 34.999078733333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.906188775,
      "lng": 35.020223375
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9062192,
      "lng": 34.98936404
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.906220566666665,
      "lng": 35.00460973333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.906227525000002,
      "lng": 34.993005225
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.906229274999998,
      "lng": 35.00487475
    },
    "signal": -88.6
  },
  {
    "location": {
      "lat": 31.906324,
      "lng": 35.02005806666666
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906346859999996,
      "lng": 34.9989785
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.906361960000005,
      "lng": 34.99685708
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.9063629,
      "lng": 34.99667806666667
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.9063902,
      "lng": 34.99303903333333
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.90639075,
      "lng": 34.99649263333333
    },
    "signal": -78.95
  },
  {
    "location": {
      "lat": 31.9063932,
      "lng": 35.00462001666667
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.906400766666668,
      "lng": 35.00488236666666
    },
    "signal": -88.4
  },
  {
    "location": {
      "lat": 31.90642396666667,
      "lng": 34.997087099999995
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.906428033333338,
      "lng": 34.99629866666667
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90643498,
      "lng": 35.019933480000006
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.906437366666665,
      "lng": 34.98949806666667
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.906466066666667,
      "lng": 34.99617323333333
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906493766666667,
      "lng": 34.997209233333336
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.906519266666667,
      "lng": 34.996054566666665
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9065434,
      "lng": 34.9895656
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.906562075,
      "lng": 35.0197919
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.906581225,
      "lng": 35.0046333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.906583,
      "lng": 34.9973686
    },
    "signal": -78.2
  },
  {
    "location": {
      "lat": 31.906585333333336,
      "lng": 34.99884586666666
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906587842857142,
      "lng": 34.993079085714285
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.906589366666665,
      "lng": 34.9959419
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906594149999997,
      "lng": 35.004905475
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.906600100000002,
      "lng": 34.989636499999996
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.9066152,
      "lng": 35.00779656666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.906638280000003,
      "lng": 35.00769466
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.906689225,
      "lng": 35.019656075
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.906691549999998,
      "lng": 34.9987845
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90672675,
      "lng": 34.995794116666666
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906736066666667,
      "lng": 34.99759513333334
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.9067361,
      "lng": 35.00465483333334
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.9067577,
      "lng": 34.989763933333336
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.906764976923082,
      "lng": 35.00767355384615
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.906770039999998,
      "lng": 35.00494004000001
    },
    "signal": -90.8
  },
  {
    "location": {
      "lat": 31.9067793,
      "lng": 34.99873375
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906802799999998,
      "lng": 35.019538366666666
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9068853,
      "lng": 34.993133033333336
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.906887525,
      "lng": 34.9898683
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.906901150000003,
      "lng": 35.01944715
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9069068,
      "lng": 35.00469525999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.906909059999997,
      "lng": 35.00497012
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9069165,
      "lng": 34.99865755
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90691735,
      "lng": 34.995638083333326
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.906922774999998,
      "lng": 34.99772072500001
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.906934585714282,
      "lng": 35.00759022857143
    },
    "signal": -72.6
  },
  {
    "location": {
      "lat": 31.90698345,
      "lng": 35.007548299999996
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.907017166666666,
      "lng": 34.993181299999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90704145,
      "lng": 34.99859035
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.907052375000003,
      "lng": 35.019297
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.907078966666663,
      "lng": 35.004755933333335
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.907107522222223,
      "lng": 35.00748682222222
    },
    "signal": -72.2
  },
  {
    "location": {
      "lat": 31.90711755,
      "lng": 34.997591275
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90712258571428,
      "lng": 34.99544472857143
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.907142099999998,
      "lng": 34.990085933333326
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.9071578,
      "lng": 35.00506412
    },
    "signal": -90.8
  },
  {
    "location": {
      "lat": 31.907158149999997,
      "lng": 34.99852655
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90716008,
      "lng": 34.99325216
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.907182225,
      "lng": 35.0191832
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.907221600000003,
      "lng": 35.00481453333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.907229066666662,
      "lng": 34.99526786666667
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907236819999998,
      "lng": 35.00739969999999
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.907257,
      "lng": 34.99333703333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90727665,
      "lng": 34.9984577
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907289242857143,
      "lng": 34.99504495714286
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9072927,
      "lng": 34.99020955
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.907297733333333,
      "lng": 35.01907979999999
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.90730357142857,
      "lng": 34.997407571428575
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.907310662500002,
      "lng": 35.0073011125
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90731195,
      "lng": 35.0051257
    },
    "signal": -90.9
  },
  {
    "location": {
      "lat": 31.907323066666667,
      "lng": 34.9948206
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9073362,
      "lng": 35.004863633333336
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.907341275,
      "lng": 34.993448975
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.907346166666667,
      "lng": 34.9946541
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90737053333333,
      "lng": 34.994486
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9073852,
      "lng": 34.9902651
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.9073875,
      "lng": 34.9935187
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90740175,
      "lng": 34.99432355
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90742973333333,
      "lng": 35.00725473333333
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9074502,
      "lng": 35.0052169
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.90745103333333,
      "lng": 34.9937117
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.90745128333333,
      "lng": 34.99396163333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907461033333334,
      "lng": 34.9903509
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.907466966666664,
      "lng": 35.0049225
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90747538,
      "lng": 35.00518522
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.90747905,
      "lng": 34.99831805
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90748092,
      "lng": 34.99721248
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.907482175000005,
      "lng": 35.018929224999994
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.907485046153848,
      "lng": 35.0071201
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.907494677777777,
      "lng": 35.00695341111111
    },
    "signal": -74.95
  },
  {
    "location": {
      "lat": 31.90754937142857,
      "lng": 34.99056382857143
    },
    "signal": -64.85
  },
  {
    "location": {
      "lat": 31.907578833333332,
      "lng": 34.990553500000004
    },
    "signal": -65.95
  },
  {
    "location": {
      "lat": 31.907609549999997,
      "lng": 34.9982121
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907612733333334,
      "lng": 35.0049931
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90763698888889,
      "lng": 35.00530311111111
    },
    "signal": -90.1
  },
  {
    "location": {
      "lat": 31.9076414,
      "lng": 34.99700433333333
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.907645163636367,
      "lng": 35.00686835454545
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.907654957142856,
      "lng": 34.99070988571428
    },
    "signal": -65.8
  },
  {
    "location": {
      "lat": 31.907668292307697,
      "lng": 35.007146815384615
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.907668299999997,
      "lng": 34.99040555
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.90767315,
      "lng": 35.018774587500005
    },
    "signal": -88.7
  },
  {
    "location": {
      "lat": 31.9076948,
      "lng": 34.998133249999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907747399999998,
      "lng": 34.9968733
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.90777106666667,
      "lng": 35.00705753333333
    },
    "signal": -71.8
  },
  {
    "location": {
      "lat": 31.9077778,
      "lng": 34.99043987500001
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.90778125,
      "lng": 34.9980492
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90779525,
      "lng": 35.0053462
    },
    "signal": -90.5
  },
  {
    "location": {
      "lat": 31.90781004285714,
      "lng": 34.99070354285715
    },
    "signal": -65.8
  },
  {
    "location": {
      "lat": 31.907821027272725,
      "lng": 35.00689295454544
    },
    "signal": -72.9
  },
  {
    "location": {
      "lat": 31.907826166666666,
      "lng": 34.990555699999994
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.907828439999996,
      "lng": 35.00511598
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90782965,
      "lng": 35.01864760000001
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.907847355555557,
      "lng": 35.00676596666667
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.907863933333335,
      "lng": 35.00544788888889
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.907867849999995,
      "lng": 34.99796105
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.907876542857142,
      "lng": 34.996663742857145
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9079464,
      "lng": 35.01855525
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.907992033333333,
      "lng": 34.99646843333333
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.907993649999998,
      "lng": 34.990749275
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.907995315384614,
      "lng": 35.006586061538464
    },
    "signal": -74.7
  },
  {
    "location": {
      "lat": 31.908028,
      "lng": 35.00670374285714
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.908030416666662,
      "lng": 35.00562276666667
    },
    "signal": -81.4
  },
  {
    "location": {
      "lat": 31.9080356,
      "lng": 35.018481566666665
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.908040800000002,
      "lng": 34.997773499999994
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90805778333333,
      "lng": 35.006386325
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.908066249999997,
      "lng": 35.0052692
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.908067166666665,
      "lng": 34.996331
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908083549999997,
      "lng": 35.00579445
    },
    "signal": -77.85
  },
  {
    "location": {
      "lat": 31.908091542857147,
      "lng": 35.00622668571429
    },
    "signal": -74.65
  },
  {
    "location": {
      "lat": 31.90811446666667,
      "lng": 35.0061763
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.908121,
      "lng": 34.9908197
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.908122525000003,
      "lng": 35.006028125
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.90812526666667,
      "lng": 34.99767403333333
    },
    "signal": -75.45
  },
  {
    "location": {
      "lat": 31.90813370526315,
      "lng": 35.0056213631579
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9081574,
      "lng": 34.99615185
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.90815884,
      "lng": 35.01839864
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.908174137499998,
      "lng": 35.006573
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.908179999999998,
      "lng": 34.990872839999994
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.9081983,
      "lng": 35.00536879999999
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.9082108,
      "lng": 34.99756409999999
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.908243575,
      "lng": 35.005840975
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.90825036,
      "lng": 34.99594618
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908332725,
      "lng": 34.997390025
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.908336233333333,
      "lng": 34.9957341
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.90836138,
      "lng": 35.00651784
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.908361859999996,
      "lng": 35.00550688
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.9083639,
      "lng": 35.018254675
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.90836866666667,
      "lng": 35.005876933333326
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.908386359999998,
      "lng": 35.00569108
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.908391166666664,
      "lng": 34.99558296666666
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908392499999998,
      "lng": 35.006468174999995
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.908424033333336,
      "lng": 34.99105326666666
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9084412,
      "lng": 34.9954315
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.9084506,
      "lng": 34.9972044
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.90849575,
      "lng": 34.995250425
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908508599999994,
      "lng": 35.00567718333333
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.908522166666668,
      "lng": 35.00604736666667
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908534466666666,
      "lng": 34.99113966666666
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.908547466666665,
      "lng": 34.99702943333333
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.908551699999997,
      "lng": 34.99505846
    },
    "signal": -78.6
  },
  {
    "location": {
      "lat": 31.90855794,
      "lng": 35.00643338
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.908559075,
      "lng": 35.018123900000006
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.908575985714286,
      "lng": 35.00649934285714
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.908606149999997,
      "lng": 35.005775199999995
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.90862783333333,
      "lng": 34.99473736666667
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90866165,
      "lng": 34.9967949
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.908661766666665,
      "lng": 35.00621566666667
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908673925,
      "lng": 34.994491725
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.908691757142858,
      "lng": 35.00586137142857
    },
    "signal": -79.95
  },
  {
    "location": {
      "lat": 31.908699666666667,
      "lng": 34.994309933333334
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90870625714286,
      "lng": 35.01802972857143
    },
    "signal": -76.6
  },
  {
    "location": {
      "lat": 31.908720200000005,
      "lng": 34.994156833333335
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.9087204,
      "lng": 34.99129258
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.90873642222222,
      "lng": 35.0063652
    },
    "signal": -73.85
  },
  {
    "location": {
      "lat": 31.908742175,
      "lng": 34.994000075
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.9087507,
      "lng": 35.0064959
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.908760133333335,
      "lng": 34.993847733333325
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90876798,
      "lng": 35.005951499999995
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.90877595,
      "lng": 34.996508250000005
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.908787942857145,
      "lng": 34.993629600000006
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.908818300000004,
      "lng": 34.9963716
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.90884026666667,
      "lng": 34.99323033333334
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90884803333334,
      "lng": 34.99627743333333
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.9088577,
      "lng": 35.006458200000004
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.90886001666667,
      "lng": 35.00607723333333
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.908860466666663,
      "lng": 34.9930747
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9088733,
      "lng": 35.0065558
    },
    "signal": -71.95
  },
  {
    "location": {
      "lat": 31.908880933333332,
      "lng": 34.992920833333336
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9088885,
      "lng": 34.9961203
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908896900000002,
      "lng": 35.006200766666666
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.9089115,
      "lng": 35.01790187499999
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9089145,
      "lng": 34.99271518
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.90891675714286,
      "lng": 34.99146768571429
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.908931799999998,
      "lng": 34.99594385
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.908959275,
      "lng": 34.992524675
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.908961700000003,
      "lng": 35.006698316666665
    },
    "signal": -72.95
  },
  {
    "location": {
      "lat": 31.908987949999997,
      "lng": 34.995838449999994
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.90901203333333,
      "lng": 34.9923636
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.909033625,
      "lng": 35.006772774999995
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.909036133333334,
      "lng": 35.017830000000004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909057999999998,
      "lng": 35.0063361
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909077383333337,
      "lng": 34.991623233333335
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909081,
      "lng": 34.99218798333333
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9090849,
      "lng": 34.995792566666665
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.909099829999995,
      "lng": 35.006910149999996
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909146675,
      "lng": 35.017764725
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909158333333334,
      "lng": 35.00650983333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.9091607,
      "lng": 34.9919917
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.909173100000004,
      "lng": 34.9917283
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909196450000003,
      "lng": 34.991822600000006
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909217924999997,
      "lng": 35.00714565
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909225066666664,
      "lng": 34.9958083
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.909251766666667,
      "lng": 35.00669753333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90929784,
      "lng": 35.0072904
    },
    "signal": -72.9
  },
  {
    "location": {
      "lat": 31.909301866666667,
      "lng": 35.01767666666666
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909341175,
      "lng": 35.00689465
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909352033333334,
      "lng": 35.00745676666666
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.90938735,
      "lng": 35.007471300000006
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.909415666666664,
      "lng": 34.99591873333333
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.909418466666665,
      "lng": 35.007099933333336
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.90942445,
      "lng": 35.013929412500005
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90942586,
      "lng": 35.00764242
    },
    "signal": -72.9
  },
  {
    "location": {
      "lat": 31.909432099999997,
      "lng": 35.01428610000001
    },
    "signal": -78.5
  },
  {
    "location": {
      "lat": 31.90943612222222,
      "lng": 35.014444677777774
    },
    "signal": -78.75
  },
  {
    "location": {
      "lat": 31.90943983125,
      "lng": 35.01356485
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90944124,
      "lng": 35.013208580000004
    },
    "signal": -77.8
  },
  {
    "location": {
      "lat": 31.909443781481485,
      "lng": 35.012903900000005
    },
    "signal": -78.1
  },
  {
    "location": {
      "lat": 31.909447018750004,
      "lng": 35.014125625000005
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909447906666664,
      "lng": 35.01301547333334
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.909454829999998,
      "lng": 35.01334989000001
    },
    "signal": -77.8
  },
  {
    "location": {
      "lat": 31.909460550000002,
      "lng": 35.0146077
    },
    "signal": -78.5
  },
  {
    "location": {
      "lat": 31.9094606,
      "lng": 35.01263482
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90946415,
      "lng": 35.01759405
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.909469474999998,
      "lng": 35.012484425
    },
    "signal": -78.95
  },
  {
    "location": {
      "lat": 31.909480828571425,
      "lng": 35.012311385714284
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909486427999994,
      "lng": 35.013767576
    },
    "signal": -78.65
  },
  {
    "location": {
      "lat": 31.9094868,
      "lng": 35.00784036666667
    },
    "signal": -72.8
  },
  {
    "location": {
      "lat": 31.909488250000003,
      "lng": 35.007309
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.90949328333333,
      "lng": 35.012134083333336
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909503349999998,
      "lng": 35.011983150000006
    },
    "signal": -78.9
  },
  {
    "location": {
      "lat": 31.909504066666663,
      "lng": 35.014807733333335
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.909509971428577,
      "lng": 35.01178995714285
    },
    "signal": -87.7
  },
  {
    "location": {
      "lat": 31.909521799999997,
      "lng": 35.011567416666665
    },
    "signal": -87.85
  },
  {
    "location": {
      "lat": 31.909524600000005,
      "lng": 35.00802215
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.90952512,
      "lng": 35.0114128
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9095287,
      "lng": 35.01123423333334
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.909536633333335,
      "lng": 35.01106516666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9095505,
      "lng": 35.014298700000005
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.90956504,
      "lng": 35.01321426
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.90956766666667,
      "lng": 35.01301376666667
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909569883333333,
      "lng": 35.01087135
    },
    "signal": -87.85
  },
  {
    "location": {
      "lat": 31.90957095,
      "lng": 35.00750995
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909572733333334,
      "lng": 35.012852333333335
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909577766666672,
      "lng": 35.0126809
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909583933333337,
      "lng": 35.0125033
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.9095911,
      "lng": 35.01232253333333
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909594025,
      "lng": 35.0107239
    },
    "signal": -87.55
  },
  {
    "location": {
      "lat": 31.90959433333333,
      "lng": 35.014626566666664
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.909596233333332,
      "lng": 35.01480443333333
    },
    "signal": -76.5
  },
  {
    "location": {
      "lat": 31.90959863333333,
      "lng": 35.01214016666666
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909601275,
      "lng": 35.017531025000004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.90960286666667,
      "lng": 35.01355026666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.90960708888889,
      "lng": 35.01054486666666
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.90960846666667,
      "lng": 35.01195283333333
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909608833333337,
      "lng": 35.01498936666667
    },
    "signal": -75.8
  },
  {
    "location": {
      "lat": 31.90960936,
      "lng": 35.011199579999996
    },
    "signal": -84.9
  },
  {
    "location": {
      "lat": 31.909611650000002,
      "lng": 34.996017275
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.9096182,
      "lng": 35.008398549999995
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.909623699999997,
      "lng": 35.008592959999994
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.909624316666665,
      "lng": 35.00982935
    },
    "signal": -96.4
  },
  {
    "location": {
      "lat": 31.909625160000008,
      "lng": 35.010359040000004
    },
    "signal": -87.7
  },
  {
    "location": {
      "lat": 31.90962581428571,
      "lng": 35.008204971428576
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.909627233333335,
      "lng": 35.011010516666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.909628199999997,
      "lng": 35.007722799999996
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909629799999998,
      "lng": 35.009655942857144
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909634125,
      "lng": 35.009284324999996
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.9096344,
      "lng": 35.011379175
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909634628571432,
      "lng": 35.01018575714286
    },
    "signal": -87.75
  },
  {
    "location": {
      "lat": 31.909637,
      "lng": 35.009160200000004
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909637033333336,
      "lng": 35.008784283333334
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909638800000003,
      "lng": 35.01156355
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.909638900000004,
      "lng": 35.0079792
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.909641475,
      "lng": 35.0100060875
    },
    "signal": -95.8
  },
  {
    "location": {
      "lat": 31.909641500000003,
      "lng": 35.00945182857142
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.90964485,
      "lng": 35.0089755
    },
    "signal": -95.7
  },
  {
    "location": {
      "lat": 31.909653414285717,
      "lng": 35.01515707142857
    },
    "signal": -75.8
  },
  {
    "location": {
      "lat": 31.909720399999998,
      "lng": 35.015330899999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9097243,
      "lng": 35.0149424
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.909734900000004,
      "lng": 35.01747387500001
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9097395,
      "lng": 35.00836835
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.90973955,
      "lng": 35.00961465
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.909747633333335,
      "lng": 35.00858483333334
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909749499999997,
      "lng": 35.00941325
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.90974986666667,
      "lng": 35.009214199999995
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.9097581,
      "lng": 35.0090072
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.9097589,
      "lng": 35.00879593333333
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.909803524999997,
      "lng": 35.01537790625
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.9098055,
      "lng": 35.01351079999999
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.909818925,
      "lng": 35.015157075
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9098207,
      "lng": 34.996062949999995
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.909834156521697,
      "lng": 35.01735745304354
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.909887760000004,
      "lng": 35.01554188
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.909913300000003,
      "lng": 35.013500799999996
    },
    "signal": -78.7
  },
  {
    "location": {
      "lat": 31.909954771428566,
      "lng": 35.01559571428571
    },
    "signal": -75.8
  },
  {
    "location": {
      "lat": 31.909973550000004,
      "lng": 35.013457956249994
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.90999135714286,
      "lng": 35.01736172857143
    },
    "signal": -72.4
  },
  {
    "location": {
      "lat": 31.909991966666666,
      "lng": 35.015426966666666
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.910001150000003,
      "lng": 34.996070950000004
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.9100364,
      "lng": 35.01573125714285
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.910099666666667,
      "lng": 35.01556023333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9101551,
      "lng": 35.015722725
    },
    "signal": -76.7
  },
  {
    "location": {
      "lat": 31.910182600000002,
      "lng": 35.01729693333333
    },
    "signal": -78.7
  },
  {
    "location": {
      "lat": 31.910193649999997,
      "lng": 34.996076375
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.91020247777778,
      "lng": 35.015888499999996
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.910307400000004,
      "lng": 35.01580453333333
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.910351471428573,
      "lng": 35.015957
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.910359866666667,
      "lng": 35.016074933333336
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.91036588571429,
      "lng": 35.01722507142858
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.910390049999997,
      "lng": 34.9960878
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.910397525,
      "lng": 35.0132303875
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.910428999999997,
      "lng": 35.0162014
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.910502100000002,
      "lng": 35.0160016
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.9105057,
      "lng": 35.01314655
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.910505942857146,
      "lng": 35.017176757142856
    },
    "signal": -78.85
  },
  {
    "location": {
      "lat": 31.91051766363636,
      "lng": 35.01625828181818
    },
    "signal": -77.8
  },
  {
    "location": {
      "lat": 31.91052225,
      "lng": 34.99609785
    },
    "signal": -67
  },
  {
    "location": {
      "lat": 31.91052693333334,
      "lng": 35.01612337777778
    },
    "signal": -75.75
  },
  {
    "location": {
      "lat": 31.910529871428572,
      "lng": 35.013391614285716
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.91054905,
      "lng": 35.013097200000004
    },
    "signal": -82
  },
  {
    "location": {
      "lat": 31.91056575,
      "lng": 35.01637505000001
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.910677514285716,
      "lng": 35.0171169
    },
    "signal": -78.7
  },
  {
    "location": {
      "lat": 31.910695480000005,
      "lng": 35.016284580000004
    },
    "signal": -81.4
  },
  {
    "location": {
      "lat": 31.910700966666667,
      "lng": 34.996110566666665
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.91071394,
      "lng": 35.01320196
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.910727727272718,
      "lng": 35.016445636363635
    },
    "signal": -85.9
  },
  {
    "location": {
      "lat": 31.910729955555553,
      "lng": 35.013350977777776
    },
    "signal": -80.95
  },
  {
    "location": {
      "lat": 31.910734214285718,
      "lng": 35.01658842857143
    },
    "signal": -86.5
  },
  {
    "location": {
      "lat": 31.910734950000002,
      "lng": 35.013548575
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.910779499999997,
      "lng": 35.017058875000004
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.910859162499996,
      "lng": 35.016496275
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.91086973333333,
      "lng": 35.01697528333333
    },
    "signal": -83.2
  },
  {
    "location": {
      "lat": 31.910874300000003,
      "lng": 35.01303275
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.910874700000004,
      "lng": 35.013759933333326
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.910877575000004,
      "lng": 35.016639618750006
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.910879133333335,
      "lng": 35.01679665555556
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.910930633333333,
      "lng": 34.99613683333333
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.910931450000003,
      "lng": 35.01288256666667
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.9109569,
      "lng": 35.0139104
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911040625,
      "lng": 35.0139861
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9110413,
      "lng": 35.0166673
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.911062175,
      "lng": 35.01679745
    },
    "signal": -78.85
  },
  {
    "location": {
      "lat": 31.9110711,
      "lng": 34.99614926666666
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.91107823333334,
      "lng": 35.01286715
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.911081485714288,
      "lng": 35.01700232857143
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.91113285,
      "lng": 35.0140801
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911205574999997,
      "lng": 35.014648125
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.911213500000002,
      "lng": 34.9961618
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.9112165,
      "lng": 35.01714368333333
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.91124125,
      "lng": 35.01485115
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.91125766666666,
      "lng": 35.01408456666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.91125804,
      "lng": 35.014492419999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9112589,
      "lng": 35.0126516
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.911260533333337,
      "lng": 35.0168744
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911305466666665,
      "lng": 35.0150155
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.9113939,
      "lng": 35.01440655
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.911396999999997,
      "lng": 35.01412633333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.911415975,
      "lng": 35.014290925000005
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.911419542857143,
      "lng": 34.99617802857143
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.911422628571426,
      "lng": 35.017152728571425
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.91143658333333,
      "lng": 35.01687485
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911439124999994,
      "lng": 35.015126325000004
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.911444816666663,
      "lng": 35.01265003333333
    },
    "signal": -87.35
  },
  {
    "location": {
      "lat": 31.9116037375,
      "lng": 35.0171105625
    },
    "signal": -79
  },
  {
    "location": {
      "lat": 31.911610042857145,
      "lng": 35.016852657142856
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911610539999998,
      "lng": 35.015226080000005
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.911636833333336,
      "lng": 35.01266116666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.91164475,
      "lng": 34.996194125
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.911669899999996,
      "lng": 35.0152843
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.911773042857153,
      "lng": 35.01682384285714
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9117763,
      "lng": 35.0126386
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.91178345,
      "lng": 35.0153444
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.911788700000002,
      "lng": 34.99620693333333
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.911790025000002,
      "lng": 35.017052750000005
    },
    "signal": -78.7
  },
  {
    "location": {
      "lat": 31.91192975,
      "lng": 34.9962156
    },
    "signal": -64
  },
  {
    "location": {
      "lat": 31.911933416666667,
      "lng": 35.01678295
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.911951157142855,
      "lng": 35.01702748571429
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.911954950000002,
      "lng": 35.015445150000005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.912004299999996,
      "lng": 35.01248486666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9120817,
      "lng": 35.01673595
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.912120166666664,
      "lng": 35.015523333333334
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.912132225,
      "lng": 35.0123252
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9121438,
      "lng": 34.99625904999999
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912168509090904,
      "lng": 35.0169564
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.91221958,
      "lng": 35.01214444
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.912230233333332,
      "lng": 35.016705333333334
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9122617,
      "lng": 35.016949125
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.91230395,
      "lng": 35.0155855
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.91230828333333,
      "lng": 35.01968866666667
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.9123403,
      "lng": 35.01930938333333
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912341033333334,
      "lng": 35.011959600000004
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.912343800000002,
      "lng": 35.01948868
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912345533333333,
      "lng": 34.99631493333334
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912346000000003,
      "lng": 35.01988502
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912350850000003,
      "lng": 35.0200409
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912360033333332,
      "lng": 35.01915936666667
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912369671428568,
      "lng": 35.016821285714286
    },
    "signal": -71.9
  },
  {
    "location": {
      "lat": 31.91238035,
      "lng": 35.011751025
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.91238045,
      "lng": 35.018791750000005
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.91238155,
      "lng": 35.018945175
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.912466185714287,
      "lng": 35.01877611428571
    },
    "signal": -76.55
  },
  {
    "location": {
      "lat": 31.912467618181825,
      "lng": 35.01701434545455
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.9124685,
      "lng": 35.015614740000004
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.912469899999994,
      "lng": 34.99636356666667
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912474682352943,
      "lng": 35.0186281117647
    },
    "signal": -76.4
  },
  {
    "location": {
      "lat": 31.91248482857143,
      "lng": 35.01683704285715
    },
    "signal": -71.9
  },
  {
    "location": {
      "lat": 31.912494249999998,
      "lng": 35.01990705
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.912495766666666,
      "lng": 35.01165116666667
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.912496078947374,
      "lng": 35.01713302105263
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.91249788,
      "lng": 35.01826808
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.912504125,
      "lng": 35.018391525
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.912516600000004,
      "lng": 35.011427766666664
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9125217,
      "lng": 35.0189757
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.91252246,
      "lng": 35.01808562
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.91253981666667,
      "lng": 35.017887800000004
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.912540087499995,
      "lng": 35.0173537625
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.9125405,
      "lng": 35.01565
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.91254324,
      "lng": 35.017515079999995
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.912557075000002,
      "lng": 35.0177095625
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.9125636,
      "lng": 35.0191566
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.912565450000002,
      "lng": 35.019349000000005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.91257305,
      "lng": 35.01952215
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.912574533333338,
      "lng": 34.99641866666666
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.9125761,
      "lng": 35.01969626666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.912578,
      "lng": 35.0112534
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.912631233333332,
      "lng": 35.01847946666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.912659266666665,
      "lng": 34.99647923333333
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912663449999997,
      "lng": 35.018290050000004
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.912666666666667,
      "lng": 35.016782733333336
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.912683125,
      "lng": 35.011060674999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.912685580000005,
      "lng": 35.01567494
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.9126905,
      "lng": 35.0180996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9127005,
      "lng": 35.0179289
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91270625,
      "lng": 35.01775415
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91272856666667,
      "lng": 35.017535933333335
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.912733233333338,
      "lng": 35.01089416666667
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9127717,
      "lng": 35.0107523
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9127794,
      "lng": 34.9965768
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912812199999998,
      "lng": 35.0172999
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9128228,
      "lng": 35.01674357500001
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.912844099999997,
      "lng": 35.0106795
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.912860583333327,
      "lng": 35.01569326666666
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.91287975,
      "lng": 34.9966647
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.912885533333334,
      "lng": 35.0171875
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91289736666667,
      "lng": 35.0105467
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9129268,
      "lng": 35.01670985
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9129313,
      "lng": 35.01036825
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.91299075,
      "lng": 34.99675429999999
    },
    "signal": -59
  },
  {
    "location": {
      "lat": 31.913020525,
      "lng": 35.010279499999996
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.913045771428568,
      "lng": 35.015693985714286
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.913050133333332,
      "lng": 35.0170102
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91306255,
      "lng": 35.00729475
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.913069649999997,
      "lng": 35.016670250000004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.913072833333334,
      "lng": 34.996841233333335
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913089833333334,
      "lng": 35.01015316666666
    },
    "signal": -90
  },
  {
    "location": {
      "lat": 31.9130941,
      "lng": 35.007178425
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.9131043,
      "lng": 35.0099705
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9131233,
      "lng": 35.00979655
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9131612,
      "lng": 34.9969338
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913171366666663,
      "lng": 35.00691200000001
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.91317835,
      "lng": 35.0095942
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9132137,
      "lng": 35.0094448
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.913217766666666,
      "lng": 35.016628833333336
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.913233975,
      "lng": 35.006683675000005
    },
    "signal": -99
  },
  {
    "location": {
      "lat": 31.913238683333333,
      "lng": 35.015668399999996
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.91325155,
      "lng": 35.00929015
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.91325416666666,
      "lng": 34.99703063333333
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913266333333336,
      "lng": 35.00656995
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913274550000004,
      "lng": 35.0168571
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.913276300000003,
      "lng": 35.009142600000004
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.9133043,
      "lng": 35.00644655
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913346580000002,
      "lng": 35.00631560000001
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913359966666665,
      "lng": 35.0088835
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.913371533333333,
      "lng": 35.01657826666667
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9133906,
      "lng": 35.006187325
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913403350000003,
      "lng": 35.008694199999994
    },
    "signal": -86
  },
  {
    "location": {
      "lat": 31.913409780000002,
      "lng": 34.99718792
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913417250000002,
      "lng": 35.0167933
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.913423359999996,
      "lng": 35.015609260000005
    },
    "signal": -65
  },
  {
    "location": {
      "lat": 31.913444,
      "lng": 35.0085425
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913466800000002,
      "lng": 35.0083923
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913468800000004,
      "lng": 35.0059905
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913520899999998,
      "lng": 35.016757500000004
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9135268,
      "lng": 35.008215750000005
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913543866666668,
      "lng": 35.0058176
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913563699999997,
      "lng": 34.997298
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913568850000004,
      "lng": 35.00804504999999
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913582960000003,
      "lng": 35.016499880000005
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.913589920000003,
      "lng": 35.015563
    },
    "signal": -65.95
  },
  {
    "location": {
      "lat": 31.9136105,
      "lng": 35.00787545
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.9136329,
      "lng": 35.016710599999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.913635033333335,
      "lng": 35.00563865
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.913656366666668,
      "lng": 35.00766733333333
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.9137068,
      "lng": 35.007479200000006
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913736,
      "lng": 35.00547365
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.91375686,
      "lng": 34.99740078
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.91376444,
      "lng": 35.01551454
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.91376535,
      "lng": 35.0072457
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913773550000002,
      "lng": 35.01665535000001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.913786974999997,
      "lng": 35.016420425
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9137998,
      "lng": 35.00710765
    },
    "signal": -93
  },
  {
    "location": {
      "lat": 31.913820400000002,
      "lng": 35.00534306
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.9138426,
      "lng": 35.00692753333333
    },
    "signal": -92.9
  },
  {
    "location": {
      "lat": 31.913922999999997,
      "lng": 35.015465500000005
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.91393606666667,
      "lng": 35.01659086666667
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.9139378,
      "lng": 34.9974551
    },
    "signal": -62
  },
  {
    "location": {
      "lat": 31.913947540000002,
      "lng": 35.006724580000004
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.913960766666673,
      "lng": 35.016345666666666
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.9139729,
      "lng": 35.005139575
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.913979233333333,
      "lng": 35.006536266666664
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9139808,
      "lng": 35.01540805
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.91399465,
      "lng": 35.006374199999996
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9140122,
      "lng": 35.0062452
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914067925,
      "lng": 35.00617545
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914082800000003,
      "lng": 35.006019300000006
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914122383333332,
      "lng": 35.004955483333326
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.914125600000002,
      "lng": 35.01655960000001
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91413326,
      "lng": 35.01532178
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.914136233333334,
      "lng": 35.01626161666667
    },
    "signal": -72.75
  },
  {
    "location": {
      "lat": 31.91413826666667,
      "lng": 34.997454749999996
    },
    "signal": -70.55
  },
  {
    "location": {
      "lat": 31.914146125,
      "lng": 35.00584025
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9141724,
      "lng": 35.00568123333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914205199999998,
      "lng": 35.0055608
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914261099999997,
      "lng": 35.004801400000005
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.914264600000003,
      "lng": 35.00546808
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9142891,
      "lng": 35.0165445
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91429868333334,
      "lng": 35.01520543333333
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.9143212,
      "lng": 34.997412233333336
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.91435526666666,
      "lng": 35.00529383333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914397499999996,
      "lng": 35.0046855
    },
    "signal": -85
  },
  {
    "location": {
      "lat": 31.914431050000005,
      "lng": 35.01512185
    },
    "signal": -66
  },
  {
    "location": {
      "lat": 31.91443602,
      "lng": 35.016156200000005
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.914463899999998,
      "lng": 35.0165509
    },
    "signal": -76.85
  },
  {
    "location": {
      "lat": 31.9144753,
      "lng": 34.99735172
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.9144816,
      "lng": 35.00511546
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.9145078,
      "lng": 35.004565885714285
    },
    "signal": -84.6
  },
  {
    "location": {
      "lat": 31.914513550000002,
      "lng": 35.01618535
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.914515875,
      "lng": 35.01503805
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.914633724999998,
      "lng": 35.016572475
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9146431,
      "lng": 35.014963140000006
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.914652266666668,
      "lng": 34.997278099999996
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.914661199999998,
      "lng": 35.00492353333333
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914677799999996,
      "lng": 35.00442533333333
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.91468874705881,
      "lng": 35.016298129411744
    },
    "signal": -69.95
  },
  {
    "location": {
      "lat": 31.914811333333336,
      "lng": 35.00433773333334
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.914811633333333,
      "lng": 34.99722108333333
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.914828875,
      "lng": 35.016216
    },
    "signal": -68.7
  },
  {
    "location": {
      "lat": 31.914829866666665,
      "lng": 35.004811133333334
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.914833942857147,
      "lng": 35.01482642857143
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.914895350000002,
      "lng": 35.016464
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.914902933333337,
      "lng": 35.00428076666666
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.9149376,
      "lng": 35.0147696
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.91501196666667,
      "lng": 34.99715253333333
    },
    "signal": -71
  },
  {
    "location": {
      "lat": 31.915024600000006,
      "lng": 35.0164102
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9150339,
      "lng": 35.0047108
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.91503733333333,
      "lng": 35.01466776666666
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.915046079999996,
      "lng": 35.00420738
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.915052924999998,
      "lng": 35.016276899999994
    },
    "signal": -75.9
  },
  {
    "location": {
      "lat": 31.915102899999997,
      "lng": 35.004672799999994
    },
    "signal": -91
  },
  {
    "location": {
      "lat": 31.915180499999998,
      "lng": 35.0145754
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.915208099999997,
      "lng": 34.99709566666667
    },
    "signal": -70.85
  },
  {
    "location": {
      "lat": 31.915231716666668,
      "lng": 35.0046415
    },
    "signal": -90.6
  },
  {
    "location": {
      "lat": 31.9153217,
      "lng": 35.014417699999996
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.915389499999996,
      "lng": 35.00405566
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.915417975,
      "lng": 35.004589975
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91542895,
      "lng": 35.01429635
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915439974999998,
      "lng": 34.9970734
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.915504133333332,
      "lng": 35.01422903333333
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915554,
      "lng": 35.00398306666667
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.915591799999998,
      "lng": 35.00455109999999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915599400000005,
      "lng": 34.99708985
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.915600833333333,
      "lng": 35.014131266666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915707700000002,
      "lng": 35.00451076666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91572085,
      "lng": 35.0039228
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.915738325000003,
      "lng": 35.013964875
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91576136666667,
      "lng": 34.99714446666667
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.9158174,
      "lng": 35.0044461
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915837800000002,
      "lng": 35.0138732
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9159049,
      "lng": 34.997249983333326
    },
    "signal": -68
  },
  {
    "location": {
      "lat": 31.915914400000002,
      "lng": 35.00443358
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915916266666667,
      "lng": 35.013713966666664
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.915932200000004,
      "lng": 35.003831733333335
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9160309,
      "lng": 35.0135782
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91605988,
      "lng": 34.99742412
    },
    "signal": -72.75
  },
  {
    "location": {
      "lat": 31.916092625,
      "lng": 35.013414425
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9161319,
      "lng": 35.00433603333334
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916146299999998,
      "lng": 35.00374763333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.91615383333333,
      "lng": 34.99758103333333
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916169733333334,
      "lng": 35.01327456666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91621495,
      "lng": 34.997739425000006
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.91626335,
      "lng": 34.997948675
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916284899999997,
      "lng": 35.004261199999995
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916286675000002,
      "lng": 35.003692349999994
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.916289433333333,
      "lng": 34.9981284
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916296700000004,
      "lng": 35.0130538
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916306966666667,
      "lng": 34.99827913333334
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916325100000005,
      "lng": 34.9984375
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916345366666665,
      "lng": 34.998600100000004
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.91636836666667,
      "lng": 34.998758966666664
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.916391599999997,
      "lng": 34.99891
    },
    "signal": -73
  },
  {
    "location": {
      "lat": 31.91641075,
      "lng": 34.9990331
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91641796666666,
      "lng": 35.00361663333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.916419940000004,
      "lng": 35.01285051999999
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916433425,
      "lng": 34.999217425
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9164389,
      "lng": 35.004176666666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916446525,
      "lng": 34.999346824999996
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916466075000002,
      "lng": 34.999542775
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916478149999996,
      "lng": 34.999722750000004
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916482560000002,
      "lng": 35.012682
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916500314285713,
      "lng": 34.999888885714284
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916525000000004,
      "lng": 35.00008046666667
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.916540766666667,
      "lng": 35.000200400000004
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91654103333333,
      "lng": 35.00352646666666
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9165741,
      "lng": 35.000440600000005
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9165936,
      "lng": 35.00061069999999
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9166,
      "lng": 35.01250415
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916620833333337,
      "lng": 35.00080755
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.916635739999997,
      "lng": 35.004049859999995
    },
    "signal": -88.7
  },
  {
    "location": {
      "lat": 31.916648666666664,
      "lng": 35.0010257
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.916668299999998,
      "lng": 35.001182566666664
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.91668833333333,
      "lng": 35.00134436666667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.916698075,
      "lng": 35.012337224999996
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.9167141,
      "lng": 35.001505949999995
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.916722633333332,
      "lng": 35.0016667
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.916757366666662,
      "lng": 35.0033053
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.9167611,
      "lng": 35.00189088571429
    },
    "signal": -71.7
  },
  {
    "location": {
      "lat": 31.91677095,
      "lng": 35.01227515
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916793599999995,
      "lng": 35.002062566666666
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.916844366666666,
      "lng": 35.00317643333333
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.916853980000003,
      "lng": 35.0121258
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.916861833333332,
      "lng": 35.00389273333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.916877275,
      "lng": 35.0022327
    },
    "signal": -69
  },
  {
    "location": {
      "lat": 31.916906,
      "lng": 35.002448799999996
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.916922999999997,
      "lng": 35.0120955
    },
    "signal": -88
  },
  {
    "location": {
      "lat": 31.91696543333333,
      "lng": 35.002941533333335
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.916973666666667,
      "lng": 35.0038015
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.91697381666667,
      "lng": 35.00230593333333
    },
    "signal": -73.75
  },
  {
    "location": {
      "lat": 31.91699605,
      "lng": 35.011924799999996
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91699706,
      "lng": 35.00244822
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.917010533333336,
      "lng": 35.002818633333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.917041233333332,
      "lng": 35.00258901666666
    },
    "signal": -73.9
  },
  {
    "location": {
      "lat": 31.91706285,
      "lng": 35.0037273
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.917124266666665,
      "lng": 35.01176533333334
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.917149979999998,
      "lng": 35.00225776
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.917184933333335,
      "lng": 35.002576499999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.91721323333333,
      "lng": 35.00357496666667
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.917259025,
      "lng": 35.00239835
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.917310933333336,
      "lng": 35.01155163333333
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91737016666666,
      "lng": 35.00248680000001
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.917381185714284,
      "lng": 35.002621271428566
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.917385279999998,
      "lng": 35.0114457
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9174344,
      "lng": 35.00334943333333
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.917505999999996,
      "lng": 35.003267525
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.9175297875,
      "lng": 35.0026032625
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.917552716666666,
      "lng": 35.011246166666666
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.91757,
      "lng": 35.003145
    },
    "signal": -89
  },
  {
    "location": {
      "lat": 31.91759355,
      "lng": 35.002736500000005
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.917606199999998,
      "lng": 35.0030441
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.917696899999996,
      "lng": 35.002628075000004
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.917714042857146,
      "lng": 35.01105178571429
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.917726179999995,
      "lng": 35.00297388
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.917761888888883,
      "lng": 35.00278282222222
    },
    "signal": -77.2
  },
  {
    "location": {
      "lat": 31.917842133333334,
      "lng": 35.00286006666666
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.917865425,
      "lng": 35.010845724999996
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.917928149999998,
      "lng": 35.002941449999994
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.91797273333333,
      "lng": 35.01074653333333
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.918075133333332,
      "lng": 35.010642999999995
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.918078949999998,
      "lng": 35.002986075
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.91822645,
      "lng": 35.00303434999999
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.9182507125,
      "lng": 35.01046128750001
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.91830285,
      "lng": 35.003124074999995
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918365300000005,
      "lng": 35.01032145
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.918441850000004,
      "lng": 35.01019181666667
    },
    "signal": -84
  },
  {
    "location": {
      "lat": 31.918444049999998,
      "lng": 35.00314639999999
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.9185493,
      "lng": 35.0032045
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918554280000002,
      "lng": 35.00727875
    },
    "signal": -76
  },
  {
    "location": {
      "lat": 31.91857918888889,
      "lng": 35.007458255555555
    },
    "signal": -75.9
  },
  {
    "location": {
      "lat": 31.918584185714284,
      "lng": 35.00711552857143
    },
    "signal": -75.95
  },
  {
    "location": {
      "lat": 31.918594959999997,
      "lng": 35.00996406
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.918604983333335,
      "lng": 35.00328758333333
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918640636363637,
      "lng": 35.00692630909091
    },
    "signal": -75.9
  },
  {
    "location": {
      "lat": 31.918658814285717,
      "lng": 35.00763291428571
    },
    "signal": -75
  },
  {
    "location": {
      "lat": 31.918693875,
      "lng": 35.006757650000004
    },
    "signal": -76.9
  },
  {
    "location": {
      "lat": 31.918701466666665,
      "lng": 35.00980123333333
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.91872583333333,
      "lng": 35.00672076666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.9187333,
      "lng": 35.0076991
    },
    "signal": -75.95
  },
  {
    "location": {
      "lat": 31.918766119999997,
      "lng": 35.0065787
    },
    "signal": -76.7
  },
  {
    "location": {
      "lat": 31.9187911,
      "lng": 35.003352750000005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918798483333337,
      "lng": 35.00961368333333
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.9188038625,
      "lng": 35.00777455
    },
    "signal": -75.95
  },
  {
    "location": {
      "lat": 31.918835925,
      "lng": 35.0063834875
    },
    "signal": -76.35
  },
  {
    "location": {
      "lat": 31.918871350000003,
      "lng": 35.006277499999996
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.91887566666667,
      "lng": 35.009424233333334
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.91892843333334,
      "lng": 35.0092669
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.91895165,
      "lng": 35.009089025
    },
    "signal": -92
  },
  {
    "location": {
      "lat": 31.918959757142858,
      "lng": 35.006014885714286
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.918960662499998,
      "lng": 35.00784475
    },
    "signal": -80.75
  },
  {
    "location": {
      "lat": 31.9189629,
      "lng": 35.008920100000005
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918967866666673,
      "lng": 35.003535750000005
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.918976966666666,
      "lng": 35.0087195
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.918986274999998,
      "lng": 35.008530074999996
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.91900676666667,
      "lng": 35.00836673333333
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.919014899999997,
      "lng": 35.00798296666667
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.919019333333335,
      "lng": 35.00584473333333
    },
    "signal": -76.35
  },
  {
    "location": {
      "lat": 31.919024516666667,
      "lng": 35.008190983333336
    },
    "signal": -81
  },
  {
    "location": {
      "lat": 31.919099183333334,
      "lng": 35.0056329
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.919109050000003,
      "lng": 35.003689324999996
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.919146399999995,
      "lng": 35.0054806125
    },
    "signal": -75.7
  },
  {
    "location": {
      "lat": 31.919172175,
      "lng": 35.003862850000004
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.91919065,
      "lng": 35.007783849999996
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.919218722222226,
      "lng": 35.00512587777778
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.919219075,
      "lng": 35.005304175
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.919253100000002,
      "lng": 35.0039466
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9192836,
      "lng": 35.0040377
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.919311,
      "lng": 35.00421465000001
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.919324659999994,
      "lng": 35.00495739
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.91935774,
      "lng": 35.00764978
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9193603,
      "lng": 35.0044118
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9193603,
      "lng": 35.00474225000001
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.919367633333334,
      "lng": 35.00459476666666
    },
    "signal": -72
  },
  {
    "location": {
      "lat": 31.9194636,
      "lng": 35.00746289999999
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.919503675,
      "lng": 35.007281525
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.9195393,
      "lng": 35.006942225
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.919544000000002,
      "lng": 35.00504904
    },
    "signal": -70
  },
  {
    "location": {
      "lat": 31.919568880000003,
      "lng": 35.00672702000001
    },
    "signal": -83
  },
  {
    "location": {
      "lat": 31.919598774999997,
      "lng": 35.006529775
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.919653975,
      "lng": 35.006316225
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.919689225,
      "lng": 35.005079675000005
    },
    "signal": -77.6
  },
  {
    "location": {
      "lat": 31.919708566666667,
      "lng": 35.00614243333334
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.919763266666667,
      "lng": 35.00597243333333
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.919813333333334,
      "lng": 35.005816466666666
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91985466,
      "lng": 35.005127560000005
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.919857775,
      "lng": 35.005678475
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.91992288,
      "lng": 35.00549538
    },
    "signal": -74
  },
  {
    "location": {
      "lat": 31.920062025,
      "lng": 35.005097375
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.920083666666667,
      "lng": 35.00527725
    },
    "signal": -78
  },
  {
    "location": {
      "lat": 31.9201533,
      "lng": 35.0051336
    },
    "signal": -78
  }
]

module.exports = { barData1 }













