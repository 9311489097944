import Constants, {errors, mapLayerValues} from "../controller/Constants";
import {isSameLocation} from "../controller/dataOperations";
import {useUserPreferencesState} from "./useUserPreferences";
import {useCorrectionFactorCalculator} from "./useCorrectionFactor";

function reduceByHighestTechnology(accmulator, placement) {
  // decler the func inside or not? // hwo needs to be a copy????
  if (accmulator.length === 0) {
    accmulator.push(placement);
    return accmulator;
  }
  const placementTechNumber = Number(placement.technology[0]);
  const accmulatorTechNumber = Number(accmulator[0].technology[0]);
  if (placementTechNumber === accmulatorTechNumber) {
    accmulator.push(placement);
  } else if (placementTechNumber > accmulatorTechNumber) {
    accmulator = [placement];
  }
  return accmulator;
}

function getHighestTechnologys(clusteredBins) {
  // decler the func inside or not?
  return clusteredBins.reduce(reduceByHighestTechnology, []);
}
function getHighestSignal(clusteredBins) {
  // decler the func inside or not? // if 2 bins have same signal??
  const sortedClusteredBins = clusteredBins.sort((a, b) => (a.signal > b.signal ? 1 : b.signal > a.signal ? -1 : 0));
  const sortedClusteredBins2 = Math.max(...clusteredBins.map((clsbin) => clsbin.signal)); // optionaly ?
  return sortedClusteredBins.slice(-1)[0]; // highest Signal // must be changed!
}
function chooseBin(bin) {
  // decler the func inside or not?
  const chosenBinsList = getHighestTechnologys(bin.clusteredBins);
  if (chosenBinsList.length === 1) return chosenBinsList[0];
  const chosenBin = getHighestSignal(chosenBinsList);
  return chosenBin;
}
function calcEMBinValue(RSSIValue) {
  return Math.pow(10, RSSIValue / 10) / 1000;
}

function calcSignalForEMForSingelBin(bin) {
  const signalEM = bin.clusteredBins.map(({signal}) => calcEMBinValue(signal)).sum() * Constants.smartCityKFactor;
  return signalEM;
}

function useClusterAndApllyFactor() {
  // Previously "binsMapperBestServer"
  const correctionFactorCalculator = useCorrectionFactorCalculator(); // correctionFactorCalculator
  const [userPreferences] = useUserPreferencesState();

  function reduceToOneList(accmulator, placement) {
    placement.bins.forEach((binOrigin) => {
      const accCopy = [...accmulator]; // shallow copy
      const binIndex = accCopy.findIndex((accmulatedBin) => isSameLocation(accmulatedBin.location, binOrigin.location));
      const signal = binOrigin.signal + correctionFactorCalculator();
      const decoratedBin = {...binOrigin, provider: placement.provider, technology: placement.technology};
      if (binIndex !== -1) {
        const bin = accCopy.splice(binIndex, 1)[0];
        bin.clusteredBins.push(decoratedBin);
        const chosenBin = chooseBin(bin);
        bin.signal = chosenBin.signal;
        bin.provider = chosenBin.provider;
        bin.technology = chosenBin.technology;
      } else {
        accmulator.push({
          clusteredBins: [decoratedBin],
          signal,
          location: binOrigin.location,
          provider: placement.provider,
          technology: placement.technology,
        });
      }
    });
    return accmulator;
  }

  const callback =
    // Should we group the dependents of useMapContentEffects together with this?
    (placements) => {
      try {
        const factoredBins = placements.reduce(reduceToOneList, []);
        if (userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP) {
          const binsAfterConversion = factoredBins.map((bin) => ({...bin, signal: calcSignalForEMForSingelBin(bin)}));
          return binsAfterConversion;
        }
        return factoredBins;
      } catch (error) {
        if (error.message === errors.NO_BINS) {
          return [];
        }
        throw error;
      }
    };

  return callback;
}

export default useClusterAndApllyFactor;
