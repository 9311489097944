import Validator from "../../controller/Validator";
import { DisplayedAntennasManager as DAM } from "../../controller/models/classes";

function validateAntennas(displayedAntennasToUpdate) {
    if (!displayedAntennasToUpdate.every(Validator.isDisplayedAntenna)) { // לשנות לפונ של המחלקה עצמה
        throw Error('error updating displayedAntennas, "displayedAntennasToUpdate" contains invalid object/s');
    }
}



export function updateDisplayedAntennas(mapState, displayedAntennasToUpdate) {
    const oldDisplayedAntennas = mapState.displayedAntennas;
    if (displayedAntennasToUpdate.length === 0) { // maby moove this check to the class // do we need this check?
        return DAM.setDisplayAll(oldDisplayedAntennas, false);
    }
    validateAntennas(displayedAntennasToUpdate);
    return displayedAntennasToUpdate;
}

