import {
  ADD_PROJECT,
  MAP_LOADED,
  RESET_PROJECTS,
  SET_DISPLAYED_ANTENNAS,
  SET_HIGHLIGHT_OPACITY,
  SET_LOCATION_STATS,
  SET_MAP_STATE,
  SET_MAP_TYPE,
  SET_MAP_ZOOM,
  SET_PREDICTION_STATE,
  SET_PROJECTS,
  SET_POLYGON_PATH,
  SET_TOTAL_COST,
  SHOW_COSTS_TABLE,
  UPDATE_DISPLAYED_ANTENNAS,
  SET_USER_ENVIRONMENT,
  SET_TIME_OF_DAY,
  SET_AVERAGE_SIGNAL,
  SET_USER_PREFERENCES,
  SET_TECHNOLOGIES_COVERAGE,
  SET_PLACEMENTS,
  SET_DATE_RANGE,
  SET_MAP_LAYER_DISPLAY_TYPE,
  PUSH_TO_PLACEMENTS,
  ARIE_COVERAGE_RELIABILITY
} from "../actionTypes";

export function setMapState(mapState) {
  return {type: SET_MAP_STATE, mapState};
}

export function setHighlightOpacity(highlightOpacity) {
  return {type: SET_HIGHLIGHT_OPACITY, highlightOpacity};
}

export function resetProjects() {
  return {type: RESET_PROJECTS};
}

export function setDisplayedAntennas(displayedAntennas) {
  return {
    type: SET_DISPLAYED_ANTENNAS,
    displayedAntennas,
  };
}

export function setTotalCost(totalCost) {
  return {
    type: SET_TOTAL_COST,
    totalCost,
  };
}

export function setProjects(projects) {
  return {
    type: SET_PROJECTS,
    projects,
  };
}

export function setMapType(mapType) {
  return {
    type: SET_MAP_TYPE,
    mapType,
  };
}

export function setMapZoomCreator(mapZoom) {
  return {
    type: SET_MAP_ZOOM,
    mapZoom,
  };
}

export function mapLoadedCreator(loaded) {
  return {
    type: MAP_LOADED,
    loaded,
  };
}

export function setPredictionState(predictionState) {
  return {
    type: SET_PREDICTION_STATE,
    predictionState,
  };
}

export function setLocationsStats(locationStats) {
  return {
    type: SET_LOCATION_STATS,
    locationStats,
  };
}

export function showCostsTable(isCostsTable) {
  return {
    type: SHOW_COSTS_TABLE,
    isCostsTable,
  };
}

export function addProject(project) {
  return {
    type: ADD_PROJECT,
    project,
  };
}

export function setPolygonPath(polygonPath) {
  return {
    type: SET_POLYGON_PATH,
    polygonPath,
  };
}

export function updateDisplayedAntennas(displayedAntennas) {
  return {
    type: UPDATE_DISPLAYED_ANTENNAS,
    displayedAntennas,
  };
}

export function setUserEnvironment(userEnvironment) {
  return {
    type: SET_USER_ENVIRONMENT,
    userEnvironment,
  };
}

export function setTimeOfDay(timeOfDay) {
  return {
    type: SET_TIME_OF_DAY,
    timeOfDay,
  };
}

export function setTechnologiesCoverage(technologiesCoverage) {
  return {
    type: SET_TECHNOLOGIES_COVERAGE,
    technologiesCoverage,
  };
}

export function setAverageSignal(averageSignal) {
  return {
    type: SET_AVERAGE_SIGNAL,
    averageSignal,
  };
}

export function setUserPreferences(userPreferences) {
  return {
    type: SET_USER_PREFERENCES,
    userPreferences,
  };
}

export function setPlacements(placements) {
  return {
    type: SET_PLACEMENTS,
    placements,
  };
}

export function pushToPlacements(placements) {
  return {
    type: PUSH_TO_PLACEMENTS,
    placements,
  };
}

export function setArieCoverageReliability(ArieCoverageReliability) {
  return {
    type: ARIE_COVERAGE_RELIABILITY,
    ArieCoverageReliability,
  };
}
