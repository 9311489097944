export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LINES = "SET_LINES";
export const SET_COVERAGE = "SET_COVERAGE";
export const SET_CURRENT_DIALOG = "SET_CURRENT_DIALOG";
export const MAP_LOADED = "MAP_LOADED";
export const ADD_TOAST = "ADD_TOAST";
export const DELETE_TOAST = "DELETE_TOAST";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_POLYGON_PATH = "SET_POLYGON_PATH";
export const RESET_PROJECTS = "RESET_PROJECTS";
export const SET_DIALOG_SITE = "SET_DIALOG_SITE";
export const ADD_PROJECT = "ADD_PROJECT";
export const SET_LOCAL_PROJECT = "SET_LOCAL_PROJECT";
export const SET_TOTAL_COST = "SET_TOTAL_COST";
export const SHOW_COSTS_TABLE = "SHOW_COSTS_TABLE";
export const SET_PROJECT = "SET_PROJECT";
export const RESET_PROJECT = "RESET_PROJECT";
export const SET_MAP_TYPE = "SET_MAP_TYPE";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_MAP_STATE = "SET_MAP_STATE";
export const SET_HIGHLIGHT_OPACITY = "SET_HIGHLIGHT_OPACITY";
export const SET_MAP_ZOOM = "SET_MAP_ZOOM";
export const SET_PREDICTION_STATE = "SET_PREDICTION_STATE";
export const SET_FETCH_LOADER = "SET_FETCH_LOADER";
export const ADD_SITE_TO_PROJECT = "ADD_SITE_TO_PROJECT";
export const UPDATE_SITE_ON_PROJECT = "UPDATE_SITE_ON_PROJECT";
export const ADD_SECTOR_TO_SITE = "ADD_SECTOR_TO_SITE";
export const UPDATE_SECTOR_TO_SITE = "UPDATE_SECTOR_TO_SITE";
export const SET_LOCATION_STATS = "SET_LOCATION_STATS";
export const SET_DISPLAYED_ANTENNAS = "SET_DISPLAYED_ANTENNAS";
export const UPDATE_DISPLAYED_ANTENNAS = "UPDATE_DISPLAYED_ANTENNAS";
export const SET_USER_ENVIRONMENT = "SET_USER_ENVIRONMENT";
export const SET_TIME_OF_DAY = "SET_TIME_OF_DAY";
export const SET_AVERAGE_SIGNAL = "SET_AVERAGE_SIGNAL";
export const SET_TECHNOLOGIES_COVERAGE = "SET_TECHNOLOGIES_COVERAGE";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_PLACEMENTS = "SET_PLACEMENTS";
export const PUSH_TO_PLACEMENTS = "PUSH_TO_PLACEMENTS";
export const ARIE_COVERAGE_RELIABILITY = "ARIE_COVERAGE_RELIABILITY";
