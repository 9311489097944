import {
  SET_PROJECT,
  RESET_PROJECT,
  ADD_TOAST,
  DELETE_TOAST,
  SET_CURRENT_DIALOG,
  SET_DIALOG_SITE,
  SHOW_COSTS_TABLE,
  SET_FETCH_LOADER,
  ADD_SITE_TO_PROJECT,
  UPDATE_SITE_ON_PROJECT,
  ADD_SECTOR_TO_SITE,
  UPDATE_SECTOR_TO_SITE,
} from "../actionTypes";
import {addToastAction, addSectorToSiteAction, addSiteToProjectAction} from "../actions/general";
import {DEFAULT_PROJECT} from "../defaultValues/general";
import Constants from "../../controller/Constants";
/*
 * currentDialog : string
 * toasts = > [{
 *   id,
 *   created,
 *   title,
 *   body,
 * }]
 * */
const DEAFAULT_STATE = {
  currentDialog: null,
  dialogSite: null,
  toasts: [], //[{id:1,created:new Date(),title:'Working', message:"So Exciting!!"}]
  isCostsTable: false,
  project: DEFAULT_PROJECT,
  isFetchLoader: false,
};

function someFunc(project) {
  throw Error("funciton not implemented");
}

const defaultReducer = (state = DEAFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_DIALOG:
      return {...state, currentDialog: action.dialog};
    case SHOW_COSTS_TABLE:
      return {...state, isCostsTable: action.isCostsTable};
    case SET_DIALOG_SITE:
      return {...state, dialogSite: action.site};
    case SET_PROJECT:
      console.log(action.project);
      return {...state, project: action.project};
    case RESET_PROJECT:
      return {...state, project: DEFAULT_PROJECT};
    case ADD_TOAST:
      return addToastAction(state, action.toastDetails);
    case DELETE_TOAST:
      return {...state, toasts: state.toasts.filter((toast) => toast.id !== action.toastId)};
    case SET_FETCH_LOADER:
      return {...state, isFetchLoader: action.isFetchLoader};
    case ADD_SITE_TO_PROJECT:
      return {...state, project: addSiteToProjectAction(state.project, action.site)};
    case UPDATE_SITE_ON_PROJECT:
      return {...state, project: someFunc(state.project)};
    case ADD_SECTOR_TO_SITE:
      return {...state, project: addSectorToSiteAction(state.project, action.siteId, action.sector)};
    case UPDATE_SECTOR_TO_SITE:
      return {...state, project: someFunc(state.project)};
    default:
      return state;
  }
};

export default defaultReducer;
