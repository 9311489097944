import React, {useCallback, useEffect, useRef, useState} from "react";
import {Polygon, useGoogleMap} from "@react-google-maps/api";
import PropTypes from "prop-types";
import {compare2Paths,} from "../../controller/common";


function PolygonWrapper({inEditMode, polygonPath, setPolygonPath, onLoad:onLoadProp}) {
  const map = useGoogleMap();
  //PolygonActions PolygonOps PolygonInput
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const mouseDrag = useRef({x: NaN, y: NaN, sameAsLast: true});

  const options = {
    fillColor: inEditMode ? "rgba(100,149,237,1)" : "rgba(205,205,205,0.8)",
    strokeColor: inEditMode ? "rgba(70,130,180,0.9)" : "rgba(205,205,205,1)",
    zIndex: inEditMode ? 11 : 0,
  };


  function alertPolygonError(toAlert) {
    if (toAlert) {
      window.alert(
        `It seems there is an error that requires a refresh....
                please refresh the page and try to drag the polygon again`
      );
    }
  }

  const onAreaChange = useCallback(
    () => {
      if (polygonRef.current) {
        const pathArray = polygonRef.current.getPath().getArray();
        if (compare2Paths(polygonPath, pathArray)) {
          alertPolygonError(!mouseDrag.current.sameAsLast && inEditMode);
          return;
        }
        setPolygonPath(pathArray);
      }
    },
    [polygonPath, inEditMode, alertPolygonError, setPolygonPath]
  );

  const handleDragEnd = useCallback(
    (ev) => {
      let pageX = ev.domEvent.x;
      let pageY = ev.domEvent.y;
      const {x, y} = mouseDrag.current;
      mouseDrag.current = {x: pageX, y: pageY, sameAsLast: pageX === x && pageY === y};
      onAreaChange();
    },
    [onAreaChange]
  );

  const handleDragStart = useCallback((ev) => {
    let pageX = ev.domEvent.x;
    let pageY = ev.domEvent.y;
    mouseDrag.current = {x: pageX, y: pageY, sameAsLast: false};
  }, []);

  const onLoad = useCallback((polygon) => {
    ///*  Defining events listeners to the polygon */
    const deleteNode = (mev) => {
      console.log("deleteNode");
      if (mev.vertex != null) {
        polygonRef.current.getPath().removeAt(mev.vertex);
        setPolygonPath(polygon.getPath().getArray());
      }
    };
    listenersRef.current.push(polygon.addListener("rightclick", deleteNode));
    polygonRef.current = polygon;
    if (typeof onLoadProp === 'function') onLoadProp(polygon);
  }, []);

  const onUnmount = React.useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    listenersRef.current = [];
    polygonRef.current = null;
  }, []);

  return <Polygon
        path={polygonPath}
        editable={inEditMode}
        draggable={inEditMode}
        onMouseUp={handleDragEnd}
        onDragEnd={handleDragEnd}
        onMouseDown={handleDragStart}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        map={map}
      />;
}

PolygonWrapper.propTypes = {
  onLoad: PropTypes.func,
  map: PropTypes.object.isRequired,
};

export default PolygonWrapper;
