import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Polygon, useGoogleMap} from "@react-google-maps/api";
import PropTypes from "prop-types";
import {latLngToPoint, pointToLatLng} from "../../controller/mapper";
import {getDefaultPolygonAccordingToMapCenter, getInitialPolygonPath, ifFuncExec} from "../../controller/common";
import Constants from "../../controller/Constants";
import {events} from "../../controller/Constants";
import EventEmitter from "../../controller/EventEmitter";
import {spreadBounds} from "../../controller/useEffectCondition";
import {usePolygonPathState} from "../../Hooks/common";
import PolygonWrapper from "./PolygonWrapper";

const {AREA_SELECTION} = Constants.mapStates;
function AreaSelectionPolygon() {
  const map = useGoogleMap();
  const center = map.getCenter();
  const bounds = map.getBounds();
  const zoom = map.getZoom();
  const [polygonPath, setPolygonPath] = usePolygonPathState();
  const {mapState} = useSelector(mapStateToProps);
  const inEditMode = mapState === AREA_SELECTION;
  const lastMapState = useRef(null);
  const boundsSpread = spreadBounds(bounds);

  useEffect(() => {
    const firstRender = boundsSpread && lastMapState.current === null && inEditMode;
    if (firstRender) {
      lastMapState.current = mapState;
      try {
        setPolygonPath(getInitialPolygonPath(center, bounds, zoom, map));
      } catch (error) {
        console.error(error);
      }
    } else if (boundsSpread) {
      lastMapState.current = mapState;
    }
  }, [center.lat, center.lng, boundsSpread].map(ifFuncExec));

  useEffect(() => {
    const {DELETE_POLYGON, RESET_POLYGON} = events;

    const deletePolygonEventId = EventEmitter.subscribe(DELETE_POLYGON, (data) => {
      setPolygonPath([]);
    });

    const resetPolygonEventId = EventEmitter.subscribe(RESET_POLYGON, (data) => {
      const newPolygonPath = getDefaultPolygonAccordingToMapCenter(map, 150);
      setPolygonPath(newPolygonPath);
    });

    return () => {
      EventEmitter.unsubscribe(DELETE_POLYGON, deletePolygonEventId);
      EventEmitter.unsubscribe(RESET_POLYGON, resetPolygonEventId);
    };
  }, []);

  return <PolygonWrapper inEditMode={inEditMode} polygonPath={polygonPath} setPolygonPath={setPolygonPath} />;
}

AreaSelectionPolygon.propTypes = {
  onLoad: PropTypes.func,
  map: PropTypes.object.isRequired,
};

function mapStateToProps(reduxState) {
  return {
    zoom: reduxState.map.mapZoom,
    mapState: reduxState.map.mapState,
  };
}

export default AreaSelectionPolygon;
