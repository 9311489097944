import React from "react";
import {useTranslation} from "react-i18next";
import Map from "./Map/index";
import styled from "styled-components";
import {connect} from "react-redux";
import {closeDialog} from "../store/actionCreators/general";
import Loader from "./Loaders/Loader";
import useInitApp from "../Hooks/useInitApp";
import MapControls from "./MapControls/MapControls";
import {ifShow} from "../controller/common";
import {setProjects} from "../store/actionCreators/map";
import {useFetchLoader, useUser} from "../Hooks/common";
import FullScreenLoader from "./Common/FullScreenLoader";
import {getTokenIdParameter} from "../controller/common"

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  position: relative;
  flex-grow: 1;
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    right: 223px !important;
    bottom: 82px !important;
  }
  //.gm-style > div:nth-child(3) > .gmnoprint{
  //  right:0 !important;
  //  top :0 !important;
  //  left: unset !important;
  //}
  button.gm-control-active.gm-fullscreen-control {
    right: 177px !important;
    top: 0 !important;
  }
`;
const H1 = styled.h1`
  text-align: center;
  width: 100%;
  padding-top: 20px;
  color: #000;
`;

const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${({theme}) => theme.palette.grey[300]};
`;

function Home() {
  const {fetching, userFetched, projectFetched, requireLogin} = useInitApp();
  const user = useUser();
  const notLoggedIn = ifShow(!user.isAuthenticated && !fetching && userFetched);
  const [isFetchLoader] = useFetchLoader();

  const getIsTermsChecked = () => {
   return user?.user?.terms === true || getTokenIdParameter("custom:isTermsChecked") === "true"
  };

  return (
    <Container>
      {ifShow(fetching) && (
        <Div>
          {" "}
          <Loader />
        </Div>
      )}
      {user.isAuthenticated && getIsTermsChecked() &&<Map />}
      {user.isAuthenticated && getIsTermsChecked() &&<MapControls projectFetched={projectFetched} requireLogin={requireLogin} />}
      {notLoggedIn && <H1>please Sign-in!</H1>}
      {user.isAuthenticated && getIsTermsChecked() && isFetchLoader && <FullScreenLoader />}
    </Container>
  );
}

function mapStateToProps(reduxState) {
  return {
    zoom: reduxState.map.mapZoom,
  };
}

export default connect(mapStateToProps, {closeDialog, setProjects})(Home);
