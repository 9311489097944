import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Api from "../controller/ApiManager";
import {dateRangeTypes, DAY_IN_MILLISECONDS} from "../controller/Constants";
import {addToast, setCurrentDialog, setFetchLoader as setFetchLoaderCreator, openDialog} from "../store/actionCreators/general";
import {
  setPlacements,
  pushToPlacements,
  setPolygonPath,
  setTechnologiesCoverage,
  setPredictionState as setPredictionStateCreator,
  setUserPreferences,
  setDateRange,
  setArieCoverageReliability,
} from "../store/actionCreators/map";
import {setUser} from "../store/actionCreators/user";
import {getCognitoId} from "./useAuthentication";
import useProject from "./useProject";
import {decodeJwtToken } from "../controller/common";
import uniqid from "uniqid";

export function useSetCurrentDialog() {
  const dispatch = useDispatch();
  return useCallback((dialogName) => {
    dispatch(setCurrentDialog(dialogName));
  });
}

export function useAddToast() {
  const dispatch = useDispatch();
  const addToastRedux = useCallback((toastMessage) => dispatch(addToast(toastMessage)), [dispatch]);
  return addToastRedux;
}

export function useUserGroups() {
  // where is useUser?
  const user = useSelector((state) => state.user.user);
  return user.groups;
}

export function usePredictionState() {
  // is there predictionState?
  const predictionState = useSelector((state) => state.map.predictionState);
  const dispatch = useDispatch();
  const setPredictionState = useCallback(
    (predictionState) => {
      dispatch(setPredictionStateCreator(predictionState));
    },
    [dispatch]
  );
  return [predictionState, setPredictionState];
}

export function useFetchLoader() {
  const isFetchLoader = useSelector((state) => state.general.isFetchLoader);
  const dispatch = useDispatch();
  const setFetchLoader = useCallback(
    (isFetchLoader) => {
      dispatch(setFetchLoaderCreator(isFetchLoader));
    },
    [dispatch]
  );
  return [isFetchLoader, setFetchLoader];
}

export function useSetFetchLoader() {
  return useFetchLoader()[1];
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function usePolygonPath() {
  return useSelector((state) => state.map.polygonPath);
}

export function usePolygonPathState() {
  const dispatch = useDispatch();
  const polygonPath = usePolygonPath();
  const setPolygonPathCB = useCallback(
    (path) => {
      dispatch(setPolygonPath(path));
    },
    [dispatch]
  );
  return [polygonPath, setPolygonPathCB];
}

export function useAlertFirstRender(message) {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      window.alert(message);
    }
  });
}

export function useSetTechnologiesCoverage() {
  const dispatch = useDispatch();
  return useCallback((technologiesCoverage) => dispatch(setTechnologiesCoverage(technologiesCoverage)), [dispatch]);
}

export function useTechnologiesCoverage() {
  return useSelector((state) => state.map.technologiesCoverage);
}

export function useSetUserRedux() {
  const dispatch = useDispatch();
  const setUserRedux = useCallback((user) => dispatch(setUser(user)), [dispatch]);
  return setUserRedux;
}

export function useSetUserToRedux() {
  const setUserRedux = useSetUserRedux();
  return useCallback(
    (idToken,refreshToken) => {      
      const decodedToken = decodeJwtToken(idToken);
      const {email, "cognito:groups": groups,"custom:isTermsChecked": checkedTerms,} = decodedToken;
      const isTermsChecked = checkedTerms === "true";
      setUserRedux({
        user: { email, groups, isTermsChecked },
        idToken,
        refreshToken, //Refresh Token is uses at the first login to save in local storge
      });
      return {isTermsChecked, email}
    },
    [setUserRedux]
  );
}

export function useUser() {
  const user = useSelector((state) => state.user);
  return user;
}

export function useOpenDialogs() {
  const dispatch = useDispatch();
  const openDialogs = useCallback((dialog) => dispatch(openDialog(dialog)), [dispatch]);
  return openDialogs;
}

export function useAverageSignal() {
  const averageSignal = useSelector((state) => state.map.averageSignal);
  return averageSignal;
}

function usePlacements() {
  const placements = useSelector((state) => state.map.placements);
  return placements;
}

function useSetPlacements() {
  const dispatch = useDispatch();
  return useCallback((placements) => dispatch(setPlacements(placements)), [dispatch]);
}

function useArieCoverageReliability() {
  const ArieCoverageReliability = useSelector((state) => state.map.ArieCoverageReliability);
  return ArieCoverageReliability;
}

function useSetArieCoverageReliability() {
  const dispatch = useDispatch();
  return useCallback((ArieCoverageReliability) => dispatch(setArieCoverageReliability(ArieCoverageReliability)), [dispatch]);
}

export function useArieCoverageReliabilityState() {
  return [useArieCoverageReliability(),useSetArieCoverageReliability()]
}

function useClearPlacements() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(setPlacements([])), [dispatch]);
}

function usePushToPlacements() {
  const dispatch = useDispatch();
  return useCallback((placements) => dispatch(pushToPlacements(placements)), [dispatch]);
}

export function usePlacementsActions() {
  return {
    placements: usePlacements(),
    clearPlacements: useClearPlacements(),
    pushToPlacements: usePushToPlacements(),
    setPlacements: useSetPlacements(),
  };
}

export function useId() {
  const id = useRef(null);
  useEffect(() => {
    id.current = uniqid();
  }, []);
  return id.current;
}

export function useEffectExceptFirst(cb, deps) {
  const first = useRef(true);
  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    return cb();
  }, deps);
}



