import urls from "../configuations/endpoints";

const Constants = {
  TRANSLATION_URL: urls.TRANSLATION_URL,
  AUTH_URL: urls.AUTH_URL,
  REST_URL: urls.REST_URL,
  //^^^^^^^^^prototypes for code completions^^^^^^^^^^
  //^^^^^^^^^will be override by urls^^^^^^^^^^
  ...urls,
  GOOGLE_MAPS_API_KEY: "AIzaSyCcw1r0WeZoTGxZDNR8ryrhHf37okOpp_U",
  EMAIL_URL: "https://aut4pawf7k.execute-api.eu-west-1.amazonaws.com/dev/",
  DEFAULT_PROJECT_SITES_IMAGE: "/assets/img/antenna.png",
  DEFAULT_STATIC_ANTENNA_MARKER: "/assets/img/antenna.png",
  SITE_IMAGE_PLACEHOLDER: "https://via.placeholder.com/150/000000/FFFFFF",
  JWT_TOKEN_ID: "token_id",
  JWT_REFRESH_TOKEN: "refresh_token",
  JWT_ACCESS_TOKEN: "access_token",
  JWT_EMAIL: "user_email",
  LOGIN_SUFFIX_URL: "login",
  REGISTER_SUFFIX_URL: "register",
  REFRESH_TOKEN_SUFFIX_URL: "refreshToken",
  EARTH_RADIUS: 6372.797,
  LAT_LNG_TO_METERS: 6372.797 * (Math.PI / 180),
  METERS_TO_LAT_LNG: (1 / 6372.797) * (Math.PI / 180),
  STAGE: process.env.REACT_APP_STAGE,
  STAGE_MODE: process.env.REACT_APP_STAGE_MODE,
  modals: {
    REGISTER: "REGISTER",
    LOGIN: "LOGIN",
    PARAMETERS: "PARAMETERS",
    ADD_PROJECT: "ADD_PROJECT",
    TABLE: "TABLE",
    PROJECT_LIST: "PROJECT_LIST",
    EDIT_PROJECT: "EDIT_PROJECT",
    EDIT_SITE: "EDIT_SITE",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    CLUSTER: "CLUSTER",
    CLUSTER_ALERT: "CLUSTER_ALERT",
    DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
    TECH_CONVERSION: "TECH_CONVERSION",
    TRY_AGAIN_GET_COVERAGE: "TRY_AGAIN_GET_COVERAGE",
    TERMS: "TERMS",
    CREATE_USER: 'CREATE_USER',
  },
  mapTypes: {
    BEST_SERVER: "BEST_SERVER",
    C2I: "C2I",
  },
  mapStates: {
    SITES_COVERAGE: "SITES_COVERAGE",
    AREA_SELECTION: "AREA_SELECTION",
  },
  events: {
    UPDATE_PROJECT: "UPDATE_PROJECT",
    SET_CENTER: "SET_CENTER",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
    DELETE_POLYGON: "DELETE_POLYGON",
    RESET_POLYGON: "RESET_POLYGON",
    CENTER_CHANGED: "CENTER_CHANGED",
    REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
    SET_LOCATION_STATS: "SET_LOCATION_STATS",
  },
  localStorage: {
    REFRESH_TOKEN: "refreshToken",
    ID_TOKEN: "smartCityIdToken",
    ACCESS_TOKEN: "accessToken",
  },
  errors: {
    NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
    FUNCTION_DEPRECATED: "function is deprecated!",
    TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
    UNAUTHORIZED: "user not authorized",
    NO_PROJECT_ID: "no providerId provided!",
    NO_BINS: "No BinsPlacements Field in Sector.",
  },
  technologies: ["3G-UMTS-FDD", "4G-LTE-FDD", "5G-TDD"],
  frequencyBands: [3600, 2600, 2100, 1800],
  channelBWs: [20, 40, 60, 100],
  polarizationTypes: ["Vertical", "Horizontal", "Circular", "Dual Slant 45deg"],

  mapLayers: {
    RSSI_COVERAGE: "RSSI - Coverage Optimized",
    RSSI_CAPACITY: "RSSI - Capacity Optimized",
    MCS_COVERAGE: "MCS - Coverage Optimized",
    MCS_CAPACITY: "MCS - Capacity Optimized",
    BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
    BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
    RSRP_COVERAGE: "RSRP - Coverage Optimized",
    RSRP_CAPACITY: "RSRP - Capacity Optimized",
    RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
    RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
  },
  userGroups: {
    MANAGER: "manager",
    ADMIN: "admin",
  },
  antennaConfigs: ["Model-A", "Model-B", "Steered"],
  siteModes: {predesign: "Predesign", postdesign: "Postdesign"},
  smartCityKFactor: 1120,
  smartCityShowSiteIcon: false,
  showStaticAntennas: false,
};
export default Constants;
export const modals = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  PARAMETERS: "PARAMETERS",
  ADD_PROJECT: "ADD_PROJECT",
  TABLE: "TABLE",
  PROJECT_LIST: "PROJECT_LIST",
  EDIT_PROJECT: "EDIT_PROJECT",
  EDIT_SITE: "EDIT_SITE",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  CLUSTER: "CLUSTER",
  CLUSTER_ALERT: "CLUSTER_ALERT",
  DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
  TECH_CONVERSION: "TECH_CONVERSION",
  TRY_AGAIN_GET_COVERAGE: "TRY_AGAIN_GET_COVERAGE",
  TERMS: "TERMS",
  CHOOSE_POLYGON_ALERT: "CHOOSE_POLYGON_ALERT",
  CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON: "CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON",
  REDUCE_POLYGON_SIZE_ALERT: "REDUCE_POLYGON_SIZE_ALERT",
  CREATE_USER: "CREATE_USER",
};
export const smartCityConstants = {
  SIGNAL_ALARM_THRESHOLD: 10,
};
export const events = {
  UPDATE_PROJECT: "UPDATE_PROJECT",
  SET_CENTER: "SET_CENTER",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
  DELETE_POLYGON: "DELETE_POLYGON",
  RESET_POLYGON: "RESET_POLYGON",
  CENTER_CHANGED: "CENTER_CHANGED",
  REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
  SET_LOCATION_STATS: "SET_LOCATION_STATS",
  REPOSITION_CENTER: "reposition_center",
};
export const errors = {
  PREDICTION_TOO_LARGE: "prediction too large",
  NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
  FUNCTION_DEPRECATED: "function is deprecated!",
  TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
  UNAUTHORIZED: "user not authorized",
  NO_PROJECT_ID: "no providerId provided!",
  NO_BINS: "No BinsPlacements Field in Sector.",
};

export const mapLayers = {
  RSSI_COVERAGE: "RSSI - Coverage Optimized",
  RSSI_CAPACITY: "RSSI - Capacity Optimized",
  MCS_COVERAGE: "MCS - Coverage Optimized",
  MCS_CAPACITY: "MCS - Capacity Optimized",
  BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
  BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
  RSRP_COVERAGE: "RSRP - Coverage Optimized",
  RSRP_CAPACITY: "RSRP - Capacity Optimized",
  RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
  RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
};

export const colors = {
  // wxtract to "theam"?
  OLIVE_GREEN: "#94c954",
  GREEN: "#00b14f",
  RED: "#ed2024",
  YELLOW: "#f6eb14",
  ORANGE: "#fec00f",
  AZURE: "#03b1fc",
  AZURE: "#03b1fc",
  PURPLE: "#a742f5",
  WHITE: "#ffff",
};

const {OLIVE_GREEN, GREEN, RED, YELLOW, ORANGE, AZURE, PURPLE, WHITE} = colors;

export const colorsLabelsInfo = {
  heatmap: [
    {
      levels: {min: 1.12, max: Infinity},
      label: "Very High EM",
      color: RED,
    },
    {
      levels: {min: 0.00112, max: 1.12},
      label: "High EM",
      color: ORANGE,
    },
    {
      levels: {min: 0.0000112, max: 0.00112},
      label: "Medium EM",
      color: YELLOW,
    },
    {
      levels: {min: 0.000000112, max: 0.0000112},
      label: "Moderate EM",
      color: OLIVE_GREEN,
    },
    {
      levels: {min: 0.00000000112, max: 0.000000112},
      label: "Low EM",
      color: GREEN,
    },
    {
      levels: {min: -Infinity, max: 0.00000000112},
      label: "Very Low EM",
      color: WHITE,
    },
  ],
  cellular: [
    {
      levels: {min: -80, max: Infinity},
      label: "High Coverage",
      color: GREEN,
    },
    {
      levels: {min: -90, max: -80},
      label: "Good Coverage",
      color: OLIVE_GREEN,
    },
    {
      levels: {min: -105, max: -90},
      label: "Moderate. Coverage",
      color: YELLOW,
    },
    {
      levels: {min: -120, max: -105},
      label: "Low Coverage",
      color: ORANGE,
    },
    {
      levels: {min: -Infinity, max: -120},
      label: "No Coverage",
      color: RED,
    },
  ],
  bestserver: [
    {
      levels: {min: -80, max: Infinity},
      label: "High Coverage",
      color: GREEN,
    },
    {
      levels: {min: -90, max: -80},
      label: "Good Coverage",
      color: OLIVE_GREEN,
    },
    {
      levels: {min: -105, max: -90},
      label: "Moderate. Coverage",
      color: YELLOW,
    },
    {
      levels: {min: -120, max: -105},
      label: "Low Coverage",
      color: ORANGE,
    },
    {
      levels: {min: -Infinity, max: -120},
      label: "No Coverage",
      color: RED,
    },
  ],
  realtime: [
    {
      levels: {min: -80, max: Infinity},
      label: "High Coverage",
      color: GREEN,
    },
    {
      levels: {min: -90, max: -80},
      label: "Good Coverage",
      color: OLIVE_GREEN,
    },
    {
      levels: {min: -105, max: -90},
      label: "Moderate. Coverage",
      color: YELLOW,
    },
    {
      levels: {min: -120, max: -105},
      label: "Low Coverage",
      color: ORANGE,
    },
    {
      levels: {min: -Infinity, max: -120},
      label: "No Coverage",
      color: RED,
    },
  ],
};

export const technolegyCoverage = [
  {range:{min: 0, max: 20}, lable: 'Poor Coverage', color:'#ed2024', starRate:1},
  {range:{min: 20, max: 40}, lable:'Low Coverage', color:'#fec00f', starRate:2},
  {range:{min: 40, max: 60}, lable:'Moderate Coverage', color:'#f6eb14', starRate:3},
  {range:{min: 60, max: 80}, lable:'Good Coverage', color:'#94c954', starRate:4},
  {range:{min: 80, max: 100}, lable:'High Coverage', color:'#00b14f', starRate:5},
]


export const PROVIDERS = {

  CELLCOM: "cellcom",
  PELEPHONE: "pelephone",
  PARTNER: "partner",
}; // smart city

export const PROVIDERS_LIST = Object.values(PROVIDERS);

export const TECHNOLOGYS = {
  _3G: "3G",
  _4G: "4G",
  _5G: "5G",
};

export const TECHNOLOGYS_LIST = Object.values(TECHNOLOGYS);

export const dateRangeTypes = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
  DATE: "date"

};

export const dateRangeValues = Object.values(dateRangeTypes);

export const environmentFactor = {
  // smart city - CHANGE NAMES!
  MEN: 6,
  CAR: 0,
  HOME: -14,
};

export const REAL_TIME_INTERVAL = 2000; // in milisec
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const userEnvironments = {
  // smart city - CHANGE NAMES!
  MEN: "Men",
  CAR: "Car",
  HOME: "Home",
};

export const timeOfDay = {
  // smart city
  MORNING: "Morning",
  AFTERNOON: "Afternoon",
  EVENING: "Evening",
};

export const defaultTechnologiesCoverage = {
  Email: 0,
  General: 0,
  "Voice Call": 0,
  "Email with attachment": 0,
  "Web Browsing - Webinar": 0,
  "Video Streaming HD - Zoom": 0,
  "Video Streaming FHD - Youtube": 0,
  "Video Streaming 4K - Youtube": 0,
  "Autonomous Vehicles": 0,
  "Autonomous Drones": 0,
};

export const techUsageCategoriesThresholds = {
  "4G": {
    "Voice Call": -105.1,
    Email: -108.8,
    "Email with attachment": -102.8,
    "Web Browsing - Webinar": -101.8,
    General: -96.0,
    "Video Streaming HD - Zoom": -99.1,
    "Video Streaming FHD - Youtube": -93.8,
    "Video Streaming 4K - Youtube": -90.2,
    "Autonomous Vehicles": -85.7,
    "Autonomous Drones": -84.4,
  },
  "5G": {
    "Voice Call": -105.1,
    Email: -108.8,
    "Email with attachment": -102.8,
    "Web Browsing - Webinar": -101.8,
    General: -96.6,
    "Video Streaming HD - Zoom": -99.1,
    "Video Streaming FHD - Youtube": -93.8,
    "Video Streaming 4K - Youtube": -90.2,
    "Autonomous Vehicles": -85.7,
    "Autonomous Drones": -84.4,
  },
  "3G": {
    "Voice Call": -100,
    Email: -101,
    "Email with attachment": -87,
    "Web Browsing - Webinar": -84,
    General: -92.5,
  },
};

export const timeOfDayFactor = {
  // smart city - CHANGE NAMES!
  MORNING: 3,
  AFTERNOON: 0,
  EVENING: -3,
};
export const availabiltyStatuses = {
  CREATED: "created",
  PENDING: "pending",
  READY: "ready",
};
export const mapLayerValues = {
  CELLULAR: "cellular",
  HEAT_MAP: "heatmap",
  REAL_TIME: "realtime",
};

export const mapControlsDisplayType = {
  STANDARD: "standard",
  DATE_RANGE_OF_24_HOURS: "dateRangeOf24Hours",
};

export const algorithmsTypes = {
  KMEANS: "kmeans",
  STATIC_CLUSTERING: "static-clustering",
};

export const mapLayerTypes = {
  CELLULAR: "cellular",
  HEAT_MAP: "heatmap",
  // REAL_TIME: "realtime",
  BEST_SERVER: "bestserver",
};

export const mapLayerTypesList = Object.values(mapLayerTypes);

export function between(x, max, min) {
  // Should be refactored ^^^^^^
  return x >= min && x <= max;
}
export const smartCity = {
  getColor: (mapLayer, rssi) => {
    if (rssi > this.labelsColorsInfo[mapLayer].maxSignal) return "#fc03ec";
    if (rssi < this.labelsColorsInfo[mapLayer].minSignal) return "#ffffff";
    const color = this.labelsColorsInfo[mapLayer].find(({range}) => between(rssi, range.max, range.min)).color;
    return color;
  },
  // getMaxSignal: (mapLayer) => Math.max(this.labelsColorsInfo[mapLayer].values.map(value => value.range.max)),
  // getMinSignal: (mapLayer) => Math.min(this.labelsColorsInfo[mapLayer].values.map(value => value.range.min)),
  labelsColorsInfo: {
    signalMapLayer: [],
    EMMapLayer: {
      // maxSignal:  smartCity.getMaxSignal("EMMapLayer"),
      // minSignal: smartCity.getMinSignal("EMMapLayer"),
      values: [
        {
          rssiValue: -20,
          label: " Very High EM City",
          color: "#9e062a", //4287f5 blue//
          range: {
            max: 0.01,
            min: 0.0004,
          },
        },
        {
          rssiValue: -60,
          label: " High EM City",
          color: "#ff0800",
          range: {
            max: 4e-6,
            min: 4e-7,
          },
        },
        {
          rssiValue: -75,
          label: " Med. EM City",
          color: "#ffc019",
          range: {
            max: 4e-7,
            min: 4e-8,
          },
        },
        {
          rssiValue: -90,
          label: " Moderate EM City",
          color: "#21ea00", //4287f5 blue//
          range: {
            max: 4e-8,
            min: 4e-9,
          },
        },
        {
          rssiValue: -105,
          label: " Low EM City",
          color: "#b3f266",
          range: {
            max: 4e-9,
            min: 4e-10,
          },
        },
        {
          rssiValue: -120,
          label: " Ultra Low EM City",
          color: "#ffff",
          range: {
            max: 4e-10,
            min: 4e-11,
          },
        },
      ],
    },
  },
};

export const TECHNOLOGIES_CALCULATION_RADIUS = 0.2;
