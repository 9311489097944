import Constants from "../Constants";
import {errors} from "../Constants";
import Validator from "../Validator";
import {fetchAuth, handleError, navigateResponse} from "./helper";

export const ProjectParams = class {
  static async getProjectParams(projectId, {binsPopulated, populated}) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      const res = await fetchAuth(
        `${Constants.REST_URL}projects/${projectId}${
          binsPopulated || populated
            ? `?${binsPopulated ? "bins-populated=true" : ""}${binsPopulated && populated ? "&" : ""}${populated ? "populated=true" : ""}`
            : ""
        }`,
        {
          method: "GET",
        }
      );
      const project = await navigateResponse(res);
      return project;
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) {
      } else {
        handleError(e);
      }
    }
  }

  static editProjectParams(proejctId, newParams) {
    const {_id, ...idlessParams} = newParams; //extract id from project params;
    return fetchAuth(Constants.REST_URL + "projects/" + proejctId + "/params/" + _id, {
      method: "PUT",
      body: JSON.stringify(idlessParams),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
};
