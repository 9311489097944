import React, {useEffect} from "react";
import {usePolygonPath, usePredictionState} from "../../Hooks/common";
import {useUserPreferencesState} from "../../Hooks/useUserPreferences";
import {REAL_TIME_INTERVAL, algorithmsTypes, mapLayerValues, TECHNOLOGYS, PROVIDERS} from "../../controller/Constants";
import Api from "../../controller/ApiManager";
import useProject from "../../Hooks/useProject";
import useClusterAndApllyFactor from "../../Hooks/useClusterAndApllyFactor";
import {ifFuncExec, generateDateRange} from "../../controller/common";
import {isPointInPolygon} from "../../controller/GEOoperations";

function RealTimeMapEffects({setBinsArray}) {

  // const project = useProject();
  // const [userPreferences, setUserPreferences] = useUserPreferencesState();
  // const clusterAndApllyFactor = useClusterAndApllyFactor();
  // const polygonPath = usePolygonPath();
  // const [placements, setPlacements] = usePlacementsState();
  // const [predictionState] = usePredictionState();
  // const {KMEANS, STATIC_CLUSTERING} = algorithmsTypes;
  // const algorithm = predictionState ? KMEANS : STATIC_CLUSTERING;
  // function getUserPreferencesForRealTime(userPreferences) {
  //   userPreferences.algorithm = STATIC_CLUSTERING;
  //   userPreferences.technologies = ["3G", "4G", "5G"];
  //   userPreferences.providers = ["cellcom", "pelephone"];
  //   userPreferences.relativeDateRange.dateType = mapLayerValues.REAL_TIME;
  //   return userPreferences;
  // }
  // function filterRelevantPlacementsFromStore() {
  //   const ans = placements.filter(
  //     ({dateRange, algorithm: placementAlgorithm, technology, provider}) =>
  //       userPreferences.providers.includes(provider) &&
  //       userPreferences.technologies.includes(technology) &&
  //       algorithm === placementAlgorithm &&
  //       isRelativeDateRangeEqualToDateRang(userPreferences.relativeDateRange, dateRange)
  //   );
  //   return ans; /// clean ans
  // }
  // function isRelativeDateRangeEqualToDateRang(relativeDateRange, dateRange) {
  //   const generatedDateRange = generateDateRangeFromDateType(relativeDateRange.dateType);
  //   return (
  //     generatedDateRange.from.getTime() === new Date(dateRange.from).getTime() &&
  //     generatedDateRange.to.getTime() === new Date(dateRange.to).getTime()
  //   );
  // }
  // function getReleventBins(arg) {
  //   console.log("getReleventBins: " + arg);
  //   const placements = filterRelevantPlacementsFromStore();
  //   const ans = filterByPolygon(clusterAndApllyFactor(placements));
  //   return ans;
  // }
  // function filterByPolygon(bins) {
  //   if (polygonPath.length > 2) {
  //     bins = bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygonPath));
  //   }
  //   return bins;
  // }
  // function filterPlacements() {
  //   return placements.filter(
  //     // add filter by date and ... ?
  //     ({technology, provider}) => userPreferences.providers.includes(provider) && userPreferences.technologies.includes(technology)
  //   );
  // }
  // useEffect(() => {
  //   async function handleInterval() {
  //     console.log("handleInterval was called!");
  //     try {
  //       const realTimePlacements = await Api.getRealTimeCoverage(project._id, getUserPreferencesForRealTime(userPreferences), STATIC_CLUSTERING);
  //       setPlacements([...placements, ...realTimePlacements.binsPlacements]);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   const interval = setInterval(() => {
  //     handleInterval();
  //   }, REAL_TIME_INTERVAL);
  //   return () => clearInterval(interval);
  // }, []);
  // useEffect(() => {
  //   setBinsArray(getReleventBins(19));
  // }, [placements]);
  // return null;
}
RealTimeMapEffects = React.memo(RealTimeMapEffects);
export default RealTimeMapEffects;
// export default RealTimeMapEffects; // REACT.MEMO !!!
