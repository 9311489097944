export const Cellcom4GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.832371,
          lng: 35.118756,
        },
        signal: -1000,
      },
      {
        location: {
          lat: 31.82448239759036,
          lng: 35.12799003614458,
        },
        signal: -2000,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "4G",
    provider: "cellcom",
    algorithm: "static-clustering",
  },
};
export const Cellcom3GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.876726206251156,
          lng: 35.00764066306436,
        },
        signal: -99,
      },
      {
        location: {
          lat: 31.876726206251156,
          lng: 35.00782047666744,
        },
        signal: -77,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "3G",
    provider: "cellcom",
    algorithm: "static-clustering",
  },
};

// export const Cellcom3GMock = {
//   _id: {$oid: "61fbc9cf183b36000a9866b6"},
//   availabilityStatus: {status: "ready", start: {$date: "2022-02-03T12:52:10.436Z"}},
//   binReliability: 5,
//   schemaVersion: "0",
//   technology: "3G",
//   provider: "cellcom",
//   binResolution: 20,
//   dateRange: {
//     dateType: "day",
//     from: "Wed Feb 23 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
//     to: "Tue Mar 01 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
//   },
//   mapLayer: "cellular",
//   algorithm: "static-clustering",
//   signalType: "rsrp",
//   project: {$oid: "61e8031227370700095da60e"},
//   bins: [
//     {location: {lat: 31.876726206251156, lng: 35.00764066306436}, signal: -127.4},
//     {location: {lat: 31.876726206251156, lng: 35.00782047666744}, signal: -127.4},
//     {location: {lat: 31.876726206251156, lng: 35.008000290270516}, signal: -127.55},
//     {location: {lat: 31.876726206251156, lng: 35.00871954468283}, signal: -127.55},
//     {location: {lat: 31.876906019854236, lng: 35.00746084946128}, signal: -127.55},
//     {location: {lat: 31.876906019854236, lng: 35.00782047666744}, signal: -125},
//     {location: {lat: 31.877085833457315, lng: 35.006381967842806}, signal: -120},
//   ],
//   __v: 0,
//   createdAt: {$date: "2022-02-03T12:25:51.571Z"},
//   updatedAt: {$date: "2022-02-03T12:52:35.232Z"},
// };

export const PHI4GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.809926,
          lng: 35.160695,
        },
        signal: -111,
      },
      {
        location: {
          lat: 31.82448239759036,
          lng: 35.12799003614458,
        },
        signal: -101,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "4G",
    provider: "PHI",
    algorithm: "static-clustering",
  },
};
export const PHI3GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.824550655172413,
          lng: 35.12814079310347,
        },
        signal: -90,
      },
      {
        location: {
          lat: 31.824571346153753,
          lng: 35.12789048076925,
        },
        signal: -102,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "3G",
    provider: "PHI",
    algorithm: "static-clustering",
  },
};
export const Pelephone4GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.805368,
          lng: 35.130268,
        },
        signal: -90,
      },
      {
        location: {
          lat: 31.809817,
          lng: 35.147391,
        },
        signal: -102,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "4G",
    provider: "pelephone",
    algorithm: "static-clustering",
  },
};
export const Pelephone3GMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.808395,
          lng: 35.144601,
        },
        signal: -90,
      },
      {
        location: {
          lat: 31.810765,
          lng: 35.162583,
        },
        signal: -102,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "3G",
    provider: "pelephone",
    algorithm: "static-clustering",
  },
};

//

//////////////////////////////////////////////

const placementMock = {
  binsPlacement: {
    bins: [
      {
        location: {
          lat: 31.832371,
          lng: 35.118756,
        },
        signal: -90,
      },
      {
        location: {
          lat: 31.831241,
          lng: 35.12249,
        },
        signal: -95,
      },
      {
        location: {
          lat: 31.830548,
          lng: 35.118166,
        },
        signal: -99,
      },
      {
        location: {
          lat: 31.82448239759036,
          lng: 35.12799003614458,
        },
        signal: -102,
      },
      {
        location: {
          lat: 31.824550655172413,
          lng: 35.12814079310347,
        },
        signal: -91.22,
      },
      {
        location: {
          lat: 31.824571346153753,
          lng: 35.12789048076925,
        },
        signal: -86.3,
      },
      {
        location: {
          lat: 31.824571346153753,
          lng: 35.12789048076925,
        },
        signal: -86.3,
      },
      {
        location: {
          lat: 31.82448239759036,
          lng: 35.12799003614458,
        },
        signal: -98.32000000000001,
      },
    ],
    binResolution: 20,
    binReliability: 6,
    samplesReliability: 0,
    fileId: "0",
    smartType: "TYPE_1X1",
    dateRange: {
      dateType: "day",
      from: "Fri Feb 25 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
      to: "Thu Mar 03 2022 02:00:00 GMT+0200 (שעון ישראל (חורף))",
    },
    mapLayer: "cellular",
    signalType: "rssi",
    technology: "4G",
    provider: "cellcom",
  },
};

const workingBinsBaruchHashem = [
  {
    binResolution: 20,
    mapLayer: "cellular",
    signalType: "rsrp",
    binReliability: 5,
    dateRange: {from: "2022-02-27,00:00:00.000000", to: "2022-03-05,00:00:00.000000", dateType: "day"},
    technology: "4G",
    provider: "cellcom",
    bins: [
      {location: {lat: 31.93264823680876, lng: 35.22431605477467}, signal: -89.00000000000001},
      {location: {lat: 31.932648236808756, lng: 35.22449586837775}, signal: -85.28},
      {location: {lat: 31.932828050411835, lng: 35.22431605477467}, signal: -86.29000000000002},
      {location: {lat: 31.932828050411835, lng: 35.22449586837775}, signal: -82.0},
      {location: {lat: 31.933007864014915, lng: 35.22431605477467}, signal: -88.75},
      {location: {lat: 31.933007864014915, lng: 35.22449586837775}, signal: -90.0},
      {location: {lat: 31.933187677617994, lng: 35.22431605477467}, signal: -89.0},
      {location: {lat: 31.93336749122107, lng: 35.22431605477467}, signal: -88.35},
      {location: {lat: 31.933547304824152, lng: 35.22413624117159}, signal: -89.6},
      {location: {lat: 31.933547304824152, lng: 35.22431605477467}, signal: -83.88000000000001},
      {location: {lat: 31.933727118427228, lng: 35.223776613965434}, signal: -86.0},
      {location: {lat: 31.933727118427228, lng: 35.22395642756851}, signal: -88.92000000000002},
      {location: {lat: 31.933727118427225, lng: 35.22413624117159}, signal: -90.64999999999999},
      {location: {lat: 31.933906932030308, lng: 35.22359680036235}, signal: -89.69},
      {location: {lat: 31.933906932030304, lng: 35.223776613965434}, signal: -91.64999999999999},
      {location: {lat: 31.93390693203031, lng: 35.22395642756851}, signal: -92.00000000000001},
      {location: {lat: 31.934086745633387, lng: 35.223416986759275}, signal: -91.0},
      {location: {lat: 31.934086745633387, lng: 35.22359680036235}, signal: -91.3},
      {location: {lat: 31.934086745633387, lng: 35.223776613965434}, signal: -91.10000000000001},
      {location: {lat: 31.93426655923647, lng: 35.22323717315619}, signal: -91.55000000000001},
      {location: {lat: 31.934266559236466, lng: 35.223416986759275}, signal: -91.86500000000001},
      {location: {lat: 31.934266559236466, lng: 35.23600393897481}, signal: -96.0},
      {location: {lat: 31.934446372839545, lng: 35.22305735955312}, signal: -90.69999999999999},
      {location: {lat: 31.93444637283955, lng: 35.22323717315619}, signal: -90.8},
      {location: {lat: 31.934446372839545, lng: 35.23600393897481}, signal: -96.0},
      {location: {lat: 31.934626186442625, lng: 35.2214390371254}, signal: -100.4},
      {location: {lat: 31.93462618644262, lng: 35.22161885072848}, signal: -81.74999999999999},
      {location: {lat: 31.934626186442628, lng: 35.22305735955312}, signal: -99.5},
      {location: {lat: 31.934626186442625, lng: 35.22323717315619}, signal: -81.0},
      {location: {lat: 31.93462618644262, lng: 35.23600393897481}, signal: -99.8},
      {location: {lat: 31.934806000045704, lng: 35.22107940991925}, signal: -101.80000000000001},
      {location: {lat: 31.934806000045704, lng: 35.221259223522324}, signal: -106.625},
      {location: {lat: 31.934806000045704, lng: 35.2214390371254}, signal: -104.0},
      {location: {lat: 31.934806000045704, lng: 35.22161885072848}, signal: -97.7},
      {location: {lat: 31.934806000045704, lng: 35.22179866433156}, signal: -103.2},
      {location: {lat: 31.934806000045704, lng: 35.22197847793464}, signal: -103.25},
      {location: {lat: 31.934806000045704, lng: 35.22215829153772}, signal: -96.66},
      {location: {lat: 31.934806000045704, lng: 35.2223381051408}, signal: -104.1},
      {location: {lat: 31.934806000045704, lng: 35.222517918743875}, signal: -78.9},
      {location: {lat: 31.934806000045704, lng: 35.22287754595003}, signal: -88.75},
      {location: {lat: 31.934806000045704, lng: 35.22305735955311}, signal: -99.25},
      {location: {lat: 31.934806000045704, lng: 35.23582412537173}, signal: -102.0},
      {location: {lat: 31.934985813648783, lng: 35.2223381051408}, signal: -81.9},
      {location: {lat: 31.934985813648783, lng: 35.22251791874387}, signal: -86.69999999999999},
      {location: {lat: 31.934985813648783, lng: 35.22269773234696}, signal: -102.45},
      {location: {lat: 31.934985813648783, lng: 35.22287754595004}, signal: -108.2},
      {location: {lat: 31.934985813648783, lng: 35.22305735955312}, signal: -107.75},
      {location: {lat: 31.934985813648783, lng: 35.22323717315619}, signal: -89.12},
      {location: {lat: 31.934985813648783, lng: 35.23582412537173}, signal: -100.0},
      {location: {lat: 31.935165627251862, lng: 35.22287754595003}, signal: -83.7},
      {location: {lat: 31.935165627251862, lng: 35.22305735955312}, signal: -95.0},
      {location: {lat: 31.935165627251862, lng: 35.22323717315619}, signal: -93.2},
      {location: {lat: 31.935165627251862, lng: 35.23582412537173}, signal: -102.0},
      {location: {lat: 31.93534544085494, lng: 35.22305735955312}, signal: -100.14999999999999},
      {location: {lat: 31.93534544085494, lng: 35.22323717315619}, signal: -93.43},
      {location: {lat: 31.93534544085494, lng: 35.23582412537173}, signal: -102.0},
      {location: {lat: 31.93552525445802, lng: 35.22323717315619}, signal: -95.8},
      {location: {lat: 31.93552525445802, lng: 35.23600393897481}, signal: -102.0},
      {location: {lat: 31.9357050680611, lng: 35.22323717315619}, signal: -94.1},
      {location: {lat: 31.9357050680611, lng: 35.23600393897481}, signal: -102.955},
      {location: {lat: 31.93588488166418, lng: 35.22323717315619}, signal: -100.0},
      {location: {lat: 31.93588488166418, lng: 35.223416986759275}, signal: -91.65499999999999},
      {location: {lat: 31.93588488166418, lng: 35.23600393897481}, signal: -103.0},
      {location: {lat: 31.936064695267262, lng: 35.22323717315619}, signal: -91.8},
      {location: {lat: 31.93606469526726, lng: 35.223416986759275}, signal: -87.68},
      {location: {lat: 31.93606469526726, lng: 35.23600393897481}, signal: -105.0},
      {location: {lat: 31.93606469526726, lng: 35.23618375257789}, signal: -105.0},
      {location: {lat: 31.93606469526726, lng: 35.23636356618097}, signal: -105.99999999999999},
      {location: {lat: 31.93606469526726, lng: 35.236543379784045}, signal: -105.0},
      {location: {lat: 31.936244508870335, lng: 35.223416986759275}, signal: -98.24999999999999},
      {location: {lat: 31.936244508870338, lng: 35.236543379784045}, signal: -105.1},
      {location: {lat: 31.936244508870338, lng: 35.23672319338713}, signal: -106.0},
      {location: {lat: 31.936424322473417, lng: 35.223416986759275}, signal: -92.85},
      {location: {lat: 31.936424322473417, lng: 35.23672319338713}, signal: -106.0},
      {location: {lat: 31.936424322473417, lng: 35.236903006990204}, signal: -106.0},
      {location: {lat: 31.936604136076497, lng: 35.223416986759275}, signal: -93.34},
      {location: {lat: 31.936604136076497, lng: 35.236903006990204}, signal: -106.0},
      {location: {lat: 31.936783949679572, lng: 35.223416986759275}, signal: -92.975},
      {location: {lat: 31.936783949679572, lng: 35.236903006990204}, signal: -105.0},
      {location: {lat: 31.936963763282648, lng: 35.223416986759275}, signal: -98.93},
      {location: {lat: 31.936963763282648, lng: 35.22359680036235}, signal: -82.17},
      {location: {lat: 31.93696376328265, lng: 35.23708282059329}, signal: -104.0},
      {location: {lat: 31.93696376328265, lng: 35.23726263419636}, signal: -105.0},
      {location: {lat: 31.93714357688573, lng: 35.223416986759275}, signal: -92.47},
      {location: {lat: 31.93714357688573, lng: 35.22359680036235}, signal: -81.0},
      {location: {lat: 31.937143576885727, lng: 35.237442447799445}, signal: -105.0},
      {location: {lat: 31.93732339048881, lng: 35.223416986759275}, signal: -96.4},
      {location: {lat: 31.93732339048881, lng: 35.22359680036235}, signal: -81.0},
      {location: {lat: 31.93732339048881, lng: 35.23762226140252}, signal: -104.10000000000001},
      {location: {lat: 31.93732339048881, lng: 35.237802075005604}, signal: -103.0},
      {location: {lat: 31.93750320409189, lng: 35.223416986759275}, signal: -91.6},
      {location: {lat: 31.93750320409189, lng: 35.23798188860868}, signal: -103.0},
      {location: {lat: 31.93768301769497, lng: 35.22323717315619}, signal: -91.95500000000001},
      {location: {lat: 31.93768301769497, lng: 35.223416986759275}, signal: -86.0},
      {location: {lat: 31.93768301769497, lng: 35.23798188860868}, signal: -102.0},
      {location: {lat: 31.93768301769497, lng: 35.23816170221176}, signal: -103.0},
      {location: {lat: 31.937862831298048, lng: 35.22323717315619}, signal: -90.6},
      {location: {lat: 31.937862831298045, lng: 35.223416986759275}, signal: -87.89999999999999},
      {location: {lat: 31.937862831298048, lng: 35.23816170221176}, signal: -104.0},
      {location: {lat: 31.938042644901124, lng: 35.22305735955312}, signal: -89.95},
      {location: {lat: 31.938042644901127, lng: 35.22323717315619}, signal: -89.0},
      {location: {lat: 31.938042644901127, lng: 35.23816170221176}, signal: -104.0},
      {location: {lat: 31.938222458504207, lng: 35.22305735955312}, signal: -92.8},
      {location: {lat: 31.938222458504207, lng: 35.223237173156186}, signal: -76.91999999999999},
      {location: {lat: 31.938222458504207, lng: 35.23834151581484}, signal: -104.0},
      {location: {lat: 31.938402272107286, lng: 35.222877545950034}, signal: -90.0},
      {location: {lat: 31.938402272107286, lng: 35.22305735955312}, signal: -89.5},
      {location: {lat: 31.938402272107286, lng: 35.23834151581484}, signal: -102.94999999999999},
      {location: {lat: 31.938582085710365, lng: 35.222877545950034}, signal: -91.0},
      {location: {lat: 31.938582085710365, lng: 35.223057359553124}, signal: -88.4},
      {location: {lat: 31.938582085710365, lng: 35.23834151581484}, signal: -103.1},
      {location: {lat: 31.938761899313445, lng: 35.222877545950034}, signal: -91.0},
      {location: {lat: 31.938761899313445, lng: 35.238521329417914}, signal: -104.9},
      {location: {lat: 31.938941712916524, lng: 35.22269773234696}, signal: -91.0},
      {location: {lat: 31.938941712916524, lng: 35.22287754595003}, signal: -88.32},
      {location: {lat: 31.938941712916524, lng: 35.238521329417914}, signal: -101.0},
      {location: {lat: 31.938941712916524, lng: 35.238701143021}, signal: -101.0},
      {location: {lat: 31.939121526519603, lng: 35.22215829153772}, signal: -85.6},
      {location: {lat: 31.939121526519603, lng: 35.2223381051408}, signal: -87.89999999999999},
      {location: {lat: 31.939121526519603, lng: 35.22251791874387}, signal: -98.85},
      {location: {lat: 31.9391215265196, lng: 35.22269773234696}, signal: -97.94999999999999},
      {location: {lat: 31.939121526519603, lng: 35.222877545950034}, signal: -86.25},
      {location: {lat: 31.939121526519603, lng: 35.238701143021}, signal: -100.0},
      {location: {lat: 31.939301340122682, lng: 35.22197847793464}, signal: -85.6},
      {location: {lat: 31.939301340122682, lng: 35.22215829153771}, signal: -85.375},
      {location: {lat: 31.939301340122682, lng: 35.2223381051408}, signal: -81.10000000000001},
      {location: {lat: 31.939301340122682, lng: 35.222517918743875}, signal: -81.10000000000001},
      {location: {lat: 31.939301340122682, lng: 35.22269773234696}, signal: -86.42},
      {location: {lat: 31.939301340122682, lng: 35.222877545950034}, signal: -95.39999999999999},
      {location: {lat: 31.939301340122682, lng: 35.238701143021}, signal: -98.95},
      {location: {lat: 31.93948115372576, lng: 35.22215829153771}, signal: -90.7},
      {location: {lat: 31.93948115372576, lng: 35.22269773234696}, signal: -90.0},
      {location: {lat: 31.939481153725765, lng: 35.222877545950034}, signal: -82.67999999999999},
      {location: {lat: 31.93948115372576, lng: 35.238701143021}, signal: -101.0},
      {location: {lat: 31.93966096732884, lng: 35.22269773234696}, signal: -88.1},
      {location: {lat: 31.93966096732884, lng: 35.222877545950034}, signal: -90.0},
      {location: {lat: 31.93966096732884, lng: 35.238701143021}, signal: -101.0},
      {location: {lat: 31.93966096732884, lng: 35.23888095662407}, signal: -101.0},
      {location: {lat: 31.93984078093192, lng: 35.22269773234696}, signal: -88.25},
      {location: {lat: 31.93984078093192, lng: 35.222877545950034}, signal: -79.53999999999999},
      {location: {lat: 31.93984078093192, lng: 35.23888095662407}, signal: -96.0},
      {location: {lat: 31.940020594535, lng: 35.22269773234696}, signal: -86.80000000000001},
      {location: {lat: 31.940020594535, lng: 35.222877545950034}, signal: -88.95},
      {location: {lat: 31.940020594535, lng: 35.238701143021}, signal: -97.94999999999999},
      {location: {lat: 31.940200408138075, lng: 35.22269773234696}, signal: -88.0},
      {location: {lat: 31.940200408138075, lng: 35.222877545950034}, signal: -81.99},
      {location: {lat: 31.940200408138075, lng: 35.23798188860868}, signal: -101.94999999999999},
      {location: {lat: 31.940200408138075, lng: 35.238521329417914}, signal: -101.0},
      {location: {lat: 31.940200408138075, lng: 35.238701143021}, signal: -100.0},
      {location: {lat: 31.94038022174115, lng: 35.22269773234696}, signal: -89.7},
      {location: {lat: 31.94038022174115, lng: 35.222877545950034}, signal: -88.10499999999999},
      {location: {lat: 31.94038022174115, lng: 35.237802075005604}, signal: -99.95},
      {location: {lat: 31.940380221741155, lng: 35.23798188860868}, signal: -104.0},
      {location: {lat: 31.940380221741155, lng: 35.238521329417914}, signal: -102.0},
      {location: {lat: 31.940560035344234, lng: 35.22269773234696}, signal: -89.73},
      {location: {lat: 31.94056003534423, lng: 35.22287754595003}, signal: -91.0},
      {location: {lat: 31.940560035344234, lng: 35.237802075005604}, signal: -99.0},
      {location: {lat: 31.940560035344234, lng: 35.23798188860868}, signal: -102.0},
      {location: {lat: 31.940560035344234, lng: 35.238521329417914}, signal: -102.0},
      {location: {lat: 31.940739848947313, lng: 35.222877545950034}, signal: -90.3},
      {location: {lat: 31.940739848947313, lng: 35.22305735955312}, signal: -79.9},
      {location: {lat: 31.940739848947313, lng: 35.23762226140252}, signal: -95.0},
      {location: {lat: 31.940739848947313, lng: 35.237802075005604}, signal: -95.0},
      {location: {lat: 31.94073984894731, lng: 35.23798188860868}, signal: -101.99999999999999},
      {location: {lat: 31.940739848947313, lng: 35.238521329417914}, signal: -102.0},
      {location: {lat: 31.940739848947313, lng: 35.238701143021}, signal: -103.0},
      {location: {lat: 31.940919662550392, lng: 35.222877545950034}, signal: -88.0},
      {location: {lat: 31.940919662550392, lng: 35.22305735955311}, signal: -86.315},
      {location: {lat: 31.940919662550392, lng: 35.237442447799445}, signal: -93.0},
      {location: {lat: 31.940919662550392, lng: 35.23762226140252}, signal: -93.0},
      {location: {lat: 31.94091966255039, lng: 35.23798188860868}, signal: -102.94999999999999},
      {location: {lat: 31.940919662550392, lng: 35.238701143021}, signal: -102.1},
      {location: {lat: 31.94109947615347, lng: 35.220899596316166}, signal: -99.0},
      {location: {lat: 31.94109947615347, lng: 35.22107940991925}, signal: -99.0},
      {location: {lat: 31.94109947615347, lng: 35.221259223522324}, signal: -99.0},
      {location: {lat: 31.94109947615347, lng: 35.22305735955312}, signal: -91.0},
      {location: {lat: 31.94109947615347, lng: 35.22323717315619}, signal: -90.0},
      {location: {lat: 31.94109947615347, lng: 35.23564431176865}, signal: -80.0},
      {location: {lat: 31.94109947615347, lng: 35.23582412537173}, signal: -87.0},
      {location: {lat: 31.94109947615347, lng: 35.23600393897481}, signal: -89.0},
      {location: {lat: 31.94109947615347, lng: 35.23618375257789}, signal: -89.0},
      {location: {lat: 31.941099476153468, lng: 35.23636356618097}, signal: -89.0},
      {location: {lat: 31.941099476153468, lng: 35.23654337978404}, signal: -92.8},
      {location: {lat: 31.94109947615347, lng: 35.23708282059329}, signal: -94.0},
      {location: {lat: 31.94109947615347, lng: 35.23726263419636}, signal: -94.0},
      {location: {lat: 31.94109947615347, lng: 35.237442447799445}, signal: -93.0},
      {location: {lat: 31.94109947615347, lng: 35.23798188860868}, signal: -103.0},
      {location: {lat: 31.94109947615347, lng: 35.238701143021}, signal: -105.0},
      {location: {lat: 31.94127928975655, lng: 35.22053996911001}, signal: -104.0},
      {location: {lat: 31.94127928975655, lng: 35.22071978271309}, signal: -99.0},
      {location: {lat: 31.94127928975655, lng: 35.221259223522324}, signal: -99.0},
      {location: {lat: 31.94127928975655, lng: 35.2214390371254}, signal: -90.0},
      {location: {lat: 31.941279289756547, lng: 35.22305735955312}, signal: -91.94999999999999},
      {location: {lat: 31.94127928975655, lng: 35.22323717315619}, signal: -93.25},
      {location: {lat: 31.941279289756547, lng: 35.23546449816557}, signal: -81.99999999999999},
      {location: {lat: 31.94127928975655, lng: 35.23672319338713}, signal: -93.0},
      {location: {lat: 31.94127928975655, lng: 35.236903006990204}, signal: -94.0},
      {location: {lat: 31.94127928975655, lng: 35.23708282059329}, signal: -94.0},
      {location: {lat: 31.941279289756547, lng: 35.237802075005604}, signal: -102.85},
      {location: {lat: 31.94127928975655, lng: 35.238521329417914}, signal: -104.0},
      {location: {lat: 31.941459103359627, lng: 35.22053996911001}, signal: -104.0},
      {location: {lat: 31.94145910335963, lng: 35.2214390371254}, signal: -90.0},
      {location: {lat: 31.941459103359634, lng: 35.22323717315619}, signal: -89.62},
      {location: {lat: 31.941459103359627, lng: 35.23007009007319}, signal: -85.9},
      {location: {lat: 31.94145910335963, lng: 35.23024990367628}, signal: -86.0},
      {location: {lat: 31.941459103359627, lng: 35.23042971727936}, signal: -93.0},
      {location: {lat: 31.94145910335963, lng: 35.23060953088244}, signal: -95.0},
      {location: {lat: 31.941459103359627, lng: 35.230789344485515}, signal: -95.0},
      {location: {lat: 31.94145910335963, lng: 35.2309691580886}, signal: -95.0},
      {location: {lat: 31.941459103359627, lng: 35.231148971691674}, signal: -94.95},
      {location: {lat: 31.94145910335963, lng: 35.23132878529476}, signal: -90.4},
      {location: {lat: 31.941459103359627, lng: 35.23150859889783}, signal: -90.0},
      {location: {lat: 31.941459103359627, lng: 35.23168841250091}, signal: -95.0},
      {location: {lat: 31.941459103359627, lng: 35.23546449816557}, signal: -86.0},
      {location: {lat: 31.94145910335963, lng: 35.23762226140252}, signal: -102.0},
      {location: {lat: 31.94145910335963, lng: 35.238521329417914}, signal: -104.0},
      {location: {lat: 31.94163891696271, lng: 35.22018034190385}, signal: -105.89999999999999},
      {location: {lat: 31.94163891696271, lng: 35.22036015550693}, signal: -104.0},
      {location: {lat: 31.94163891696271, lng: 35.2214390371254}, signal: -90.0},
      {location: {lat: 31.94163891696271, lng: 35.22323717315619}, signal: -92.28},
      {location: {lat: 31.94163891696271, lng: 35.223416986759275}, signal: -87.91},
      {location: {lat: 31.94163891696271, lng: 35.227912326836254}, signal: -96.65},
      {location: {lat: 31.94163891696271, lng: 35.22809214043933}, signal: -97.10000000000001},
      {location: {lat: 31.94163891696271, lng: 35.228271954042405}, signal: -96.5},
      {location: {lat: 31.94163891696271, lng: 35.22845176764549}, signal: -92.0},
      {location: {lat: 31.94163891696271, lng: 35.228631581248564}, signal: -88.685},
      {location: {lat: 31.94163891696271, lng: 35.22881139485165}, signal: -91.00000000000001},
      {location: {lat: 31.94163891696271, lng: 35.22899120845472}, signal: -76.2},
      {location: {lat: 31.94163891696271, lng: 35.229171022057805}, signal: -78.0},
      {location: {lat: 31.94163891696271, lng: 35.22935083566088}, signal: -84.0},
      {location: {lat: 31.94163891696271, lng: 35.229530649263964}, signal: -83.0},
      {location: {lat: 31.94163891696271, lng: 35.22971046286704}, signal: -83.0},
      {location: {lat: 31.94163891696271, lng: 35.22989027647012}, signal: -84.0},
      {location: {lat: 31.94163891696271, lng: 35.23186822610399}, signal: -95.0},
      {location: {lat: 31.94163891696271, lng: 35.23204803970707}, signal: -95.0},
      {location: {lat: 31.94163891696271, lng: 35.23222785331015}, signal: -94.0},
      {location: {lat: 31.94163891696271, lng: 35.23510487095941}, signal: -83.0},
      {location: {lat: 31.94163891696271, lng: 35.235284684562494}, signal: -83.0},
      {location: {lat: 31.94163891696271, lng: 35.23762226140252}, signal: -104.0},
      {location: {lat: 31.94163891696271, lng: 35.23834151581484}, signal: -106.0},
      {location: {lat: 31.94163891696271, lng: 35.238521329417914}, signal: -104.0},
      {location: {lat: 31.94181873056579, lng: 35.21982071469769}, signal: -106.0},
      {location: {lat: 31.94181873056579, lng: 35.22000052830077}, signal: -106.0},
      {location: {lat: 31.94181873056579, lng: 35.2214390371254}, signal: -99.9},
      {location: {lat: 31.94181873056579, lng: 35.22161885072848}, signal: -100.5},
      {location: {lat: 31.94181873056579, lng: 35.22179866433156}, signal: -100.265},
      {location: {lat: 31.94181873056579, lng: 35.22197847793464}, signal: -93.0},
      {location: {lat: 31.94181873056579, lng: 35.22305735955312}, signal: -95.72999999999999},
      {location: {lat: 31.94181873056579, lng: 35.22323717315619}, signal: -95.60000000000001},
      {location: {lat: 31.94181873056579, lng: 35.223416986759275}, signal: -88.62},
      {location: {lat: 31.94181873056579, lng: 35.227552699630095}, signal: -97.0},
      {location: {lat: 31.94181873056579, lng: 35.22773251323317}, signal: -96.65},
      {location: {lat: 31.94181873056579, lng: 35.227912326836254}, signal: -78.0},
      {location: {lat: 31.94181873056579, lng: 35.23222785331015}, signal: -94.0},
      {location: {lat: 31.94181873056579, lng: 35.232407666913225}, signal: -94.0},
      {location: {lat: 31.94181873056579, lng: 35.23258748051631}, signal: -99.7},
      {location: {lat: 31.94181873056579, lng: 35.232767294119384}, signal: -100.0},
      {location: {lat: 31.94181873056579, lng: 35.23294710772247}, signal: -105.0},
      {location: {lat: 31.94181873056579, lng: 35.2343856165471}, signal: -115.0},
      {location: {lat: 31.94181873056579, lng: 35.23456543015018}, signal: -92.0},
      {location: {lat: 31.94181873056579, lng: 35.23474524375326}, signal: -97.0},
      {location: {lat: 31.94181873056579, lng: 35.234925057356335}, signal: -86.74999999999999},
      {location: {lat: 31.94181873056579, lng: 35.23762226140252}, signal: -100.0},
      {location: {lat: 31.94181873056579, lng: 35.23834151581484}, signal: -107.0},
      {location: {lat: 31.941998544168868, lng: 35.219640901094614}, signal: -105.99999999999999},
      {location: {lat: 31.941998544168868, lng: 35.2214390371254}, signal: -101.00000000000001},
      {location: {lat: 31.941998544168868, lng: 35.22197847793464}, signal: -96.9},
      {location: {lat: 31.941998544168868, lng: 35.22215829153772}, signal: -95.55},
      {location: {lat: 31.941998544168868, lng: 35.2223381051408}, signal: -93.0},
      {location: {lat: 31.941998544168868, lng: 35.222517918743875}, signal: -92.61},
      {location: {lat: 31.941998544168868, lng: 35.22269773234696}, signal: -93.75},
      {location: {lat: 31.941998544168868, lng: 35.222877545950034}, signal: -96.14500000000001},
      {location: {lat: 31.941998544168868, lng: 35.223057359553124}, signal: -95.64000000000001},
      {location: {lat: 31.941998544168868, lng: 35.223416986759275}, signal: -97.56},
      {location: {lat: 31.941998544168868, lng: 35.22359680036235}, signal: -88.9},
      {location: {lat: 31.941998544168868, lng: 35.22737288602701}, signal: -82.0},
      {location: {lat: 31.941998544168868, lng: 35.227552699630095}, signal: -96.55},
      {location: {lat: 31.941998544168868, lng: 35.23312692132554}, signal: -105.0},
      {location: {lat: 31.941998544168868, lng: 35.233306734928625}, signal: -105.0},
      {location: {lat: 31.941998544168868, lng: 35.233486548531694}, signal: -105.94999999999999},
      {location: {lat: 31.941998544168868, lng: 35.233666362134784}, signal: -106.9},
      {location: {lat: 31.941998544168868, lng: 35.23384617573786}, signal: -115.0},
      {location: {lat: 31.941998544168868, lng: 35.23402598934094}, signal: -115.0},
      {location: {lat: 31.941998544168868, lng: 35.23420580294402}, signal: -115.0},
      {location: {lat: 31.941998544168868, lng: 35.23762226140252}, signal: -104.0},
      {location: {lat: 31.941998544168868, lng: 35.23834151581484}, signal: -107.0},
      {location: {lat: 31.942178357771947, lng: 35.219281273888456}, signal: -106.0},
      {location: {lat: 31.942178357771947, lng: 35.21946108749153}, signal: -106.0},
      {location: {lat: 31.942178357771947, lng: 35.221259223522324}, signal: -99.85},
      {location: {lat: 31.942178357771947, lng: 35.2214390371254}, signal: -99.0},
      {location: {lat: 31.942178357771947, lng: 35.222877545950034}, signal: -97.0},
      {location: {lat: 31.942178357771947, lng: 35.22305735955312}, signal: -85.89},
      {location: {lat: 31.942178357771947, lng: 35.223416986759275}, signal: -89.18},
      {location: {lat: 31.942178357771947, lng: 35.22359680036235}, signal: -98.325},
      {location: {lat: 31.94217835777195, lng: 35.22737288602701}, signal: -95.12},
      {location: {lat: 31.942178357771947, lng: 35.23762226140252}, signal: -107.8},
      {location: {lat: 31.942178357771947, lng: 35.23834151581484}, signal: -109.0},
      {location: {lat: 31.942358171375027, lng: 35.2189216466823}, signal: -106.0},
      {location: {lat: 31.942358171375027, lng: 35.21910146028537}, signal: -106.0},
      {location: {lat: 31.942358171375027, lng: 35.221259223522324}, signal: -98.95},
      {location: {lat: 31.942358171375027, lng: 35.22269773234696}, signal: -96.55},
      {location: {lat: 31.942358171375027, lng: 35.222877545950034}, signal: -94.92},
      {location: {lat: 31.942358171375027, lng: 35.22305735955312}, signal: -95.5},
      {location: {lat: 31.942358171375027, lng: 35.22323717315619}, signal: -95.25},
      {location: {lat: 31.94235817137503, lng: 35.223416986759275}, signal: -94.68},
      {location: {lat: 31.942358171375027, lng: 35.22359680036235}, signal: -89.7},
      {location: {lat: 31.942358171375027, lng: 35.223776613965434}, signal: -89.05000000000001},
      {location: {lat: 31.942358171375027, lng: 35.22395642756851}, signal: -85.67999999999999},
      {location: {lat: 31.942358171375027, lng: 35.22719307242394}, signal: -79.0},
      {location: {lat: 31.942358171375027, lng: 35.22737288602701}, signal: -78.8},
      {location: {lat: 31.942358171375027, lng: 35.23762226140252}, signal: -107.89999999999999},
      {location: {lat: 31.942358171375027, lng: 35.23834151581484}, signal: -108.19},
      {location: {lat: 31.942537984978106, lng: 35.2189216466823}, signal: -106.0},
      {location: {lat: 31.94253798497811, lng: 35.221259223522324}, signal: -98.80000000000001},
      {location: {lat: 31.942537984978106, lng: 35.22269773234696}, signal: -99.00000000000001},
      {location: {lat: 31.94253798497811, lng: 35.22287754595004}, signal: -91.80000000000001},
      {location: {lat: 31.942537984978106, lng: 35.22323717315619}, signal: -92.0},
      {location: {lat: 31.942537984978106, lng: 35.223416986759275}, signal: -92.0},
      {location: {lat: 31.942537984978106, lng: 35.22359680036235}, signal: -94.0},
      {location: {lat: 31.942537984978106, lng: 35.22395642756851}, signal: -74.5},
      {location: {lat: 31.94253798497811, lng: 35.22413624117159}, signal: -93.0},
      {location: {lat: 31.942537984978106, lng: 35.22431605477467}, signal: -86.115},
      {location: {lat: 31.942537984978106, lng: 35.22719307242394}, signal: -87.4},
      {location: {lat: 31.942537984978106, lng: 35.237442447799445}, signal: -108.9},
      {location: {lat: 31.942537984978106, lng: 35.23816170221176}, signal: -110.0},
      {location: {lat: 31.942537984978106, lng: 35.23834151581484}, signal: -108.0},
      {location: {lat: 31.942717798581185, lng: 35.2189216466823}, signal: -105.99999999999999},
      {location: {lat: 31.94271779858119, lng: 35.221259223522324}, signal: -96.8},
      {location: {lat: 31.94271779858119, lng: 35.22269773234696}, signal: -85.28},
      {location: {lat: 31.942717798581185, lng: 35.22287754595004}, signal: -87.80000000000001},
      {location: {lat: 31.942717798581185, lng: 35.22431605477467}, signal: -76.71},
      {location: {lat: 31.942717798581185, lng: 35.22449586837775}, signal: -85.6},
      {location: {lat: 31.942717798581185, lng: 35.22467568198083}, signal: -78.0},
      {location: {lat: 31.942717798581185, lng: 35.227013258820854}, signal: -83.45},
      {location: {lat: 31.942717798581185, lng: 35.22719307242394}, signal: -82.6},
      {location: {lat: 31.942717798581185, lng: 35.23762226140252}, signal: -109.0},
      {location: {lat: 31.942717798581185, lng: 35.237802075005604}, signal: -109.0},
      {location: {lat: 31.942717798581185, lng: 35.23798188860868}, signal: -110.0},
      {location: {lat: 31.942897612184264, lng: 35.2189216466823}, signal: -106.0},
      {location: {lat: 31.942897612184264, lng: 35.22107940991925}, signal: -95.2},
      {location: {lat: 31.942897612184264, lng: 35.221259223522324}, signal: -92.5},
      {location: {lat: 31.942897612184268, lng: 35.22269773234696}, signal: -97.35},
      {location: {lat: 31.942897612184268, lng: 35.222877545950034}, signal: -79.72000000000001},
      {location: {lat: 31.942897612184268, lng: 35.22467568198083}, signal: -85.69999999999999},
      {location: {lat: 31.942897612184264, lng: 35.2248554955839}, signal: -77.41999999999999},
      {location: {lat: 31.942897612184264, lng: 35.225035309186985}, signal: -70.0},
      {location: {lat: 31.942897612184264, lng: 35.22611419080546}, signal: -74.0},
      {location: {lat: 31.942897612184264, lng: 35.22629400440853}, signal: -78.755},
      {location: {lat: 31.942897612184268, lng: 35.22647381801162}, signal: -83.25},
      {location: {lat: 31.942897612184264, lng: 35.226653631614695}, signal: -82.0},
      {location: {lat: 31.942897612184268, lng: 35.22683344521778}, signal: -83.50000000000001},
      {location: {lat: 31.942897612184264, lng: 35.227013258820854}, signal: -86.2},
      {location: {lat: 31.942897612184264, lng: 35.22719307242394}, signal: -73.0},
      {location: {lat: 31.942897612184264, lng: 35.22737288602701}, signal: -73.0},
      {location: {lat: 31.943077425787344, lng: 35.2189216466823}, signal: -106.0},
      {location: {lat: 31.943077425787344, lng: 35.22107940991925}, signal: -95.085},
      {location: {lat: 31.943077425787344, lng: 35.22269773234696}, signal: -96.9},
      {location: {lat: 31.943077425787344, lng: 35.22287754595003}, signal: -81.0},
      {location: {lat: 31.943077425787344, lng: 35.225035309186985}, signal: -85.55},
      {location: {lat: 31.943077425787344, lng: 35.22521512279006}, signal: -82.11},
      {location: {lat: 31.943077425787344, lng: 35.225394936393144}, signal: -76.52000000000002},
      {location: {lat: 31.943077425787344, lng: 35.22557474999622}, signal: -83.2},
      {location: {lat: 31.943077425787344, lng: 35.2257545635993}, signal: -83.39999999999999},
      {location: {lat: 31.943077425787344, lng: 35.22593437720238}, signal: -82.2},
      {location: {lat: 31.943077425787344, lng: 35.22611419080546}, signal: -83.25},
      {location: {lat: 31.943077425787344, lng: 35.22629400440853}, signal: -78.85},
      {location: {lat: 31.943077425787344, lng: 35.22737288602701}, signal: -73.0},
      {location: {lat: 31.943077425787344, lng: 35.227552699630095}, signal: -75.0},
      {location: {lat: 31.943257239390423, lng: 35.2189216466823}, signal: -106.0},
      {location: {lat: 31.943257239390423, lng: 35.22107940991925}, signal: -99.595},
      {location: {lat: 31.943257239390423, lng: 35.22269773234696}, signal: -97.75},
      {location: {lat: 31.943257239390423, lng: 35.222877545950034}, signal: -83.0},
      {location: {lat: 31.943257239390423, lng: 35.225394936393144}, signal: -83.0},
      {location: {lat: 31.943257239390423, lng: 35.227552699630095}, signal: -75.0},
      {location: {lat: 31.943437052993502, lng: 35.2189216466823}, signal: -105.94999999999999},
      {location: {lat: 31.943437052993502, lng: 35.22107940991925}, signal: -102.595},
      {location: {lat: 31.943437052993502, lng: 35.221259223522324}, signal: -113.7},
      {location: {lat: 31.943437052993502, lng: 35.2214390371254}, signal: -107.4},
      {location: {lat: 31.943437052993502, lng: 35.222517918743875}, signal: -92.09},
      {location: {lat: 31.943437052993502, lng: 35.22269773234696}, signal: -83.81},
      {location: {lat: 31.943437052993502, lng: 35.22773251323317}, signal: -74.0},
      {location: {lat: 31.943616866596578, lng: 35.2189216466823}, signal: -100.5},
      {location: {lat: 31.943616866596578, lng: 35.22107940991925}, signal: -103.0},
      {location: {lat: 31.943616866596578, lng: 35.221259223522324}, signal: -107.10000000000001},
      {location: {lat: 31.943616866596578, lng: 35.2214390371254}, signal: -93.8},
      {location: {lat: 31.943616866596578, lng: 35.2223381051408}, signal: -92.94999999999999},
      {location: {lat: 31.943616866596578, lng: 35.222517918743875}, signal: -93.335},
      {location: {lat: 31.943616866596578, lng: 35.22269773234696}, signal: -85.0},
      {location: {lat: 31.943616866596578, lng: 35.227912326836254}, signal: -74.0},
      {location: {lat: 31.943616866596578, lng: 35.22809214043933}, signal: -74.0},
      {location: {lat: 31.943796680199654, lng: 35.2189216466823}, signal: -101.99999999999999},
      {location: {lat: 31.943796680199654, lng: 35.22107940991925}, signal: -103.0},
      {location: {lat: 31.94379668019966, lng: 35.221259223522324}, signal: -103.0},
      {location: {lat: 31.943796680199657, lng: 35.22215829153772}, signal: -93.0},
      {location: {lat: 31.943796680199657, lng: 35.2223381051408}, signal: -92.7},
      {location: {lat: 31.943796680199654, lng: 35.22251791874387}, signal: -84.0},
      {location: {lat: 31.943796680199657, lng: 35.22809214043933}, signal: -74.0},
      {location: {lat: 31.943976493802733, lng: 35.21820239226998}, signal: -100.035},
      {location: {lat: 31.943976493802737, lng: 35.218741833079214}, signal: -96.0},
      {location: {lat: 31.943976493802737, lng: 35.2189216466823}, signal: -102.85},
      {location: {lat: 31.943976493802733, lng: 35.219101460285366}, signal: -103.75},
      {location: {lat: 31.943976493802737, lng: 35.219281273888456}, signal: -104.00000000000001},
      {location: {lat: 31.943976493802733, lng: 35.219461087491524}, signal: -103.89999999999999},
      {location: {lat: 31.943976493802737, lng: 35.219640901094614}, signal: -99.30000000000001},
      {location: {lat: 31.943976493802737, lng: 35.22107940991925}, signal: -100.3},
      {location: {lat: 31.94397649380274, lng: 35.221259223522324}, signal: -102.60000000000001},
      {location: {lat: 31.943976493802737, lng: 35.2214390371254}, signal: -91.63},
      {location: {lat: 31.943976493802737, lng: 35.22161885072848}, signal: -75.8},
      {location: {lat: 31.943976493802737, lng: 35.22215829153772}, signal: -92.75},
      {location: {lat: 31.943976493802737, lng: 35.2223381051408}, signal: -91.73},
      {location: {lat: 31.943976493802737, lng: 35.228271954042405}, signal: -74.10000000000001},
      {location: {lat: 31.943976493802737, lng: 35.22845176764549}, signal: -75.0},
      {location: {lat: 31.944156307405816, lng: 35.21820239226998}, signal: -103.0},
      {location: {lat: 31.944156307405816, lng: 35.218382205873056}, signal: -100.0},
      {location: {lat: 31.944156307405816, lng: 35.21856201947614}, signal: -98.0},
      {location: {lat: 31.944156307405812, lng: 35.219640901094614}, signal: -101.62499999999999},
      {location: {lat: 31.944156307405816, lng: 35.21982071469769}, signal: -102.0},
      {location: {lat: 31.944156307405812, lng: 35.22107940991925}, signal: -102.69999999999999},
      {location: {lat: 31.944156307405816, lng: 35.221259223522324}, signal: -99.25},
      {location: {lat: 31.944156307405812, lng: 35.22143903712539}, signal: -94.57},
      {location: {lat: 31.944156307405816, lng: 35.22161885072848}, signal: -99.7},
      {location: {lat: 31.944156307405812, lng: 35.22179866433156}, signal: -93.33},
      {location: {lat: 31.944156307405816, lng: 35.22197847793464}, signal: -96.0},
      {location: {lat: 31.94415630740582, lng: 35.22215829153772}, signal: -89.86000000000001},
      {location: {lat: 31.944156307405816, lng: 35.228631581248564}, signal: -75.0},
      {location: {lat: 31.9443361210089, lng: 35.21982071469769}, signal: -101.3},
      {location: {lat: 31.944336121008895, lng: 35.22000052830077}, signal: -93.0},
      {location: {lat: 31.944336121008895, lng: 35.22107940991925}, signal: -100.6},
      {location: {lat: 31.9443361210089, lng: 35.221259223522324}, signal: -105.22},
      {location: {lat: 31.944336121008895, lng: 35.2214390371254}, signal: -103.0},
      {location: {lat: 31.944336121008895, lng: 35.22161885072848}, signal: -92.53},
      {location: {lat: 31.944336121008895, lng: 35.22179866433156}, signal: -93.9},
      {location: {lat: 31.94433612100889, lng: 35.22197847793464}, signal: -80.0},
      {location: {lat: 31.944336121008895, lng: 35.22881139485165}, signal: -73.0},
      {location: {lat: 31.944515934611978, lng: 35.22000052830077}, signal: -103.92},
      {location: {lat: 31.94451593461197, lng: 35.22107940991925}, signal: -105.46000000000001},
      {location: {lat: 31.944515934611974, lng: 35.221259223522324}, signal: -104.93},
      {location: {lat: 31.944515934611978, lng: 35.2214390371254}, signal: -105.18},
      {location: {lat: 31.944515934611974, lng: 35.22161885072848}, signal: -106.0},
      {location: {lat: 31.944515934611974, lng: 35.22179866433156}, signal: -104.6},
      {location: {lat: 31.944515934611974, lng: 35.22899120845472}, signal: -73.0},
      {location: {lat: 31.944515934611974, lng: 35.229171022057805}, signal: -74.0},
      {location: {lat: 31.94469574821505, lng: 35.22000052830077}, signal: -101.89999999999999},
      {location: {lat: 31.944695748215054, lng: 35.220899596316166}, signal: -103.42999999999999},
      {location: {lat: 31.944695748215054, lng: 35.22107940991925}, signal: -107.6},
      {location: {lat: 31.944695748215054, lng: 35.221259223522324}, signal: -110.01999999999998},
      {location: {lat: 31.944695748215054, lng: 35.2214390371254}, signal: -111.0},
      {location: {lat: 31.944695748215054, lng: 35.229171022057805}, signal: -74.0},
      {location: {lat: 31.944875561818133, lng: 35.22018034190385}, signal: -100.0},
      {location: {lat: 31.944875561818133, lng: 35.22071978271309}, signal: -103.0},
      {location: {lat: 31.944875561818133, lng: 35.220899596316166}, signal: -101.8},
      {location: {lat: 31.944875561818133, lng: 35.22107940991925}, signal: -110.8},
      {location: {lat: 31.944875561818133, lng: 35.221259223522324}, signal: -109.25000000000001},
      {location: {lat: 31.944875561818133, lng: 35.22359680036235}, signal: -72.0},
      {location: {lat: 31.94487556181813, lng: 35.223776613965434}, signal: -78.85},
      {location: {lat: 31.944875561818133, lng: 35.22935083566088}, signal: -76.0},
      {location: {lat: 31.945055375421212, lng: 35.22018034190385}, signal: -100.0},
      {location: {lat: 31.945055375421212, lng: 35.22036015550693}, signal: -97.15499999999999},
      {location: {lat: 31.945055375421212, lng: 35.22071978271309}, signal: -107.0},
      {location: {lat: 31.945055375421212, lng: 35.221259223522324}, signal: -107.36},
      {location: {lat: 31.945055375421212, lng: 35.2214390371254}, signal: -109.10000000000001},
      {location: {lat: 31.945055375421212, lng: 35.223416986759275}, signal: -73.0},
      {location: {lat: 31.945055375421212, lng: 35.22395642756851}, signal: -77.0},
      {location: {lat: 31.945055375421212, lng: 35.22413624117159}, signal: -73.95},
      {location: {lat: 31.945055375421212, lng: 35.22431605477467}, signal: -75.0},
      {location: {lat: 31.945055375421212, lng: 35.22449586837775}, signal: -75.0},
      {location: {lat: 31.945055375421212, lng: 35.22467568198083}, signal: -74.28500000000001},
      {location: {lat: 31.945055375421212, lng: 35.2248554955839}, signal: -87.0},
      {location: {lat: 31.945055375421212, lng: 35.229530649263964}, signal: -76.0},
      {location: {lat: 31.94523518902429, lng: 35.22053996911001}, signal: -107.2},
      {location: {lat: 31.94523518902429, lng: 35.22071978271309}, signal: -108.0},
      {location: {lat: 31.94523518902429, lng: 35.221259223522324}, signal: -108.95},
      {location: {lat: 31.94523518902429, lng: 35.223416986759275}, signal: -76.8},
      {location: {lat: 31.94523518902429, lng: 35.2248554955839}, signal: -82.0},
      {location: {lat: 31.94523518902429, lng: 35.225035309186985}, signal: -82.0},
      {location: {lat: 31.94523518902429, lng: 35.22971046286704}, signal: -76.0},
      {location: {lat: 31.94523518902429, lng: 35.22989027647012}, signal: -76.0},
      {location: {lat: 31.94541500262737, lng: 35.22053996911001}, signal: -92.0},
      {location: {lat: 31.94541500262737, lng: 35.22071978271309}, signal: -94.0},
      {location: {lat: 31.94541500262737, lng: 35.221259223522324}, signal: -109.0},
      {location: {lat: 31.94541500262737, lng: 35.222877545950034}, signal: -75.0},
      {location: {lat: 31.94541500262737, lng: 35.22305735955312}, signal: -75.0},
      {location: {lat: 31.94541500262737, lng: 35.223237173156186}, signal: -70.0},
      {location: {lat: 31.94541500262737, lng: 35.223416986759275}, signal: -77.0},
      {location: {lat: 31.94541500262737, lng: 35.225035309186985}, signal: -79.0},
      {location: {lat: 31.94541500262737, lng: 35.22521512279006}, signal: -79.0},
      {location: {lat: 31.94541500262737, lng: 35.22989027647012}, signal: -76.0},
      {location: {lat: 31.94559481623045, lng: 35.220899596316166}, signal: -89.95},
      {location: {lat: 31.94559481623045, lng: 35.221259223522324}, signal: -109.0},
      {location: {lat: 31.94559481623045, lng: 35.22269773234696}, signal: -78.0},
      {location: {lat: 31.94559481623045, lng: 35.222877545950034}, signal: -75.0},
      {location: {lat: 31.94559481623045, lng: 35.22521512279006}, signal: -85.0},
      {location: {lat: 31.94559481623045, lng: 35.22989027647012}, signal: -78.0},
      {location: {lat: 31.94577462983353, lng: 35.22053996911001}, signal: -106.0},
      {location: {lat: 31.94577462983353, lng: 35.22107940991925}, signal: -84.5},
      {location: {lat: 31.94577462983353, lng: 35.221259223522324}, signal: -109.0},
      {location: {lat: 31.94577462983353, lng: 35.22269773234696}, signal: -78.85},
      {location: {lat: 31.94577462983353, lng: 35.225394936393144}, signal: -93.0},
      {location: {lat: 31.94577462983353, lng: 35.22557474999622}, signal: -90.0},
      {location: {lat: 31.94577462983353, lng: 35.22989027647012}, signal: -78.0},
      {location: {lat: 31.94595444343661, lng: 35.22036015550693}, signal: -106.0},
      {location: {lat: 31.94595444343661, lng: 35.22053996911001}, signal: -106.94999999999999},
      {location: {lat: 31.94595444343661, lng: 35.221259223522324}, signal: -75.0},
      {location: {lat: 31.94595444343661, lng: 35.2214390371254}, signal: -85.60000000000001},
      {location: {lat: 31.94595444343661, lng: 35.22161885072848}, signal: -71.0},
      {location: {lat: 31.94595444343661, lng: 35.22179866433156}, signal: -69.0},
      {location: {lat: 31.94595444343661, lng: 35.222517918743875}, signal: -85.0},
      {location: {lat: 31.94595444343661, lng: 35.22269773234696}, signal: -81.10000000000001},
      {location: {lat: 31.94595444343661, lng: 35.2257545635993}, signal: -90.0},
      {location: {lat: 31.94595444343661, lng: 35.23007009007319}, signal: -71.99999999999999},
      {location: {lat: 31.946134257039688, lng: 35.22036015550693}, signal: -106.99999999999999},
      {location: {lat: 31.946134257039688, lng: 35.22053996911001}, signal: -107.0},
      {location: {lat: 31.946134257039688, lng: 35.22197847793464}, signal: -75.0},
      {location: {lat: 31.946134257039688, lng: 35.22215829153772}, signal: -83.0},
      {location: {lat: 31.946134257039688, lng: 35.2223381051408}, signal: -86.0},
      {location: {lat: 31.946134257039688, lng: 35.222517918743875}, signal: -85.0},
      {location: {lat: 31.946134257039688, lng: 35.2257545635993}, signal: -94.0},
      {location: {lat: 31.946134257039688, lng: 35.22593437720238}, signal: -94.0},
      {location: {lat: 31.946134257039688, lng: 35.2300700900732}, signal: -77.0},
      {location: {lat: 31.94631407064277, lng: 35.22036015550693}, signal: -107.0},
      {location: {lat: 31.946314070642767, lng: 35.22053996911001}, signal: -106.99999999999999},
      {location: {lat: 31.946314070642767, lng: 35.22593437720238}, signal: -95.0},
      {location: {lat: 31.946314070642767, lng: 35.23024990367628}, signal: -77.94999999999999},
      {location: {lat: 31.946493884245847, lng: 35.22036015550693}, signal: -107.09499999999998},
      {location: {lat: 31.946493884245847, lng: 35.22053996911001}, signal: -106.99999999999999},
      {location: {lat: 31.946493884245847, lng: 35.22611419080546}, signal: -94.95},
      {location: {lat: 31.946493884245847, lng: 35.23024990367628}, signal: -78.0},
      {location: {lat: 31.946493884245847, lng: 35.23042971727936}, signal: -78.0},
      {location: {lat: 31.946673697848926, lng: 35.22629400440853}, signal: -96.99999999999999},
      {location: {lat: 31.946673697848926, lng: 35.23042971727936}, signal: -78.0},
      {location: {lat: 31.946673697848926, lng: 35.23060953088244}, signal: -77.94999999999999},
      {location: {lat: 31.946853511452005, lng: 35.22647381801162}, signal: -99.0},
      {location: {lat: 31.946853511452005, lng: 35.230789344485515}, signal: -83.0},
      {location: {lat: 31.94703332505508, lng: 35.22647381801162}, signal: -99.0},
      {location: {lat: 31.94703332505508, lng: 35.230789344485515}, signal: -84.0},
      {location: {lat: 31.947213138658157, lng: 35.22647381801162}, signal: -99.0},
      {location: {lat: 31.94721313865816, lng: 35.230789344485515}, signal: -85.0},
      {location: {lat: 31.94739295226124, lng: 35.226653631614695}, signal: -99.0},
      {location: {lat: 31.94739295226124, lng: 35.23060953088244}, signal: -83.0},
      {location: {lat: 31.94739295226124, lng: 35.231148971691674}, signal: -91.0},
      {location: {lat: 31.94739295226124, lng: 35.23132878529476}, signal: -86.0},
      {location: {lat: 31.94739295226124, lng: 35.23150859889783}, signal: -82.0},
      {location: {lat: 31.947572765864315, lng: 35.22665363161469}, signal: -100.0},
      {location: {lat: 31.94757276586432, lng: 35.23060953088244}, signal: -82.0},
      {location: {lat: 31.94757276586432, lng: 35.230789344485515}, signal: -73.0},
      {location: {lat: 31.94757276586432, lng: 35.2309691580886}, signal: -76.0},
      {location: {lat: 31.94757276586432, lng: 35.231148971691674}, signal: -82.0},
      {location: {lat: 31.94757276586432, lng: 35.23150859889783}, signal: -88.0},
      {location: {lat: 31.947752579467394, lng: 35.22665363161469}, signal: -100.99999999999999},
      {location: {lat: 31.947752579467398, lng: 35.23150859889783}, signal: -88.0},
      {location: {lat: 31.947752579467398, lng: 35.23168841250091}, signal: -91.0},
      {location: {lat: 31.947932393070474, lng: 35.22683344521778}, signal: -103.0},
      {location: {lat: 31.947932393070474, lng: 35.23168841250091}, signal: -86.0},
      {location: {lat: 31.947932393070477, lng: 35.23186822610399}, signal: -92.0},
      {location: {lat: 31.948112206673557, lng: 35.22683344521778}, signal: -104.0},
      {location: {lat: 31.948112206673557, lng: 35.23186822610399}, signal: -92.0},
      {location: {lat: 31.948112206673557, lng: 35.23204803970707}, signal: -92.0},
      {location: {lat: 31.948292020276632, lng: 35.227013258820854}, signal: -103.6},
      {location: {lat: 31.948292020276632, lng: 35.23204803970707}, signal: -100.99999999999999},
      {location: {lat: 31.948471833879715, lng: 35.227013258820854}, signal: -96.0},
      {location: {lat: 31.948471833879715, lng: 35.23222785331015}, signal: -106.99999999999999},
      {location: {lat: 31.948471833879715, lng: 35.232407666913225}, signal: -114.0},
      {location: {lat: 31.948651647482794, lng: 35.227013258820854}, signal: -89.0},
      {location: {lat: 31.948651647482794, lng: 35.22719307242394}, signal: -89.0},
      {location: {lat: 31.948651647482794, lng: 35.232407666913225}, signal: -114.0},
      {location: {lat: 31.948651647482794, lng: 35.23258748051631}, signal: -103.0},
      {location: {lat: 31.948651647482794, lng: 35.232767294119384}, signal: -103.0},
      {location: {lat: 31.948651647482794, lng: 35.23294710772247}, signal: -98.0},
      {location: {lat: 31.948831461085874, lng: 35.22719307242394}, signal: -89.0},
      {location: {lat: 31.948831461085874, lng: 35.233126921325535}, signal: -105.69999999999999},
      {location: {lat: 31.949011274688953, lng: 35.22719307242394}, signal: -93.0},
      {location: {lat: 31.949011274688953, lng: 35.23312692132554}, signal: -106.0},
      {location: {lat: 31.949011274688953, lng: 35.233306734928625}, signal: -105.0},
      {location: {lat: 31.949191088292032, lng: 35.22719307242394}, signal: -98.0},
      {location: {lat: 31.949191088292032, lng: 35.233306734928625}, signal: -105.0},
      {location: {lat: 31.949191088292032, lng: 35.2334865485317}, signal: -104.0},
      {location: {lat: 31.94937090189511, lng: 35.22719307242394}, signal: -98.0},
      {location: {lat: 31.94937090189511, lng: 35.22737288602701}, signal: -95.0},
      {location: {lat: 31.94937090189511, lng: 35.2334865485317}, signal: -104.0},
      {location: {lat: 31.94937090189511, lng: 35.233666362134784}, signal: -104.0},
      {location: {lat: 31.94955071549819, lng: 35.22737288602701}, signal: -94.955},
      {location: {lat: 31.94955071549819, lng: 35.233666362134784}, signal: -104.0},
      {location: {lat: 31.94973052910127, lng: 35.227552699630095}, signal: -94.0},
      {location: {lat: 31.94973052910127, lng: 35.23384617573786}, signal: -104.0},
      {location: {lat: 31.94973052910127, lng: 35.23402598934094}, signal: -105.0},
      {location: {lat: 31.94991034270435, lng: 35.227552699630095}, signal: -94.0},
      {location: {lat: 31.94991034270435, lng: 35.23402598934094}, signal: -105.0},
      {location: {lat: 31.95009015630743, lng: 35.22773251323317}, signal: -101.99999999999999},
      {location: {lat: 31.95009015630743, lng: 35.23024990367628}, signal: -96.0},
      {location: {lat: 31.95009015630743, lng: 35.23042971727936}, signal: -100.0},
      {location: {lat: 31.95009015630743, lng: 35.23060953088244}, signal: -99.0},
      {location: {lat: 31.95009015630743, lng: 35.23402598934094}, signal: -108.0},
      {location: {lat: 31.95009015630743, lng: 35.23420580294402}, signal: -108.0},
      {location: {lat: 31.950269969910508, lng: 35.227912326836254}, signal: -100.99999999999999},
      {location: {lat: 31.950269969910508, lng: 35.22809214043933}, signal: -99.0},
      {location: {lat: 31.950269969910508, lng: 35.2300700900732}, signal: -96.0},
      {location: {lat: 31.950269969910508, lng: 35.230789344485515}, signal: -98.75},
      {location: {lat: 31.950269969910508, lng: 35.23420580294402}, signal: -106.0},
      {location: {lat: 31.950449783513584, lng: 35.22809214043933}, signal: -99.0},
      {location: {lat: 31.950449783513584, lng: 35.228271954042405}, signal: -100.0},
      {location: {lat: 31.950449783513584, lng: 35.22989027647012}, signal: -98.0},
      {location: {lat: 31.950449783513584, lng: 35.2300700900732}, signal: -97.0},
      {location: {lat: 31.950449783513584, lng: 35.230789344485515}, signal: -93.75},
      {location: {lat: 31.950449783513584, lng: 35.23420580294402}, signal: -105.99999999999999},
      {location: {lat: 31.950629597116663, lng: 35.228271954042405}, signal: -100.0},
      {location: {lat: 31.950629597116663, lng: 35.22845176764549}, signal: -96.0},
      {location: {lat: 31.95062959711666, lng: 35.229530649263964}, signal: -100.99999999999999},
      {location: {lat: 31.950629597116663, lng: 35.22971046286704}, signal: -101.0},
      {location: {lat: 31.950629597116663, lng: 35.22989027647012}, signal: -98.0},
      {location: {lat: 31.95062959711666, lng: 35.2309691580886}, signal: -89.95},
      {location: {lat: 31.95062959711666, lng: 35.23420580294402}, signal: -106.94999999999999},
      {location: {lat: 31.95080941071974, lng: 35.228631581248564}, signal: -95.99999999999999},
      {location: {lat: 31.950809410719742, lng: 35.22881139485165}, signal: -96.0},
      {location: {lat: 31.95080941071974, lng: 35.228991208454715}, signal: -95.85},
      {location: {lat: 31.95080941071974, lng: 35.229171022057805}, signal: -100.6},
      {location: {lat: 31.95080941071974, lng: 35.2309691580886}, signal: -89.45},
      {location: {lat: 31.950809410719742, lng: 35.23420580294402}, signal: -107.0},
      {location: {lat: 31.950989224322818, lng: 35.231148971691674}, signal: -78.64999999999999},
      {location: {lat: 31.950989224322818, lng: 35.23402598934094}, signal: -108.0},
      {location: {lat: 31.9511690379259, lng: 35.231148971691674}, signal: -72.0},
      {location: {lat: 31.9511690379259, lng: 35.23132878529476}, signal: -72.0},
      {location: {lat: 31.9511690379259, lng: 35.23384617573786}, signal: -102.0},
      {location: {lat: 31.9511690379259, lng: 35.23402598934094}, signal: -102.6},
      {location: {lat: 31.95134885152898, lng: 35.23132878529476}, signal: -69.0},
      {location: {lat: 31.95134885152898, lng: 35.233666362134784}, signal: -104.0},
      {location: {lat: 31.95134885152898, lng: 35.23384617573786}, signal: -102.0},
      {location: {lat: 31.95152866513206, lng: 35.23150859889783}, signal: -69.0},
      {location: {lat: 31.95152866513206, lng: 35.233306734928625}, signal: -109.0},
      {location: {lat: 31.95152866513206, lng: 35.2334865485317}, signal: -102.0},
      {location: {lat: 31.951528665132056, lng: 35.233666362134784}, signal: -103.89999999999999},
      {location: {lat: 31.951708478735135, lng: 35.23150859889783}, signal: -70.0},
      {location: {lat: 31.951708478735135, lng: 35.23294710772247}, signal: -105.99999999999999},
      {location: {lat: 31.95170847873514, lng: 35.23312692132554}, signal: -109.0},
      {location: {lat: 31.95170847873514, lng: 35.233306734928625}, signal: -109.0},
      {location: {lat: 31.951888292338218, lng: 35.23168841250091}, signal: -74.0},
      {location: {lat: 31.951888292338218, lng: 35.232407666913225}, signal: -106.0},
      {location: {lat: 31.951888292338218, lng: 35.23258748051631}, signal: -115.0},
      {location: {lat: 31.951888292338218, lng: 35.232767294119384}, signal: -115.0},
      {location: {lat: 31.952068105941297, lng: 35.23186822610399}, signal: -78.30000000000001},
      {location: {lat: 31.952068105941297, lng: 35.23204803970707}, signal: -115.0},
      {location: {lat: 31.952068105941297, lng: 35.23222785331015}, signal: -114.55},
    ],
    algorithm: "static-clustering",
  },
];
