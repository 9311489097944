import React, {useRef, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo1 from "../images/logo/logo-13.png";
import logo2 from "../images/logo/logo-14.png";
import {connect} from "react-redux";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import Constants, {mapLayerTypes, techUsageCategoriesThresholds} from "../controller/Constants";
import {modals, mapLayers} from "../controller/Constants";
import useProject, {useProjectParams, useSetProject} from "../Hooks/useProject";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import DropdownItem from "./Common/Dropdown/DropdownItem";
import StarsBar from "./Common/StarsBar";
import StarsBarInfo from "./Common/StarsBarInfo";
import {logoutUser} from "../store/actionCreators/user.js";
import {resetProject} from "../store/actionCreators/general";
import {resetProjects, setDisplayedSectors} from "../store/actionCreators/map";
import Api, {UserApi} from "../controller/ApiManager";
import {A, Div, NavbarHeader, StyledBadge, StyledProgress} from "./NavbarStyles";
import {getLayerType} from "../controller/mapper";
import {useAverageSignal, useFetchLoader, useUser, useTechnologiesCoverage, useUserGroups, useArieCoverageReliabilityState} from "../Hooks/common";
import {useUserPreferencesState, useUserPreferencesActions} from "../Hooks/useUserPreferences";
import styled from "styled-components";
import {useGetColor} from "../Hooks/useGetColor"; //  useGetColor";
import {object} from "prop-types";
import TechnologyRating,{calcTechnologyCoverage} from './TechnologyRating'
import {useLocationStatsState} from "../Hooks/map"
import {DEFAULT_LOCATION_STATS} from "../store/defaultValues/map"

const {smartTypeStrings} = Constants;
const {REAL_TIME, HEAT_MAP, CELLULAR, BEST_SERVER} = mapLayerTypes;

const isProd = Constants.STAGE_MODE === "prod";

const EmptyDiv = styled.div`
  width: 10px;
`;

function Navbar({dispatch, lines, logoutUser, projects, resetProject, resetProjects, ...props}) {
  const {t} = useTranslation();
  const project = useProject();
  const setProject = useSetProject();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const history = useHistory();
  const {resetPreferences, updateUserData, updateUserPreferences} = useUserPreferencesActions();
  const projectParams = useProjectParams();
  const {rssiThreshold, mapLayer} = projectParams;
  const mapLayerTypeType = getLayerType(mapLayer);
  const {isAuthenticated,user} = useUser();  
  const [fetching, setFetchLoader] = useFetchLoader();
  const [ArieCoverageReliability, setArieCoverageReliability] = useArieCoverageReliabilityState();
  const getColor = useGetColor();
  const userGroups = useUserGroups();
  const isAdmin = userGroups?.includes(Constants.userGroups.ADMIN);
  const technologiesCoverage = useTechnologiesCoverage();
  let ArieCoverageReliabilityKey = "";


  if(userPreferences.technologies.length > 0 && userPreferences.providers.length > 0){

    if(userPreferences.technologies[0].includes("LTE M") && userPreferences.providers[0].includes("AT&T")){
      ArieCoverageReliabilityKey = "AT&T (AT&T Mobility) | LTE M | United States\r"

  
    }else if(userPreferences.technologies[0].includes("LTE M") && userPreferences.providers[0].includes("Verizon")){
      ArieCoverageReliabilityKey = "Verizon Wireless | LTE M | United States"

  
    }else if(userPreferences.technologies[0].includes("NB-IoT") && userPreferences.providers[0].includes("Verizon")){
      ArieCoverageReliabilityKey = "Verizon Wireless | NB-IoT | United States"

  
    }
  }

  // const configFileManagerRef = useRef(null);
  const timeoutRef = useRef();
  const averageSignal = useAverageSignal();
  const [,setLocationStats ] = useLocationStatsState();
  const starRate = 5; ///////////////////////////////// ? //? / / /

  const [charts, setCharts] = useState(false);



  function openDialog(dialogName) {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: dialogName,
    });
  }

  function openAddProjectDialog(ev) {
    openDialog(modals.ADD_PROJECT);
  }

  function resetBins() {
    resetPreferences();
  }

  function handleSetCurrentMapLayer(mapLayer) {
    updateUserPreferences({...userPreferences, currentMapLayer: mapLayer});
  }

  async function toggleRealTimePropOfCurrentMapLayer() {
    if (typeof projectParams.isRealTime == "undefined")
      throw Error("The field 'isRealTime' is not yet implemented for this currentMapLayer.projectParams");
    const newProjectParams = {...projectParams, isRealTime: !projectParams.isRealTime};
    const newMapLayersParams = project.mapLayersParams.map((layerParams) => {
      if (layerParams.mapLayer === userPreferences.currentMapLayer) {
        return {...layerParams, projectParams: newProjectParams};
      }
      return layerParams;
    });
    setProject({...project, mapLayersParams: newMapLayersParams});
    try {
      const updatedProjectParams = await Api.ProjectParams.editProjectParams(project._id, newProjectParams);
    } catch (err) {
      console.log("There was a problem updating ProjectParams:");
      console.log(err);
    }
  }

  function openDisplayCoverage(ev) {
    openDialog(modals.DISPLAY_COVERAGE);
  }

  function showProjects() {
    openDialog(modals.PROJECT_LIST);
  }

  function openEditProjectDialog() {
    openDialog(modals.EDIT_PROJECT);
  }

  function setBestSever() {
    console.log("'setBestSever' was clicked but is not implemented!");
  }

  function setC2I() {
    console.log("'setC2I' was clicked but is not implemented!");
  }

  function updateData() {
    updateUserData();
  }

  function openLoginDialog() {
    openDialog(modals.LOGIN);
  }
    const cleanReduxBeforeLogout = ()=> {
    setLocationStats(DEFAULT_LOCATION_STATS)
  }

  const logout = async () => {
    try {
      //delete from Redux the state that set the location marker
      cleanReduxBeforeLogout();

      const logoutRes = await UserApi.logout(user.email);
      console.log("logout:", logoutRes);
      resetProject();
      resetProjects();
      logoutUser();
      history.push("/");
    } catch (e) {
      console.log({e});
      console.error(e);
    }
  };

  function onDisplayCoverage() {
    openDisplayCoverage();
  }

  function returnNavbars() {
    let tech = "3G";
    if (userPreferences.technologies.includes("5G")) tech = "5G";
    else if (userPreferences.technologies.includes("4G")) tech = "4G";

    return Object.keys(techUsageCategoriesThresholds[tech]).map((title) => {
      if (title != "General")
        return (
          <DropdownItem title={title} icon={<i className={"fa fa-cog nav-icon"} />} content={<StarsBarInfo showPercentage title={title} />} />
        );
    });
  }


  function handleCharts() {
      if(charts) history.goBack()
      else history.push("/charts");
//    else history.push(`/projects/${project._id}/charts`)
      setCharts(!charts)  
  }

  function OpenCreateUserDialog() {
    openDialog(modals.CREATE_USER);
  }

  return (
    <NavbarHeader id="page-header">
      <nav>
        <div className="left">
          <div className="secondary-nav">
            {!isAuthenticated ? <a onClick={openLoginDialog}>{t("Sign_In")}</a> : <a onClick={logout}>{t("Logout_")}</a>}
            {/*<a onClick={openRegisterDialog} className="">{t('Register')}</a>*/}
          </div>
          {isAuthenticated && user.isTermsChecked &&(

            <>
              <div className="primary-nav has-mega-menu">
                <ul className="navigation">
                  <NavbarDropdown title={t("Projects")} onClick={showProjects}>
                    <DropdownItem
                      title={
                        <>
                          <i className="fa fa-sitemap nav-icon" /> <span>{t("Add_Project")}</span>
                        </>
                      }
                      onClick={openAddProjectDialog}
                    />
                    <DropdownItem
                      title={
                        <>
                          <i className="fa fa-pencil-square-o nav-icon" />
                          <span>{t("Edit_Project")}</span>
                        </>
                      }
                      onClick={openEditProjectDialog}
                    />
                    <DropdownItem title={t("List_")} icon={<i className="fa fa-list-ul nav-icon" />}>
                      {projects.map((project) => (
                        <li key={project._id}>
                          <Link to={"/projects/" + project._id} className={`add-listing`}>
                            <i className="fa fa-map-marker nav-icon" />
                            <span>
                              {project.name
                                .split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
                                .join(" ")}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </DropdownItem>
                    {isAdmin&& <DropdownItem title={t("Create_User")} onClick={OpenCreateUserDialog} icon={<i className="fa fa-solid fa-user-plus nav-icon" />}/>}
                  </NavbarDropdown>
                  <NavbarDropdown title={t("Controls")}>
                    <DropdownItem title={t("Map_Type")} icon={<i className="fa fa-globe nav-icon" />}>
                      <DropdownItem title={t("Best_Server")} onClick={() => handleSetCurrentMapLayer(BEST_SERVER)} />
                      <DropdownItem title={t("cellular_map_")} onClick={() => handleSetCurrentMapLayer(CELLULAR)} />
                      <DropdownItem title={t("real_time_map_")} onClick={() => console.log(`Click on ${REAL_TIME} mapLayer`)} />
                      <DropdownItem title={t("heat_map_")} onClick={() => handleSetCurrentMapLayer(HEAT_MAP)} />
                    </DropdownItem>
                    <DropdownItem title={t("Setting_")} icon={<i className={"fa fa-cog nav-icon"} />}>
                      <DropdownItem title={t("update_data")} onClick={updateData} />
                      {/* <DropdownItem title={t("import_project_file_")} onClick={() => configFileManagerRef.current.uploadFile()} />
                      <DropdownItem title={t("export_project_file_")} onClick={() => configFileManagerRef.current.downloadFile()} />
                      <ConfigFileManager ref={configFileManagerRef} /> */}
                    </DropdownItem>
                    <DropdownItem
                      title={t("Reset_Display")}
                      icon={<i className="fa fa-window-close-o nav-icon" />}
                      onClick={() => resetBins()}
                    />
                    {/* <DropdownItem
                      title={t("Tech_Conversion")}
                      icon={<i className="fa fa-magic nav-icon" />}
                      onClick={() => openDialog(modals.TECH_CONVERSION)}
                    /> */}
                  </NavbarDropdown>
                </ul>
              </div>
              {true && (
                <>
                  <div className="third-nav">
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={userPreferences.currentMapLayer === BEST_SERVER}
                      onClick={() => handleSetCurrentMapLayer(BEST_SERVER)}
                    >
                      <i className="fa fa-object-group" />
                      <span>{t("best_server_map_")}</span>
                    </A>
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={userPreferences.currentMapLayer === CELLULAR}
                      onClick={() => handleSetCurrentMapLayer(CELLULAR)}
                    >
                      <i className="fa fa-wifi cellular-icon" />
                      <span>{t("cellular_map_")}</span>
                    </A>
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing a-disabled"
                      active={projectParams.isRealTime}
                      onClick={() => toggleRealTimePropOfCurrentMapLayer()}
                    >
                      <i className="fa fa-object-group" />
                      <span>{t("real_time_map_")}</span>
                    </A>
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={userPreferences.currentMapLayer === HEAT_MAP}
                      onClick={() => handleSetCurrentMapLayer(HEAT_MAP)}
                    >
                      <img src="/assets/img/heatmap icon.jpg" className="img-icon" />
                      <span>{t("heat_map_")}</span>
                    </A>
                  </div>
                </>
              )}

              <Div>
                {t("Rate")}
                &nbsp; &nbsp;
                <StarsBarInfo title={"General"} starRate={calcTechnologyCoverage(technologiesCoverage["General"]).starRate} />
                {!(userPreferences.currentMapLayer === mapLayerTypes.HEAT_MAP) && (

                  <div className="primary-nav has-mega-menu">
                    <ul className="navigation">
                      <NavbarDropdown title={""}>{<TechnologyRating showIcons={true} userPreferences={userPreferences} technologiesCoverage={technologiesCoverage}/>}</NavbarDropdown>
                    </ul>
                  </div>
                )}
              </Div>
              <Div>
                {`Coverage Reliability: ${ArieCoverageReliability[ArieCoverageReliabilityKey]? ArieCoverageReliability[ArieCoverageReliabilityKey]: "No data"}`}
                &nbsp; &nbsp;
             
              </Div>
            </>
          )}
        </div>
        <A
          className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${isAdmin?'':'a-disabled'}`}
          onClick={() => handleCharts()}
        // active={userPreferences.currentMapLayer === HEAT_MAP}
        // onClick={() => handleCurrentMapLayer("heatmap")}
        >
          <i className="fa fa-bar-chart-o" />
          <span>{t("Charts")}</span>
        </A>
        <div className="right">
          <a href="/" className="brand">
            <img className="logo-icon" src={logo1} alt="flycomm icon" />
            <img className="logo-text" src={logo2} alt="flycomm text" />
          </a>
        </div>
      </nav>
    </NavbarHeader>
  );
}

function mapStateToProps(reduxState) {
  return {
    lines: reduxState.map.lines,
    user: reduxState.user,
    projects: reduxState.map.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    logoutUser: () => dispatch(logoutUser()),
    resetProject: () => dispatch(resetProject()),
    resetProjects: () => dispatch(resetProjects()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);