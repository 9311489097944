export const mockProject = {
  // _id: "61c2f32bb6218800086aa557",
  _id: "61e82fbf59dd9400093d6752",
  cityName: "New York",
  provider: null,
  currentMapLayer: "RSRP - Coverage Optimized",
  name: "test-renew",
  created: "2021-12-22T09:43:07.886Z",
  lastModified: "2021-12-22T09:43:07.886Z",
  polygon: [
    {
      lat: 32.05623471962127,
      lng: 35.356522265624996,
    },
    {
      lat: 32.05623471962127,
      lng: 35.081864062499996,
    },
    {
      lat: 31.823158581318197,
      lng: 35.081864062499996,
    },
    {
      lat: 31.823158581318197,
      lng: 35.356522265624996,
    },
  ],
  binsPlacements: [
    {
      provider: "cellcom",
      technology: "4G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8905214,
            lng: 35.0641499,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.890129,
            lng: 35.0653518,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "cellcom",
      technology: "5G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.890629,
            lng: 35.0638385,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.8907415,
            lng: 35.0635254,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "cellcom",
      technology: "3G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8908558,
            lng: 35.0632021,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "pelephone",
      technology: "3G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8779821,
            lng: 35.0063704,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "pelephone",
      technology: "4G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "pelephone",
      technology: "5G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.881398,
            lng: 35.0062859,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "PHI",
      technology: "3G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8905214,
            lng: 35.0641499,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.890129,
            lng: 35.0653518,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "PHI",
      technology: "4G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8905214,
            lng: 35.0641499,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.890129,
            lng: 35.0653518,
          },
          signal: -73,
        },
      ],
    },
    {
      provider: "PHI",
      technology: "5G",
      date: "11-2021",
      polygon: [],
      bins: [
        {
          location: {
            lat: 31.8905214,
            lng: 35.0641499,
          },
          signal: -73,
        },
        {
          location: {
            lat: 31.890129,
            lng: 35.0653518,
          },
          signal: -73,
        },
      ],
    },
  ],
  mapLayersParams: [
    {
      _id: "61c2f32cb6218800086aa559",
      mapLayer: "RSSI - Coverage Optimized",
      projectParams: {
        _id: "61c2f32bb6218800086aa558",
        devParams: {
          samplesReliability: 95,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSSI - Coverage Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:07.953Z",
        lastModified: "2021-12-22T09:43:07.953Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:07.955Z",
        updatedAt: "2021-12-22T09:43:07.955Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa55b",
      mapLayer: "RSSI - Capacity Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa55a",
        devParams: {
          samplesReliability: 5,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSSI - Capacity Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.041Z",
        lastModified: "2021-12-22T09:43:08.041Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.042Z",
        updatedAt: "2021-12-22T09:43:08.042Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa55d",
      mapLayer: "MCS - Coverage Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa55c",
        devParams: {
          samplesReliability: 95,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "MCS - Coverage Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.048Z",
        lastModified: "2021-12-22T09:43:08.048Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.049Z",
        updatedAt: "2021-12-22T09:43:08.049Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa55f",
      mapLayer: "MCS - Capacity Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa55e",
        devParams: {
          samplesReliability: 5,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "MCS - Capacity Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.062Z",
        lastModified: "2021-12-22T09:43:08.062Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.063Z",
        updatedAt: "2021-12-22T09:43:08.063Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa561",
      mapLayer: "Bit Rate - Coverage Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa560",
        devParams: {
          samplesReliability: 95,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "Bit Rate - Coverage Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.078Z",
        lastModified: "2021-12-22T09:43:08.078Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.079Z",
        updatedAt: "2021-12-22T09:43:08.079Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa563",
      mapLayer: "Bit Rate - Capacity Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa562",
        devParams: {
          samplesReliability: 5,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "Bit Rate - Capacity Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.084Z",
        lastModified: "2021-12-22T09:43:08.084Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.085Z",
        updatedAt: "2021-12-22T09:43:08.085Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa565",
      mapLayer: "RSRP - Coverage Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa564",
        devParams: {
          samplesReliability: 95,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSRP - Coverage Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.090Z",
        lastModified: "2021-12-22T09:43:08.090Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.091Z",
        updatedAt: "2021-12-22T09:43:08.091Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa567",
      mapLayer: "RSRP - Capacity Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa566",
        devParams: {
          samplesReliability: 5,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSRP - Capacity Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.094Z",
        lastModified: "2021-12-22T09:43:08.094Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.095Z",
        updatedAt: "2021-12-22T09:43:08.095Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa569",
      mapLayer: "RSRQ - Coverage Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa568",
        devParams: {
          samplesReliability: 95,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSRQ - Coverage Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.098Z",
        lastModified: "2021-12-22T09:43:08.098Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.099Z",
        updatedAt: "2021-12-22T09:43:08.099Z",
        __v: 0,
      },
    },
    {
      _id: "61c2f32cb6218800086aa56b",
      mapLayer: "RSRQ - Capacity Optimized",
      projectParams: {
        _id: "61c2f32cb6218800086aa56a",
        devParams: {
          samplesReliability: 5,
          binResolution: 20,
          binReliability: 5,
          polarizationLoss: 3,
          systemGain: 16,
          minCoverage: 80,
        },
        mapLayer: "RSRQ - Capacity Optimized",
        technology: "5G-TDD",
        frequencyBand: 3600,
        channelBW: 60,
        rssiThreshold: -95,
        downUpLinkRatio: 75,
        antennaPolarization: "Dual Slant 45deg",
        project: "61c2f32bb6218800086aa557",
        created: "2021-12-22T09:43:08.102Z",
        lastModified: "2021-12-22T09:43:08.102Z",
        legendTable: [],
        createdAt: "2021-12-22T09:43:08.103Z",
        updatedAt: "2021-12-22T09:43:08.103Z",
        __v: 0,
      },
    },
  ],
  createdAt: "2021-12-22T09:43:07.891Z",
  updatedAt: "2021-12-22T09:46:00.874Z",
  __v: 1,
  sitesImage: null,
};
