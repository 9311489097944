
import { Col, Row, Container } from "react-bootstrap"
import BarRechartComponent from "./barcharts/bar.recharts";
import AreachartComponent from "./areacharts/area.rechart";
import LinechartComponent from "./linecharts/line.rechart";
import LinechartComponentMedian from "./linechartsForMedian/line.rechart";

import { yearData } from "./mockData";

function CoverageReliability({ reliabilityData, monthsSequence }) {
    return (<>
        <Container fluid  style={{ textAlign: "center" }} >
            {/* <div style={{ textAlign: "center" }}>
                <h2 style={{ textDecoration: "underline" }} > Coverage Reliability</h2>
            </div> */}
            <Row >
                <Col md={12}lg={6}xl={4} > <h4> Cellcom-4G</h4> <LinechartComponent monthsSequence={monthsSequence} lineData={reliabilityData.filter(placement => placement.provider === "cellcom" && placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date }))} /> </Col> 
                <Col md={12}lg={6}xl={4}>  <h4> Partner-4G</h4><LinechartComponent monthsSequence={monthsSequence} lineData={reliabilityData.filter(placement => placement.provider === "partner" && placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date }))} /> </Col> 
                <Col md={12}lg={6}xl={4}> <h4> Pelephone-4G</h4> <LinechartComponent monthsSequence={monthsSequence} lineData={reliabilityData.filter(placement => placement.provider === "pelephone" && placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date }))} /> </Col>
                {/* <Col md={12}lg={6}xl={3}>  <h4> Partner-4G</h4><LinechartComponentMedian monthsSequence={monthsSequence} lineData={reliabilityData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date , provider: placement.provider }))} /> </Col> */}


            </Row>
            <Row >
            <Col md={{ span: 4, offset: 3 }} lg={6}xl={3}>  <h4> Partner-4G</h4><LinechartComponentMedian monthsSequence={monthsSequence} lineData={reliabilityData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date , provider: placement.provider }))} /> </Col>

                {/* <Col md={3}>  <LinechartComponent lineData={reliabilityData.filter(placement => placement.provider === "cellcom" && placement.technology==="5G" ).map((placement) => ({ placements: placement.bins, date: placement.dateRange.date}))}  /> </Col>
            <Col md={3}>  <LinechartComponent lineData={reliabilityData.filter(placement => placement.provider === "pelephone" && placement.technology==="5G" ).map((placement) => ({ placements: placement.bins, date: placement.dateRange.date}))}  /> </Col>
            <Col md={3}>  <LinechartComponent lineData={reliabilityData.filter(placement => placement.provider === "partner" && placement.technology==="5G" ).map((placement) => ({ placements: placement.bins, date: placement.dateRange.date}))}  /> </Col> */}
            </Row>
        </Container>
    </>
    );
}
export default CoverageReliability;

