import React, {useState, useEffect} from "react";
import {MarkerClusterer, Marker as GoogleMarker, useGoogleMap} from "@react-google-maps/api";
import Marker from "./Marker";
import {useMapZoom} from "../../Hooks/map";
import {isLatLngInBounds} from "../../controller/dataOperations";
// C:\Users\aviad\Documents\GitHub\flycomm\Smart Cities\smart-cities3-react\src\components\Map\SitesClusterer.js
const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function filterByMarker(mapZoom) {
    return (site) => {
        if (mapZoom > 15) return true;
        return true;
    }
}

function inScope(mapBounds) {
    console.log('inscope function inside SiteCluster.js')
    return site => mapBounds ? isLatLngInBounds(site.location, mapBounds) : false;
}

function SitesClusterer({sites, chosenSites, mapBounds}) {
    const map = useGoogleMap();
    const mapZoom = useMapZoom();
    const sitesInScope = sites.filter(inScope(mapBounds));
    const markerSites = sites.filter(filterByMarker(mapZoom, mapBounds));
    const showOverlays = Boolean(sitesInScope.length < 10);

    console.log('sitesInScope:', sitesInScope);
    console.log('mapBounds:',mapBounds)
    function positionMap(location) {
        map.setCenter(location);
        map.setZoom(Math.max(mapZoom + 1, 15));
    }

    useEffect(()=>{
        console.log('useeffect mapBounds',mapBounds)
    },[mapBounds])

    return <>
        {/* <MarkerClusterer>
            {(clusterer) => !showOverlays && markerSites.map((site, i) => {
                return <GoogleMarker
                    onClick={() => positionMap(site.location)}
                    clusterer={clusterer}
                    position={site.location}
                />
            })}
        </MarkerClusterer> */}


        <MarkerClusterer>
            {(clusterer) => sites.map((site, i) => {
                return <GoogleMarker
                    onClick={() => positionMap(site.location)}
                    clusterer={clusterer}
                    position={site.location}
                />
            })}
        </MarkerClusterer>


        {/* { showOverlays && sites.map((site) => {
            
            return( <Marker
                key={site.location.lat + ',' + site.location.lng}
                // title={chosenSites ? (i === 0 ? 'Main' : 'Secondary') : ''}
                site={site}
            />
        )})} */}

    </>
}

SitesClusterer = React.memo(SitesClusterer);
export default SitesClusterer;