
import { Col, Row, Container } from "react-bootstrap"
import BarRechartComponent from "./barcharts/bar.recharts";
import AreachartComponent from "./areacharts/area.rechart";
import AreachartComponent2 from "./areacharts2/area.rechart";


function CoveragePreformence({ preformenceData }) {
    return (<>
        <Container fluid style={{ textAlign: "center", paddingRight: 0 }}>
            {/* <div >
                <h2 style={{textDecoration: "underline" }} > Coverage Preformence</h2>
            </div> */}
            <Row >  
                <Col md={12}lg={6}xl={4}>   <h4  > Cellcom-4G</h4>  <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "cellcom" && placement.technology === "4G").map(placement => placement.bins)[0]} />   </Col>
                <Col md={12}lg={6}xl={4}>  <h4  > Pelephone-4G</h4> <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "pelephone" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col>
                <Col md={12}lg={6}xl={4}>   <h4  > Partner-4G</h4>  <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "partner" && placement.technology === "4G").map(placement => placement.bins)[0]} /> </Col> 
                {/* <Col md={12}lg={6}xl={3}> <h4  > Distribution</h4>  <AreachartComponent2 areaData={preformenceData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, provider: placement.provider }))} /> </Col> */}

            </Row>
            <Row  >
            <Col md={{ span: 4, offset: 3 }} lg={6}xl={3}> <h4  > Distribution</h4>  <AreachartComponent2 areaData={preformenceData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, provider: placement.provider }))} /> </Col>
            {/* <Col md={{ span: 4, offset: 4 }} lg={6}xl={3}>  </Col>
            <Col md={{ span: 4, offset: 4 }} lg={6}xl={3}>  </Col> */}

                {/* <Col md={3}>  <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "cellcom" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>
                 <Col md={3}>  <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "pelephone" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>
                 <Col md={3}>  <BarRechartComponent barData={preformenceData.filter(placement => placement.provider === "partner" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>
                <Col md={3}>  <AreachartComponent areaData={preformenceData.filter(placement => placement.provider === "cellcom" && placement.technology==="5G" ).map(placement => placement.bins)[0]}  /> </Col>  */}
            </Row>
        </Container>
    </>
    );
}
export default CoveragePreformence;

