export const allSamples = [
  {
    location: {
      lat: 31.8777953,
      lng: 34.9974288,
    },
    signal: -95,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779935,
      lng: 34.9974973,
    },
    signal: -95,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8778187,
      lng: 35.005453,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779464,
      lng: 35.0052783,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8783458,
      lng: 35.0047406,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8789156,
      lng: 35.0039682,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8787746,
      lng: 35.004162,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8782093,
      lng: 35.0049221,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8776901,
      lng: 35.00563,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8786308,
      lng: 35.0043589,
    },
    signal: -105,
    provider: "Cellcom",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8536345,
      lng: 35.0007111,
    },
    signal: -61,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8536027,
      lng: 35.0005193,
    },
    signal: -61,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8536657,
      lng: 35.0008979,
    },
    signal: -61,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8536345,
      lng: 35.0007111,
    },
    signal: -61,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8534455,
      lng: 34.9995551,
    },
    signal: -53,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8584281,
      lng: 35.0016311,
    },
    signal: -55,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8568906,
      lng: 35.0026558,
    },
    signal: -55,
    provider: "Cellcom",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8677339,
      lng: 35.0020871,
    },
    signal: -91,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8679489,
      lng: 35.0019477,
    },
    signal: -91,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.869527,
      lng: 35.0004116,
    },
    signal: -73,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8702102,
      lng: 34.999558,
    },
    signal: -73,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8703396,
      lng: 34.9993981,
    },
    signal: -73,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.86971,
      lng: 35.0001807,
    },
    signal: -73,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8707388,
      lng: 34.9988932,
    },
    signal: -57,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.871539,
      lng: 34.9978916,
    },
    signal: -65,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8709468,
      lng: 34.9986295,
    },
    signal: -57,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.871151,
      lng: 34.9983725,
    },
    signal: -65,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -107,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -113,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -107,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -113,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -111,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -107,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -107,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -113,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8779862,
      lng: 35.0062984,
    },
    signal: -111,
    provider: "PHI",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.849325,
      lng: 34.9932403,
    },
    signal: -79,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.853102,
      lng: 34.9969857,
    },
    signal: -69,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8531832,
      lng: 34.9975909,
    },
    signal: -69,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8534237,
      lng: 34.9993818,
    },
    signal: -55,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8533686,
      lng: 34.9989808,
    },
    signal: -55,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8535151,
      lng: 34.9999539,
    },
    signal: -55,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8532597,
      lng: 34.9981864,
    },
    signal: -69,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8541241,
      lng: 35.0035477,
    },
    signal: -63,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8541241,
      lng: 35.0035477,
    },
    signal: -63,
    provider: "PHI",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.890129,
      lng: 35.0653518,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8905214,
      lng: 35.0641499,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.890629,
      lng: 35.0638385,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8907415,
      lng: 35.0635254,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8908558,
      lng: 35.0632021,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8909701,
      lng: 35.0628737,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8910871,
      lng: 35.0625377,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8912055,
      lng: 35.0621983,
    },
    signal: -97,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8913242,
      lng: 35.0618484,
    },
    signal: -103,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8914477,
      lng: 35.0614869,
    },
    signal: -103,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8915744,
      lng: 35.0611179,
    },
    signal: -103,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8926075,
      lng: 35.0584679,
    },
    signal: -103,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8927753,
      lng: 35.0581312,
    },
    signal: -103,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8929375,
      lng: 35.0578048,
    },
    signal: -101,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8930935,
      lng: 35.0574996,
    },
    signal: -101,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8932425,
      lng: 35.0572086,
    },
    signal: -101,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8933764,
      lng: 35.0569545,
    },
    signal: -101,
    provider: "Pelephone",
    tech: "3G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8888048,
      lng: 35.080502,
    },
    signal: -65,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8888048,
      lng: 35.080502,
    },
    signal: -65,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8890212,
      lng: 35.0802272,
    },
    signal: -65,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8892458,
      lng: 35.0799522,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8894683,
      lng: 35.079681,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8896955,
      lng: 35.0794051,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8901307,
      lng: 35.0788454,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8903144,
      lng: 35.0785467,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8904797,
      lng: 35.0782322,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8906276,
      lng: 35.0779104,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8907631,
      lng: 35.0775681,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8908825,
      lng: 35.0772039,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8909771,
      lng: 35.0768347,
    },
    signal: -69,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
  {
    location: {
      lat: 31.8910467,
      lng: 35.0764615,
    },
    signal: -67,
    provider: "Pelephone",
    tech: "4G",
    date: "11-2021",
  },
].map((el) => ({location: el.location, signal: el.signal, provider: el.provider, date: el.date, technology: el.tech}));
