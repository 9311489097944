import { median } from 'mathjs';

//optimize
const math = require('mathjs');

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = { "count": 0, "list": [] }
    }
    acc[key].list.push(obj)
    acc[key].count++
    return acc
  }, [])
}

function getRange(key) {
  switch (true) {
    case (key <= 0 && key >= -20):
      return 0;
    case (key < -20 && key >= -40):
      return 1;
    case (key < -40 && key >= -60):
      return 2;
    case (key < -60 && key >= -80):
      return 3;
    case (key < -80 && key >= -100):
      return 4;
    case (key < -100 && key >= -120):
      return 5;
    default:
      return -1
  }

}

// export function groupByRange(objectArray) {
//   return objectArray.reduce(function (acc, obj) {
//     var key = obj[0]
//     var range = getRange(key)
//     if (!acc[range]) {
//       acc[range] = { "count": 0, "list": [] }
//     }
//     acc[range].list.push(obj[1].list)
//     acc[range].count += obj[1].count
//     return acc
//   }, [])
// }
export function countLessThenAndAll(group, dbm) {
  var count = 0;
  var countAll = 0
  for (const [key, value] of Object.entries(group)) {
    countAll += value.count;
    if (key <= dbm)
      count += value.count;
  }
  return { count, countAll };

}

// export function countAll(group, dbm) {
//   var count = 0;
//   for (const [key, value] of Object.entries(group)) {
//       count++;
//   }
//   return count;

// }
// export function sumAllKeysValue(groupedBySignalRange) {
//   var sum = 0;
//   for (const [key, value] of Object.entries(groupedBySignalRange)) {
//     console.log(key, value);
//     sum += value.count;
//   }
//   return sum;
// }

export function toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, data) {
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);
    if (key != "-1")
      data[key].dBm = (value.count / sumOfAllKeysValue) * 100;
  }
}


export function calcMedian(listOfAllSignals) {
  if (listOfAllSignals.length === 0)
    return 0;
  let median = math.median(listOfAllSignals)
  return median;
}