const lineData1 = [[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

],
[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

],
[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

],
[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

],
[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

],
[
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -60
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -80
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

], [
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845789433333334,
      "lng": 34.99167886666667
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.845972525,
      "lng": 34.9917041
    },
    "signal": -77
  },
  {
    "location": {
      "lat": 31.84615905,
      "lng": 34.991788075
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.846331185714288,
      "lng": 34.99183131428571
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84657053333333,
      "lng": 34.991930800000006
    },
    "signal": -97
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -100
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -120
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },
  {
    "location": {
      "lat": 31.84564325,
      "lng": 34.99165005
    },
    "signal": -20
  },

]
]
// ]
// const month = [...Array(30).keys()].map(m => {
//   "location": {
//     "lat": 31.84564325,
//     "lng": 34.99165005
//   },
//   "signal": -20
// }
















module.exports = { lineData1 }
