import {SET_CURRENT_USER,LOGOUT_USER} from "../actionTypes";
import {logoutUser, setUser} from "../actions/user";

const DEAFAULT_STATE = {
    isAuthenticated: false,
    user: {
        email:'',
        groups:[],
        //terms:''  ??
    },
    idToken:''
};

const userReducer = (state = DEAFAULT_STATE, action)=>{
    switch (action.type) {
        case SET_CURRENT_USER:
            return {...state,...setUser(action)};
        case LOGOUT_USER:
            return {...state,...logoutUser()};
        default:
            return state;
    }
};

export default userReducer;