// src/components/line.rechart.js

import React from "react";
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { lineData1 } from './data'
import { groupBy, countLessThen, toDataStructureAsPrecent, countLessThenAndAll } from "./helper";


// var groupedBySignal = groupBy(lineData1[0], 'signal')
// var { count, countAll } = countLessThenAndAll(groupedBySignal, -75)
// data[0].dBm = (count / countAll) * 100

// var groupedBySignal = groupBy(lineData1[1], 'signal')
// var { count, countAll } = countLessThenAndAll(groupedBySignal, -75)
// data[1].dBm = (count / countAll) * 100
// debugger;

// function randn_bm() {
//     var u = 0, v = 0;
//     while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
//     while (v === 0) v = Math.random();
//     return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
// }
// function randn_bm() {
//     let u = 0, v = 0;
//     while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
//     while (v === 0) v = Math.random();
//     let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
//     num = num / 10.0 + 0.5; // Translate to 0 -> 1
//     if (num > 1 || num < 0) return randn_bm() // resample between 0 and 1
//     return num
// }
// for (let index = 0; index < 10; index++) {
//     data.push(
//         {
//             "month": "Jan 2019",
//             "Product A": randn_bm(),
//             "Procuct B": 2342
//         })

// }


export default function LinechartComponent({ lineData, monthsSequence }) {
    const dataStruct = [
        {
            month: monthsSequence[0].split("-")[1],
            year: monthsSequence[0].split("-")[0]
            // dBm: 70,
        },
        {
            month: monthsSequence[1].split("-")[1],
            year: monthsSequence[1].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[2].split("-")[1],
            year: monthsSequence[2].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[3].split("-")[1],
            year: monthsSequence[3].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[4].split("-")[1],
            year: monthsSequence[4].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[5].split("-")[1],
            year: monthsSequence[5].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[6].split("-")[1],
            year: monthsSequence[6].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[7].split("-")[1],
            year: monthsSequence[7].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[8].split("-")[1],
            year: monthsSequence[8].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[9].split("-")[1],
            year: monthsSequence[9].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[10].split("-")[1],
            year: monthsSequence[10].split("-")[0]

            // dBm: 70,
        },
        {
            month: monthsSequence[11].split("-")[1],
            year: monthsSequence[11].split("-")[0]

            // dBm: 70,
        },
        // {
        //     month: monthsSequence[12].split("-")[1],
        //     // dBm: 70,
        // },


    ];
    function extractData(linData) {
        if (linData !== undefined) {
            lineData.forEach(function (item, index) {
                console.log(item.placements, index);
                const month = item.date.split("-")[1]
                var groupedBySignal = groupBy(item.placements, 'signal')
                debugger

                var { count, countAll } = countLessThenAndAll(groupedBySignal, -95)
                // dataStruct[month].dBm = (count / countAll) * 100
                dataStruct[dataStruct.findIndex(object => { return object.month === month })].dBm = (count / countAll) * 100

            });
            return dataStruct;
        }
    }
    function formatDate(month) {
        if (month !== undefined) {
            const year = dataStruct[dataStruct.findIndex(object => { return object.month === month })].year.slice(2, 4);
            return `${month}/${year}`
        }
    }
    function formatTicks(t) {
        if (t !== undefined) {
            return dataStruct.map(a => a.month);
        }
    }

    //ticks={(t) =>  formatTicks(t)}
    //textAnchor="end" fill="#666" transform="rotate(-35)"
    //angle={-30} 
    //x={0} y={0} dy={16}  textAnchor="end" fill="#666" transform="rotate(-35)"
    return (
        <ResponsiveContainer width="100%" height={280}>

            <LineChart width={580} height={300} data={extractData(lineData) || []}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"month"} tickFormatter={(m) => formatDate(m)} >
                    {/* <Label value="<-75" offset={-10} position="insideBottom" /> */}
                </XAxis>
                {/* <YAxis  dataKey ={"dBm"} type="number" domain={[dataMin => (Math.ceil(dataMin)-2) , 100]} /> */}
                <YAxis dataKey={"dBm"} label={{ value: 'percent %', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} type="number" domain={[0, 100]} />
                <Tooltip />
                <Legend iconType="circle" />
                <Line type="monotone" dataKey="dBm" stroke="#0095FF" />
                {/* <Line type="monotone" dataKey="Procuct B" stroke="#FF0000" /> */}
            </LineChart>
        </ResponsiveContainer>

    );
}

// ticks={[0, 1, 2, 3, 4 , 5, 6, 7, 8, 9, 10, 11, 12]}
//dataKey={"month"} type="number" 